import React from 'react'
import { BackBtn } from '../components/Buttons/BackBtn/BackBtn'
import { Create } from '../components/Forms/Team/Create'

export const TeamCreate = () => {
	return (
		<section className="section-lk">
			<div className="lk-cols">
				<div className="lk-col lk-col__team">
					<div className="lk-container">
						<div className="lk-container__title-primary">
							<BackBtn className="lk-container__back-btn" />
							<h4 className="lk-container-title h4">Новая команда</h4>
						</div>
						<div className="lk-container__content">
							<Create />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
