import React, { useState } from 'react'
import { VariantsItem } from './VariantsItem.jsx'

export const Variants = ({ variants }) => {
	return (
		<div className="variants-points">
			<div className="variants-points__body">
				{variants.map((variant) => (
					<VariantsItem
						key={variant.id}
						title={variant.title}
						text={variant.text}
						icon={variant.icon}
						button={variant.button}
						isComplete={variant.isComplete}
					/>
				))}
			</div>
		</div>
	)
}
