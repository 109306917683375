import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { routeNames } from '../../../router'

import ReactTooltip from 'react-tooltip'

import { normalizedImageSrc } from '../../../utils/helpers'

import { Swiper, SwiperSlide } from 'swiper/react'

import { ReactComponent as NoteIcon } from '../../../static/img/icons/gift-icon-white.svg'
import { ReactComponent as CheckIcon } from '../../../static/img/icons/check-icon-white.svg'
import Logo1 from '../../../static/img/titles/disabled_01.png'
import Logo2 from '../../../static/img/titles/disabled_02.png'
import Logo3 from '../../../static/img/titles/disabled_03.png'
import Logo4 from '../../../static/img/titles/disabled_04.png'
import Logo5 from '../../../static/img/titles/disabled_05.png'
import Logo6 from '../../../static/img/titles/disabled_06.png'
import Logo7 from '../../../static/img/titles/disabled_07.png'
import Logo8 from '../../../static/img/titles/disabled_08.png'
import Logo9 from '../../../static/img/titles/disabled_09.png'
import Logo10 from '../../../static/img/titles/disabled_10.png'
import { useSelector } from 'react-redux'
import { declOfNum } from '../../../utils/helpers'

function calcImage(title) {
	const begin = process.env.REACT_APP_IMAGES_URL
	switch (title.status) {
		case 'active':
			return begin + title.image_active

		case 'disabled':
			return begin + title.image_disabled

		case 'done':
			return begin + title.image_done

		default:
			return begin + title.image_disabled
	}
}

const titlesMock = [
	{
		id: 1,
		status: 'disabled',
		image: Logo1,
		title: 'Wooden',
		points: 50,
		sale: 0,
		gift: false,
	},
	{
		id: 2,
		status: 'disabled',
		image: Logo2,
		title: 'Iron',
		points: 300,
		sale: 0,
		gift: true,
	},
	{
		id: 3,
		status: 'disabled',
		image: Logo3,
		title: 'Bronze',
		points: 500,
		sale: 5,
		gift: true,
	},
	{
		id: 4,
		status: 'disabled',
		image: Logo4,
		title: 'Silver',
		points: 1000,
		sale: 10,
		gift: false,
	},
	{
		id: 5,
		status: 'disabled',
		image: Logo5,
		title: 'Golden',
		points: 1500,
		sale: 15,
		gift: true,
	},
	{
		id: 6,
		status: 'disabled',
		image: Logo6,
		title: 'Platinum',
		points: 2000,
		sale: 20,
		gift: false,
	},
	{
		id: 7,
		status: 'disabled',
		image: Logo7,
		title: 'Diamond',
		points: 2500,
		sale: 25,
		gift: true,
	},
	{
		id: 8,
		status: 'disabled',
		image: Logo8,
		title: 'Bronze Veteran',
		points: 3000,
		sale: 30,
		gift: false,
	},
	{
		id: 9,
		status: 'disabled',
		image: Logo9,
		title: 'Silver Veteran',
		points: 4000,
		sale: 0,
		gift: true,
	},
	{
		id: 10,
		status: 'disabled',
		image: Logo10,
		title: 'Gold Veteran',
		points: 6000,
		sale: 0,
		gift: true,
	},
]

export const Titles = () => {
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	const [hasTitle, sethasTitle] = useState({})

	React.useEffect(() => {
		ReactTooltip.rebuild()
	}, [])

	useEffect(() => {
		userState.title_id ? sethasTitle(userState.titles?.filter((t) => t.id === userState.title_id)[0]) : sethasTitle({})
	}, [userState])

	const titlesHint = `
		<div class="react-tooltip__hint lk-titles__head-hint">
			<p class="react-tooltip__hint-text p8">Персональная и командная скидки суммируются</p>
			<ul class="react-tooltip__hint-list">
				<li class="react-tooltip__hint-list-item p7">
					Ваша скидка <span class="p12">${userState.total_sale ? userState.total_sale : 0}%</span>
				</li>
				<li class="react-tooltip__hint-list-item p7">
					Скидка команды <span class="p12">${userState.team?.sale || 0}%</span>
				</li>
			</ul>
		</div>
	`

	const titlesHintNotCommander = `
		<div class="react-tooltip__hint lk-titles__head-hint">
			<div class="react-tooltip__hint-list-item p7">
				Ваша скидка <span class="p12">${userState?.sale ? userState?.sale : 0}%</span>
			</div>
		</div>
	`

	return (
		<div className="lk-container lk-container__titles">
			<div className="lk-container__title">
				<div className="lk-container__title-part">
					<h4 className="lk-container-title h4">
						{hasTitle.id ? `Ваш опыт «${hasTitle.title}»` : 'У вас пока нет опыта'}
					</h4>
					<h6 className="lk-container-title-second --colored h7">
						{userState.point > 0
							? userState.point + ' ' + declOfNum(userState.point, ['балл', 'балла', 'баллов'])
							: '0 баллов'}
					</h6>
					<h6 className="lk-container-title-second --colored h8" style={{fontWeight:'600', marginTop:'16px', fontSize:'12px'}}>
						<a href={'https://privatkaclub.ru/system'}>
						{userState.all_points > 0
							? userState.all_points + ' ' + declOfNum(userState.all_points, ['балл', 'балла', 'баллов'])
							: '0 баллов'}
						</a>
					</h6>
				</div>
				<div className="lk-container__title-part">
					<NavLink
						to={routeNames.POINTS.path}
						className="lk-container-link p7"
						title="Кнопка история начисления баллов"
					>
						История начисления баллов
					</NavLink>
				</div>
			</div>
			<div className="lk-container__content">
				<div className="lk-titles">
					<div className="lk-titles__head">
						<span className="lk-titles__head-text p9">
							{userState?.team?.commander_id !== userState.id ? userState?.sale : userState.total_sale}% моя скидка
						</span>
						<div className="lk-titles__head-hint hint-box">
							<span
								className="hint-box__head"
								title="подсказка"
								data-tip={userState?.team?.commander_id == userState.id ? titlesHint : titlesHintNotCommander}
								data-html={true}
								data-for="myTooltip"
							/>
						</div>
					</div>

					<div className="lk-titles__body">
						{userState?.titles.length > 0 ? (
							<Swiper className="lk-titles__swiper" slidesPerView={'auto'} freemode={'true'}>
								{userState.titles.map((title) => (
									<SwiperSlide key={title.id} className={'lk-titles__item ' + title.status}>
										<div>
											{/* <img className="lk-titles__item-logo" src={normalizedImageSrc(title.image)} alt="title-icon" title="иконка звания" /> */}
											<img
												className="lk-titles__item-logo"
												src={calcImage(userState.guest ? { ...title, status: 'disabled' } : title)}
												alt="title-icon"
												title="иконка звания"
											/>
											<div className="lk-titles__item-check">
												<CheckIcon className="lk-titles__item-check-icon" />
											</div>
											<div className="lk-titles__item-title p7">{title.title}</div>
											<div className="lk-titles__item-points p12">{title.points}</div>
											<div className="lk-titles__item-sub p16">
												{title.sale > 0 && <>СКИДКА {title.sale}%</>}
												{title.gift && <span>( +подарок )</span>}
											</div>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						) : (
							<div className="lk-container-empty__text p4">Пока нет титулов.</div>
						)}
					</div>

					<div className="lk-titles__footer">
						{hasTitle.id ? (
							<>
								<div className="lk-titles__footnote lk-footnote">
									<div className="lk-footnote__image">
										<NoteIcon className="lk-footnote__icon" />
									</div>
									<div className="lk-footnote__text p7">При повышении уровня вы получите подарок на следующей игре</div>
								</div>
							</>
						) : (
							<div />
						)}

						<a
							href={process.env.REACT_APP_FRONTEND_URL + 'system'}
							className="lk-container-link p7"
							title="Кнопка бально-рейтинговая система клуба"
						>
							Бально-рейтинговая система клуба
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
