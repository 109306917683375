import React from 'react'
import EmptyBlock from '../EmptyBlock'
import Hourglass from '../../static/img/icons/EmptyBlock/hourglass.svg'
import { PastGamesList } from './PastGamesList'
import { LoaderMini } from '../LoaderMini'
import {
	getTeamProfileGamesPassed,
	getProfileGamesPassed,
	getTeamProfileGames,
	getUserGamesPassed, getTeamGamesPassed
} from '../../services/Config'
import { dateForRequest } from '../../utils/helpers'
import { Pagination } from '../Pagination/Pagination'

export const PastGames = ({ team, anotherId }) => {
	const defaultLimit = 4
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [games, setGames] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const fetchGames = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			if (!anotherId) {
				const res = team
					? await getTeamProfileGames(limit, offset)
					: await getProfileGamesPassed(offset, limit, null, dateForRequest(new Date(Date.now())), 'desc')
				setGames(res.data)
			} else {
				const res =  team
					? await getTeamGamesPassed(team, limit, offset)
					: await getUserGamesPassed(anotherId, limit, offset)
				setGames(res.data)
			}
		} catch (error) {
			console.log('fetchGames error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		fetchGames()
	}, [page])

	const changePage = (num) => {
		setPage(num)
	}

	return (
		<div className="lk-container past-games-container">
			<div className="lk-container__title-primary">
				<h4 className="lk-container-title h4">Прошедшие игры</h4>
			</div>
			<div className="lk-container__content">
				{isLoad ? (
					<LoaderMini />
				) : team && games?.list?.length > 0 ? (
					<>
						<PastGamesList events={games?.list} />
						{games?.total_count > defaultLimit && (
							<div className="pagination">
								<Pagination changePage={changePage} page={page} length={games?.total_count} limit={defaultLimit} />
								<div className="pagination__count p13">
									Показано {games?.list?.length} из {games?.total_count}
								</div>
							</div>
						)}
					</>
				) : games?.list?.length > 0 ? (
					<>
						<PastGamesList events={games?.list} />
						{games?.total_count > defaultLimit && (
							<div className="pagination">
								<Pagination changePage={changePage} page={page} length={games?.total_count} limit={defaultLimit} />
								<div className="pagination__count p13">
									Показано {games?.list?.length} из {games?.total_count}
								</div>
							</div>
						)}
					</>
				) : (
					<EmptyBlock
						img={Hourglass}
						title={'Вы не посетили игры'}
						text={'Все посещенные вами мероприятия будут отображаться здесь'}
						buttonText={'Мероприятия'}
						buttonLink={process.env.REACT_APP_FRONTEND_URL + 'payment'}
					/>
				)}
			</div>
		</div>
	)
}
