import React from 'react'
import ReactDOM from 'react-dom'
import { overflowHidden, overflowVisible } from '../../utils/helpers'

export const Portal = ({ children }) => {
	const [container] = React.useState(() => document.createElement('div'))

	React.useEffect(() => {
		overflowHidden()
		document.body.querySelector('.parent-wrapper').appendChild(container)
		return () => {
			overflowVisible()
			document.body.querySelector('.parent-wrapper').removeChild(container)
		}
	}, [])
	return ReactDOM.createPortal(children, container)
}
