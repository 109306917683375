import React from 'react'

import desc from '../static/img/404_desc.jpg'
import ipad from '../static/img/404_1024.jpg'
import ipadMini from '../static/img/404_768.jpg'
import mobile from '../static/img/404_375.jpg'
import { Button } from './Buttons/Button'

export const NotFound = ({ primaryTitle, title, text, btnClassNames, btnHref, btnText }) => {
	return (
		<div className="section-notfound">
			<div className="section-notfound__bg">
				<picture>
					<source media="(max-width: 1279px) and (min-width:1024px)" srcSet={ipad} />
					<source media="(max-width: 1023px) and (min-width:768px)" srcSet={ipadMini} />
					<source media="(max-width: 767px)" srcSet={mobile} />
					<img src={desc} title="privatka" alt="privatka" layout="fill" />
				</picture>
			</div>
			<div className="grid">
				<div className="section-notfound__cont">
					<h1 className="section-notfound__title h2">
						{primaryTitle && <span>{primaryTitle}</span>} {title}
					</h1>
					<p className="section-notfound__text p4">{text}</p>
					<Button className={`section-notfound__btn ${btnClassNames}`} type="navlink" href={btnHref} text={btnText} />
				</div>
			</div>
		</div>
	)
}
