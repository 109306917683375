import React from 'react'

import { TeamCard } from '../TeamCard'
import { Pagination } from '../../Pagination/Pagination'
import {
	addTeamFriendFromRequest,
	deleteTeamFriendFromRequest,
	getTeamProfileFriendsRequests,
} from '../../../services/Config'
import { LoaderMini } from '../../LoaderMini'
import { notifyUser, notifyUserError } from '../../../services/Pnotify'
import { useSelector } from 'react-redux'

export const FriendsRequests = ({ reRender, setCount }) => {
	const defaultLimit = 4
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamFriends, setTeamFriends] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	const fetchFriends = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamProfileFriendsRequests(limit, offset)
			setTeamFriends(res.data)
		} catch (error) {
			console.log('fetchFriends error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		fetchFriends()
	}, [])

	React.useEffect(() => {
		if (reRender) fetchFriends()
	}, [reRender, page])

	React.useEffect(() => {
		setCount(teamFriends.total_count)
	}, [setCount, teamFriends.total_count])

	const changePage = (num) => {
		setPage(num)
	}

	const confirmAssist = async (id) => {
		try {
			const res = await addTeamFriendFromRequest(id)
			fetchFriends()
		} catch (error) {
			notifyUserError(`Ошибка принятия команды в друзья`)
		}
	}

	const rejectAssist = async (id) => {
		try {
			const res = await deleteTeamFriendFromRequest(id)
			fetchFriends()
		} catch (error) {
			notifyUserError(`Ошибка при отклонении заявки в друзья, id команды -` + id)
		}
	}
	return isLoad ? (
		<LoaderMini />
	) : (
		<>
			{teamFriends?.list?.length > 0 ? (
				<div className="teams__cards">
					{teamFriends?.list?.map((team) => (
						<TeamCard
							onConfirm={confirmAssist}
							onReject={rejectAssist}
							key={team.id}
							team={team}
							fromFriendsRequests="true"
							captain={userState.team.commander_id === userState.id}
						/>
					))}
				</div>
			) : (
				<div className="lk-container__friends-empty lk-container-empty">
					<div className="lk-container-empty__text p4">У вас пока нет заявок от команд.</div>
				</div>
			)}
			{teamFriends.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={teamFriends?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {teamFriends?.list?.length} из {teamFriends?.total_count}
					</div>
				</div>
			)}
		</>
	)
}
