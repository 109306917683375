import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import SIcon from "../../static/img/lk/website-48.svg";

export default function SocialItem({ text, icon, type = 'open' }) {
	const elem = React.useRef()
	const [copied, setCopied] = React.useState(false)
	const onCopy = () => {
		setCopied(true)
		setTimeout(() => {
			setCopied(false)
		}, 3000)
	}

	const clickOutsideCb = () => {
		return setCopied(false)
	}

	useOnClickOutside(elem, clickOutsideCb)

	if (type === 'copy') {
		return (
			<CopyToClipboard onCopy={onCopy} text={text}>
				<li ref={elem} className="user-sideinfo__link">
					<div className="user-sideinfo__link-ico">
						<img src={icon} alt="link-icon" title="иконка"/>
					</div>
					<div className="user-sideinfo__link-text p5">
						<span>{text}</span>
						{copied ? <div className="user-sideinfo__link-panel p8">Скопировано</div> : null}
					</div>
				</li>
			</CopyToClipboard>
		)
	} else if (type === 'open'){
		return (
			<li className="user-sideinfo__link">
				<div className="user-sideinfo__link-ico">
					<img src={icon} alt="link-icon" title="иконка" />
				</div>
				<div className="user-sideinfo__link-text p5">
					<a href={text} target={"_blank"}>{text}</a>
				</div>
			</li>
		)
	}

}
