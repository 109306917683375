import React from 'react'
import { useParams } from 'react-router'
import { useActions } from '../hooks/useActions'
import { Main } from '../components/Team/Personal/Main'
import { About } from '../components/Team/Personal/About'
import { Game } from '../components/Team/Personal/Game'
import { Members } from '../components/Team/Another/Members'
import { Friends } from '../components/Team/Another/Friends'
import { getTeam, getTeamFriends } from '../services/Config'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { routeNames } from '../router'
import { Achievement } from 'components/Profile/Personal/Achievement'

export const Team = () => {
	const { loadingData } = useActions()
	const { id } = useParams()
	const [teamData, setTeamData] = React.useState(null)
	const [teamFriends, setTeamFriends] = React.useState([])
	const navigate = useNavigate()
	const {
		isAuth,
		userData: { user: userState },
	} = useSelector((state) => state.user)

	React.useEffect(() => {
		if (isAuth && userState?.team?.id === parseInt(id)) {
			navigate(routeNames.TEAMPROFILE.path)
		}
	}, [id, navigate, userState?.team?.id])

	React.useEffect(() => {
		const fetchUserData = async () => {
			try {
				loadingData(true)
				const response = await getTeam(id)
				setTeamData(response.data)
			} catch (error) {
				navigate(routeNames.NOTFOUND.path)
			} finally {
				loadingData(false)
			}
		}

		fetchUserData()
	}, [id])

	React.useEffect(() => {
		const fetchFriends = async () => {
			try {
				loadingData(true)
				const res = await getTeamFriends(id, 1000, 0)
				setTeamFriends(res.response.list)
			} catch (error) {
				console.log('fetchFriends error', error)
			} finally {
				loadingData(false)
			}
		}
		fetchFriends()
	}, [])

	return (
		<section className="section-lk">
			<div className="lk-cols">
				<div className="lk-col lk-col__team">
					{teamData && (
						<>
							<Main
								team={teamData}
								members_count={teamData?.team_members?.length ?? 0}
								friends={teamFriends}
								anotherTeam={true}
								teamCaptain={userState && userState?.team?.commander_id === userState.id}
							/>
							<Achievement anotherUser="true" achievements={teamData?.achievements} team={true} />
							<About
								anotherTeam={true}
								about={teamData?.description}
								ds={teamData?.soc_discord}
								vk={teamData?.soc_vk}
								site={teamData?.soc_site}
							/>
							<Game id={id} anotherTeam={true} teamId={teamData?.id} />
							<Members id={id} />
							<Friends id={id} />
						</>
					)}
				</div>
			</div>
		</section>
	)
}
