import React from 'react'
import { useSelector } from 'react-redux'

export const Loader = () => {
	const { isLoading, isUserUpdating } = useSelector((state) => state.user)
	if (!isLoading && !isUserUpdating) return null
	return (
		<div className="loader-wrapper">
			<div className="loader">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	)
}
