import React, { useState, useEffect} from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from './Portal'

const backdrop = {
    visible: { opacity: 1, visibility: 'visible' },
    hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
    hidden: { opacity: 0, visibility: 'hidden' },
    visible: {
        opacity: 1,
        visibility: 'visible',
        transition: { delay: 0.2 },
    },
}


export const LibraryModal = ({ isShow, hideModal, img, photos, index}) => {
    const [activeImg, setActiveImg] = useState(img)
    const [currentIndex, setCurrentIndex] = useState(index)

    const closeModal = () => {
        setCurrentIndex(index)
        hideModal()
        
    }

    useEffect(() => {
        const startFetching = async () => {
          setActiveImg(process.env.REACT_APP_IMAGES_URL + photos[currentIndex]?.path)
        }
        startFetching()
      })


    useEffect(() => {
        setActiveImg(process.env.REACT_APP_IMAGES_URL + photos[currentIndex]?.path)
    }, [currentIndex, index, photos])

    const nextSlide = () => {
        if (currentIndex < photos?.length - 1) {
            setCurrentIndex(currentIndex + 1)
        }
    }

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
        }
    }

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {isShow && (
                    <Portal>
                        <motion.div
                            className={clsx('pp', isShow && 'show')}
                            variants={backdrop}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                        >
                            <div className="pp__bg" onClick={closeModal}></div>
                            <motion.div className="pp__content pp__authors" variants={modal} style={{display: 'flex', justifyContent: 'center'}}>
                                <button className="pp__close" onClick={closeModal}>
                                    <span className="close__lane" />
                                    <span className="close__lane" />
                                </button>
                                <button onClick={prevSlide} className="library-btn slider-btn-prev" style={{"position": "absolute", "left": "5%", "top": "50%"}}></button>
                                <img src={activeImg} alt={'test'}/>
                                <button onClick={nextSlide} className="library-btn slider-btn-next" style={{"position": "absolute", "right": "5%", "top": "50%"}}></button>
                            </motion.div>
                        </motion.div>
                    </Portal>
                )}
            </AnimatePresence>
        </>
    )
}
