import React from 'react'
import { NavLink } from 'react-router-dom'
import { routeNames } from 'router'
import { getProfileGamesComing, getUserGamesComing, getUserGamesPassed } from '../../../services/Config'
import { Button } from '../../Buttons/Button'
import { EventCard } from '../../Events/EventCard'
import {PastGames} from "../../GameHistory/PastGames";

export const Game = ({ id, anotherUser }) => {
	const [game, setGame] = React.useState(null)
    const [passedGamesCount, setPassedGames] = React.useState(0)

	React.useEffect(() => {
		const fetchGame = async () => {
			let res
			if (!anotherUser) {
				res = await getProfileGamesComing()
				setGame(res.data.game)
			} else {
				res = await getUserGamesComing(id)
				setGame(res.response.game)

                const response = await getUserGamesPassed(id)
		        setPassedGames(response.data.total_count)
			}
		}
		fetchGame()
	}, [])

    return (
        <>
            {!anotherUser && (<div className="lk-container">
                    <div className="lk-container__title">
                        <h4 className="lk-container-title h4">Ближайшая игра</h4>
                        <NavLink to={routeNames.GAME_HISTORY.path} className="lk-container-link p7" title="История игр">
                            История игр
                        </NavLink>
                    </div>
                    <div className="lk-container__content">
                        {game && game.id ? (
                            <EventCard event={game}/>
                        ) : anotherUser ? (
                            <div className="lk-container__event-empty lk-container-empty">
                                <div className="lk-container-empty__text p4">Информация отсутствует</div>
                            </div>
                        ) : (
                            <div className="lk-container__event-empty lk-container-empty">
                                <div className="lk-container-empty__text p4">
                                    К сожалению, в ближайшее время нет игр. <br/>
                                    Все будущие мероприятия будут отображаться здесь.
                                </div>
                                <div className="lk-container-empty__btns">
                                    <Button
                                        type="link"
                                        href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
                                        className="lk-container-empty__btn button large yellow"
                                        text="Мероприятия"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {anotherUser && (
                passedGamesCount > 0 ? <PastGames anotherId={id}/> : <></>
            )}
        </>
    )
}
