import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'

import { Edit } from '../components/Forms/Team/Edit'
import { Tabs } from '../components/Tabs'
import { CommanderAssists } from '../components/Team/Personal/CommanderAssists'
import { AssistsRequests } from '../components/Team/Personal/AssistsRequests'

import { BackBtn } from '../components/Buttons/BackBtn/BackBtn'
import { Socials } from 'components/Forms/Team/Socials'
import Library from "../components/Library/Library";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {TeamAddPhotoFormSchema} from "../utils/validations";
import {addPhotoTeamAction} from "../store/action_creators/user";
import {useActions} from "../hooks/useActions";
import { notifyUserError } from 'services/Pnotify'

const tabs = [
	{
		title: 'Заместители',
		path: 'commanderAssists',
		path2: '?commanderAssists="true"',
		component: CommanderAssists,
	},
	{
		title: 'Ожидание подтверждения',
		path: 'assistsRequests',
		path2: '?assistsRequests="true"',
		component: AssistsRequests,
	},
]

export const TeamEdit = () => {
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const { addPhotoTeamAction } = useActions()
	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(TeamAddPhotoFormSchema),
	})
	const {
		register,
		formState: { errors },
		setValue,
	} = form
	console.log(userState)
	const addPhoto = (data) => {
		let formData = new FormData()
		const files = [...data.picture]
		files.forEach((item, index) => {
			formData.append('photo[]', item)
		})
		//formData.append('photo', data.picture[0])
		addPhotoTeamAction(formData)
	}

	useEffect(() => {
		if (form.formState.errors.picture) {
			notifyUserError(form.formState?.errors?.picture?.message)
			form.clearErrors('picture')
		}
    }, [form.formState.errors?.picture])


	return (
		<section className="section-lk">
			<div className="lk-cols">
				<div className="lk-col lk-col__team">
					<div className="lk-container">
						<div className="lk-container__title-primary">
							<BackBtn className="lk-container__back-btn" />
							<h4 className="lk-container-title h4">Редактирование команды</h4>
						</div>
						<div className="lk-container__content">
							<Edit team={userState.team} />
						</div>
					</div>
					<div className="lk-container">
						<Library edit={true} type={'team'}/>
						<form
							onChange={form.handleSubmit(addPhoto)}
							className="profile__form profile__form-avatar"
							encType="multipart/form-data"
						>
							<div className="profile__form-footer">
								<div className="profile__form-avatar-cont">
									<div className="profile__form-btns">
										<div className="profile__form-avatar-btn button yellow">
											<input className="profile__form-avatar-input" {...register('picture')} multiple name="picture" type="file" />
											<span className="button-text">Добавить фото</span>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>

					<div className="lk-container">
						<div className="lk-container__title">
							<h6 className="lk-container-title p1">Социальные профили</h6>
						</div>
						<div className="lk-container__content">
							<div className="lk-container__socials">
								<div className="lk-container__socials-part">
									<Socials
										ds={userState?.team.soc_discord}
										vk={userState?.team.soc_vk}
										site={userState?.team.soc_site} />
								</div>
							</div>
						</div>
					</div>

					<div className="lk-container">
						<Tabs
							items={tabs}
							teamCaptain={userState.team?.commander_id === userState.id}
							countPath="assistsRequests"
						/>
					</div>
				</div>
			</div>
		</section>
	)
}
