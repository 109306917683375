import React, { useState, useEffect, useRef, useCallback } from 'react'
import clsx from 'clsx'
import { ReactComponent as SelectArrow } from '../../static/img/icons/calendar-arrow-down-black.svg'
import { ReactComponent as CrossIcon } from '../../static/img/icons/close-icon-small-white.svg'
import { ReactComponent as ArticleUploadImage } from '../../static/img/icons/article-upload-image.svg'
import { AddArticleModalClose } from './AddArticleModalClose'
import { AddArticleModalPublish } from './AddArticleModalPublish'
import { EditArticlePictureModal } from './EditArticlePictureModal'
import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from './Portal'
import { addArticle, getCategories, addArticleFile } from 'services/Config'
import { Button } from '../Buttons/Button'
import { useForm } from 'react-hook-form'
import { AvatarFormSchema } from 'utils/validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { useActions } from 'hooks/useActions'
import { notifyUserError } from 'services/Pnotify'
import {Editor} from "@tinymce/tinymce-react";

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const AddArticleModal = ({ isShow, hideModal, userAvatar, callName, fullName, article, fetchArticles }) => {
	const apiBase = process.env.REACT_APP_API_URL
	const urlProfileArticlesFile = '/profile/articles/file'
	const [isAddArticleModalClose, setIsAddArticleModalClose] = useState(false)
	const [isAddArticleModalPublish, setIsAddArticleModalPublish] = useState(false)
	const [selectItems, setSelectItems] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const [isSelectOpen, setIsSelectOpen] = useState(false)
	const [articleTitle, setArticleTitle] = useState('')
	const [fileName, setFileName] = useState('')
	const [file, setFile] = useState(null)
	const [croppedFile, setCroppedFile] = useState(null)
	const { loadingData } = useActions()
	const [openEditArticleModal, setOpenEditArticleModal] = React.useState(false)
	const [text, setText] = useState('')
	const editorRef = useRef(null);

	const showEditArticleModal = () => {
		// fix cache browser for new image, and preload img before open
		fetch(process.env.REACT_APP_API_URL + "/article/preview?name="+ croppedFile).then(r=>{
			setOpenEditArticleModal(true);
		})

	}
	const hideEditArticleModal = () => {
		setOpenEditArticleModal(false)
	}

	const uploadByFile = (file) => {
		const sendFile = async (file) => {
			let formData = new FormData()
			formData.append('image', file)
			const response = await addArticleFile(formData)
			return response
		}
		return sendFile(file)
			.then((data) => {
				setCroppedFile(data?.data?.file?.filename)
				return {
					success: 1,
					file: {
						url: data?.data?.file?.url,
					},
				}
			})
			.catch((e) => {
				notifyUserError('Ошибка загрузки файла')
				notifyUserError(
					'Ошибка загрузки файла. Возможно размер файла превышает 1мб, попробуйте загрузить другой файл'
				)
				return false
			})
	}

	const uploadImage = async (e) => {
		const file = e.picture[0]
		//console.log(file);
		setFileName(file.name)
		uploadByFile(file)
		setFile(file)
	}

	const clearData = () => {
		setFileName('')
		setCroppedFile('')
		setArticleTitle('')
		setFile(null)
		setText('')
		setIsSelectOpen(false)
		setSelectedItems([])
	}

	const publishArticle = async () => {
		const categories = selectedItems.map((item) => item.id)
		const title = articleTitle === '' ? 'Заголовок' : articleTitle
		setText(editorRef.current.getContent())
		let formData = new FormData()
		formData.append('image', file)
		formData.append('title', title)
		formData.append('text', editorRef.current.getContent())
		formData.append('categories', categories)

		try {
			loadingData(true)
			await addArticle(formData)
			fetchArticles()
			hideModal()
			clearData()
			setIsAddArticleModalPublish(true)
		} catch (error) {
			notifyUserError('Произошла ошибка при публикации статьи')
		} finally {
			loadingData(false)
		}
	}

	const hideAllModals = () => {
		setIsAddArticleModalClose(false)
		hideModal()
		clearData()
	}

	const setSelectOpen = () => {
		setIsSelectOpen((prev) => !prev)
	}

	const deletSelected = (id) => {
		setSelectedItems(selectedItems.filter((item) => item.id !== id))
	}

	const addSelected = (id, name) => {
		setSelectedItems([...selectedItems, { id, name }])
	}

	const showAddArticleModalClose = () => {
		setIsAddArticleModalClose(true)
	}

	const hideAddArticleModalClose = () => {
		setIsAddArticleModalClose(false)
	}

	const hideAddArticleModalPublish = () => {
		setIsAddArticleModalPublish(false)
	}

	const deleteFile = (e) => {
		e.preventDefault()
		setFile(null)
		setFileName('')
		setCroppedFile('')
	}

	const CropImg = (e) => {
		e.preventDefault()
		showEditArticleModal()
	}

	const fetchCategories = async () => {
		try {
			const res = await getCategories()
			setSelectItems(res.data)
		} catch (error) {
			console.log('e', error)
		}
	}

	const closeModal = () => {
		hideModal()
		clearData()
	}

	const corpImg = (newImg) => {
		setOpenEditArticleModal(false)
		let file = new File([newImg],`qwerty.${newImg.type.split('/').pop()}`)
		setFileName(file.name)
		setFile(file)

	}

	const { setValue, register, reset, formState, handleSubmit } = useForm({
		mode: 'onChange',
		resolver: yupResolver(AvatarFormSchema),
	})

	useEffect(() => {
		fetchCategories()
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	useEffect(async () => {
		console.log(article.text)
		setText(article.text)
		setArticleTitle(article.title)
		if (article.categories) {
			setSelectedItems(article.categories)
		}
		if (article.image) {
			setFileName(article.image.slice(9, article.image.length))
		}

	}, [article])

	const updateArticle = async () => {
		const categories = selectedItems.map((item) => item.id)
		const title = articleTitle === '' ? 'Заголовок' : articleTitle
		let formData = new FormData()
		formData.append('id', article.id)
		if (file) {
			formData.append('image', file)
		}
		formData.append('title', title)
		console.log(editorRef.current.getContent())
		formData.append('text', editorRef.current.getContent())
		formData.append('categories', categories)

		try {
			loadingData(true)
			await addArticle(formData)
			fetchArticles()
			hideModal()
			clearData()
			setIsAddArticleModalPublish(true)
		} catch (error) {
			notifyUserError('Произошла ошибка при изменении статьи')
		} finally {
			loadingData(false)
		}
	}

	return (
		<>
			<AnimatePresence exitBeforeEnter>
				{isShow && (
					<Portal>
						<motion.div
							className={clsx('pp', isShow && 'show')}
							variants={backdrop}
							initial="hidden"
							animate="visible"
							exit="hidden"
						>
							<div className="pp__bg" onClick={showAddArticleModalClose}></div>
							<motion.div className="pp__content pp__article" variants={modal}>
								<button className="pp__close" onClick={showAddArticleModalClose} title="кнопка закрыть модальное окно">
									<span className="close__lane" />
									<span className="close__lane" />
								</button>

								<div className="pp__content-head pp__article-head">
									<img className="pp__article-head__img" width={10} src={userAvatar} alt="Позывной" />
									<div className="pp__article-head__info">
										<span className="pp__article-head__callname p1">{callName}</span>
										<span className="pp__article-head__name p6">{fullName}</span>
									</div>
								</div>
								<div className="pp__content-body pp__article-body">
									<div className="pp__article-body-inner">
										<div className="pp__article__select select_pp__article">
											{selectedItems.map((item) => (
												<div className="select_pp__article__select-item" key={item.id}>
													{item.name}
													<CrossIcon onClick={() => deletSelected(item.id)} />
												</div>
											))}
											<div className="select_pp__article__body">
												<div
													className={isSelectOpen ? 'select_pp__article__head open' : 'select_pp__article__head'}
													onClick={() => setSelectOpen()}
												>
													<div className="select_pp__article__title">Выберите тег</div>
													<div className="select_pp__article__icon">
														<SelectArrow />
													</div>
													<div
														className="select_pp__article__list"
														style={{ display: isSelectOpen ? 'block' : 'none' }}
													>
														{selectItems.map((item) => (
															<div
																className={
																	selectedItems.find((it) => it.id == item.id)
																		? 'select_pp__article__item disabled'
																		: 'select_pp__article__item'
																}
																key={item.id}
																onClick={() => addSelected(item.id, item.name)}
															>
																{item.name}
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
										<form
											onChange={handleSubmit(uploadImage)}
											encType="multipart/form-data"
											className="pp__article-file file_pp__article"
										>
											<input
												className="file_pp__article-input"
												// onChange={(e) => uploadImage(e)}
												id="image"
												type="file"
												name="picture"
												{...register('picture')}
											/>
											<label htmlFor="image" className="file_pp__article-label">
												<ArticleUploadImage className="file_pp__article-icon" />
												<span className={clsx('file_pp__article-filename', { ['show']: fileName !== '' })}>
													{fileName}
												</span>
												{fileName === '' ? (
													<span className="file_pp__article-text">Добавить обложку статьи</span>
												) : (
													<>
														{article.image ? (<div>
															<span className="file_pp__article-delete" onClick={(e) => deleteFile(e)}>
																Удалить
															</span></div>) : (<>
															<span className="file_pp__article-delete" onClick={(e) => deleteFile(e)}>
																Удалить
															</span>
															<span className="file_pp__article-crop" onClick={(e) => CropImg(e)}>
																Обрезать
															</span></>)}
													</>
												)}
											</label>
											{formState.errors['picture'] && (
												<label className="file_pp__article-error">{formState.errors['picture']?.message}</label>
											)}
										</form>
										<div className="pp__article-editor">
											<div className="pp__article-title">
												<input
													type="text"
													placeholder="Заголовок"
													value={articleTitle}
													onInput={(e) => setArticleTitle(e.target.value)}
												/>
											</div>
											<Editor
												apiKey='3lz0hov2sio2e2o7c32hdlbxe91c5qfkbgtishubhsy1kuxc'
												onInit={(evt, editor) => editorRef.current = editor}
												initialValue={text}
												init={{
													height: 500,
													a11y_advanced_options: true,
													link_default_protocol: 'https',
													plugins: 'advlist autolink lists link image charmap print preview anchor quickbars table image link lists media autoresize help searchreplace visualblocks code fullscreen insertdatetime media table paste imagetools wordcount',
													toolbar: 'undo redo | styleselect | bold italic | alignleft aligncentre alignright alignjustify | indent outdent | bullist numlist | link image',
													content_style: 'body { ' +
														'font-family: \'Raleway\', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,\n' +
														'\tDroid Sans, Helvetica Neue, sans-serif;' +
														'font-weight: 400;\n' +
														'\tfont-style: normal;\n' +
														'\tfont-size: 18px;' +
														'}',
													image_caption: true,
													images_upload_handler: async function(
														blobInfo,
														success,
														failure
													) {
														try {
															let file = blobInfo.blob();
															let formData = new FormData()
															formData.append('image', file)
															const {data} = await addArticleFile(formData)
															return process.env.REACT_APP_IMAGES_URL + 'articles/' + data?.file?.filename;
														} catch (error) {
															notifyUserError('Ошибка загрузки файла')
															notifyUserError(
																'Ошибка загрузки файла. Возможно размер файла превышает 1мб, попробуйте загрузить другой файл'
															)
															return;
														}
													},
													init_instance_callback: function(editor) {
													// 	var freeTiny = document.querySelector('.tox .tox-notification--in');
													//    freeTiny.style.display = 'none';
													}
												}}
											/>
										</div>
										<div className="pp__article-button">
											{article.title ?
												(<Button type={'button'} onClick={updateArticle} text="Изменить" className="button yellow"/>) :
												(<Button type={'button'} onClick={publishArticle} text="Опубликовать" className="button yellow"/>)}
										</div>
									</div>
								</div>
							</motion.div>
						</motion.div>
					</Portal>
				)}
			</AnimatePresence>
			<AddArticleModalClose
				isShow={isAddArticleModalClose}
				hideModal={hideAddArticleModalClose}
				hideAllModals={hideAllModals}
			/>
			<EditArticlePictureModal
				isShown={openEditArticleModal}
				hideModal={hideEditArticleModal}
				cropImg={corpImg}
				imgUrl={process.env.REACT_APP_API_URL + "/article/preview?name="+ croppedFile}
			/>
			<AddArticleModalPublish isShow={isAddArticleModalPublish} hideModal={hideAddArticleModalPublish} />
		</>
	)
}
