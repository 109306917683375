import React from 'react'

import { useNavigate } from 'react-router-dom'

import { useActions } from '../../hooks/useActions'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginFormSchema } from '../../utils/validations'

import { FormField } from '../FormField'
import { Button } from '../Buttons/Button'

import { motion } from 'framer-motion'
import { Social } from './Social'
import { routeNames } from '../../router'
import { useSearchParams } from "react-router-dom";

export const Login = () => {
	const navigate = useNavigate()
	const { loginUser, changeForm } = useActions()
	const fieldsRef = React.useRef()
	const [searchParams, setSearchParams] = useSearchParams();

	window.addEventListener('load', ()=>{
		if (searchParams.get("isAdminViewer") == "1") {
			loginUser({email: 'adminView@gmail.view', password: '123456789'},true).then(() => {
				navigate(routeNames.PROFILE.path)
			})
		}
	});

	React.useEffect(() => {
		if (fieldsRef.current) {
			const fields = fieldsRef.current.querySelectorAll('input')
			setTimeout(() => {
				for (const inp of fields) {
					// console.log('elem', inp)
					// console.log('elem-value',inp.value)
					if (!inp.value) {
						inp.closest('.input-box').classList.remove('--filled')
					}
				}
			}, 100)
		}

		return () => {}
	}, [])

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(LoginFormSchema),
	})

	const onSubmit = (data) => {
		loginUser(data).then(() => {
			navigate(routeNames.PROFILE.path)
		})
	}

	const showRecoveryForm = () => {
		changeForm('recovery')
	}

	return (
		<FormProvider {...form}>
			<motion.form
				animate={{ opacity: 1, x: 0 }}
				initial={{ opacity: 0, x: '-10%' }}
				onSubmit={form.handleSubmit(onSubmit)}
				className="auth__form login__form"
			>
				<h4 className="login__form-title">
					Чтобы продолжить, <br />
					войдите в <span>Privatka Club</span>
				</h4>

				<div className="login__form-fields" ref={fieldsRef}>
					{/* <div className="login__form-fields" ref={fieldsRef}> */}
					<div className="form-row login__form-field">
						<FormField
							className="--filled"
							autoFocus={true}
							elem={'input'}
							name={'email'}
							label={'Email'}
							placeholder={' '}
						/>
					</div>
					<div className="form-row login__form-field">
						<FormField
							className="--filled"
							autoFocus={true}
							elem={'input'}
							inputType={'password'}
							name={'password'}
							label={'Пароль'}
							placeholder={' '}
						/>
					</div>
				</div>

				<div className="form-row login__form-btns">
					<Button type={'button'} className="login__form-btn button yellow large" text={'Войти'} />
					<span className="login__form-btn --recovery p4" onClick={showRecoveryForm}>
						Забыли пароль?
					</span>
				</div>
			</motion.form>
			<Social />
		</FormProvider>
	)
}
