import clsx from 'clsx'
import React from 'react'
import { getLastReview } from '../../../services/Config'

import { ReactComponent as ReviewBtnIcon } from '../../../static/img/icons/arrow-right-black.svg'
import { normalizeDate } from '../../../utils/helpers'
import { Button } from '../../Buttons/Button'

export const Review = ({ anotherUser, id, userData }) => {
	const [review, setReview] = React.useState(null)
	const [open, setOpen] = React.useState(false)
	const [isBtnShown, setIsBtnShown] = React.useState(false)
	const [activeHeight, setActiveHeight] = React.useState(null)

	const textWrapperRef = React.useRef()
	const textRef = React.useRef()

	React.useEffect(() => {
		const fetchReview = async () => {
			try {
				const res = await getLastReview(id)
				setReview(res.response.review)
			} catch (e) {
				console.log('error: ', e)
			}
		}
		fetchReview()
	}, [])

	React.useEffect(() => {
		if (review) {
			const textWrapperHeight = textWrapperRef.current.clientHeight
			const textHeight = textRef.current.clientHeight
			const showBtn = textHeight > textWrapperHeight

			setIsBtnShown(showBtn)
			setActiveHeight(textHeight)
		}
	}, [review])

	return (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">Отзыв о Клубе</h4>
				{review && !anotherUser && (
					<div
						className={clsx('lk-container-status p15', {
							['--pending']: review.is_published === 0,
							['--aproved']: review.is_published === 1,
						})}
					>
						{review.is_published === 0 && 'На модерации'}
						{review.is_published === 1 && 'Одобрен'}
					</div>
				)}
			</div>
			<div className="lk-container__content">
				{review ? (
					<div className="lk-container__review-wrap">
						<div
							className="lk-container__review"
							ref={textWrapperRef}
							style={open && activeHeight ? { height: activeHeight } : {}}
						>
							<div className="lk-container__review-text p4" ref={textRef}>
								{review.content}
							</div>
						</div>
						<div className="lk-container__review-footer">
							{isBtnShown && (
								<button
									onClick={() => setOpen(!open)}
									className="lk-container__review-btn p15"
									title="кнопка раскрыть/закрыть текст отзыва"
								>
									{open ? (
										<>Свернуть</>
									) : (
										<>
											Читать полностью
											<ReviewBtnIcon className="lk-container__review-btn-icon" />
										</>
									)}
								</button>
							)}

							<div className="lk-container__review-info">
								<div className="lk-container__review-rating rate">
									{Array.from({ length: 5 }).map((_, i) => {
										return <span key={i} className={clsx('rate__item', review.rating > i ? 'active' : '')}></span>
									})}
								</div>
								<div className="lk-container__review-date p12">{normalizeDate(review.created_at)}</div>
							</div>
						</div>
					</div>
				) : anotherUser ? (
					<div className="lk-container__review-empty lk-container-empty">
						<div className="lk-container-empty__text p4">Пользователь не оставил ни одного отзыва</div>
					</div>
				) : (
					<div className="lk-container__review-empty lk-container-empty">
						<div className="lk-container-empty__text p4">
							Нам важно ваше мнение о нашем Клубе. Вы можете его написать и получить баллы.
						</div>
						<div className="lk-container-empty__btns">
							{userData.user.guest ? (
								<div className="lk-container-empty__text p5">
									<div className="lk-container-empty__btn button large" style={{color:"#e78346", backgroundColor: "rgba(231, 131, 70, 0.1)"}}>Оставить отзыв</div>
									<br></br>
									<span>Отзыв оставить может только член Клуба</span>
								</div>
							) : (
								<Button
								className="lk-container-empty__btn button large yellow"
								type="link"
								href={process.env.REACT_APP_FRONTEND_URL + 'reviews?ppReview=true'}
								text="Оставить отзыв"
							/>
							)}

						</div>
					</div>
				)}
			</div>
		</div>
	)
}
