import React from 'react'
import { NavLink } from 'react-router-dom'
import { routeNames } from '../../router'

import { useSelector } from 'react-redux'

import TeamLogo from '../../static/img/team_default.png'
import TeamDefault from '../../static/img/team_default.png'
import { declOfNum, normalizedImageSrc } from '../../utils/helpers'

export const SidebarInfo = ({ className }) => {
	const { userData } = useSelector((state) => state.user)
	return (
		<div className={'sidebar__info' + ' ' + className}>
			{/* {userData?.user?.team_id ? ( */}
			{userData?.user?.team ? (
				<NavLink to={routeNames.TEAMPROFILE.path} className="sidebar__info-club">
					<div className="sidebar__info-club-img">
						<img
							className="img_cover"
							src={userData?.user?.team?.avatar ? normalizedImageSrc(userData?.user?.team?.avatar) : TeamLogo}
							alt="team-logo"
							title="Лого команды"
						/>
					</div>
					<div className="sidebar__info-club-info">
						<div className="sidebar__info-club-name p1">{userData?.user?.team?.name}</div>
						<div className="sidebar__info-club-scores p11">
							{userData?.user?.team?.point > 0
								? userData?.user?.team?.point +
								  ' ' +
								  declOfNum(userData?.user?.team?.point, ['балл', 'балла', 'баллов'])
								: '0 баллов'}
						</div>
					</div>
				</NavLink>
			) : (
				<div className="sidebar__info-club">
					<div className="sidebar__info-club-img">
						<img src={TeamDefault} alt="team-logo" title="Лого команды" />
					</div>
					<div className="sidebar__info-club-info">
						<div className="sidebar__info-club-name p1">
							У вас сейчас
							<br />
							нет команды
						</div>
					</div>
				</div>
			)}

			<div className="sidebar__info-banners">
				{!userData?.user?.team && (
					<div className="sidebar__info-banner">
						<div className="sidebar__info-banner-title p1">Вы можете</div>
						<div className="sidebar__info-banner-list">
							<div className="sidebar__info-banner-item">
								<NavLink
									to={routeNames.TEAMCREATE.path}
									className="sidebar__info-banner-link p5"
									title="Создать свою команду"
								>
									Создать свою команду
								</NavLink>
							</div>
							<div className="sidebar__info-banner-item">
								<a
									href={process.env.REACT_APP_FRONTEND_URL + 'members?shownType=команды'}
									className="sidebar__info-banner-link p5"
									title="Присоединиться к существующей команде"
								>
									Присоединиться к существующей команде
								</a>
							</div>
						</div>
					</div>
				)}

				{userData?.user?.guest ? (
					<div className="sidebar__info-banner">
						<div className="sidebar__info-banner-title p1">Вы гость клуба</div>
						<div className="sidebar__info-banner-list">
							<div className="sidebar__info-banner-item">
								<a
									href={process.env.REACT_APP_FRONTEND_URL + 'toclub'}
									className="sidebar__info-banner-link p5"
									title="Как попасть в клуб"
								>
									Как попасть в клуб?
								</a>
							</div>
							<div className="sidebar__info-banner-item">
								<a
									href={process.env.REACT_APP_FRONTEND_URL + 'rules'}
									className="sidebar__info-banner-link p5"
									title="Правила клуба"
								>
									Правила клуба
								</a>
							</div>
						</div>
					</div>
				) : (
					<div className="sidebar__info-banner">
						<div className="sidebar__info-banner-title p1">Вы член Клуба</div>
						<div className="sidebar__info-banner-list">
							<div className="sidebar__info-banner-item">
								<a
									href={process.env.REACT_APP_FRONTEND_URL + 'rules'}
									className="sidebar__info-banner-link p5"
									title="Правила клуба"
								>
									Правила клуба
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
