import { useActions } from 'hooks/useActions'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { routeNames } from 'router'
import { notifyUserError } from 'services/Pnotify'
import { BackBtn } from '../components/Buttons/BackBtn/BackBtn'
import PaymentForm from '../components/Forms/Payment/PaymentForm'
import PaymentUnAuthForm from '../components/Forms/Payment/PaymentUnAuthForm'
import { getGameRoles, getSingleGame, getTeamProfile } from '../services/Config'
import TeamDefault from '../static/img/team_default.png'
import { rangeOfDates } from '../utils/helpers'
import { motion } from 'framer-motion'

function calcSale(u) {
	if (u.price) return u.price - u.price * (u.sale / 100)
	else return u
}

export const Payment = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [membersList, setMembersList] = useState([])
	const [gameRoles, setGameRoles] = useState([])

	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const { isAuth } = useSelector((state) => state.user)

	const [gameId, setGameId] = useState(Object.fromEntries([...searchParams])?.id || null)
	const [gameData, setGameData] = useState({})
	const [teamData, setTeamData] = useState({})
	const [onlyTeam, setOnlyTeam] = useState(false)
	const [price, setPrice] = useState({
		selfPrice: 0,
		selfSale: 0,
		selfDeposite: 0,
		teamPrice: [],
	})
	const [cost, setCost] = useState({})
	const [hideTeam, setHideTeam] = useState(false)

	const user = useSelector((state) => state.user)
	const navigate = useNavigate()
	const { loadingData } = useActions()
	const setSelfSale = (val) => {
		if (val > 0) {
			setPrice({ ...price, selfSale: val })
		} else {
			setPrice({
				...price,
				selfSale: userState?.team?.commander_id !== userState?.id ? userState?.sale : userState?.total_sale ?? 0,
			})
		}
	}

	React.useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getGameRoles()
				let roles = [{ value: 0, label: 'Игрок', sale: 0 }]
				res.data.forEach((element) => {
					roles.push({ value: element.id, label: element.role_name, sale: element.sale })
				})

				setGameRoles(roles)
			} catch (error) {
				notifyUserError('Ошибка получения игровых ролей')
			}
		}

		fetchRoles()
	}, [])

	React.useEffect(() => {
		const fetchGame = async () => {
			if (gameId) {
				try {
					loadingData(true)
					const res = await getSingleGame(gameId)
					setGameData(res.data)
				} catch (error) {
					console.log(error)
				} finally {
					loadingData(false)
				}
			} else {
				navigate(routeNames.NOTFOUND.path)
			}
		}
		const fetchTeam = async () => {
			try {
				loadingData(true)
				const teamRes = await getTeamProfile()
				setTeamData(teamRes.data)
			} catch (error) {
				console.log(error)
			} finally {
				loadingData(false)
			}
		}

		fetchGame()
		if (isAuth) {
			fetchTeam()
		}

		//  get game for id
	}, [gameId, searchParams, isAuth])

	React.useEffect(() => {
		// fix
		setPrice({
			...price,
			selfPrice: gameData.current_price,
			selfSale: userState?.team?.commander_id !== userState?.id ? userState?.sale : userState?.total_sale ?? 0,
		})
	}, [gameData, userState])

	React.useEffect(() => {
		const calcCost = () => {
			var cost = onlyTeam
				? 0 - price.selfDeposite
				: price.selfPrice - price.selfPrice * (price.selfSale / 100) - price.selfDeposite
			var sum = 0
			if (membersList.length > 0) {
				sum = membersList.reduce((acc, val) => calcSale(acc) + calcSale(val), 0)
				// sum = gameData.current_price * membersList.length
				setCost((cost + sum).toFixed(2))
			} else {
				setCost(cost.toFixed(2))
			}

			return
		}

		calcCost()
	}, [gameData, membersList, price, onlyTeam])

	// React.useEffect(() => {
	// 	const checWidth = () => {
	// 		if (window?.innerWidth) {
	// 			if (window.innerWidth > 768) {
	// 				setHideTeam(false)
	// 			}
	// 		}
	// 	}

	// 	window.addEventListener('resize', checWidth)
	// 	window.addEventListener('orientationchange', checWidth)

	// 	return () => {
	// 		window.removeEventListener('resize', checWidth)
	// 		window.removeEventListener('orientationchange', checWidth)
	// 	}
	// }, [])

	const onChangeDeposite = (e) => {
		let p = e.target.value

		setPrice({
			...price,
			selfDeposite: p < userState?.deposit ? p : userState.deposit,
		})
	}

	return (
		<div className="lk-container-payment">
			<div className="lk-container lk-container_left">
				<div className="lk-container__title-primary">
					<BackBtn className="lk-container__back-btn" />
					<h4 className="lk-container-title h4">Оплата мероприятия</h4>
				</div>
				{gameData.can_be_paid ? (
					<div className="lk-container__content">
						{user.isAuth ? (
							<PaymentForm
								setSelfSale={setSelfSale}
								gameRoles={gameRoles}
								membersList={membersList}
								setMembersList={setMembersList}
								game_id={gameId}
								gameData={gameData}
								teamData={teamData}
								by_deposit={price.selfDeposite}
								setOnlyTeam={setOnlyTeam}
								onlyTeam={onlyTeam}
							/>
						) : (
							<PaymentUnAuthForm game_id={gameId} />
						)}
					</div>
				) : null}
			</div>
			<div className="lk-container_right payment-container">
				{gameData.id && gameData.can_be_paid ? (
					<>
						{gameData.name && <h4 className="payment-container__title h4">{gameData.name}</h4>}
						<div className="payment-container__body">
							{gameData.type && (
								<div className="payment-container__info-item">
									<span className="info-item__label p16">Тип игры</span>
									<div className="info-item__value p14">{gameData.type.name}</div>
								</div>
							)}
							{gameData.date_start && (
								<div className="payment-container__info-item">
									<span className="info-item__label p16">Дата</span>
									<div className="info-item__value p14">{rangeOfDates(gameData.date_start, gameData.date_end)}</div>
								</div>
							)}
							{gameData.polygon && (
								<div className="payment-container__info-item">
									<span className="info-item__label p16">Полигон</span>
									<div className="info-item__value p14">{gameData.polygon.name}</div>
								</div>
							)}
							{gameData.country && (
								<div className="payment-container__info-item">
									<span className="info-item__label p16">Город</span>
									<div className="info-item__value p14">{gameData.country}</div>
								</div>
							)}
							{gameData.current_price && (
								<div className="payment-container__info-item">
									<span className="info-item__label p16">Стоимость</span>
									<div className="info-item__value p14">{gameData.current_price} ₽</div>
								</div>
							)}
						</div>

						{user.isAuth && (
							<div className="payment-container__other">
								{price.selfPrice ? (
									<div className="payment-container__info-item">
										<span className="info-item__label p16">Взнос</span>
										<div className="info-item__value h8">
											{onlyTeam ? 0 : (price.selfPrice - price.selfPrice * (price.selfSale / 100)).toFixed(2)} ₽
											{!onlyTeam && !userState?.guest && price.selfSale !== 0 ? (
												<span className="info-item__price p14">– {price.selfSale}%</span>
											) : null}
										</div>
									</div>
								) : null}
								{userState?.deposit ? (
									<div className="payment-container__deposit">
										<div className="payment-container__info-item row">
											<span className="info-item__label p16">Баланс на депозите:</span>
											<span className="info-item__value p14">{userState?.deposit} ₽</span>
										</div>
										<div className="payment-container__info-item row">
											<span className="info-item__label p16">Списать:</span>
											<span className="info-item__value ">
												<input
													className="p13"
													type="text"
													placeholder="0"
													onChange={onChangeDeposite}
													value={price.selfDeposite ? price.selfDeposite : ''}
												/>
											</span>
										</div>
									</div>
								) : null}

								{!membersList?.length > 0 ? null : (
									<div className="payment-container__team">
										<div
											className={`team-payment__head ${hideTeam ? 'active' : null}`}
											onClick={() => setHideTeam(!hideTeam)}
										>
											<span className="p12">Игроки к оплате</span>
										</div>
										<motion.div
											transition={{ ease: 'easeOut', duration: 0.3 }}
											animate={hideTeam ? { height: 0, opacity: 0 } : { height: 'auto', opacity: 1 }}
										>
											<ul className="team-payment__body">
												{membersList?.map((e, i) => {
													return (
														<li key={i} className="team-payment__item">
															<NavLink className="team-payment__link" to={routeNames.USER.path + e.id}>
																<div className="team-payment__photo">
																	<img
																		src={e.avatar ? process.env.REACT_APP_IMAGES_URL + e.avatar : TeamDefault}
																		alt={e.callname}
																	/>
																</div>
																<div className="team-payment__content">
																	{e.callname && <p className="team-payment__title p7">{e.callname}</p>}
																	{e.name && <span className="team-payment__label p8">{e.name}</span>}
																</div>
																{
																	<div className="team-payment__price p11">
																		{e.sale ? e.price - e.price * (e.sale / 100) : e.price} ₽
																	</div>
																}
															</NavLink>
														</li>
													)
												})}
											</ul>
										</motion.div>
									</div>
								)}
							</div>
						)}
						<div className="payment-container__footer">
							<span className="payment-container__footer_label p13">К оплате:</span>
							<span className="payment-container__footer_price h6">{cost} ₽</span>
						</div>
					</>
				) : (
					<h4 className="payment-container__title h4">Это мероприятие не может быть оплачено</h4>
				)}
			</div>
		</div>
	)
}
