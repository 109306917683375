import React from 'react'
import DefaultAvatar from 'static/img/user_default.png'
import { declOfNum, normalizedImageSrc } from 'utils/helpers'

export const MostExperienced = ({ users }) => {
	return (
		<div className="experienced-statistic">
			{users.map((item, idx) => (
				<div className="experienced-statistic__col" key={idx}>
					<div className="experienced-statistic__item">
						<div className="experienced-statistic__info">
							<div className="experienced-statistic__avatar">
								<img src={item.user.avatar ? normalizedImageSrc(item.user.avatar) : DefaultAvatar} alt="user avatar" />
							</div>
							<div className="experienced-statistic__data">
								<div className="experienced-statistic__name p4">
									{item.user.name || 'Я'} {item.user.lastname || ''}
								</div>
								<div className="experienced-statistic__nickname h5">{item.user.callname}</div>
							</div>
						</div>
						<div className="experienced-statistic__desc">
							<div className="experienced-statistic__text p5">{item.title}</div>
							<div className="experienced-statistic__games p12">
								{item.total_count} {declOfNum(item.total_count, ['игр', 'игры', 'игр'])}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
