import EmptyBlock from 'components/EmptyBlock'
import LoaderMini from 'components/LoaderMini'
import React from 'react'
import { useSelector } from 'react-redux'
import { getTeamDeputiesRequests } from 'services/Config'
import Hourglass from '../../../static/img/icons/EmptyBlock/hourglass.svg'
import { Pagination } from '../../Pagination/Pagination'
import { UserCard } from '../../User/UserCard'

export const AssistsRequests = ({ reRender, setCount }) => {
	const defaultLimit = 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamMembers, setTeamMembers] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	React.useEffect(() => {
		fetchMembers()
	}, [])

	React.useEffect(() => {
		if (reRender) fetchMembers()
	}, [reRender, page])

	const fetchMembers = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamDeputiesRequests(limit, offset)
			setTeamMembers(res.data)
		} catch (error) {
			console.log('fetchMembers error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		setCount(teamMembers.total_count)
	}, [teamMembers.total_count])

	const changePage = (num) => {
		setPage(num)
	}

	return isLoad ? (
		<LoaderMini />
	) : teamMembers?.members?.length > 0 ? (
		<>
			<div className="users__cards">
				{teamMembers.members.map((user) => (
					<UserCard key={user.id} user={user} hideFooter={true} fromAssistsRequests="true" />
				))}
			</div>
			{teamMembers?.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={teamMembers?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {teamMembers?.members?.length} из {teamMembers?.total_count}
					</div>
				</div>
			)}
		</>
	) : (
		<EmptyBlock img={Hourglass} title={'Все отправленные заявки будут отображаться здесь'} />
	)
}
