import React, { useEffect, useState } from 'react'
import EmptyBlock from '../components/EmptyBlock'
import { Button } from '../components/Buttons/Button'
import Stars from '../static/img/icons/EmptyBlock/stars.svg'
import MedalActive from '../static/img/medal-active.svg'
import MedalInactive from '../static/img/medal-inactive.svg'
import { getProfileAchievements } from 'services/Config'
import { useActions } from 'hooks/useActions'
import clsx from 'clsx'
import ReactTooltip from 'react-tooltip'
// import { motion, useAnimation } from 'framer-motion'
// import { useInView } from 'react-intersection-observer'

// const boxVariants = {
// 	hidden: {
// 		rotateY: 180,
// 		opacity: 0,
// 		visibility: 'hidden',
// 	},
// 	visible: {
// 		rotateY: 0,
// 		opacity: 1,
// 		visibility: 'visible',
// 		transition: {
// 			duration: 0.5,
// 		},
// 	},
// }

export default function Achievements() {
	const { loadingData } = useActions()
	// const controls = useAnimation()
	// const { ref, inView } = useInView()

	// useEffect(() => {
	// 	if (inView) {
	// 		controls.start('visible')
	// 	}
	// 	if (!inView) {
	// 		controls.start('hidden')
	// 	}
	// }, [controls, inView])
	const [user_achievements, setuser] = useState([
		// {
		// 	id: 1,
		// 	image: MedalActive, //  ссылка на  изображение ачивки активной
		// 	image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 	status: 'bool', // получена ли ачивка данным юзером
		// 	title: 'string',
		// 	text: 'string',
		// },
		// {
		// 	id: 2,
		// 	image: MedalActive, //  ссылка на  изображение ачивки активной
		// 	image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 	status: 'bool', // получена ли ачивка данным юзером
		// 	title: 'string',
		// 	text: 'string',
		// },
		// {
		// 	id: 3,
		// 	image: MedalActive, //  ссылка на  изображение ачивки активной
		// 	image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 	status: 'bool', // получена ли ачивка данным юзером
		// 	title: 'string',
		// 	text: 'string',
		// },
	])
	const [list_achievements, setlist] = useState([
		// {
		// 	// каждый объект это категория достижений
		// 	id: 1, //id категории
		// 	title: '1 категория', // название категории
		// 	achievements: [
		// 		{
		// 			id: 1,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: false, // получена ли ачивка данным юзером
		// 			title: 'Bronze medal',
		// 			text: 'благодарственная медаль клуба',
		// 		},
		// 		{
		// 			id: 2,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: true, // получена ли ачивка данным юзером
		// 			title: '2 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 		{
		// 			id: 3,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: true, // получена ли ачивка данным юзером
		// 			title: '3 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 		{
		// 			id: 4,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: false, // получена ли ачивка данным юзером
		// 			title: 'Bronze medal',
		// 			text: 'благодарственная медаль клуба',
		// 		},
		// 	],
		// },
		// {
		// 	// каждый объект это категория достижений
		// 	id: 2, //id категории
		// 	title: '2 категория', // название категории
		// 	achievements: [
		// 		{
		// 			id: 1,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: false, // получена ли ачивка данным юзером
		// 			title: '1 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 		{
		// 			id: 2,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: true, // получена ли ачивка данным юзером
		// 			title: '2 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 		{
		// 			id: 3,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: true, // получена ли ачивка данным юзером
		// 			title: '3 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 	],
		// },
		// {
		// 	// каждый объект это категория достижений
		// 	id: 3, //id категории
		// 	title: '3 категория', // название категории
		// 	achievements: [
		// 		{
		// 			id: 1,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: false, // получена ли ачивка данным юзером
		// 			title: '1 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 		{
		// 			id: 2,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: true, // получена ли ачивка данным юзером
		// 			title: '2 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 		{
		// 			id: 3,
		// 			image: MedalActive, //  ссылка на  изображение ачивки активной
		// 			image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
		// 			status: true, // получена ли ачивка данным юзером
		// 			title: '3 ачивка',
		// 			text: 'Описание ачивки',
		// 		},
		// 	],
		// },
	])

	useEffect(() => {
		const fetch = async () => {
			try {
				loadingData(true)
				const res = await getProfileAchievements()
				setuser(res.data.user_achievements)
				setlist(res.data.list_achievements)
			} catch (error) {
				console.log('e', error)
			} finally {
				loadingData(false)
			}
		}
		ReactTooltip.rebuild()
		fetch()
	}, [])

	return (
		<>
			<div className="lk-container">
				<div className="lk-container__title-primary lk-container__title-row">
					<h4 className="lk-container-title h4">Полученные достижения</h4>
					<Button
						type="link"
						href={process.env.REACT_APP_FRONTEND_URL + 'system'}
						className="lk-container-button button lightyellow"
						text="Бально-рейтинговая система"
					/>
				</div>
				<div className="lk-container__content achievements-content">
					{user_achievements.length > 0 ? (
						user_achievements.map((e, i) => {
							return (
								<div
									data-offset="{'top': -70}"
									data-for="myTooltip"
									data-tip={e.status ? e.text : ''}
									className="achievements-item"
									id={e.id}
									key={i}
									style={{
										transform: `translateX(calc(-20% * ${i}))`,
										zIndex: `${user_achievements.length - i}`,
									}}
								>
									<div className="achievements-item__image">
										<img
											src={
												e.status
													? process.env.REACT_APP_IMAGES_URL + e.image
													: process.env.REACT_APP_IMAGES_URL + e.image__inactive
											}
											alt={e.title}
										/>
									</div>
								</div>
							)
						})
					) : (
						<EmptyBlock
							img={Stars}
							title={'Нет полученых достижений'}
							text={'Все полученые достижения будут отображаться здесь'}
							buttonText={'Бально-рейтинговая система '}
							buttonLink={process.env.REACT_APP_FRONTEND_URL + 'system'}
						/>
					)}
				</div>
			</div>
			<div className="lk-container">
				<div className="lk-container__title-primary">
					<h4 className="lk-container-title h4">Все достижения</h4>
				</div>
				<div
					className={clsx('lk-container__content', {
						'achievements-categories': list_achievements.length > 0,
					})}
				>
					{list_achievements.length > 0 ? (
						list_achievements.map((c, cIndex) => {
							return (
								<div className="achievements-category" id={c.id} key={cIndex}>
									<h5 className="achievements-category__title">{c.name || 'undefined'}</h5>
									{c.achievements.length > 0 ? (
										<div className="achievements-category__container">
											{c.achievements.map((a, aIndex) => {
												return (
													<div
														data-offset="{'top': -70}"
														data-for="myTooltip"
														data-tip={a.status ? a.text : ''}
														className="achievements-item"
														id={c.id + '-' + a.id}
														key={aIndex}
													>
														<div className="achievements-item__image">
															<img
																src={
																	a.status
																		? process.env.REACT_APP_IMAGES_URL + a.image
																		: process.env.REACT_APP_IMAGES_URL + a.image__inactive
																}
																alt="Bronze medal"
															/>
														</div>
														<div className="achievements-item__name">{a.title}</div>
														<div className="achievements-item__description">{a.text}</div>
													</div>
												)
											})}
										</div>
									) : (
										<div className="lk-container-empty__text p4">Тут пока ничего нет</div>
									)}
								</div>
							)
						})
					) : (
						<EmptyBlock
							img={Stars}
							title={'Достижения не заполнены'}
							text={'Все достижения будут отображаться здесь'}
							buttonText={'Бально-рейтинговая система '}
							buttonLink={process.env.REACT_APP_FRONTEND_URL + 'system'}
						/>
					)}
				</div>
			</div>
		</>
	)
}
