import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/useActions'

import { useForm, FormProvider, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ProfileAboutFormSchema } from '../../../utils/validations'
import Select from 'react-select'
import { FormField } from '../../FormField'
import { Button } from '../../Buttons/Button'
import clsx from 'clsx'

const years = [
	{ value: '2000-01-01', label: '2000' },
	{ value: '2001-01-01', label: '2001' },
	{ value: '2002-01-01', label: '2002' },
	{ value: '2003-01-01', label: '2003' },
	{ value: '2004-01-01', label: '2004' },
	{ value: '2005-01-01', label: '2005' },
	{ value: '2006-01-01', label: '2006' },
	{ value: '2007-01-01', label: '2007' },
	{ value: '2008-01-01', label: '2008' },
	{ value: '2009-01-01', label: '2009' },
	{ value: '2010-01-01', label: '2010' },
	{ value: '2011-01-01', label: '2011' },
	{ value: '2012-01-01', label: '2012' },
	{ value: '2013-01-01', label: '2013' },
	{ value: '2014-01-01', label: '2014' },
	{ value: '2015-01-01', label: '2015' },
	{ value: '2016-01-01', label: '2016' },
	{ value: '2017-01-01', label: '2017' },
	{ value: '2018-01-01', label: '2018' },
	{ value: '2019-01-01', label: '2019' },
	{ value: '2020-01-01', label: '2020' },
	{ value: '2021-01-01', label: '2021' },
	{ value: '2022-01-01', label: '2022' },
	{ value: '2023-01-01', label: '2023' },
	{ value: '2024-01-01', label: '2024' },
	{ value: '2025-01-01', label: '2025' },
	{ value: '2026-01-01', label: '2026' },
	{ value: '2027-01-01', label: '2027' },
	{ value: '2028-01-01', label: '2028' },
	{ value: '2029-01-01', label: '2029' },
	{ value: '2030-01-01', label: '2030' },
]

export const About = () => {
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const { editProfileAction } = useActions()

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(ProfileAboutFormSchema),
	})

	const {
		register,
		control,
		formState: { errors },
		setValue,
	} = form

	React.useEffect(() => {
		if (userState.bio) {
			setValue('bio', userState.bio)
		}

		if (userState.start_year) {
			setValue('start_year', {
				label: userState.start_year.split('-')[0],
				value: userState.start_year,
			})
		}
	}, [])

	const onSubmit = (data) => {
		const formData = {
			...userState,
			bio: data.bio,
			start_year: data.start_year.value,
		}

		Object.keys(formData).forEach((f) => {
			if (!formData[f]) {
				delete formData[f]
			}
		})
		editProfileAction(formData)
	}

	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="profile__form profile__form-about">
					<div className="profile__form-about-body">
						<div className="profile__form-fields form-fields">
							<div className="form-row profile__form-about-row">
								<FormField
									elem={'textarea'}
									name={'bio'}
									label={'Опишите свою биографию в страйкболе (не менее 80 символов)'}
								/>
								<div
									className={clsx('select__form-row select__start_year custom-select__wrap', {
										['error']: form.formState.errors['start_year'],
									})}
								>
									<Controller
										render={({ field: { value, name, onChange }, ref }) => {
											return (
												<Select
													{...form.register('start_year')}
													options={years}
													closeMenuOnSelect={true}
													inputRef={ref}
													classNamePrefix="custom-select"
													id="user_start_year"
													instanceId="user_start_year"
													onChange={onChange}
													placeholder=" "
													name={name}
													value={value || null}
												/>
											)
										}}
										name="start_year"
										defaultValue={''}
										options={years}
									/>
									{form.formState.errors['start_year'] && (
										<label className="input-box__error">{form.formState.errors['start_year']?.message}</label>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="profile__form-footer">
						<Button type="button" className="profile__form-btn button yellow" text="Сохранить" />
					</div>
				</form>
			</FormProvider>
		</>
	)
}
