import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { UserCard } from '../../User/UserCard'
import { Pagination } from '../../Pagination/Pagination'
import { createDialog, deleteTeamMember, getTeamProfileMembers } from '../../../services/Config'
import { LoaderMini } from '../../LoaderMini'
import { useSelector } from 'react-redux'
import { useActions } from 'hooks/useActions'
import { notifyUserError } from 'services/Pnotify'
import { routeNames } from 'router'
import { isChatExist } from 'utils/helpers'

export const Members = ({ reRender }) => {
	const defaultLimit = 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamMembers, setTeamMembers] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)
	const dialogs = useSelector((state) => state.dialogs)

	const navigate = useNavigate()
	const { loadingData } = useActions()

	// const [searchParams, setSearchParams] = useSearchParams()

	const fetchMembers = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamProfileMembers(limit, offset)
			setTeamMembers(res.data)
		} catch (error) {
			console.log('fetchMembers error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		if (reRender) fetchMembers()
	}, [reRender, page])

	// React.useEffect(() => {
	// 	const currentParams = Object.fromEntries([...searchParams])
	// 	console.log(currentParams)
	// }, [searchParams])

	const changePage = (num) => {
		setPage(num)
	}

	const startChat = async (id) => {
		try {
			loadingData(true)
			const res = await createDialog({ user_id: id })
			navigate(routeNames.MESSAGES.path + res.data.chat.id)
		} catch (error) {
			notifyUserError('Ошибка создания диалога')
		} finally {
			loadingData(false)
		}
	}

	const deleteFromTeam = async (user) => {
		let ids = [user.id]

		try {
			const res = await deleteTeamMember(ids)
			fetchMembers()
		} catch (error) {}
	}

	return isLoad ? (
		<LoaderMini />
	) : (
		<>
			<div className="users__cards">
				{teamMembers?.list?.map((user) => (
					<UserCard
						dialogId={isChatExist(user.id, dialogs)}
						onConfirm={startChat}
						onReject={deleteFromTeam}
						key={user.id}
						user={user}
						fromMembers="true"
						fetchMembers={fetchMembers}
					/>
				))}
			</div>
			{teamMembers.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={teamMembers?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {teamMembers?.list?.length} из {teamMembers?.total_count}
					</div>
				</div>
			)}
		</>
	)
}
