import * as yup from 'yup'

export const LoginFormSchema = yup.object().shape({
	email: yup.string().email('Неверная почта').required('Почта обязательная'),
	password: yup.string().required('Введите пароль').min(6, 'Короткий пароль'),
})
export const RegisterFormSchema = yup
	.object()
	.shape({
		name: yup.string().required('Имя обязательно'),
		callname: yup.string().required('Позывной обязателен'),
	})
	.concat(LoginFormSchema)
export const PasswordRecoveryFormSchema = yup.object().shape({
	email: yup.string().email('Неверная почта').required('Почта обязательная'),
})
export const PasswordNewFormSchema = yup.object().shape({
	password: yup.string().required('Введите пароль'),
	password_confirmation: yup.string().when('password', {
		is: (val) => (val && val.length > 0 ? true : false),
		then: yup.string().oneOf([yup.ref('password')], 'Пароли не совпадают'),
	}),
})

export const ProfileMainFormSchema = yup.object().shape({
	callname: yup.string().nullable().required('Позывной обязателен'),
	email: yup.string().nullable().email('Неверная почта').required('Почта обязательная'),
	name: yup.string().nullable().required('Имя обязательно'),
	birthday: yup.string().nullable().required('Дата рождения обязательна'),
	city: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Город обязателен'),
})

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/

export const PaymentFormAuth = yup.object().shape({
	name: yup.string().required('Имя обязательно'),
	team: yup.string().required('Название команды обязательно'),
	email: yup.string().email('Неверная почта').required('Почта обязательная'),
	callname: yup.string().required('Позывной обязателен'),
	// game_role: yup
	// 	.object()
	// 	.shape({
	// 		label: yup.string(),
	// 		value: yup.string(),
	// 	})
	// 	.nullable()
	// 	.required('Роль обязательна'),
})
export const PaymentFormUnAuth = yup.object().shape({
	name: yup.string().required('Имя обязательно'),
	team: yup.string().required('Название команды обязательно'),
	email: yup.string().email('Неверная почта').required('Почта обязательная'),
	callname: yup.string().required('Позывной обязателен'),
})
export const ProfileAboutFormSchema = yup.object().shape({
	bio: yup.string().required('Заполните поле').min(80, 'Биография меньше 80 символов'),
	start_year: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Год обязателен'),
})

export const AvatarFormSchema = yup.object().shape({
	picture: yup
		.mixed()
		.test('fileLength', 'Загрузите фото', (value) => {
			return !!value.length
		})
		.test('fileSize', 'Размер превышает 1Мб', (value) => {
			return value && value[0].size <= 1000000
		})
		.test('type', 'Допустимые форматы: .jpeg, .jpg, and .png', (value) => {
			return value && (value[0].type === 'image/jpeg' || value[0].type === 'image/jpg' || value[0].type === 'image/png')
		}),
})

export const TeamCreateFormSchema = yup.object().shape({
	picture: yup
		.mixed()
		// .test('fileLength', 'Загрузите фото', (value) => {
		// 	return !!value.length
		// })
		.test('fileSize', 'Размер превышает 1Мб', (value) => {
			if (!value.length) return true
			return value && value[0].size <= 1000000
		})
		.test('type', 'Допустимые форматы: .jpeg, .jpg, and .png', (value) => {
			if (!value.length) return true
			return value && (value[0].type === 'image/jpeg' || value[0].type === 'image/jpg' || value[0].type === 'image/png')
		}),
	name: yup.string().required('Название обязательно'),
	city: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Город обязателен'),
	year: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Год обязателен'),
	modeling: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.bool(),
		})
		.nullable()
		.required('Выберете значение'),
	recruiting: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.bool(),
		})
		.nullable()
		.required('Выберете значение'),
})

export const TeamEditFormSchema = yup.object().shape({
	picture: yup
		.mixed()
		.test('fileSize', 'Размер превышает 1Мб', (value) => {
			if (!value.length) return true
			return value && value[0].size <= 1000000
		})
		.test('type', 'Допустимые форматы: .jpeg, .jpg, and .png', (value) => {
			if (!value.length) return true
			return value && (value[0].type === 'image/jpeg' || value[0].type === 'image/jpg' || value[0].type === 'image/png')
		}),
	teamname: yup.string().required('Название обязательно'),
	city: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Город обязателен'),
	year: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Год обязателен'),
	modeling: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.bool(),
		})
		.nullable()
		.required('Выберете значение'),
	recruiting: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.bool(),
		})
		.nullable()
		.required('Выберете значение'),
})

export const TeamAddPhotoFormSchema = yup.object().shape({
	picture: yup
		.mixed()
		.test('fileSize', 'Размер превышает 2Мб', (value) => {
			if(value && value?.length>0){
			  for (let i=0;i<value.length;i++){
			   if(value[i].size>2*1024*1024){
				 return false;
			   }
			  }
		   }
		   return true;
		  })
		.test('type', 'Допустимые форматы: .jpeg, .jpg, and .png', (value) =>{
			if(value && value.length>0){
			  for (let i=0;i<value.length;i++){
			   if(value[i].type!= "image/png" && value[i].type!= "image/jpg" && value[i].type!= "image/jpeg"){
				 return false;
			   }
			  }
		   }
		   return true;
		  })
})
