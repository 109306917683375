import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { FormField } from '../../FormField'
import { Button } from '../../Buttons/Button'
import { useActions } from 'hooks/useActions'
import { getProfile, setTeamSocials } from 'services/Config'
import { notifyUser, notifyUserError } from 'services/Pnotify'
import { userTypes } from 'store/types/user'
import { useDispatch } from 'react-redux'

export const Socials = ({ ds, vk, site }) => {
	const { loadingData } = useActions()
	const dispatch = useDispatch()

	const form = useForm()

	useEffect(() => {
		form.setValue('vk', vk)
		form.setValue('ds', ds)
		form.setValue('site', site)
	}, [])

	const onSubmit = async (data) => {
		try {
			loadingData(true)
			const formData = new FormData()
			formData.append('discord', data['ds'])
			formData.append('vk', data['vk'])
			formData.append('site', data['site'])

			const res = await setTeamSocials(formData)

			form.setValue('vk', data['vk'])
			form.setValue('ds', data['ds'])
			form.setValue('site', data['site'])

			const response = await getProfile()

			dispatch({
				type: userTypes.SET_USER_DATA,
				payload: response.data,
			})

			notifyUser('Данные успешно обновлены')
		} catch (error) {
			notifyUserError('Ошибка обновления данных')
		} finally {
			loadingData(false)
		}
	}

	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="profile__form profile__form-socials">
					<div className="profile__form-text p6">
						Заполните поля с соцсетями, которые хотите чтобы были видны в вашем публичном профиле
					</div>
					<div className="profile__form-socials-body">
						<div className="profile__form-fields form-fields">
							<div className="form-row profile__form-socials-row double">
								<FormField elem="input" name="vk" className="profile__form-socials-field --vk" />
								<FormField elem="input" name="ds" className="profile__form-socials-field --discord" />
								<FormField elem="input" name="site" className="profile__form-socials-field --site" />
							</div>
						</div>
					</div>

					<div className="profile__form-footer">
						<Button type="button" className="profile__form-btn button yellow" text="Сохранить" />
					</div>
				</form>
			</FormProvider>
		</>
	)
}
