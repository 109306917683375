import React from 'react'
import Select from 'react-select'
import TeamDefault from '../../static/img/team_default.png'
// import { gameRoles } from '../../utils/helpers'

export default function TeamCardPayment({ user, onChangeRole, onRemove, gameRoles, isGuest }) {
	return (
		<div className="team-card">
			<button type="button" onClick={() => onRemove(user.id)} className="team-card__remove"></button>
			<div className="team-card__top">
				<img
					src={user.avatar ? process.env.REACT_APP_IMAGES_URL + user.avatar : TeamDefault}
					alt={user.callname}
					className="team-card__photo"
				/>
				{user.callname && <div className="team-card__callname p3">{user.callname}</div>}
				{user.name && <div className="team-card__name p8">{user.name}</div>}
			</div>
			<div className="team-card__bottom">
				<div className="team-card__role">
					<Select
						options={gameRoles}
						closeMenuOnSelect={true}
						classNamePrefix="game-role"
						className={!isGuest ? '' : 'disabled'}
						id="role"
						instanceId="role"
						placeholder="Выбор роли"
						name={'teammate_role'}
						isSearchable={false}
						onChange={(e) => onChangeRole(user.id, e)}
						defaultValue={gameRoles[0]}
					/>
				</div>
				<div className="team-card__summary">
					<span className="summary-price p11">
						{!user.sale ? user.price : user.price - user.price * (user.sale / 100)} ₽
					</span>
					{user.sale ? <span className="summary-sale p15">- {user.sale}%</span> : null}
				</div>
			</div>
		</div>
	)
}
