export const userTypes = {
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT',
	CHANGE_FORM: 'CHANGE_FORM',
	SET_USER_LOADING: 'SET_USER_LOADING',
	SET_USER_UPDATING: 'SET_USER_UPDATING',
	SET_USER_DATA: 'SET_USER_DATA',
	EDIT_USER_DATA: 'EDIT_USER_DATA',
	EDIT_USER_TEAM_DATA: 'EDIT_USER_TEAM_DATA',
	UPDATE_USER_DATA: 'UPDATE_USER_DATA',
}
