import React, { useState} from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from './Portal'


const backdrop = {
    visible: { opacity: 1, visibility: 'visible' },
    hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
    hidden: { opacity: 0, visibility: 'hidden' },
    visible: {
        opacity: 1,
        visibility: 'visible',
        transition: { delay: 0.2 },
    },
}


export const AvatarModal = ({ isShow, hideModal, img}) => {
    const closeModal = () => {
        hideModal()
    }

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {isShow && (
                    <Portal>
                        <motion.div
                            className={clsx('pp', isShow && 'show')}
                            variants={backdrop}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                        >
                            <div className="pp__bg" onClick={closeModal}></div>
                            <motion.div className="pp__content pp__authors" variants={modal} style={{display: 'flex', justifyContent: 'center'}}>
                                <button className="pp__close" onClick={closeModal}>
                                    <span className="close__lane" />
                                    <span className="close__lane" />
                                </button>
                                <img src={img} alt={'test'}/>
                            </motion.div>
                        </motion.div>
                    </Portal>
                )}
            </AnimatePresence>
        </>
    )
}
