import React, { useRef } from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'

import { Portal } from './Portal'
import { Button } from '../Buttons/Button'

import userDefault from '../../static/img/user_default.png'
import { ReactComponent as Logo } from '../../static/img/icons/club-request-pp-icon.svg'
import { dateForRequest, formateDate, normalizedImageSrc } from '../../utils/helpers'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const WhoPaidModal = ({ isShown, hideModal, items }) => {
	const [srollBottom, setScrollBottom] = React.useState(false)
	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	React.useEffect(() => {
		if (document.querySelector('.who-paid__items')) setScrollBottom(true)
	}, [items])

	const closeModal = () => {
		hideModal()
	}

	const handleScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
		if (bottom) {
			setScrollBottom(true)
		} else {
			if (srollBottom) {
				setScrollBottom(false)
			}
		}
	}

	return (
		<AnimatePresence exitBeforeEnter>
			{isShown && (
				<Portal>
					<motion.div
						className={clsx('pp pp-who-paid', isShown && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={closeModal}></div>
						<motion.div className="pp__content pp__who-paid" variants={modal}>
							<button className="pp__close" onClick={closeModal}>
								<span className="close__lane" />
								<span className="close__lane" />
							</button>

							<div className="pp__content-head">
								<h4 className="pp__title">Кто оплатил</h4>
							</div>
							<div className="pp__content-body">
								<div className="who-paid__table">
									<div className="who-paid__head">
										<div className="who-paid__head-item">
											<div className="who-paid__head-title p15">Название игры</div>
											<div className="who-paid__head-value p10">{items.name}</div>
										</div>
										<div className="who-paid__head-item">
											<div className="who-paid__head-title p15">Участвуют</div>
											<div className="who-paid__head-value p10">{items.total_count}</div>
										</div>
									</div>
									<div
										className={clsx('who-paid__body', {
											['--scrollBottom']: srollBottom,
										})}
									>
										<div className="who-paid__items" onScroll={handleScroll}>
											{items?.list?.map((item) => (
												<div key={item.id} className="who-paid__item">
													<div className="who-paid__item-part">
														<div className="who-paid__user">
															<div className="who-paid__avatar">
																<img
																	className="img_cover"
																	src={item.avatar ? normalizedImageSrc(item.avatar) : userDefault}
																	alt="user-avatar"
																	title="фото пользователя"
																/>
															</div>
															<div className="who-paid__info">
																<div className="who-paid__info-part">
																	<div className="who-paid__callname p1">{item.callname}</div>
																	<div className="who-paid__name p6">{item.name || ''}</div>
																</div>
																<div className="who-paid__info-part">
																	<div className="who-paid__role p6">{item?.role?.role_name || 'Игрок'}</div>
																</div>
															</div>
														</div>
													</div>
													<div className="who-paid__item-part">
														<div className="who-paid__date p14">{formateDate(item.created_at)}</div>
														<div className="who-paid__price p9">Оплачено</div>
														{/* <div className="who-paid__price p9">{item.amount} ₽</div> */}
													</div>
												</div>
											))}
										</div>
									</div>
								</div>

								<div className="pp__content-btns">
									<Button onClick={closeModal} type="button" className="pp__content-btn button yellow" text="Закрыть" />
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
