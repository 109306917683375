import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import './draggable-list.css'

import DraggableListItem from './DraggableListItem'
import {sortPhoto, sortTeamPhoto} from "../../services/Config";
import {notifyUser} from "../../services/Pnotify";
import {useActions} from "../../hooks/useActions";

const DraggableList = props => {

    const [data, setdata] = useState(props.data);

    const [dragStartIndex, setdragStartIndex] = useState(null);

    // get index of draged item
    const onDragStart = (index) => setdragStartIndex(index)

    // update list when item dropped
    const onDrop = async (dropIndex) => {
        // get draged item
        const dragItem = data[dragStartIndex]
        // delete draged item in list
        let list = [...data]
        list.splice(dragStartIndex, 1)

        // update list
        if (dragStartIndex < dropIndex) {
            if (props?.type === 'team') {
                let res = await sortTeamPhoto({sortedList: [
                        ...list.slice(0, dropIndex - 1),
                        dragItem,
                        ...list.slice(dropIndex - 1, list.length)
                    ]})
                if (res.status === 200) {
                    notifyUser('Вы успешно обновили порядок фотографий')
                }
            } else {
                let res = await sortPhoto({sortedList: [
                        ...list.slice(0, dropIndex - 1),
                        dragItem,
                        ...list.slice(dropIndex - 1, list.length)
                    ]})
                if (res.status === 200) {
                    notifyUser('Вы успешно обновили порядок фотографий')
                }
            }
            setdata([
                ...list.slice(0, dropIndex - 1),
                dragItem,
                ...list.slice(dropIndex - 1, list.length)
            ])
        } else {
            if (props?.type === 'team') {
                let res = await sortTeamPhoto({sortedList: [
                        ...list.slice(0, dropIndex),
                        dragItem,
                        ...list.slice(dropIndex, list.length)
                    ]})
                if (res.status === 200) {
                    notifyUser('Вы успешно обновили порядок фотографий')
                }
            } else {
                let res = await sortPhoto({sortedList: [
                        ...list.slice(0, dropIndex),
                        dragItem,
                        ...list.slice(dropIndex, list.length)
                    ]})
                if (res.status === 200) {
                    notifyUser('Вы успешно обновили порядок фотографий')
                }
            }
            setdata([
                ...list.slice(0, dropIndex),
                dragItem,
                ...list.slice(dropIndex, list.length)
            ])
        }
    }

    useEffect(() => {
        setdata(props.data)
    }, [props])
    return (
        <div className="draggable-list">
            {
                data.map((item, index) => (
                    <DraggableListItem
                        key={index}
                        index={index}
                        onDragStart={(index) => onDragStart(index)}
                        onDrop={(index) => onDrop(index)}
                    >
                        {
                            props.renderItemContent(item)
                        }
                    </DraggableListItem>
                ))
            }
        </div>
    )
}

DraggableList.propTypes = {
    data: PropTypes.array,
    renderItemContent: PropTypes.func
}

export default DraggableList
