import clsx from 'clsx'
import { useNavigate, useLocation } from 'react-router-dom'

import { routeNames } from './router'

import ReactTooltip from 'react-tooltip'

import { AppRouter } from './components/AppRouter'
import { Header } from './components/Header'
import { Footer } from './components/Footer'

import 'swiper/swiper-bundle.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import './static/fonts/fonts.css'
import './static/styles/normalize.css'
import './static/styles/style.scss'
import { useActions } from './hooks/useActions'
import React from 'react'
import { Loader } from './components/Loader'
import { getCookie } from './utils/helpers'
import { getProfileDialogs } from './services/Config'
import { notifyUserError } from './services/Pnotify'

export function App() {
	const navigate = useNavigate()
	const location = useLocation()
	const { checkAuth, logOutUser } = useActions()

	React.useEffect(() => {
		const currPath = location.pathname
		const currSearch = location.search

		if (getCookie('prv_token')) {
			checkAuth()
				.then(() => {
					if (currPath !== '/') {
						navigate(currPath + currSearch, { replace: true })
					} else {
						navigate(routeNames.PROFILE.path, { replace: true })
					}
				})
				.catch(logOutUser)
		}
	}, [])

	return (
		<div className="parent-wrapper">
			{location.pathname !== routeNames.LOGIN.path &&
				location.pathname !== routeNames.REGISTRATION.path &&
				location.pathname !== routeNames.NOTFOUND.path &&
				location.pathname !== routeNames.NOACCESS.path &&
				location.pathname !== routeNames.TECHWORKS.path && <Header />}
			<main
				className={clsx('main', {
					['main-login']:
						location.pathname === routeNames.LOGIN.path ||
						location.pathname === routeNames.REGISTRATION.path ||
						location.pathname === routeNames.NOTFOUND.path ||
						location.pathname === routeNames.NOACCESS.path ||
						location.pathname === routeNames.TECHWORKS.path,
				})}
			>
				<AppRouter />
			</main>
			{location.pathname !== routeNames.LOGIN.path &&
				location.pathname !== routeNames.REGISTRATION.path &&
				location.pathname !== routeNames.NOTFOUND.path &&
				location.pathname !== routeNames.NOACCESS.path &&
				location.pathname !== routeNames.TECHWORKS.path && <Footer />}
			<Loader />
			<ReactTooltip insecure={false} id="myTooltip" html={true} effect="solid" className="react-tooltip" />
		</div>
	)
}
