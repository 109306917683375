import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { UserCard } from '../../User/UserCard'
import { Pagination } from '../../Pagination/Pagination'
import { createDialog, deleteFriend, getProfileFriends } from '../../../services/Config'
import { LoaderMini } from '../../LoaderMini'
import EmptyBlock from '../../EmptyBlock'
import Nofriends from '../../../static/img/icons/EmptyBlock/nofriends.svg'
import { notifyUserError } from '../../../services/Pnotify'
import { DeleteFriendModal } from '../../Modals/DeleteFriendModal'
import { routeNames } from '../../../router'
import { useActions } from '../../../hooks/useActions'
import { useSelector } from 'react-redux'
import { isChatExist } from 'utils/helpers'

export const MyFriends = ({ reRender }) => {
	const defaultLimit = 15
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamFriends, setTeamFriends] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [deletebleUser, setDeletebleUser] = React.useState(null)

	const [searchParams, setSearchParams] = useSearchParams()
	const dialogs = useSelector((state) => state.dialogs)

	const navigate = useNavigate()
	const { loadingData } = useActions()

	const fetchFriends = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getProfileFriends(limit, offset)
			setTeamFriends(res.data)
		} catch (error) {
			console.log('fetchMembers error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		if (reRender) fetchFriends()
	}, [reRender, page])

	const changePage = (num) => {
		setPage(num)
	}

	const startChat = async (id) => {
		try {
			loadingData(true)
			const res = await createDialog({ user_id: id })
			navigate(routeNames.MESSAGES.path + res.data.chat.id)
		} catch (error) {
			notifyUserError('Ошибка создания диалога')
		} finally {
			loadingData(false)
		}
	}

	const deleteFromFriends = (user) => {
		setDeletebleUser(user)
		showDeleteModal()
	}

	const deleteFromFriendsCb = async () => {
		hideDeleteModal()
		try {
			const res = await deleteFriend(deletebleUser.id)
			fetchFriends()
		} catch (error) {
			notifyUserError('Ошибка удаления из друзей')
		}
	}

	const showDeleteModal = () => {
		setOpenDeleteModal(true)
	}
	const hideDeleteModal = () => {
		setOpenDeleteModal(false)
	}

	return isLoad ? (
		<LoaderMini />
	) : teamFriends?.list?.length > 0 ? (
		<>
			<div className="users__cards">
				{teamFriends?.list?.map((user) => (
					<UserCard
						dialogId={isChatExist(user.id, dialogs)}
						onConfirm={startChat}
						onReject={deleteFromFriends}
						key={user.id}
						user={user}
						fromFriends="true"
					/>
				))}
			</div>
			{teamFriends.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={teamFriends?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {teamFriends?.list?.length} из {teamFriends.total_count}
					</div>
				</div>
			)}

			<DeleteFriendModal
				isShown={openDeleteModal}
				hideModal={hideDeleteModal}
				deleteUserCb={deleteFromFriendsCb}
				user={deletebleUser}
			/>
		</>
	) : (
		<EmptyBlock
			img={Nofriends}
			title={'У вас нет друзей'}
			text={'Найти знакомых пользователей'}
			buttonText={'Список пользователей'}
			buttonLink={process.env.REACT_APP_FRONTEND_URL + 'members'}
		/>
	)
}
