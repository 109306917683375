import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import './draggable-list.css'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {routeNames} from "../../router";
import {Button} from "../Buttons/Button";
import { ReactComponent as CrossIcon } from '../../static/img/icons/close-icon-small-black.svg'
import DraggableList from "./DraggableList";
import {deletePhoto, deleteTeamPhoto, getPhotos, getTeamPhotos} from "../../services/Config";
import {useSelector} from "react-redux";
import {loadingData} from "../../store/action_creators/user";
import {notifyUser} from "../../services/Pnotify";
import {useActions} from "../../hooks/useActions";
import {AvatarModal} from "../Modals/AvatarModal";
import {normalizedImageSrc} from "../../utils/helpers";
import DefaultLogo from "../../static/img/user_default.png";
import {LibraryModal} from "../Modals/LibraryModal";

SwiperCore.use([Navigation])

const Library = props => {
    const {
        userData: { user: userState },
    } = useSelector((state) => state.user)
    const [photos, setPhotos] = React.useState([])
    const [activePhoto, setActivePhoto] = React.useState('')
    const [isAvatarModal, setIsAvatarModal] = React.useState(false)
    const [activePhotoIndex, setActivePhotoIndex] = React.useState(0)

    const showAvatarModal = (photo) => {
        setActivePhoto(photo)
        const index = photos?.findIndex(elem => elem.path === photo)
        setActivePhotoIndex(index < 0 ? 0 : index)
        setIsAvatarModal(true)
    }
    const hideAvatarModal = () => {
        setIsAvatarModal(false)
    }

    const fetch = async () => {
        try {
            loadingData(true)
            if (userState.id) {
                if (props?.type === 'team') {
                    const res = await getTeamPhotos(userState.id)
                    setPhotos(res.data.photos)
                } else {
                    const res = await getPhotos(userState.id)
                    setPhotos(res.data.photos)
                }
            }
            loadingData(false)
        } catch (error) {
        } finally {
            loadingData(false)
        }
    }

    useEffect(() => {
        fetch()
    }, [userState, activePhotoIndex, activePhoto])

    const Card = item => (
        <div className={'card-image'}>
            <CrossIcon width={30} height={30} className={'card-image-icon'} onClick={async () => {
                if (props?.type === 'team') {
                    const res = await deleteTeamPhoto({photo_id: item.id})
                    if (res.status === 200) {
                        notifyUser('Фото удалено')
                    }
                    setPhotos(res.data.photos)
                } else {
                    const res = await deletePhoto({photo_id: item.id})
                    if (res.status === 200) {
                        notifyUser('Фото удалено')
                    }
                    setPhotos(res.data.photos)
                }
            }} />
            <div style={{cursor: 'pointer', height: '165px', width: '165px', backgroundRepeat: 'no-repeat', backgroundImage: "url(" + process.env.REACT_APP_IMAGES_URL + item.path + ")", backgroundSize: 'cover'}} onClick={() => showAvatarModal(item.path)}></div>

        </div>
    )

    return (
        <div>
            {props.edit && (
                <div>
                    <div className="lk-container__title">
                        <h4 className="lk-container-title h4">Галерея</h4>
                    </div>
                    <div className="lk-container__content">
                        {photos.length > 0 && (<DraggableList
                            data={photos}
                            type={props?.type}
                            renderItemContent={(item) => Card(item)}
                        />)}
                        {photos.length === 0 && (
                            props?.type === 'team' ? 
                            (
                                <div className={'lk-container-empty__text p6'}>{"Добавьте до 10-ти фотографий игроков своей команды или используемого снаряжения для команды"}</div>
                            ) : (
                                <div className={'lk-container-empty__text p6'}>{"Добавьте до 10-ти фотографий для лучшей узнаваемости своего профиля"}</div>
                            )
                        )}
                    </div>
                </div>
            )}
            {!props.edit && (
                <div>
                    <div className="lk-container__title">
                        <h4 className="lk-container-title h4">Галерея</h4>
                    </div>
                    <div className="lk-container__content">
                        {photos.length > 0 ?
                            <Swiper
                                className="teams-cards__swiper"
                                slidesPerView={'auto'}
                                watchSlidesProgress
                                navigation={{
                                    prevEl: '.teams-cards__swiper-btn.slider-btn-prev',
                                    nextEl: '.teams-cards__swiper-btn.slider-btn-next',
                                }}
                            >
                                {photos?.map((item, index) => (
                                    <SwiperSlide key={index} className="teams-cards__swiper-item">
                                        <img style={{cursor: 'pointer', objectFit: "cover"}} onClick={() => showAvatarModal(item.path)} width={"100%"} height={165} src={process.env.REACT_APP_IMAGES_URL + item.path} alt="" />
                                    </SwiperSlide>
                                ))}
                                <div className="teams-cards__swiper-btn swiper-btn slider-btn-prev"></div>
                                <div className="teams-cards__swiper-btn swiper-btn slider-btn-next"></div>
                            </Swiper>
                        : (
                            props?.type === 'team' ? 
                            (
                                <div className={'lk-container-empty__text p4'}>{"Добавьте до 10-ти фотографий игроков своей команды или используемого снаряжения для команды"}</div>
                            ) : (
                                <div className={'lk-container-empty__text p4'}>{"Добавьте до 10-ти фотографий для лучшей узнаваемости своего профиля"}</div>
                            )
                        )}
                        {props?.type !== 'team' && (<div className="lk-container-empty__btns">
                            <Button
                                type="navlink"
                                href={routeNames.PROFILEEDIT.path}
                                className="lk-container-empty__btn button large yellow"
                                text="Редактировать профиль"
                            />
                        </div>)}
                    </div>
                </div>
            )}
            {isAvatarModal && <LibraryModal isShow={isAvatarModal} hideModal={hideAvatarModal} img={activePhoto} photos={photos} index={activePhotoIndex}/>}
            {/*<AvatarModal isShow={isAvatarModal} hideModal={hideAvatarModal} img={activePhoto}/>*/}
        </div>
    )
}

Library.propTypes = {
    data: PropTypes.array,
    renderItemContent: PropTypes.func
}

export default Library
