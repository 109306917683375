import React from 'react'
import { NavLink } from 'react-router-dom'

import { routeNames } from '../../router'

import { Button } from '../Buttons/Button'

import defaultUserLogo from '../../static/img/user_default.png'
import defaultTeamLogo from '../../static/img/team_default.png'
import { ReactComponent as MessageIcon } from '../../static/img/icons/message-icon-white.svg'
import { normalizedImageSrc } from '../../utils/helpers'
import clsx from 'clsx'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ReactComponent as StatIcon } from '../../static/img/icons/stat-icon-small-black.svg'
import { ReactComponent as CloseIcon } from '../../static/img/icons/close-icon-small-black.svg'
import { useSelector } from 'react-redux'
import { UserStatistic } from 'components/Modals/UserStatistic'

export const UserCard = ({
	user,
	fromFriends,
	fromAssists,
	fromAssistsRequests,
	fromMembers,
	fromAnotherTeam,
	onConfirm,
	onReject,
	dialogId,
	hideFooter,
	fetchMembers,
}) => {
	const [openMenu, setOpenMenu] = React.useState(false)
	const [userModal, setUserModal] = React.useState(false)
	const [userInModal, setUserInModal] = React.useState(null)
	const menu = React.useRef()
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const openUserModal = () => {
		setUserInModal(user)
		setUserModal(true)
	}
	const hideUserModal = () => {
		setUserModal(false)
	}
	const onOpenMenu = () => {
		setOpenMenu((prev) => !prev)
	}
	const onCloseMenu = () => {
		setOpenMenu(false)
	}
	useOnClickOutside(menu, onCloseMenu)

	return (
		<div
			className={clsx('user__card', {
				['another-team']: fromAnotherTeam,
			})}
		>
			<NavLink
				to={routeNames.USER.path + user.id}
				className="user__card-hidden-link"
				title="ссылка на профиль пользователя"
			/>

			{fromMembers ? (
				<div
					ref={menu}
					className={clsx('user__card-menu card-menu', {
						active: openMenu,
					})}
				>
					<span onClick={onOpenMenu} className="card-menu-head">
						<span />
						<span />
						<span />
					</span>
					<div className="card-menu-body">
						<button
							onClick={openUserModal}
							className="card-menu-link p5"
							disabled={userState && userState.team.commander_id === userState.id ? false : true}
							title="кнопка просмотра статистики"
						>
							<StatIcon className="card-menu-link-icon" />
							<div className="card-menu-link-text">Статистика</div>
						</button>
						{userState && userState.id !== user.id && userState.team.commander_id === userState.id && (
							<button onClick={() => onReject(user)} className="card-menu-link p5" title="кнопка исключения из команды">
								<CloseIcon className="card-menu-link-icon" />
								<div className="card-menu-link-text">Исключить из команды</div>
							</button>
						)}
					</div>
				</div>
			) : (
				fromFriends && (
					<div
						ref={menu}
						className={clsx('user__card-menu card-menu', {
							active: openMenu,
						})}
					>
						<span onClick={onOpenMenu} className="card-menu-head">
							<span />
							<span />
							<span />
						</span>
						<div className="card-menu-body">
							<button onClick={() => onReject(user)} className="card-menu-link p5" title="кнопка удаления из друзей">
								<CloseIcon className="card-menu-link-icon" />
								<div className="card-menu-link-text">Удалить из друзей</div>
							</button>
						</div>
					</div>
				)
			)}
			<div className="user__card-head">
				<div className="user__card-photo">
					<img
						className="img_cover"
						// src={normalizedImageSrc(user?.avatar)}
						src={user.avatar ? process.env.REACT_APP_IMAGES_URL + user.avatar : defaultUserLogo}
						title="фото пользователя"
						alt="user-avatar"
					/>
				</div>
				{!fromAssists && !fromAssistsRequests && !fromMembers && !fromAnotherTeam && !fromFriends && (
					<NavLink to={`/team/${user.id}`} className="user__card-team">
						<img
							className="img_cover"
							// src={normalizedImageSrc(user?.team?.logo)}
							src={user.team.logo ? user.team.logo : defaultTeamLogo}
							title="логотип команды"
							alt="team-avatar"
						/>
					</NavLink>
				)}
			</div>
			<div className="user__card-body">
				{user?.callname && <div className="user__card-nickname p1">{user?.callname}</div>}
				<div className="user__card-name p6">{user?.name}</div>
			</div>
			{!hideFooter && userState && userState.id !== user.id && (
				<div className="user__card-footer">
					{fromAssists && (
						<Button
							onClick={() => onReject(user)}
							type="button"
							className="user__card-btn button medium yellow"
							text="Разжаловать"
						/>
					)}

					{fromAssistsRequests && (
						<>
							<Button
								onClick={() => onConfirm(user.id)}
								type="button"
								className="user__card-btn button medium yellow"
								text="Принять"
							/>
							<Button
								onClick={() => onReject(user.id)}
								type="button"
								className="user__card-btn button medium lightyellow"
								text="Отклонить"
							/>
						</>
					)}

					{(fromMembers || fromFriends) &&
						(dialogId ? (
							<Button
								type="navlink"
								href={routeNames.MESSAGES.path + dialogId}
								iconSide="left"
								Icon={MessageIcon}
								className="user__card-btn button medium lightyellow"
								text="Перейти в чат"
							/>
						) : (
							<Button
								onClick={() => onConfirm(user.id)}
								type="button"
								iconSide="left"
								Icon={MessageIcon}
								className="user__card-btn button medium lightyellow"
								text="Перейти в чат"
							/>
						))}
				</div>
			)}
			{userInModal && (
				<UserStatistic
					isShow={userModal}
					hideModal={hideUserModal}
					user={userInModal}
					setUser={setUserInModal}
					fetchAttendance={fetchMembers}
				/>
			)}
		</div>
	)
}
