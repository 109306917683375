import React from 'react'

import { useActions } from '../../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { routeNames } from '../../router'

import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordNewFormSchema } from '../../utils/validations'

import { FormField } from '../FormField'
import { Button } from '../Buttons/Button'
import { ReactComponent as Logo } from '../../static/img/icons/new-password-logo.svg'

import { motion } from 'framer-motion'

export const PasswordNew = () => {
	const navigate = useNavigate()
	const { createNewUserPassword, changeForm } = useActions()

	const [createdSuccess, setCreatedSuccess] = React.useState(false)
	const [searchParams, setSearchParams] = useSearchParams()

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(PasswordNewFormSchema),
	})

	const onSubmit = (data) => {
		const email = searchParams.get('email')
		const token = searchParams.get('token')

		const result = { ...data, passwordToken: token, email }
		createNewUserPassword(result).then(
			() => {
				setCreatedSuccess(true)
			},
			() => {}
		)
	}

	const showLoginForm = () => {
		navigate(routeNames.LOGIN.path)
		changeForm('login')
	}

	return (
		<FormProvider {...form}>
			{createdSuccess ? (
				<motion.div
					animate={{ opacity: 1, x: 0 }}
					initial={{ opacity: 0, x: '-10%' }}
					className="password__new-success"
				>
					<Logo className="password__new-success-logo" />
					<h5 className="h5 password__new-success-title">Ваш пароль успешно изменен!</h5>
					<Button
						type="span"
						text="Войти с новым паролем"
						className="password__new-success-btn button yellow large"
						onClick={showLoginForm}
					/>
				</motion.div>
			) : (
				<motion.form
					animate={{ opacity: 1, x: 0 }}
					initial={{ opacity: 0, x: '-10%' }}
					onSubmit={form.handleSubmit(onSubmit)}
					className="auth__form password__new"
				>
					<h5 className="password__new-title">Задать новый пароль</h5>

					<div className="form-row password__new-field">
						<FormField elem={'input'} inputType={'password'} name={'password'} label={'Пароль'} placeholder={' '} />
					</div>
					<div className="form-row password__new-field">
						<FormField
							elem={'input'}
							inputType={'password'}
							name={'password_confirmation'}
							label={'Повторить пароль'}
							placeholder={' '}
						/>
					</div>
					<div className="form-row password__new-btns">
						<Button type={'button'} className="password__new-btn button yellow large" text={'Установить пароль'} />
					</div>
				</motion.form>
			)}
		</FormProvider>
	)
}
