import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Routes, Route, useLocation } from 'react-router-dom'
// import { UserProfile } from '../pages/UserProfile'
import { privateRoutes, publicRoutes } from '../router'
import { getProfileDialogs, getProfileFriendsRequests, getProfileNotificationsCheck } from '../services/Config'
import { notifyUser, notifyUserError } from '../services/Pnotify'
import { Sidebar } from './Sidebar/Sidebar'
import { routeNames } from '../router'
import { updateToken } from '../services/Config'
import Cookies from 'js-cookie'
import clsx from 'clsx'

export const AppRouter = () => {
	const { isAuth, isLoading } = useSelector((state) => state.user)
	const location = useLocation()
	const dispatch = useDispatch()
	const dialogsTimeout = React.useRef(null)

	const getDialogs = async () => {
		// получаем все диалоги
		try {
			const res = await getProfileDialogs()
			dispatch({ type: 'DIALOGS_SET', payload: res.data })
		} catch (error) {
			notifyUserError('Не удалось получить список диалогов')
		}
	}

	const getFriendsRequest = async () => {
		// получаем все запросы в друзья
		try {
			const res = await getProfileFriendsRequests(1000, 0)
			dispatch({ type: 'FRIENDSREQS_SET', payload: res.data })
		} catch (error) {
			notifyUserError('Не удалось получить список запросов в  друзья')
		}
	}

	const getNots = async () => {
		try {
			const res = await getProfileNotificationsCheck()
			dispatch({ type: 'NOTYFICATIONS_SET', payload: res.data })
		} catch (error) {
			notifyUserError('Не удалось получить список уведомлений')
		}
	}

	const getNewToken = async () => {
		try {
			await updateToken()
		} catch (error) {
			console.log('не удалось обновить токен: ', error)
		}
	}

	React.useEffect(() => {
		if (location.search === '?deputy=true') {
			notifyUser('Вы подтвердили, что являетесь заместителем своей команды')
		}
	}, [location])

	React.useEffect(() => {
		if (isAuth) {
			getDialogs()
			getFriendsRequest()
			getNots()
			dialogsTimeout.current = setInterval(() => {
				if (Date.now() >= +Cookies.get('prv_token_expires')) {
					getNewToken()
					setTimeout(() => {
						getDialogs()
						getFriendsRequest()
						getNots()
					}, 2000)
				} else {
					getDialogs()
					getFriendsRequest()
					getNots()
				}
			}, 30000)
		}

		return () => {
			clearInterval(dialogsTimeout.current)
		}
	}, [isAuth])

	return (
		<div
			className={clsx(
				{ main__inner: location.pathname !== routeNames.TECHWORKS.path },
				{ grid: location.pathname !== routeNames.TECHWORKS.path }
			)}
		>
			{isAuth && <Sidebar />}

			<div className="main__container">
				<Routes>
					{isAuth
						? privateRoutes.map((route) => (
								<Route key={route.path} exact={route.exact} path={route.path} element={route.element} />
						  ))
						: publicRoutes.map((route) => (
								<Route key={route.path} exact={route.exact} path={route.path} element={route.element} />
						  ))}
				</Routes>
			</div>
		</div>
	)
}
