import axios from 'axios'
import { deleteCookie, getCookie, setCookie } from '../utils/helpers'
import Cookies from 'js-cookie'
import { notifyUser } from './Pnotify'

const apiBase = process.env.REACT_APP_API_URL
// const apiKey = process.env.NEXT_PUBLIC_API_KEY

const urlTeams = '/team/list'
const urlTeamStatistics = '/team/profile/statistics'
const urlTeamAttendance = '/team/profile/attendance'

const urlCategories = '/article/get-categories'

const urlAuth = '/auth'
const urlRegister = '/auth/register'
const urlForgotPassword = '/auth/forgot/password'
const urlCreateNewPassword = '/auth/reset/password'
const urlTokenUpdate = '/auth/token/update'
const urlVkAuth = urlAuth + '/socauth'

const urlProfile = '/profile'
const urlAddPhoto = '/profile/add_photo'
const urlGetPhotos = '/profile/get_photos'
const urlDeletePhoto = '/profile/delete_photo'
const urlSortPhoto = '/profile/sort_photo'
const urlUpdatePhoto = '/profile/update_photo'
const urlAddTeamPhoto = '/team/profile/add_photo'
const urlUpdateTeamPhoto = '/team/profile/update_photo'
const urlGetTeamPhotos = '/team/profile/get_photos'
const urlDeleteTeamPhoto = '/team/profile/delete_photo'
const urlSortTeamPhoto = '/team/profile/sort_photo'
const urlProfileHowTo = '/profile/points/how_to'
const urlProfilePointsHistory = '/profile/points/history'
const urlProfileNotifications = '/profile/notifications'
const urlProfileNotificationsCheck = '/profile/notifications/check'
const urlProfileArticlesFile = '/profile/articles/file'
const urlProfileAvatarRemove = urlProfile + '/avatar/remove'
const urlProfileFriends = urlProfile + '/friends'
const urlProfileFriendsRequests = urlProfile + '/friends/requests'
const urlProfileCallnames = urlProfile + '/callnames'
const urlProfileGamesComing = urlProfile + '/games/coming'
const urlProfileGamesPassed = urlProfile + '/games/passed'
const urlProfileGamesComingPaid = urlProfileGamesComing + '/paid'
const urlProfileDialogs = urlProfile + '/dialogs'
const urlProfileSocio = urlProfile + '/socio'
const urlProfileAchievements = urlProfile + '/achievements'

const urlTeamCreate = '/team'
const urlTeamProfile = '/team/profile'
const urlTeamProfileAllGames = urlTeamProfile + '/all_games'
const urlTeamGames = urlTeamProfile + '/games_passed/'
const urlTeamProfileSocio = urlTeamProfile + '/socio'
const urlTeamProfileAvatarRemove = '/team/profile/avatar'
const urlTeamLeave = '/team/profile/leave'
const urlTeamToClub = '/team/profile/toclub'
const urlTeamProfileMembers = '/team/profile/members'
const urlTeamProfileMembersRequests = '/team/profile/members/requests'
const urlTeamProfileFriends = '/team/profile/friends'
const urlTeamProfileFriendsRequests = '/team/profile/friends/requests'
const urlTeamProfileGamesComing = '/team/profile/games/coming'
// getTeamProfileGames
const urlTeamProfileGamesPassed = urlTeamProfile + '/games/passed'
const urlTransferRights = urlTeamProfile + '/commander/'
const urlDeputy = urlTeamProfile + '/deputy'
const urlDeputyRequests = urlDeputy + '/requests'

const urlRoles = '/game_roles'

const urlPlayers = '/gameplayers'

const urlGetUser = '/user/'
const urlUserVkAuth = urlGetUser + 'socauth'
const urlGetTeam = '/team/'

const urlGetReview = '/review/'

const urlGames = '/game'
const urlAllGames = '/game/list'

const urlAllArticles = '/profile/articles'
const urlAllTests = '/profile/tests'

const urlTransaction = '/transaction'
const urlTransactionList = urlTransaction + '/list'
const urlTransactionPrice = urlTransaction + '/get_price'

// request templates

const $api = axios.create({
	withCredentials: false,
	baseURL: apiBase,
})

$api.interceptors.request.use((config) => {
	const token = Cookies.get('prv_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN || '' })
	config.headers.Authorization = `Bearer ${token}`
	return config
})

$api.interceptors.response.use(
	(config) => {
		return config
	},
	async (error) => {
		const originalRequest = error.config
		if (error?.response?.status == 401 && error.config && !error.config._isRetry) {
			Cookies.remove('prv_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN || '' })
			window.location.reload()
		}
		throw error.response
	}
)

export const getResourse = async (url) => {
	try {
		const res = await axios.get(`${apiBase}${url}`, {
			headers: {
				'Content-Type': 'application/json',
				// 'Authorization': `Bearer ${this.jwtToken}`,
				// 'X-API-Key': apiKey,
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
		})
		let status = res.status
		if (status === 404 || status === 500) {
			throw new Error(`Couldn't fetch ${url}; received ${status} `)
		}
		let response = res.data

		return {
			status,
			response,
		}
	} catch (e) {
		return {
			response: e.message,
		}
	}
}

export const postResourse = async (url, data = {}) => {
	//
	const params = {
		// method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json',
			// 'Authorization': `Bearer ${this.jwtToken}`,
			// 'X-API-Key': apiKey,
			// 'Content-Type': 'application/x-www-form-urlencoded',
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *client
		// body: JSON.stringify(data), // body data type must match "Content-Type" header
	}
	const res = await axios.post(`${apiBase}${url}`, data)
	let response = res.data
	let status = res.status

	if (status === 404 || status === 500) {
		throw new Error(`Couldn't fetch ${url}; received ${status} `)
	}
	return {
		status,
		response,
	}
}

export const getTeams = async (sort = 'point', offset = 0, limit = 1000) => {
	const res = await getResourse(
		urlTeams + `?direction=asc&sort=${sort}&offset=${offset}${limit ? `&limit=${limit}` : ''}`
	)
	return res
}

export const login = async (data) => {
	const res = await postResourse(urlAuth, data)
	return res
}

export const setArticle = async (data) => {
	const res = await postResourse(urlAuth, data)
	return res
}

export const register = async (formData) => {
	const res = await postResourse(urlRegister, formData)
	return res
}

export const sendForgotPassword = async (formData) => {
	const res = await postResourse(urlForgotPassword, formData)
	return res
}

export const createNewPassword = async (formData) => {
	const res = await postResourse(urlCreateNewPassword, formData)
	return res
}

export const addArticle = async (data) => {
	const res = await $api.post(urlAllArticles, data)
	return res
}

export const addArticleFile = async (data) => {
	const res = await $api.post(urlProfileArticlesFile, data)
	return res
}

export const bindVkAcc = async (data) => {
	const res = await $api.post(urlUserVkAuth, data)
	return res
}

export const setTeamSocials = async (data) => {
	const res = await $api.post(urlTeamProfileSocio, data)
	return res
}

export const authWithVk = async (data) => {
	const res = await $api.post(urlVkAuth, data)
	return res
}

// Profile
export const updateToken = async () => {
	const res = await $api.post(urlTokenUpdate)
	Cookies.remove('prv_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN || '' })
	Cookies.set('prv_token', res.data.access_token, { expires: 7, domain: process.env.REACT_APP_COOKIE_DOMAIN })
	Cookies.set('prv_token_expires', Date.now() + 300000)
	return res
}
// Profile
export const getProfile = async () => {
	const res = await $api.get(urlProfile)
	return res
}
export const getProfileHowTo = async () => {
	const res = await $api.get(urlProfileHowTo)
	return res
}
export const getUserArticles = async (id) => {
	const res = await $api.get(urlGetUser + `${id}/articles`)
	return res
}
export const getProfilePointsHistory = async (limit = 15, offset = 0) => {
	const res = await $api.get(urlProfilePointsHistory + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getTeamProfileGames = async (limit = 2, offset = 0) => {
	const res = await $api.get(urlTeamProfileAllGames + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getTeamProfileGamesPassed = async (limit = 2, offset = 0) => {
	const res = await $api.get(urlTeamProfileGamesPassed + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getProfileNotifications = async (limit = 15, offset = 0) => {
	const res = await $api.get(urlProfileNotifications + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getProfileNotificationsCheck = async () => {
	const res = await $api.get(urlProfileNotificationsCheck)
	return res
}
export const setProfileNotificationsAll = async () => {
	const res = await $api.post(urlProfileNotifications)
	return res
}
export const setProfileNotification = async (id) => {
	const res = await $api.post(`${urlProfileNotifications}/${id}`)
	return res
}
export const editProfile = async (formData, config = {}) => {
	const res = await $api.post(urlProfile, formData, config)
	return res
}
export const getPhotos = async (id) => {
	const res = await $api.get(`${urlGetPhotos}/${id}`)
	return res
}
export const addPhoto = async (formData, config = {}) => {
	const res = await $api.post(urlAddPhoto, formData, config)
	return res
}
export const updatePhoto = async (formData, config = {}) => {
	const res = await $api.post(urlUpdatePhoto, formData, config)
	return res
}
export const deletePhoto = async (formData, config = {}) => {
	const res = await $api.post(urlDeletePhoto, formData, config)
	return res
}
export const sortPhoto = async (formData, config = {}) => {
	const res = await $api.post(urlSortPhoto, formData, config)
	return res
}
export const getTeamPhotos = async (id) => {
	const res = await $api.get(`${urlGetTeamPhotos}/${id}`)
	return res
}
export const addTeamPhoto = async (formData, config = {}) => {
	const res = await $api.post(urlAddTeamPhoto, formData, config)
	return res
}
export const updateTeamPhoto = async (formData, config = {}) => {
	const res = await $api.post(urlUpdateTeamPhoto, formData, config)
	return res
}
export const deleteTeamPhoto = async (formData, config = {}) => {
	const res = await $api.post(urlDeleteTeamPhoto, formData, config)
	return res
}
export const sortTeamPhoto = async (formData, config = {}) => {
	const res = await $api.post(urlSortTeamPhoto, formData, config)
	return res
}
export const deleteProfileAvatar = async () => {
	const res = await $api.delete(urlProfileAvatarRemove)
	return res
}
export const deleteProfile = async () => {
	const res = await $api.delete(urlProfile)
	return res
}

export const getProfileFriends = async (limit = 15, offset = 0) => {
	const res = await $api.get(urlProfileFriends + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getTeamStatistics = async () => {
	const res = await $api.get(urlTeamStatistics)
	return res
}
export const getTeamUserStatistics = async (id) => {
	const res = await $api.get(`${urlTeamStatistics}/${id}`)
	return res
}
export const getArticles = async (limit = 15, offset = 0) => {
	const res = await $api.get(urlAllArticles + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getTests = async (limit = 15, offset = 0) => {
	const res = await $api.get(urlAllTests + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getCategories = async () => {
	const res = await $api.get(urlCategories)
	return res
}
export const addFriend = async (id) => {
	const res = await $api.post(urlProfileFriends + '/' + id)
	return res
}
export const deleteFriend = async (id) => {
	const res = await $api.delete(urlProfileFriends + '/' + id)
	return res
}

export const getProfileFriendsRequests = async (limit = 15, offset = 0) => {
	const res = await $api.get(urlProfileFriendsRequests + `?limit=${limit}&offset=${offset}`)
	return res
}
export const addFriendFromRequest = async (id) => {
	const res = await $api.post(urlProfileFriendsRequests + '/' + id)
	return res
}
export const deleteFriendRequest = async (id) => {
	const res = await $api.delete(urlProfileFriendsRequests + '/' + id)
}
export const getProfileCallnames = async () => {
	const res = await $api.get(urlProfileCallnames)
	return res
}
export const getProfileGamesComing = async () => {
	const res = await $api.get(urlProfileGamesComing)
	return res
}
export const getUserGamesPassed = async (id, limit = 4, offset = 0) => {
	const res = await $api.get(urlGetUser + id + `/game/passed?limit=${limit}&offset=${offset}`)
	return res
}
export const getTeamGamesPassed = async (id, limit = 4, offset = 0) => {
	const res = await $api.get(urlTeamGames + id + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getProfileGamesPassed = async () => {
	const res = await $api.get(urlProfileGamesPassed)
	return res
}
export const getProfileGamesComingPaid = async () => {
	const res = await $api.get(urlProfileGamesComingPaid)
	return res
}
export const getProfileDialogs = async () => {
	const res = await $api.get(urlProfileDialogs)
	return res
}
export const setProfileSocio = async (data, config = {}) => {
	const res = await $api.post(urlProfileSocio, data, config)
	return res
}
export const getProfileAchievements = async () => {
	const res = await $api.get(urlProfileAchievements)
	return res
}
export const getTeamAttendance = async (limit = 1, offset = 0) => {
	const res = await $api.get(urlTeamAttendance + `?limit=${limit}&offset=${offset}`)
	return res
}
export const setTeamDeputy = async (id) => {
	const res = await $api.post(urlDeputy + '/' + id)
	return res
}

export const deleteTeamDeputy = async (id) => {
	const res = await $api.delete(urlDeputy + '/' + id)
	return res
}
export const getTeamDeputies = async (limit = 10, offset = 0) => {
	const res = await $api.get(urlDeputy + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getTeamDeputiesRequests = async (limit = 10, offset = 0) => {
	const res = await $api.get(urlDeputyRequests + `?limit=${limit}&offset=${offset}`)
	return res
}

export const getDialogById = async (id, params) => {
	const res = await $api.get(urlProfileDialogs + '/' + id, {
		params,
	})
	return res
}

export const sendDialogMessage = async (id, formData, config = {}) => {
	const res = await $api.post(urlProfileDialogs + '/' + id, formData, config)
	return res
}

export const createDialog = async (formData) => {
	const res = await $api.post(urlProfileDialogs + '/create', formData)
	return res
}

// Team

export const createTeam = async (formData, config = {}) => {
	const res = await $api.post(urlTeamCreate, formData, config)
	return res
}

export const getTeamProfile = async () => {
	const res = await $api.get(urlTeamProfile)
	return res
}
export const editTeamProfile = async (formData) => {
	const res = await $api.post(urlTeamProfile, formData)
	return res
}
export const deleteTeamProfileAvatar = async () => {
	const res = await $api.delete(urlTeamProfileAvatarRemove)
	return res
}
export const deleteTeamProfile = async () => {
	const res = await $api.delete(urlTeamProfile)
	return res
}

export const leaveTeam = async () => {
	const res = await $api.post(urlTeamLeave)
	return res
}
export const sendToTeamRequest = async (id) => {
	const res = await $api.post(urlGetTeam + id + '/request')
	return res
}
export const requestTeamToClub = async () => {
	const res = await $api.post(urlTeamToClub)
	return res
}
export const deleteTeamFromClub = async () => {
	const res = await $api.delete(urlTeamToClub)
	return res
}

export const getTeamProfileMembers = async (limit = 10, offset = 0) => {
	const res = await $api.get(urlTeamProfileMembers + `?limit=${limit}&offset=${offset}`)
	return res
}
export const addTeamMember = async (data) => {
	const res = await $api.post(urlTeamProfileMembers, { data: { ids: data } })
	return res
}
export const deleteTeamMember = async (data) => {
	const res = await $api.delete(urlTeamProfileMembers, { data: { ids: data } })
	return res
}

export const getTeamProfileMembersRequests = async (limit = 10, offset = 0) => {
	const res = await $api.get(urlTeamProfileMembersRequests + `?limit=${limit}&offset=${offset}`)
	return res
}
export const addTeamMemberFromRequest = async (id) => {
	const res = await $api.post(urlTeamProfileMembersRequests + '/' + id)
	return res
}
export const deleteTeamMemberFromRequest = async (id) => {
	const res = await $api.delete(urlTeamProfileMembersRequests + '/' + id)
	return res
}

export const getTeamProfileFriends = async (limit = 4, offset = 0) => {
	const res = await $api.get(urlTeamProfileFriends + `?limit=${limit}&offset=${offset}`)
	return res
}
export const addTeamFriend = async (id) => {
	const res = await $api.post(urlTeamProfileFriends + '/' + id)
	return res
}
export const deleteTeamFriend = async (id) => {
	const res = await $api.delete(urlTeamProfileFriends + '/' + id)
	return res
}

export const getTeamProfileFriendsRequests = async (limit = 4, offset = 0) => {
	const res = await $api.get(urlTeamProfileFriendsRequests + `?limit=${limit}&offset=${offset}`)
	return res
}
export const addTeamFriendFromRequest = async (id) => {
	const res = await $api.post(urlTeamProfileFriendsRequests + '/' + id)
	return res
}
export const deleteTeamFriendFromRequest = async (id) => {
	const res = await $api.delete(urlTeamProfileFriendsRequests + '/' + id)
	return res
}

export const getTeamProfileGamesComing = async () => {
	const res = await $api.get(urlTeamProfileGamesComing)
	return res
}

export const transferRights = async (id) => {
	const res = await $api.post(urlTransferRights + id)
	return res
}

export const sendToTeamFriendRequest = async (id) => {
	const res = await $api.post(urlTeamCreate + '/' + id + '/friend/request')
	return res
}

export const getLastReview = async (id) => {
	const res = await getResourse(urlGetReview + id)
	return res
}

// Another

export const getUser = async (id) => {
	const res = await $api.get(urlGetUser + id)
	return res
}
export const getUserGamesComing = async (id) => {
	const res = await getResourse(urlGetUser + id + '/game/coming')
	return res
}

export const getTeam = async (id) => {
	const res = await $api.get(urlGetTeam + id)
	return res
}
export const getTeamMembers = async (id, limit = 10, offset = 0) => {
	const res = await getResourse(urlTeamCreate + '/' + id + '/members' + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getTeamFriends = async (id, limit = 4, offset = 0) => {
	const res = await getResourse(urlTeamCreate + '/' + id + '/friends' + `?limit=${limit}&offset=${offset}`)
	return res
}
export const getTeamGamesComing = async (id) => {
	const res = await $api.get(urlTeamCreate + '/' + id + '/game/coming')
	return res
}

export const getGameRoles = async () => {
	const res = await $api.get(urlRoles)
	return res
}

// Event Payment

export const getSingleGame = async (id) => {
	const res = await $api.get(urlGames + '/' + id)
	return res
}

// Events

export const getGames = async (
	offset = 0,
	limit = 8,
	from = null,
	to = null,
	direction = 'asc',
	type = 0,
	city = ''
) => {
	const res = await getResourse(
		urlAllGames +
			`?sort=date_start&direction=${direction}&offset=${offset}${limit ? `&limit=${limit}` : ''}${
				from ? `&from=${from}` : ''
			}${to ? `&to=${to}` : ''}${type ? `&game_type=${type}` : ''}${city ? `&city=${city}` : ''}`
	)
	return res
}

// Transactions

export const payment = async (fromdata, config = {}) => {
	const res = await $api.post(urlTransaction, fromdata, config)
	return res
}
export const getTransactions = async (limit = 5, offset = 0) => {
	const res = await $api.get(urlTransactionList + `?limit=${limit}&offset=${offset}&direction=desc`)
	return res
}
export const getTransactionPrice = async (data = []) => {
	const res = await $api.post(urlTransactionPrice, data)
	return res
}

// game players
export const getPlayers = async (id) => {
	const res = await $api.get(urlPlayers + '/' + id)
	return res
}
