import React from 'react'
import { Button } from './Buttons/Button'

export default function EmptyBlock({ img, title, text, buttonText, buttonType, buttonLink }) {
	return (
		<div className="empty-block">
			{img && <img src={img} alt="icon" className="empty-block__img" />}
			{title && <h4 className="empty-block__title">{title}</h4>}
			{text && <p className="empty-block__text p4">{text}</p>}
			{buttonText && (
				<Button
					type={buttonType ? buttonType : buttonLink ? 'link' : 'button'}
					href={buttonLink}
					className="empty-block__button profile__form-btn button yellow"
					text={buttonText}
				/>
			)}
		</div>
	)
}
