import React from 'react'
import { ReactComponent as Logo } from '../static/img/logo-white.svg'

export default function TechWorksPage() {
	return (
		<div className="work-page__background">
			<div className="work-page__logo">
				<Logo />
			</div>

			<div className="work-page__content">
				<h2 className="work-page__title">
					На сайте ведутся <br /> технические работы
				</h2>
				<p className="work-page__text">Приносим извинения за доставленные неудобства</p>
			</div>
		</div>
	)
}
