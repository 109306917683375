import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { useSelector } from 'react-redux'

import { routeNames } from '../../router'
import { useActions } from '../../hooks/useActions'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { Button } from '../Buttons/Button'
import { NavList } from '../Nav/NavList'

import userDefault from '../../static/img/user_default.png'
import teamDefault from '../../static/img/team_default.png'
import { ReactComponent as BellIcon } from '../../static/img/icons/bell-icon-black.svg'
import { ReactComponent as EditIcon } from '../../static/img/icons/pen-icon-small-black.svg'
import { ReactComponent as LogOutIcon } from '../../static/img/icons/log-out-icon-small-black.svg'
import titleLogo from '../../static/img/titles/active_03.png'
import { declOfNum, normalizedImageSrc } from '../../utils/helpers'

const user = {
	avatar: '',
	callname: 'Белая стрела',
	name: 'Александр',
	lastname: 'Смирнов',
	title: {
		name: 'Bronze',
		photo: titleLogo,
	},
	points: 450,
	discount: '4,5',
	team: {
		avatar: '',
		name: 'S.D.G',
		points: 860,
		discount: '1',
	},
	total_discount: '5,5',
	deposit: 1500,
}

export const Menu = React.memo(() => {
	const location = useLocation()
	const { logOutUser } = useActions()
	const { userData } = useSelector((state) => state.user)
	const notifs = useSelector((state) => state.notifications)
	const [openMenu, setOpenMenu] = React.useState(false)
	const menu = React.useRef()

	React.useEffect(() => {
		setOpenMenu(false)
	}, [location])

	const onOpenMenu = () => {
		setOpenMenu((prev) => !prev)
	}
	const onCloseMenu = () => {
		setOpenMenu(false)
	}
	useOnClickOutside(menu, onCloseMenu)

	const logoutHandle = () => {
		logOutUser()
	}

	const isCommander = (user) => {
		return user?.team?.commander_id === user.id
	}

	return (
		<div className="profile-menu">
			{/* {notifs?.total_unread_count > 0 && ( */}
			<div className="profile-menu__item">
				<div className="profile-menu__head">
					<NavLink to={routeNames.NOTIFICATIONS.path} className="profile-menu__head profile-menu__notifs">
						<BellIcon className="profile-menu__head-icon" />
						{notifs?.total_unread_count > 0 && (
							<span className="profile-menu__notifs-count notif-count">{notifs?.total_unread_count}</span>
						)}
					</NavLink>
				</div>
			</div>
			{/* )} */}
			<div
				ref={menu}
				className={clsx('profile-menu__item', {
					active: openMenu,
				})}
			>
				<div onClick={onOpenMenu} className="profile-menu__head">
					<div className="profile-menu__head-inner">
						<img
							className="img_cover"
							src={userData.user.avatar ? normalizedImageSrc(userData.user.avatar) : userDefault}
							alt="user-photo"
							title="фото пользователя"
						/>
					</div>
				</div>
				<div className="profile-menu__body profile-info">
					<div className="profile-info__item profile-info__head">
						<div className="profile-info__head-cont">
							<div className="profile-info__logos">
								<NavLink to={routeNames.PROFILE.path} className="profile-info__userlogo">
									<img
										className="img_cover"
										src={userData.user.avatar ? normalizedImageSrc(userData.user.avatar) : userDefault}
										alt="user-photo"
										title="фото пользователя"
									/>
								</NavLink>
								{/* {userData.user.team_id && ( */}
								{userData.user.team && (
									<NavLink to={routeNames.TEAMPROFILE.path} className="profile-info__teamlogo">
										<img
											className="img_cover"
											src={
												userData?.user?.team?.avatar ? normalizedImageSrc(userData?.user?.team?.avatar) : teamDefault
											}
											alt="team-photo"
											title="фото команды"
										/>
									</NavLink>
								)}
							</div>
							<NavLink to={routeNames.PROFILE.path} className="profile-info__names">
								<div className="profile-info__nickname p1">{userData.user.callname}</div>
								<div className="profile-info__name p7">
									{userData.user.name}{' '}
									{userData.user.lastname && userData.user.lastname !== 'null' ? userData.user.lastname : ''}
								</div>
							</NavLink>
						</div>
						<div className="profile-info__btns">
							<Button
								type="navlink"
								href={routeNames.PROFILEEDIT.path}
								className="profile-info__btn button medium white"
								iconSide="left"
								Icon={EditIcon}
								text="Редактировать"
							/>
						</div>
					</div>
					<div className="profile-info__item profile-info__body">
						<div className="profile-info__rows">
							{/* {user.title && ( */}
							{false && (
								<div className="profile-info__row">
									<div className="profile-info__row-head user-title-logo">
										<img className="img_cover" src={user.title.photo} alt="user-title-logo" />
									</div>
									<div className="profile-info__row-body">
										<div className="profile-info__row-title p5">{user.title.name}</div>
										<div className="profile-info__row-points p16">
											{userData.user.point > 0
												? userData.user.point + ' ' + declOfNum(userData.user.point, ['балл', 'балла', 'баллов'])
												: '0 баллов'}
										</div>
									</div>
									<div className="profile-info__row-footer p12">{userData.user.sale} %</div>
								</div>
							)}
							{/* {userData.user.team_id ? ( */}
							{userData.user.team ? (
								<Link className="profile-info__row" to={routeNames.TEAMPROFILE.path}>
									<div className="profile-info__row-head user-team-logo">
										<img
											className="img_cover"
											src={
												userData?.user?.team?.avatar ? normalizedImageSrc(userData?.user?.team?.avatar) : teamDefault
											}
											alt="user-title-logo"
										/>
									</div>
									<div className="profile-info__row-body">
										<div className="profile-info__row-title p5">{userData?.user?.team?.name}</div>
										<div className="profile-info__row-points p16">
											{userData?.user?.team.point > 0
												? userData?.user?.team.point +
												  ' ' +
												  declOfNum(userData?.user?.team.point, ['балл', 'балла', 'баллов'])
												: '0 баллов'}
										</div>
									</div>
									<div className="profile-info__row-footer p12">
										{!userData.user?.guest && userData?.user?.team?.sale ? userData?.user?.team?.sale : 0} %
									</div>
								</Link>
							) : (
								<div className="profile-info__row --team-create">
									<div className="profile-info__team-create">
										<Button
											type="navlink"
											href={routeNames.TEAMCREATE.path}
											className="profile-info__btn profile-info__team-create-btn button medium blue"
											text="Создать команду"
										/>
										<a
											href={process.env.REACT_APP_FRONTEND_URL + 'members?shownType=команды'}
											className="profile-info__outlink profile-info__team-create-link p5"
											title="Присоединиться к существующей команде"
										>
											Присоединиться <br /> к существующей команде
										</a>
									</div>
								</div>
							)}
						</div>
						<div className="profile-info__wallet">
							<div className="profile-info__wallet-item">
								<div className="profile-info__wallet-title p5">Итоговая скидка:</div>
								<div className="profile-info__wallet-val p12">
									{isCommander(userData.user) ? userData?.user?.total_sale : userData?.user?.sale} %
								</div>
							</div>
							<div className="profile-info__wallet-item">
								<div className="profile-info__wallet-title p5">Депозит:</div>
								<div className="profile-info__wallet-val p12">{userData?.user?.deposit} ₽</div>
							</div>
						</div>
					</div>
					<div className="profile-info__item profile-info__footer">
						<NavList className="profile-info__nav" />
						<div className="profile-info__btns">
							<Button
								onClick={logoutHandle}
								type="button"
								iconSide="left"
								Icon={LogOutIcon}
								className="profile-info__btn button medium lightyellow"
								text="Выйти"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
})
