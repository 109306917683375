import React from 'react'
import { getTeamGamesComing, getTeamProfileGamesComing, getTeamGamesPassed } from '../../../services/Config'
import { Button } from '../../Buttons/Button'
import { EventCard } from '../../Events/EventCard'
import { WhoPaidModal } from '../../Modals/WhoPaidModal'
import { NavLink } from 'react-router-dom'
import { routeNames } from 'router'
import {PastGames} from "../../GameHistory/PastGames";

const game = true

const whoPaidMock = {
	name: 'Игра по мотивам FarCry5',
	total_count: 25,
	list: [
		{
			id: 1,
			avatar: 'users/qyCMkNvb4Jw.jpg',
			callname: 'Авторитет',
			lastname: 'Авторитетов',
			name: 'Авторитет',
			created_at: '2021-12-29T21:48:10.000000Z',
			game_role: 'игрок',
			amount: '2000',
		},
		{
			id: 2,
			avatar: '',
			callname: 'Перехватчик',
			lastname: 'Войнов',
			name: 'Сергей',
			created_at: '2021-12-29T21:48:10.000000Z',
			game_role: 'водитель',
			amount: '0',
		},
		{
			id: 3,
			avatar: '',
			callname: 'Авторитет',
			lastname: 'Константинов',
			name: 'Константин',
			created_at: '2021-12-29T21:48:10.000000Z',
			game_role: 'медик',
			amount: '1250',
		},
		{
			id: 4,
			avatar: '',
			callname: 'Авторитет',
			lastname: 'Константинов',
			name: 'Константин',
			created_at: '2021-12-29T21:48:10.000000Z',
			game_role: 'водитель',
			amount: '2000',
		},
		{
			id: 5,
			avatar: '',
			callname: 'Авторитет',
			lastname: 'Константинов',
			name: 'Константин',
			created_at: '2021-12-29T21:48:10.000000Z',
			game_role: 'водитель',
			amount: '2000',
		},
		{
			id: 6,
			avatar: '',
			callname: 'Авторитет',
			lastname: 'Константинов',
			name: 'Константин',
			created_at: '2021-12-29T21:48:10.000000Z',
			game_role: 'водитель',
			amount: '2000',
		},
	],
}

export const Game = ({ id, anotherTeam, teamId }) => {
	const [openWhoPaidModal, setOpenWhoPaidModal] = React.useState(false)
	const [games, setGames] = React.useState([])
	const [passedGamesCount, setPassedGames] = React.useState(0)

	const fetchGame = async () => {
		const res = await getTeamGamesComing(id)
		setGames([res.data.game])
		const response = await getTeamGamesPassed(id)
		setPassedGames(response.data.total_count)
	}

	// React.useEffect(() => {
	// 	fetchGame()
	// }, [])

	React.useEffect(() => {
		fetchGame()
	}, [id])

	const showWhoPaidModal = (e) => {
		e.stopPropagation()
		e.preventDefault()
		setOpenWhoPaidModal(true)
	}
	const hideWhoPaidModal = () => {
		setOpenWhoPaidModal(false)
	}

	return (
		<>
			<div className="lk-container">
				<div className="lk-container__title">
					<h4 className="lk-container-title h4">Ближайшая игра</h4>
					{!anotherTeam && (
						<NavLink to={routeNames.TEAM_GAME_HISTORY.path} className="lk-container-link p7" title="История игр">
							История игр
						</NavLink>
					)}
				</div>
				<div className="lk-container__content">
					{games.length > 0 && games[0].id ? (
						<div className="events-cards">
							<EventCard openModal={showWhoPaidModal} event={games[0]} myTeam="true" />
							{games?.[1] && <EventCard game={games[1]} myTeam="true" />}
						</div>
					) : anotherTeam ? (
						<div className="lk-container__event-empty lk-container-empty">
							<div className="lk-container-empty__text p4">Информация отсутствует</div>
						</div>
					) : (
						<div className="lk-container__event-empty lk-container-empty">
							<div className="lk-container-empty__text p4">
								Нет запланированных игр. <br />
								Все оплаченные мероприятия будут отображаться здесь.
							</div>
							<div className="lk-container-empty__btns">
								<Button
									type="link"
									href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
									className="lk-container-empty__btn button large yellow"
									text="Смотреть все мероприятия"
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			<WhoPaidModal items={whoPaidMock} isShown={openWhoPaidModal} hideModal={hideWhoPaidModal} />
			{anotherTeam && (
				passedGamesCount > 0 ? <PastGames anotherId={id} team={teamId}/> : <></>
			)}
		</>
	)
}
