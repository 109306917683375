import React, { useEffect } from 'react'
import EmptyBlock from '../components/EmptyBlock'
import { Button } from '../components/Buttons/Button'
import Stars from '../static/img/icons/EmptyBlock/stars.svg'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { normalizedImageSrc } from 'utils/helpers'

export default function TeamAchievements() {
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const list_achievements = [
		{
			id: 14,
			title: 'Топ - 3',
			text: 'Третье место по итогам года',
			image: 'achievements/February2022/9omE5fd8j6QvfZEVS95j.png',
			image__inactive: 'achievements/February2022/NIudjCCFWmcbMplsZfQH.png',
			status: userState.team.achievements.find((item) => item.id === 14) ? 1 : 0,
		},
		{
			id: 15,
			title: 'Топ - 2',
			text: 'Второе место по итогам года',
			image: 'achievements/February2022/9hO0xGJGe0520xXEgug8.png',
			image__inactive: 'achievements/February2022/6OfppFpIzJM7fxbrUBIB.png',
			status: userState.team.achievements.find((item) => item.id === 15) ? 1 : 0,
		},
		{
			id: 16,
			title: 'Топ - 1',
			text: 'Первое место по итогам года',
			image: 'achievements/February2022/DPXMWmXa3y2peA9YtJIb.png',
			image__inactive: 'achievements/February2022/8Tc8kftVStS5WaKaSpow.png',
			status: userState.team.achievements.find((item) => item.id === 16) ? 1 : 0,
		},
	]

	useEffect(() => {
		ReactTooltip.rebuild()
	}, [])

	return (
		<>
			<div className="lk-container">
				<div className="lk-container__title-primary lk-container__title-row">
					<h4 className="lk-container-title h4">Полученные достижения</h4>
					<Button
						type="link"
						href={process.env.REACT_APP_FRONTEND_URL + 'system'}
						className="lk-container-button button lightyellow"
						text="Бально-рейтинговая система"
					/>
				</div>
				<div className="lk-container__content achievements-content">
					{userState.team.achievements.length > 0 ? (
						userState.team.achievements.map((e, i) => {
							return (
								<div
									data-offset="{'top': -70}"
									data-for="myTooltip"
									data-tip={e.status ? e.text : ''}
									className="achievements-item"
									id={e.id}
									key={i}
									style={{
										transform: `translateX(calc(-20% * ${i}))`,
										zIndex: `${userState.team.achievements.length - i}`,
									}}
								>
									<div className="achievements-item__image">
										<img src={normalizedImageSrc(e.picture)} alt={e.title} />
									</div>
								</div>
							)
						})
					) : (
						<EmptyBlock
							img={Stars}
							title={'Нет полученых достижений'}
							text={'Все полученые достижения будут отображаться здесь'}
							buttonText={'Бально-рейтинговая система '}
							buttonLink={process.env.REACT_APP_FRONTEND_URL + 'system'}
						/>
					)}
				</div>
			</div>
			<div className="lk-container">
				<div className="lk-container__title-primary">
					<h4 className="lk-container-title h4">Все достижения</h4>
				</div>
				<div
					className={clsx('lk-container__content', {
						'achievements-categories': list_achievements.length > 0,
					})}
				>
					<div className="achievements-category">
						<h5 className="achievements-category__title">Топ</h5>
						<div className="achievements-category__container">
							{list_achievements.map((item) => (
								<div
									data-offset="{'top': -70}"
									data-for="myTooltip"
									data-tip={item.status ? item.text : ''}
									className="achievements-item"
									key={item.id}
								>
									<div className="achievements-item__image">
										<img
											src={
												item.status
													? process.env.REACT_APP_IMAGES_URL + item.image
													: process.env.REACT_APP_IMAGES_URL + item.image__inactive
											}
											alt="Bronze medal"
										/>
									</div>
									<div className="achievements-item__name">{item.title}</div>
									<div className="achievements-item__description">{item.text}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
