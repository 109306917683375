import React, { useEffect } from 'react'
import NotificationIcon from '../../static/img/icons/notifications.svg'
import NotificationIcon2 from '../../static/img/icons/notifications2.svg'
import NotificationIcon3 from '../../static/img/icons/notifications3.svg'
import NotificationIcon4 from '../../static/img/icons/notifications4.svg'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as NotificationEye } from '../../static/img/icons/eye-icon-black.svg'
import { ReactComponent as NotificationEyeOff } from '../../static/img/icons/eye-icon-off-black.svg'

export const NotificationsItem = ({ item, readOnce }) => {
	const notificationsTolltip = () => `
		<div class="list-notifications__tolltip">
			пометить как прочитанное
		</div>
	`

	useEffect(() => {
		ReactTooltip.rebuild()
	}, [])
	return (
		<li className="list-notifications__item">
			<div className="list-notifications__image">
				<img
					src={
						item.type === 2
							? NotificationIcon2
							: item.type === 3
							? NotificationIcon3
							: item.type === 4
							? NotificationIcon4
							: NotificationIcon
					}
					alt="иконка уведомления"
				/>
				{item.points !== 0 && (
					<div className="list-notifications__count p16">{item.points > 0 ? `+  ${item.points}` : item.points}</div>
				)}
			</div>
			<div className="list-notifications__info">
				<p className="list-notifications__title p3">{item.title}</p>
				<p className="list-notifications__text p13">{item.text}</p>
			</div>
			{!item.is_read ? (
				<div className="list-notifications__icon">
					<div
						className={'list-notifications__buton'}
						title={'пометить как прочитанное'}
						data-tip={notificationsTolltip(item)}
						data-html={true}
						data-for="myTooltip"
					>
						<NotificationEye onClick={() => readOnce(item.id)} />
					</div>
				</div>
			) : (
				<div className="list-notifications__icon is-read">
					<div className={'list-notifications__buton'}>
						<NotificationEyeOff />
					</div>
				</div>
			)}
		</li>
	)
}
