import React from 'react'

export const EventsItem = ({ image, icon, count, text }) => {
	return (
		<div className="card-events-points">
			<div className="card-events-points__bg">
				<img src={image} title="фото мероприятия" alt="bg" />
			</div>
			<div className="card-events-points__body">
				<div className="card-events-points__head">
					<div className="card-events-points__title h7">{count} баллов</div>
					<div className="card-events-points__subtitle p7">{text}</div>
				</div>
				<div className="card-events-points__icon">
					<img src={icon} alt="icon" />
				</div>
			</div>
		</div>
	)
}
