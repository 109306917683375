import React from 'react'

import defaultUserLogo from '../../static/img/user_default.png'
import { normalizedImageSrc } from '../../utils/helpers'
import clsx from 'clsx'

export const UserAssist = ({ user, onClick, selected }) => {
	const onSelectAssist = () => {
		onClick(user)
	}
	return (
		<div
			className={clsx('user__card card user-assist__card', {
				['--selected']: selected,
			})}
			onClick={onSelectAssist}
		>
			<div className="user__card-head">
				<div className="user__card-photo">
					<img
						className="img_cover"
						src={user.avatar ? normalizedImageSrc(user?.avatar) : defaultUserLogo}
						title="фото пользователя"
						alt="user-avatar"
					/>
				</div>
			</div>
			<div className="user__card-body">
				{user?.callname && <div className="user__card-nickname p1">{user?.callname}</div>}
				<div className="user__card-name p6">
					{user?.name ? user?.name : ''} {user?.lastname ? user?.lastname : ''}
				</div>
			</div>
		</div>
	)
}
