import React from 'react'
import { formateDate } from 'utils/helpers'

export default function PaymentItem({ status, icon, id, date, name, payment, by_deposit, title }) {
	return (
		<div className="game-history-table__row">
			<div className="game-history-table__col col-status p13">
				<img
					src={icon}
					alt={status === 'success' ? 'Оплата прошла успешно' : 'Ошибка при оплате'}
					data-tip={status === 'success' ? 'Оплата прошла успешно' : 'Ошибка при оплате'}
					data-for="myTooltip"
					className="status"
				/>
			</div>
			<div className="game-history-table__col col-id p13"># {id}</div>
			<div className="game-history-table__col col-date">{formateDate(date, 'dd.MM.yyyy')}</div>
			<div className="game-history-table__col col-name">{title}</div>
			<div className="game-history-table__col col-payment">{payment} &#8381;</div>
			{by_deposit ? (
				<div className={`game-history-table__col col-deposit active`}>– {by_deposit} &#8381;</div>
			) : (
				<div className={`game-history-table__col col-deposit ${by_deposit ? 'active' : ''}`}>—</div>
			)}
		</div>
	)
}
