import React from 'react'
import { BackBtn } from '../components/Buttons/BackBtn/BackBtn'
import { Button } from '../components/Buttons/Button'
import F from '../static/img/payment-failed-icon.svg'

export default function Failed() {
	return (
		<div className="lk-container payment-status payment-status_success">
			<div className="payment-status__img">
				<img src={F} alt="icon" />
			</div>
			<div className="payment-status__container">
				<h4 className="lk-container-title h4">К сожалению, оплата не прошла</h4>
				<div className="payment-status__body">
					<div className="p6 payment-status__text">
						<p>
							Возможно вы ввели неккоректные данные или произошла ошибка. <br /> Попробуйте повторить платеж позже.
						</p>
					</div>
					<div className="payment-status__buttons">
						<Button
							type="navlink"
							href="/"
							text="Перейти в профиль"
							className="lk-container-empty__btn button large yellow"
						/>
						<Button
							type="link"
							href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
							text="Вернуться к мероприятиям"
							className="lk-container-empty__btn button large lightyellow"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
