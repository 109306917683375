import React from 'react'

import { getTeamMembers } from '../../../services/Config'
import { LoaderMini } from '../../LoaderMini'

import { Pagination } from '../../Pagination/Pagination'
import { UserCard } from '../../User/UserCard'

export const Members = ({ id }) => {
	const defaultLimit = 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamMembers, setTeamMembers] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const fetchMembers = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamMembers(id, limit, offset)
			setTeamMembers(res.response)
		} catch (error) {
			console.log('fetchMembers error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		setPage(1)
		fetchMembers()
	}, [id])

	React.useEffect(() => {
		fetchMembers()
	}, [page])

	const changePage = (num) => {
		setPage(num)
	}
	return isLoad ? (
		<LoaderMini />
	) : (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">Состав</h4>
			</div>
			<div className="lk-container__content">
				{teamMembers?.list?.length > 0 ? (
					<div className="users__cards">
						{teamMembers?.list?.map((user) => (
							<UserCard key={user.id} user={user} fromAnotherTeam="true" />
						))}
					</div>
				) : (
					<div className="lk-container-empty__text p4">В этой команде никто не состоит</div>
				)}

				{teamMembers?.total_count > defaultLimit && (
					<div className="pagination">
						<Pagination changePage={changePage} page={page} length={teamMembers?.total_count} limit={defaultLimit} />
						<div className="pagination__count p13">
							Показано {defaultLimit * page > teamMembers?.total_count ? teamMembers?.total_count : defaultLimit * page}{' '}
							из {teamMembers?.total_count}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
