import { useActions } from 'hooks/useActions'
import React from 'react'
import { deleteTeamDeputy, getTeamDeputies, setTeamDeputy } from '../../../services/Config'
import LoaderMini from '../../LoaderMini'
import { SetTeamAssistsModal } from '../../Modals/SetTeamAssistsModal'
import { ChangeCaptainModal } from 'components/Modals/ChangeCaptainModal'
import { DeleteDeputyModal } from '../../Modals/DeleteDeputyModal'
import { Pagination } from '../../Pagination/Pagination'
import { UserCard } from '../../User/UserCard'
import { notifyUser, notifyUserError } from '../../../services/Pnotify'
import { useSelector } from 'react-redux'
import { transferRights, getProfile } from '../../../services/Config'
import { useNavigate } from 'react-router-dom'
import { routeNames } from 'router'
import { useDispatch } from 'react-redux'
import { userTypes } from 'store/types/user'

export const CommanderAssists = ({ reRender, teamCaptain = false }) => {
	const [addAssistsModal, setAddAssistsModal] = React.useState(false)
	const [deleteAssistsModal, setDeleteAssistsModal] = React.useState(false)
	const [changeCaptainModal, setChangeCaptainModal] = React.useState(false)
	const [currentUser, setCurrentUser] = React.useState({})
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const defaultLimit = 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamMembers, setTeamMembers] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)
	const { loadingData } = useActions()
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	React.useEffect(() => {
		if (reRender) fetchMembers()
	}, [reRender, page])

	const fetchMembers = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamDeputies(limit, offset)
			setTeamMembers(res.data)
		} catch (error) {
			console.log('fetchMembers error', error)
		} finally {
			setIsLoad(false)
		}
	}

	const changePage = (num) => {
		setPage(num)
	}

	const removeAssist = async (user) => {
		try {
			loadingData(true)
			const res = await deleteTeamDeputy(user.id)
			notifyUser('Заместитель успешно разжалован')
			hideDeleteAssistModal()
			fetchMembers()
		} catch (error) {
			notifyUserError('Ошибка разжалования заместителя')
		} finally {
			loadingData(false)
		}
	}

	const showAddAssistsModal = () => {
		if (teamCaptain) {
			setAddAssistsModal(true)
		} else {
			notifyUser('Только капитан может назначать заместителей')
		}
	}
	const showChangeCaptainModal = () => {
		if (teamCaptain) {
			setChangeCaptainModal(true)
		} else {
			notifyUser('Только капитан может назначить нового капитана')
		}
	}
	const hideAddAssistsModal = () => {
		setAddAssistsModal(false)
	}
	const hideChangeCaptainModal = () => {
		setChangeCaptainModal(false)
	}

	const addAssists = async (users) => {
		try {
			loadingData(true)
			const res = await setTeamDeputy(users[0].id)
			notifyUser('Приглашение отправлено')
			hideAddAssistsModal()
		} catch (error) {
			notifyUserError('Ошибка отправки приглашения')
		} finally {
			loadingData(false)
		}
	}

	const showDeleteAssistsModal = (user) => {
		setDeleteAssistsModal(true)
		setCurrentUser(user)
	}
	const hideDeleteAssistModal = () => {
		setDeleteAssistsModal(false)
	}

	const teamChangeCaptaion = async (user) => {
		if (user) {
			try {
				loadingData(true)
				await transferRights(user.id)
				hideChangeCaptainModal()
				const res = await getProfile()
				dispatch({
					type: userTypes.SET_USER_DATA,
					payload: res.data,
				})
				navigate(routeNames.TEAM.path)
				notifyUser('Вы передали роль капитана')
			} catch (error) {
				notifyUserError('Ошибка при передаче прав командира')
			} finally {
				loadingData(false)
			}
		} else {
			notifyUserError('Выберите пользователя')
		}
	}

	return isLoad ? (
		<LoaderMini />
	) : (
		<>
			<div className="users__cards">
				{teamMembers?.members?.map((user) => (
					<UserCard onReject={showDeleteAssistsModal} key={user.id} user={user} fromAssists="true" />
				))}

				<div onClick={showAddAssistsModal} className="user__card new-user__card">
					<div className="new-user__card-logo" />
					<div className="new-user__card-text p7">
						Добавить
						<br /> зам. командира
					</div>
				</div>
				<div onClick={showChangeCaptainModal} className="user__card new-user__card">
					<div className="new-user__card-logo" />
					<div className="new-user__card-text p7">
						Передать
						<br /> полномочия командира
					</div>
				</div>
			</div>
			{teamMembers?.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={teamMembers?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {teamMembers?.members?.length} из {teamMembers?.total_count}
					</div>
				</div>
			)}
			<ChangeCaptainModal
				isShown={changeCaptainModal}
				hideModal={hideChangeCaptainModal}
				teamChangeCaptaion={teamChangeCaptaion}
			/>
			<SetTeamAssistsModal isShown={addAssistsModal} hideModal={hideAddAssistsModal} addAssistsCb={addAssists} />
			<DeleteDeputyModal
				isShown={deleteAssistsModal}
				hideModal={hideDeleteAssistModal}
				deleteUserCb={removeAssist}
				user={currentUser}
			/>
		</>
	)
}
