import React from 'react'

import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { useParams, useNavigate } from 'react-router-dom'

import { About } from '../components/Profile/Personal/About'
import { Achievement } from '../components/Profile/Personal/Achievement'
import { Game } from '../components/Profile/Personal/Game'
import { Review } from '../components/Profile/Personal/Review'
import { Titles } from '../components/Profile/Another/Titles'
import { SideInfo } from '../components/User/SideInfo'
import { Articles } from 'components/Profile/Personal/Articles'

import { getUser, getUserArticles } from '../services/Config'
import { routeNames } from '../router'

export const User = () => {
	const { loadingData } = useActions()
	const { id } = useParams()
	const navigate = useNavigate()
	const { isAuth, userData: userState } = useSelector((state) => state.user)
	const [userData, setUserData] = React.useState(null)
	const [articles, setArticles] = React.useState(null)

	React.useEffect(() => {
		if (isAuth && userState.user.id == id) {
			navigate(routeNames.PROFILE.path)
		}
		const fetchUserData = async () => {
			try {
				loadingData(true)
				const response = await getUser(id)
				const res = await getUserArticles(id)
				setUserData(response.data)
				setArticles(res.data)
			} catch (error) {
				navigate(routeNames.NOTFOUND.path)
			} finally {
				loadingData(false)
			}
		}

		fetchUserData()
	}, [id])

	return (
		<section className="section-lk">
			<div className="lk-cols">
				{userData && (
					<>
						<div className="lk-col">
							<Titles titles={userData?.user?.titles} points={userData?.user?.point} guest={userData?.user?.guest} />
							<Achievement anotherUser="true" achievements={userData.user.achievements} />
							<About anotherUser="true" bio={userData.user.bio} />
							<Game id={id} anotherUser="true" />
							{/* <Review id={userData.user.last_review_id} anotherUser="true" /> */}
							{articles?.articles.length > 0 && <Articles articles={articles} userData={userData} profile={true} />}
						</div>

						<SideInfo anotherUser="true" id={id} friends={userData.friends} user={userData.user} />
					</>
				)}
			</div>
		</section>
	)
}
