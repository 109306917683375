import React from 'react'

import { NavLink, useNavigate } from 'react-router-dom'

import UserDefault from '../../static/img/user_default.png'
import TeamDefault from '../../static/img/team_default.png'
import VIcon from '../../static/img/lk/lk-list-link-ico-1.svg'
import FIcon from '../../static/img/lk/lk-list-link-ico-2.svg'
import DIcon from '../../static/img/lk/lk-list-link-ico-3.svg'
import IIcon from '../../static/img/lk/lk-list-link-ico-4.svg'
import { Button } from '../Buttons/Button'

import { ReactComponent as UserIcon } from '../../static/img/icons/add-user-icon-white.svg'
import { ReactComponent as UserIconFriend } from '../../static/img/icons/friend-user-icon-white.svg'
import { ReactComponent as UserIconRequest } from '../../static/img/icons/request-user-icon-white.svg'
import { ReactComponent as MessageIcon } from '../../static/img/icons/message-icon-white.svg'
import { routeNames } from '../../router'
import { declOfNum, normalizedImageSrc } from '../../utils/helpers'
import { addFriend, createDialog, addFriendFromRequest, getProfileFriendsRequests } from '../../services/Config'
import { notifyUser, notifyUserError } from '../../services/Pnotify'
import { useSelector } from 'react-redux'
import { useActions } from 'hooks/useActions'
import { isChatExist } from 'utils/helpers'
import SocialItem from './SocialItem'
import {AvatarModal} from "../Modals/AvatarModal";

export const SideInfo = ({ anotherUser, id, user, friends = [] }) => {
	const {
		isAuth,
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const [requestFromHim, setRequestFromHim] = React.useState(null)
	const [friend, setFriend] = React.useState(
		friends.length < 1 ? false : userState ? userState.id === friends.filter((e) => e.id === userState.id)[0]?.id : friends
	)
	const [requestFriend, setRequestFriend] = React.useState(user.request ?? false)
	// const [confrimRequestFriend, setConfirmRequestFriend] = React.useState(false)
	const dialogs = useSelector((state) => state.dialogs)
	const navigate = useNavigate()
	const { loadingData } = useActions()

	const [isAvatarModal, setIsAvatarModal] = React.useState(false)

	const showAvatarModal = () => {
		setIsAvatarModal(true)
	}
	const hideAvatarModal = () => {
		setIsAvatarModal(false)
	}

	const checkInvalid = (vk) => {
		const url = document.createElement('a')
		url.href = vk

		if (url.host === "vk.com" && url.pathname.length > 1 && url.protocol === 'https:') return true
		return false
	}

	const addToFriends = async (fromHim) => {
		try {
			let res
			fromHim ? (res = res = await addFriendFromRequest(id)) : await addFriend(id)
			fromHim ? notifyUser('Заявка подтверждена') : notifyUser('Заявка отправлена')
			fromHim ? setFriend(true) : setRequestFriend(true)
		} catch (error) {
			notifyUserError('Не удалось отправить заявку в друзья')
		}
	}

	const startChat = async (id) => {
		try {
			loadingData(true)
			const res = await createDialog({ user_id: id })
			navigate(routeNames.MESSAGES.path + res.data.chat.id)
		} catch (error) {
			notifyUserError('Ошибка создания диалога')
		} finally {
			loadingData(false)
		}
	}

	const hasSocials = Object.keys(user.socials).filter((s) => user.socials[s].name).length > 0

	const getIcon = (name) => {
		switch (name) {
			case 'vk':
				return VIcon
			case 'discord':
				return DIcon
			case 'facebook':
				return FIcon
			case 'instagram':
				return IIcon
			default:
				return VIcon
		}
	}

	React.useEffect(() => {
		const getFriendsRequest = async () => {
			try {
				const res = await getProfileFriendsRequests(1000, 0)
				setRequestFromHim(res.data.list.find((item) => item.id === +id) ? true : false)
			} catch (error) {
				notifyUserError('Не удалось получить список запросов в  друзья')
			}
		}
		if (isAuth) {
			getFriendsRequest()
		}
		
	}, [])

	return (
		<div className="user-sideinfo">
			<div className="user-sideinfo__main">
				<div className="user-sideinfo__avatar">
					<div className="user-sideinfo__avatar-person">
						<a onClick={showAvatarModal}>
							<img
								className="img_cover"
								src={user.avatar ? normalizedImageSrc(user.avatar) : UserDefault}
								alt={user.name}
								title="аватар пользователя"
							/>
						</a>
						<AvatarModal isShow={isAvatarModal} hideModal={hideAvatarModal} img={user.avatar ? normalizedImageSrc(user.avatar) : UserDefault}/>
					</div>
					{/* {user.team_id && */}
					{user.team &&
						(anotherUser ? (
							<NavLink to={routeNames.TEAM.path + user.team_id} className="user-sideinfo__avatar-team">
								<img
									className="img_cover"
									src={user?.team?.avatar ? normalizedImageSrc(user?.team?.avatar) : TeamDefault}
									alt="team-logo"
									title="лого команды"
								/>
							</NavLink>
						) : (
							<NavLink to={routeNames.TEAMPROFILE.path} className="user-sideinfo__avatar-team">
								<img
									className="img_cover"
									src={user?.team?.avatar ? normalizedImageSrc(user?.team?.avatar) : TeamDefault}
									alt="team-logo"
									title="лого команды"
								/>
							</NavLink>
						))}
				</div>
				<div className="user-sideinfo__personinfo">
					{(user.name || user.lastname) && (
						<div className="user-sideinfo__personinfo-name p4">
							{user.name ? user.name : ''} {user.lastname ? user.lastname : ''}
						</div>
					)}
					{user.callname && <h5 className="user-sideinfo__personinfo-callname h5">{user.callname}</h5>}
					<div className="user-sideinfo__personinfo-other">
						{user.age > 0 && (
							<span className="p6">
								{user.age} {declOfNum(user.age, ['год', 'года', 'лет'])}, {user.city}
							</span>
						)}
						{user.start_year && <span className="p6">в страйкболе с {user.start_year.split('-')[0]}г</span>}
					</div>
				</div>
			</div>
			{!user.isProfileFilled && !anotherUser && (
				<NavLink to={routeNames.PROFILEEDIT.path} className="user-sideinfo__personinfo-note">
					<div className="user-sideinfo__personinfo-note-ico"></div>
					<div className="user-sideinfo__personinfo-note-text p14">
						Заполните все поля профиля <br />и получите <b>+ 30 баллов</b>
					</div>
				</NavLink>
			)}
			{isAuth ? 
			(anotherUser && (
					<div className="user-sideinfo__btns">
						{friend ? (
							<Button
								className="user-sideinfo__btn button blue"
								type="button"
								Icon={UserIconFriend}
								iconSide="left"
								text="В друзьях"
							/>
						) : requestFriend ? (
							<Button
								className="user-sideinfo__btn button blue"
								type="button"
								Icon={UserIconRequest}
								iconSide="left"
								text="Заявка отправлена"
							/>
						) : requestFromHim ? (
							<Button
								onClick={() => addToFriends(true)}
								className="user-sideinfo__btn button blue"
								type="button"
								text="Подтвердить заявку в друзья"
							/>
						) : (
							<Button
								onClick={() => addToFriends()}
								className="user-sideinfo__btn button blue"
								type="button"
								Icon={UserIcon}
								iconSide="left"
								text="Добавить в друзья"
							/>
						)}
						{isChatExist(id, dialogs) ? (
							<Button
								className="user-sideinfo__btn button blue"
								type="navlink"
								Icon={MessageIcon}
								iconSide="left"
								text="Написать сообщение"
								href={routeNames.MESSAGES.path + isChatExist(id, dialogs)}
							/>
						) : (
							<Button
								onClick={() => startChat(id)}
								className="user-sideinfo__btn button blue"
								type="button"
								Icon={MessageIcon}
								iconSide="left"
								text="Написать сообщение"
							/>
						)}
					</div>
				)
			) : 
			(<></>)}
			{}
			{friends && friends.length > 0 && (
				<div className="user-sideinfo__list --friends">
					<div className="user-sideinfo__list-title p13">Друзья</div>
					<div className="user-sideinfo__list-friends">
						{friends.length > 6 ? (
							<>
								{friends.slice(0, 6).map((friend) => (
									<NavLink
										key={friend.id}
										to={routeNames.USER.path + friend.id}
										className="user-sideinfo__list-friend"
										title="ссылка на страницу друга"
									>
										<img
											src={process.env.REACT_APP_IMAGES_URL + friend.avatar}
											className="img_cover"
											alt={friend.name}
											title={friend.name}
										/>
									</NavLink>
								))}
								<NavLink to={routeNames.FRIENDS.path} className="user-sideinfo__list-friend">
									<span>+ {friends.length - 6}</span>
								</NavLink>
							</>
						) : (
							<>
								{' '}
								{friends.slice(0, 6).map((friend) => (
									<NavLink
										key={friend.id}
										to={routeNames.USER.path + friend.id}
										className="user-sideinfo__list-friend"
										title="ссылка на страницу друга"
									>
										<img
											className="img_cover"
											src={process.env.REACT_APP_IMAGES_URL + friend.avatar}
											alt={friend.name}
											title={friend.name}
										/>
									</NavLink>
								))}
							</>
						)}
					</div>
				</div>
			)}
			{hasSocials ? (
				<div className="user-sideinfo__list">
					<div className="user-sideinfo__list-title p13">Ссылки</div>
					<ul className="user-sideinfo__links">
						{/* {Object.keys(user.socials).map((s, i) => {
							return !user.socials[s].name ? null : <SocialItem icon={getIcon(s)} key={i} text={user.socials[s].name} />
						})} */}
						{!user?.socials?.vk?.name || !checkInvalid(user?.socials?.vk?.name) ? null : <SocialItem icon={getIcon('vk')} text={user.socials.vk.name} type='open' />}
						{!user?.socials?.discord?.name ? null : (
							<SocialItem icon={getIcon('discord')} text={user.socials.discord.name} type='copy' />
						)}
					</ul>
				</div>
			) : null}
		</div>
	)
}
