import React from 'react'
import DefaultAvatar from 'static/img/user_default.png'
import { getTeamAttendance } from 'services/Config'
import { LoaderMini } from 'components/LoaderMini.jsx'
import { Pagination } from 'components/Pagination/Pagination'
import { normalizedImageSrc } from 'utils/helpers'
import { UserStatistic } from 'components/Modals/UserStatistic'

export const Attendance = () => {
	const defaultLimit = 5
	const defaultOffset = 0

	const [isLoad, setIsLoad] = React.useState(false)
	const [attendance, setAttendance] = React.useState({})
	const [page, setPage] = React.useState(1)
	const [userModal, setUserModal] = React.useState(false)
	const [user, setUser] = React.useState(null)
	const changePage = (num) => {
		setPage(num)
	}
	const findGame = (item, name) => {
		if (name === 'Суточная игра') {
			const count = item.games.find((elem) => elem.title === 'Суточная игра')
				? item.games.find((elem) => elem.title === 'Суточная игра').count
				: item.games.find((elem) => elem.title === 'Суточная').count
			return count
		} else {
			return item.games.find((elem) => elem.title === name).count
		}
	}
	const fetchAttendance = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamAttendance(limit, offset)
			setAttendance(res.data)
		} catch (error) {
			console.log('e', error)
		} finally {
			setIsLoad(false)
		}
	}
	const openUserModal = (user) => {
		setUser(user)
		setUserModal(true)
	}
	const hideUserModal = () => {
		setUserModal(false)
	}
	React.useEffect(() => {
		fetchAttendance()
	}, [page])
	return (
		<div className="attendance-statistic">
			<div className="attendance-statistic__title h4">Посещаемость игр</div>
			<div className="attendance-statistic__content">
				{isLoad ? (
					<LoaderMini />
				) : attendance?.list?.length > 0 ? (
					<>
						<div className="attendance-statistic__titles">
							<div className="attendance-statistic__label">Игрок</div>
							<div className="attendance-statistic__label">Дневных</div>
							<div className="attendance-statistic__label">Суточных</div>
							<div className="attendance-statistic__label">Челленджей</div>
							<div className="attendance-statistic__label">Ночных</div>
							<div className="attendance-statistic__label">Баллы</div>
						</div>
						<div className="attendance-statistic__body">
							{attendance.list.map((item) => (
								<div className="attendance-statistic__row" key={item.user.id}>
									<div className="attendance-statistic__col">
										<div className="attendance-statistic__user" onClick={() => openUserModal(item.user)}>
											<div className="attendance-statistic__avatar">
												<img
													src={item.user.avatar ? normalizedImageSrc(item.user.avatar) : DefaultAvatar}
													alt="user avatar"
												/>
											</div>
											<div className="attendance-statistic__callname p3">{item.user.callname}</div>
										</div>
									</div>
									<div className="attendance-statistic__col">
										<div className="attendance-statistic__count p13">{findGame(item, 'Дневная игра')}</div>
									</div>
									<div className="attendance-statistic__col">
										<div className="attendance-statistic__count p13">{findGame(item, 'Суточная игра')}</div>
									</div>
									<div className="attendance-statistic__col">
										<div className="attendance-statistic__count p13">{findGame(item, 'Челленж')}</div>
									</div>
									<div className="attendance-statistic__col">
										<div className="attendance-statistic__count p13">{findGame(item, 'Ночная игра')}</div>
									</div>
									<div className="attendance-statistic__col">
										<div className="attendance-statistic__count p12">{item.point}</div>
									</div>
								</div>
							))}
						</div>
					</>
				) : (
					<div className="p2">Ещё нет данных для статистики</div>
				)}
			</div>
			{attendance?.total_count > defaultLimit && (
				<div className="statistic__pagination">
					<Pagination changePage={changePage} page={page} length={attendance?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {defaultLimit * page >= attendance?.total_count ? attendance?.total_count : defaultLimit * page} из{' '}
						{attendance?.total_count}
					</div>
				</div>
			)}
			{user && (
				<UserStatistic
					isShow={userModal}
					hideModal={hideUserModal}
					user={user}
					setUser={setUser}
					fetchAttendance={fetchAttendance}
				/>
			)}
		</div>
	)
}
