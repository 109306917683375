import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Main } from '../components/Team/Personal/Main'
import { Guest } from '../components/Team/Personal/Guest'
import { About } from '../components/Team/Personal/About'
import { Game } from '../components/Team/Personal/Game'
import { TeamMembers } from '../components/Team/Personal/TeamMembers'
import { TeamFriends } from '../components/Team/Personal/TeamFriends'
import { TeamCreateModal } from '../components/Modals/TeamCreateModal'
import { useActions } from '../hooks/useActions'
import { routeNames } from '../router'
import { getTeam } from '../services/Config'
import { notifyUserError } from '../services/Pnotify'
import { Achievement } from 'components/Profile/Personal/Achievement'
import Library from "../components/Library/Library";

export const TeamProfile = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [openTeamCreateModal, setOpenTeamCreateModal] = React.useState(false)
	const { leaveTeamAction, loadingData } = useActions()
	const [team, setTeam] = useState({})

	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	React.useEffect(() => {
		if (!userState?.team?.id) {
			navigate(routeNames.PROFILE.path)
		}
	}, [navigate, userState?.team?.id])

	const showTeamCreateModal = () => {
		setOpenTeamCreateModal(true)
	}
	const hideTeamCreateModal = () => {
		setOpenTeamCreateModal(false)
		location.search = ''
	}

	const fetchTeam = async () => {
		try {
			loadingData(true)
			const response = await getTeam(userState?.team_id)
			setTeam(response.data)
		} catch (error) {
			notifyUserError('Ошибка получения данных о команде')
		} finally {
			loadingData(false)
		}
	}

	React.useEffect(() => {
		const currSearch = location.search
		if (currSearch.indexOf('ppCreate=true') !== -1) {
			showTeamCreateModal()
		}
	}, [])

	React.useEffect(() => {
		userState?.team_id && fetchTeam()
	}, [userState?.team_id])

	const leaveTeam = async () => {
		try {
			const res = await leaveTeamAction()
			navigate(routeNames.PROFILE.path)
		} catch (e) {
		} finally {
		}
	}

	return (
		<section className="section-lk">
			<div className="lk-cols">
				{userState?.team && (
					<div className="lk-col lk-col__team">
						<Main
							team={team}
							leaveTeam={leaveTeam}
							anotherTeam={false}
							teamCaptain={team?.commander_id === userState?.id}
							teamDeputy={team?.second_commander?.filter((s) => s.id === userState?.id).length > 0}
							members_count={team?.team_members?.length ?? 0}
						/>
						{team?.commander_id === userState?.id && (
							<Guest
								inClub={!userState?.guest}
								teamId={team?.id}
								canClubRequest={team?.canClubRequest}
								clubRequested={team?.clubRequest}
							/>
						)}
						<Achievement achievements={userState?.team?.achievements} team={true} />
						<About
							about={userState?.team?.description}
							ds={userState?.team?.soc_discord}
							vk={userState?.team?.soc_vk}
							site={userState?.team?.soc_site}
						/>
						<div className="lk-container">
							<Library edit={false} type={'team'}/>
						</div>
						<Game id={userState?.team?.id} />
						<TeamMembers
							teamDeputy={team?.second_commander?.filter((s) => s.id === userState?.id).length > 0}
							teamCaptain={team?.commander_id === userState?.id}
						/>
						<TeamFriends
							teamDeputy={team?.second_commander?.filter((s) => s.id === userState?.id).length > 0}
							teamCaptain={team?.commander_id === userState?.id}
						/>
					</div>
				)}
			</div>

			<TeamCreateModal
				isShown={openTeamCreateModal}
				hideModal={hideTeamCreateModal}
				name={userState?.team?.name}
				logoSrc={userState?.team?.avatar}
			/>
		</section>
	)
}
