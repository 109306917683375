import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import EmptyBlock from 'components/EmptyBlock'
import { NotificationsList } from 'components/Notifications/NotificationsList.jsx'
import { Button } from '../components/Buttons/Button'
import { Pagination } from '../components/Pagination/Pagination'
import EmptyNotifications from '../static/img/icons/EmptyBlock/notifications.svg'
import { getProfileNotifications, setProfileNotificationsAll, setProfileNotification } from 'services/Config'
import LoaderMini from 'components/LoaderMini'
import { notifyUser, notifyUserError } from '../services/Pnotify'

export const Notifications = () => {
	const defaultLimit = 8
	const defaultOffset = 0

	const notifs = useSelector((state) => state.notifications)
	const [page, setPage] = useState(1)
	const [notifications, setNotifications] = useState({})
	const [isLoad, setIsLoad] = useState(false)

	const fetchNots = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getProfileNotifications(limit, offset)
			const notifies = res.data.notify
			let newNotifies = {}
			let data = []
			for (const key in notifies) {
				let object = {}
				object.date = key
				object.list = notifies[key]
				data.push(object)
			}
			newNotifies.data = data
			newNotifies.total_count = res.data.total_count
			newNotifies.total_unread_count = res.data.total_unread_count
			setNotifications(newNotifies)
		} catch (error) {
			console.log('fetchNots error', error)
		} finally {
			setIsLoad(false)
		}
	}

	useEffect(() => {
		fetchNots()
	}, [page, notifs?.total_count])

	const changePage = (num) => {
		setPage(num)
	}

	const readAll = async () => {
		if (notifications.total_unread_count === 0) {
			notifyUserError('Все уведомления  уже прочитаны')
		} else {
			try {
				const res = await setProfileNotificationsAll()
				fetchNots()
				notifyUser('Все уведомления прочитаны')
			} catch (error) {
				console.log('Read notifications error: ', error)
			}
		}
	}

	const readOnce = async (id) => {
		try {
			const res = await setProfileNotification(id)
			fetchNots()
			notifyUser('Уведомление прочитано')
		} catch (error) {
			console.log('Read notification error: ', error)
		}
	}
	return (
		<div className="lk-container">
			<div className="notifications__header header-notifications">
				<div className="header-notifications__left">
					<h4 className="header-notifications__title h4">Уведомления</h4>
					{notifications?.data?.length > 0 && (
						<p className="header-notifications__subtitle p6">Показаны уведомления за последние 2 недели</p>
					)}
				</div>
				{notifications?.data?.length > 0 && (
					<>
						<Button
							onClick={readAll}
							type={'button'}
							iconSide="left"
							className="header-notifications__button button lightyellow"
							text="Отметить все как прочитанные"
						/>
					</>
				)}
			</div>
			{isLoad ? (
				<LoaderMini />
			) : notifications?.data?.length > 0 ? (
				<div className="lk-container__content notifications__content">
					{notifications.data.map((notification) => (
						<NotificationsList
							key={notification.date}
							notifications={notification.list}
							date={notification.date}
							readOnce={readOnce}
						/>
					))}
				</div>
			) : (
				<EmptyBlock
					img={EmptyNotifications}
					title={'Ваши уведомления'}
					text="Здесь будут отоброжаться уведомления вашего профиля и команды"
					buttonLink={process.env.REACT_APP_FRONTEND_URL + 'payment'}
					buttonText="Мероприятия"
				/>
			)}
			{notifications?.total_count > defaultLimit && (
				<div className="notifications__pagination pagination">
					<Pagination
						changePage={changePage}
						page={page}
						length={notifications?.total_count}
						limit={defaultLimit}
						finalPages={true}
					/>
					<div className="pagination__count p13">
						Показано {defaultLimit * page > notifications.total_count ? notifications.total_count : defaultLimit * page}{' '}
						из {notifications.total_count}
					</div>
				</div>
			)}
		</div>
	)
}
