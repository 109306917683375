import clsx from 'clsx'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ReactComponent as CloseIcon } from '../../static/img/icons/close-icon-small-black.svg'
import { ReactComponent as UserIcon } from '../../static/img/icons/user-icon-white.svg'
import { ReactComponent as MapPinIcon } from '../../static/img/icons/map-pin-icon-white.svg'
import { declOfNum, normalizedImageSrc } from '../../utils/helpers'
import defaultTeamLogo from '../../static/img/team_default.png'
import { Button } from '../Buttons/Button'

export const TeamCard = ({ team, fromFriends, fromFriendsRequests, onConfirm, onReject, onDelete, captain }) => {
	const [openMenu, setOpenMenu] = React.useState(false)
	const menu = React.useRef()

	const onOpenMenu = () => {
		setOpenMenu((prev) => !prev)
	}
	const onCloseMenu = () => {
		setOpenMenu(false)
	}
	useOnClickOutside(menu, onCloseMenu)
	return (
		<div className="team__card" title="ссылка на профиль команды">
			<NavLink to={`/team/${team.id}`} className="team__card-hidden-link" title="ссылка на профиль команды"></NavLink>
			<div className="team__card-head">
				<img
					className="img_cover"
					// src={normalizedImageSrc(team.avatar)}
					src={team.avatar ? process.env.REACT_APP_IMAGES_URL + team.avatar : defaultTeamLogo}
					alt="team-logo"
					title="логотип команды"
				/>
			</div>

			{fromFriends && captain && (
				<div
					ref={menu}
					className={clsx('team__card-menu card-menu', {
						active: openMenu,
					})}
				>
					<span onClick={onOpenMenu} className="card-menu-head">
						<span />
						<span />
						<span />
					</span>
					<div className="card-menu-body">
						<button onClick={() => onDelete(team)} className="card-menu-link p5" title="кнопка исключения из друзей">
							<CloseIcon className="card-menu-link-icon" />
							<div className="card-menu-link-text">Удалить команду из друзей</div>
						</button>
					</div>
				</div>
			)}

			<div className="team__card-body">
				<div className="team__card-body-head">
					<h5 className="team__card-title h5">{team.name}</h5>
					<div className="team__card-subtitle p11">{team.points} баллов</div>
				</div>
				<div className="team__card-info">
					<div className="team__card-info-item p13 --members">
						<UserIcon className="team__card-info-icon" />
						<div className="team__card-info-text">
							{team.members_count} {declOfNum(team.members_count, ['участник', 'участника', 'участников'])}
						</div>
					</div>
					{team.city && (
						<div className="team__card-info-item p13 --city">
							<MapPinIcon className="team__card-info-icon" />
							<div className="team__card-info-text">{team.city}</div>
						</div>
					)}
				</div>
				{fromFriendsRequests && captain && (
					<div className="team__card-btns">
						<Button
							onClick={() => onConfirm(team.id)}
							type="button"
							className="team__card-btn button medium yellow"
							text="Принять"
						/>
						<Button
							onClick={() => onReject(team.id)}
							type="button"
							className="team__card-btn button medium lightyellow"
							text="Отклонить"
						/>
					</div>
				)}
			</div>
		</div>
	)
}
