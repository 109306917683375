import React, { useState, useRef } from 'react'
import { normalizeDate } from 'utils/helpers'

export const HistoryItem = ({ id, status, title, updated_at, points_count, text, onChange, isOpen }) => {
	const [isBtnShown, setIsBtnShown] = useState(false)
	const [activeHeight, setActiveHeight] = useState(null)

	const textWrapperRef = useRef()
	const textRef = useRef()

	React.useEffect(() => {
		const textWrapperHeight = textWrapperRef.current.clientHeight
		const textHeight = textRef.current.clientHeight
		const showBtn = textHeight > textWrapperHeight

		setIsBtnShown(showBtn)
		setActiveHeight(textHeight)
	}, [])
	return (
		<div className="history-points__item item-history-points">
			<div className="item-history-points__body">
				<div className="item-history-points__count">
					<span className={status === 'replenishment' ? 'p12 ' : 'p12 red'}>
						{points_count > 0 ? `+${points_count}` : points_count}
					</span>
				</div>
				<div className="item-history-points__content">
					<div className="item-history-points__title p9">{title}</div>
					<div
						ref={textWrapperRef}
						style={isOpen && activeHeight ? { maxHeight: activeHeight } : {}}
						className="item-history-points__text p13"
					>
						<div ref={textRef}>{text}</div>
					</div>
					{isBtnShown && (
						<button
							onClick={() => onChange(id)}
							className="item-history-points__button p14"
							title="кнопка раскрыть/закрыть текст"
						>
							{isOpen ? <>Свернуть</> : <>Читать полностью</>}
						</button>
					)}
				</div>
				<span className="item-history-points__date p14">{normalizeDate(updated_at)}</span>
			</div>
		</div>
	)
}
