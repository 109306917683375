import { userTypes } from '../types/user'

const initialState = {
	userData: {},
	isAuth: false,
	isLoading: false,
	isUserUpdating: false,
	authFormType: 'login',
}

export default function SomeFunc(state = initialState, action) {
	if (action.type === userTypes.SET_USER_LOADING) {
		return {
			...state,
			isLoading: action.payload,
		}
	}
	if (action.type === userTypes.SET_USER_UPDATING) {
		return {
			...state,
			isUserUpdating: action.payload,
		}
	}
	if (action.type === userTypes.SET_USER_DATA) {
		return {
			...state,
			userData: action.payload,
			isAuth: true,
		}
	}
	if (action.type === userTypes.EDIT_USER_DATA) {
		return {
			...state,
			userData: { user: action.payload },
		}
	}
	if (action.type === userTypes.UPDATE_USER_DATA) {
		const newData = { ...state.user }
		Object.entries(action.payload).forEach(([k, v]) => {
			newData[k] = v
		})
		return {
			...state,
			userData: newData,
		}
	}
	if (action.type === userTypes.LOGOUT) {
		return {
			...state,
			isAuth: false,
			userData: {},
		}
	}
	if (action.type === userTypes.CHANGE_FORM) {
		return {
			...state,
			authFormType: action.payload,
		}
	}

	return state
}
