import React from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from './Portal'
import { Button } from '../Buttons/Button'
const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}
export const UserStatisticDelete = ({ isOpen, hide, removeUser }) => {
	return (
		<AnimatePresence exitBeforeEnter>
			{isOpen && (
				<Portal>
					<motion.div
						className={clsx('pp', isOpen && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={hide}></div>
						<motion.div className="pp__content user-statistic-del" variants={modal}>
							<button
								className="pp__close user-statistic-del__close"
								onClick={hide}
								title="кнопка закрыть модальное окно"
							>
								<span className="close__lane" />
								<span className="close__lane" />
							</button>
							<div className="pp__content-head">
								<div className="user-statistic-del__title h4">Вы уверены?</div>
							</div>
							<div className="user-statistic-del__body">
								<div className="user-statistic-del__text p3">Вы уверены, что хотите исключить игрока из команды?</div>
								<div className="user-statistic-del__buttons">
									<Button
										type={'button'}
										onClick={removeUser}
										text="да, уверен"
										className="user-statistic-del__button button yellow"
									/>
									<Button
										type={'button'}
										onClick={hide}
										text="отмена"
										className="user-statistic-del__button button lightyellow"
									/>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
