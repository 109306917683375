import React, { useEffect } from 'react'
import { ReactComponent as VkLogo } from '../../../static/img/lk/lk-acc-1.svg'
import { DeleteSocialModal } from '../../Modals/DeleteSocialModal'
import { bindVkAcc } from 'services/Config'
import { notifyUser, notifyUserError } from 'services/Pnotify'
import { useSelector } from 'react-redux'

export const Accounts = () => {
	const [accToDelete, setAccToDelete] = React.useState(null)
	const [isActive, setIsActive] = React.useState(false)
	const [openDeleteAccountModal, setOpenDeleteAccountModal] = React.useState(false)

	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	const showDeleteAccountModal = () => {
		setOpenDeleteAccountModal(true)
	}
	const hideDeleteAccountModal = () => {
		setOpenDeleteAccountModal(false)
	}

	const addAccount = () => {
		//eslint-disable-next-line no-undef
		VK.Auth.login(async (r) => {
			if (r.status === 'connected') {
				try {
					await bindVkAcc(r.session)
					notifyUser('Аккаунт успешно привязан')
				} catch (error) {
					notifyUserError('Не удалось привязать аккаунт')
				}
			}
		})
	}

	useEffect(() => {
		setIsActive(userState?.soc?.length > 0)
	}, [])

	return (
		<>
			<div className="profile__form profile__form-accounts">
				<div className="profile__form-text p6">
					Нажмите на соответствующую иконку соц. сети, чтобы связать ее с вашим аккаунтом для быстрого входа на сайт
				</div>
				<div className="profile__form-accounts-body profile-accounts">
					<div className="profile-account">
						<a
							onClick={() => (isActive ? notifyUser('Вы уже привязали аккаунт') : addAccount())}
							className={isActive ? 'profile-account__logo active' : 'profile-account__logo'}
							title="Кнопка привязать аккаунт"
						>
							<VkLogo />
						</a>
						{/* {isActive && (
							<button
								onClick={() => showDeleteAccountModal()}
								className="profile-account__delete-btn"
								title="кнопка отвязать аккаунт"
							/>
						)} */}
					</div>
					{/* {accounts.map((acc) => (
						<div
							className={clsx('profile-account', {
								['active']: acc.active,
							})}
							key={acc.id}
						>
							<button
								onClick={() => addAccount(acc)}
								className="profile-account__logo"
								title="Кнопка привязать аккаунт"
							>
								{acc.name === 'vk' && <VkLogo />}
								{acc.name === 'google' && <GooogleLogo />}
							</button>
							{acc.active && (
								<button
									onClick={() => showDeleteAccountModal(acc)}
									className="profile-account__delete-btn"
									title="кнопка отвязать аккаунт"
								/>
							)}
						</div>
					))} */}
				</div>
			</div>
			<DeleteSocialModal
				isShown={openDeleteAccountModal}
				hideModal={hideDeleteAccountModal}
				account_to_delete={accToDelete}
			/>
		</>
	)
}
