import React from 'react'
import { deleteTeamFromClub, requestTeamToClub } from '../../../services/Config'
import { notifyUser, notifyUserError } from '../../../services/Pnotify'

import { ReactComponent as Logo } from '../../../static/img/icons/club-logo.svg'
import { Button } from '../../Buttons/Button'
import { ClubRequestModal } from '../../Modals/ClubRequestModal'
import { useActions } from '../../../hooks/useActions'
import { ClubLeaveModal } from '../../Modals/ClubLeaveModal'

export const Guest = ({ teamId, inClub, canClubRequest, clubRequested }) => {
	const [openClubRequestModal, setOpenClubRequestModal] = React.useState(false)
	const [openClubLeaveModal, setOpenClubLeaveModal] = React.useState(false)
	const [teamInClub, setTeamInClub] = React.useState(inClub)
	const [clubRequestCan, setClubRequestCan] = React.useState(canClubRequest ? canClubRequest : false)
	const [isRequested, setIsRequested] = React.useState(clubRequested ? clubRequested : false)
	const { loadingData } = useActions()

	const showClubRequestModal = () => {
		setOpenClubRequestModal(true)
	}
	const hideClubRequestModal = () => {
		setOpenClubRequestModal(false)
	}

	const onSendRequest = async () => {
		try {
			loadingData(true)
			await requestTeamToClub()
			showClubRequestModal()
			setIsRequested(true)
		} catch (error) {
			notifyUserError('Ошибка отправки заявки на вступление в клуб')
		} finally {
			loadingData(false)
		}
	}

	const showClubLeaveModal = () => {
		setOpenClubLeaveModal(true)
	}
	const hideClubLeaveModal = () => {
		setOpenClubLeaveModal(false)
	}
	const leaveClubCb = () => {
		hideClubLeaveModal()
		onLeave()
		setClubRequestCan(true)
		setIsRequested(false)
	}

	const onLeave = async () => {
		try {
			loadingData(true)
			const res = await deleteTeamFromClub()
			notifyUser('Команда успешно вышла из клуба')
			setTeamInClub(false)
		} catch (error) {
			notifyUserError('Ошибка выхода из клуба')
		} finally {
			loadingData(false)
		}
	}

	return (
		<>
			<div className="lk-container">
				<div className="lk-container__content guest-banner">
					<div className="guest-banner__body">
						<div className="guest-banner__logo">
							<Logo className="guest-banner__logo-icon" />
						</div>
						<div className="guest-banner__cont">
							<h5 className="guest-banner__title h5">Клуб Privatka</h5>
							<div className="guest-banner__cont-row">
								<div className="guest-banner__text p7">
									{teamInClub ? 'Ваша команда сейчас Член Клуба' : 'Ваша команда сейчас Гость Клуба'}
								</div>
								<div className="guest-banner__links">
									{!teamInClub && (
										<a href={process.env.REACT_APP_FRONTEND_URL + 'toclub'} className="guest-banner__link p7">
											как попасть в Клуб?
										</a>
									)}
									<a href={process.env.REACT_APP_FRONTEND_URL + 'rules'} className="guest-banner__link p7">
										правила Клуба
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="guest-banner__btns">
						{isRequested && !teamInClub ? (
							<Button className={'guest-banner__btn button yellow disabled'} type="button" text="Заявка отправлена" />
						) : teamInClub ? (
							<Button
								onClick={showClubLeaveModal}
								className="guest-banner__btn button white"
								type="button"
								text="выйти из Клуба"
							/>
						) : (
							<Button
								onClick={onSendRequest}
								className={
									clubRequestCan ? 'guest-banner__btn button yellow' : 'guest-banner__btn button yellow disabled'
								}
								type="button"
								text="Подать заявку на вступление в Клуб"
							/>
						)}
					</div>
				</div>
			</div>
			<ClubRequestModal isShown={openClubRequestModal} hideModal={hideClubRequestModal} />
			<ClubLeaveModal isShown={openClubLeaveModal} hideModal={hideClubLeaveModal} clubLeaveCb={leaveClubCb} />
		</>
	)
}
