import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { routeNames } from '../../router'

import { ReactComponent as Logo1 } from '../../static/img/lk/lk-menu-item-1.svg'
import { ReactComponent as Logo2 } from '../../static/img/lk/lk-menu-item-2.svg'
import { ReactComponent as Logo3 } from '../../static/img/lk/lk-menu-item-3.svg'
import { ReactComponent as Logo4 } from '../../static/img/lk/lk-menu-item-4.svg'
import { ReactComponent as Logo5 } from '../../static/img/lk/lk-menu-item-5.svg'
import { ReactComponent as Logo6 } from '../../static/img/lk/lk-menu-item-6.svg'

export const NavList = ({ className }) => {
	const dialogs = useSelector((state) => state.dialogs)
	const friendsRequests = useSelector((state) => state.friendsRequests)
	const [unreadMessages, setunreadMessages] = useState(0)

	useEffect(() => {
		setunreadMessages(
			dialogs.reduce(function (sum, current) {
				return parseInt(sum + current.unread_count)
			}, 0)
		)
	}, [dialogs])

	return (
		<nav className={'lk-menu' + ' ' + className}>
			<ul className="lk-menu__list">
				<li className="lk-menu__item-wrap">
					<NavLink
						to={routeNames.PROFILE.path}
						className={({ isActive }) => (isActive ? 'lk-menu__item active' : 'lk-menu__item')}
					>
						<Logo1 className="lk-menu__item-icon" />
						<span className="lk-menu__item-name">{routeNames.PROFILE.name}</span>
						{/* <span className="lk-menu__item-quant">14</span> */}
					</NavLink>
				</li>
				<li className="lk-menu__item-wrap">
					<NavLink
						to={routeNames.MESSAGES.path}
						className={({ isActive }) => (isActive ? 'lk-menu__item active' : 'lk-menu__item')}
					>
						<Logo2 className="lk-menu__item-icon" />
						<span className="lk-menu__item-name">{routeNames.MESSAGES.name}</span>
						{unreadMessages ? <span className="lk-menu__item-quant">{unreadMessages}</span> : null}
					</NavLink>
				</li>
				<li className="lk-menu__item-wrap">
					<NavLink
						to={routeNames.FRIENDS.path}
						className={({ isActive }) => (isActive ? 'lk-menu__item active' : 'lk-menu__item')}
					>
						<Logo3 className="lk-menu__item-icon" />
						<span className="lk-menu__item-name">{routeNames.FRIENDS.name}</span>
						{friendsRequests?.total_count ? (
							<span className="lk-menu__item-quant">{friendsRequests.total_count}</span>
						) : null}
					</NavLink>
				</li>
				<li className="lk-menu__item-wrap">
					<NavLink
						to={routeNames.ARTICLES.path}
						className={({ isActive }) => (isActive ? 'lk-menu__item active' : 'lk-menu__item')}
					>
						<Logo4 className="lk-menu__item-icon" />
						<span className="lk-menu__item-name">{routeNames.ARTICLES.name}</span>
					</NavLink>
				</li>
				<li className="lk-menu__item-wrap">
					<NavLink to="/tests" className={({ isActive }) => (isActive ? 'lk-menu__item active' : 'lk-menu__item')}>
						<Logo5 className="lk-menu__item-icon" />
						<span className="lk-menu__item-name">Пройденные тесты</span>
					</NavLink>
				</li>
				<li className="lk-menu__item-wrap">
					<NavLink
						to={routeNames.PAYMENTS.path}
						className={({ isActive }) => (isActive ? 'lk-menu__item active' : 'lk-menu__item')}
					>
						<Logo6 className="lk-menu__item-icon" />
						<span className="lk-menu__item-name">{routeNames.PAYMENTS.name}</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	)
}
