import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import Select from 'react-select'
import { PaymentFormAuth } from '../../../utils/validations'
import { Button } from '../../Buttons/Button'
import { FormField } from '../../FormField'
import TeamDefault from '../../../static/img/team_default.png'
// import { gameRoles } from '../../../utils/helpers'
import TeamCardPayment from '../../Team/TeamCardPayment'
import { useSelector } from 'react-redux'
import { AddUserEmptyModal } from '../../Modals/AddUserEmptyModal'
import { AddUsersModal } from '../../Modals/AddUsersModal'
import { useActions } from 'hooks/useActions'
import { getTransactionPrice, payment } from 'services/Config'
import { notifyUser, notifyUserError } from 'services/Pnotify'
import { useNavigate } from 'react-router-dom'
import { routeNames } from 'router'

function canEdit(id = null, teamObj = null) {
	if (
		id &&
		teamObj &&
		(teamObj?.second_commander?.filter((s) => s.id === id).length > 0 || id === teamObj?.commander_id)
	) {
		return true
	}
	return false
}

export const PaymentForm = ({
	setSelfSale,
	gameRoles,
	game_id,
	gameData,
	membersList,
	setMembersList,
	teamData,
	by_deposit,
	onlyTeam,
	setOnlyTeam,
}) => {
	const [openAddUsersModal, setOpenAddUsersModal] = useState(false)
	const { userData } = useSelector((state) => state.user)
	const { loadingData } = useActions()
	const navigate = useNavigate()
	const isSecondCommander = teamData.second_commander?.find((item) => item.id === userData.user.id) ? true : false
	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(PaymentFormAuth),
	})

	const {
		register,
		control,
		formState: { errors },
		setValue,
		getValues,
	} = form

	const selfGameRole = useWatch({
		control,
		name: 'game_role', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
		defaultValue: 0, // default value before the render
	})

	useEffect(() => {
		selfGameRole && setSelfSale(selfGameRole.sale)
	}, [selfGameRole])

	const pay = async (data) => {
		try {
			loadingData(true)
			const res = await payment(data)
			if (res?.data?.parameter_link) {
				window.location.replace(res.data.parameter_link)
			} else {
				notifyUser('Оплата прошла успешно')
				navigate(routeNames.GAME_HISTORY.path)
			}
		} catch (error) {
			if (error?.data?.error) {
				notifyUserError(error.data.error)
			} else {
				notifyUserError('При оплате произошла ошибка')
			}
		} finally {
			loadingData(false)
		}
	}

	const onSubmit = (data) => {
		let list = membersList.map((m) => {
			return {
				id: m.id,
				game_role: m.game_role,
			}
		})
		let formData
		if (onlyTeam) {
			formData = {
				game_id: parseInt(game_id),
				name: data.name,
				team: data.team,
				callname: data.callname,
				email: data.email,
				game_role: parseInt(data.game_role.value),
				members_list: list.length > 0 ? JSON.stringify(list) : null,
				by_deposit: by_deposit > 0 ? by_deposit : 0,
				only_team_pay: '1',
			}
		} else {
			formData = {
				game_id: parseInt(game_id),
				name: data.name,
				team: data.team,
				callname: data.callname,
				email: data.email,
				game_role: parseInt(data.game_role.value),
				members_list: list.length > 0 ? JSON.stringify(list) : null,
				by_deposit: by_deposit > 0 ? by_deposit : 0,
			}
		}

		Object.keys(formData).forEach((e) => {
			if (!formData[e]) {
				delete formData[e]
			}
		})
		pay(formData)
	}

	useEffect(() => {
		setValue('game_role', gameRoles[0])
		setValue('name', userData.user?.name)
		setValue('team', userData.user?.team?.name || '')
		setValue('callname', userData.user?.callname || '')
		setValue('email', userData.user?.email)
	}, [])

	const showAddUsersModal = () => {
		setOpenAddUsersModal(true)
	}
	const hideAddUsersModal = () => {
		setOpenAddUsersModal(false)
	}

	const addUsers = async (users) => {
		users.forEach((u) => {
			u.defaultSale = u.sale
			u.price = gameData.current_price
		})

		/* 		try {
			const pricesData = {
				game_id: gameData.id,
				users_id: users.map((e) => ({ id: e.id })),
			}

			const prices = await getTransactionPrice(JSON.stringify(pricesData))
			console.log('p', prices)
		} catch (error) {
			console.log('e', error)
		} */
		setMembersList(users)
		hideAddUsersModal()
	}

	const onChangeSelfRole = (role) => {}

	const onChangeRole = (id, role) => {
		let roleUpdate = [...membersList]
		roleUpdate.forEach((m, i) => {
			if (m.id === id) {
				if (role.sale > 0) {
					m.game_role = role.value
				} else {
					delete roleUpdate[i].game_role
				}
				if (role.value > 0) {
					m.sale = role.sale
				} else {
					m.sale = m.defaultSale
				}
			}
		})
		setMembersList(roleUpdate)
	}

	const onRemove = async (id) => {
		setMembersList([...membersList.filter((m) => m.id !== id)])
	}

	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="profile__form profile__form-main">
					<div className="profile__form-main-body">
						<div className="profile__form-fields form-fields">
							<div className="form-row double">
								<FormField elem={'input'} name={'name'} label={'Имя'} />
								<FormField
									className={userData?.user?.team ? 'disabled' : null}
									elem={'input'}
									name={'team'}
									label={'Команда'}
								/>
							</div>

							<div className="form-row double">
								<FormField elem={'input'} name={'callname'} label={'Позывной'} />
								<FormField elem={'input'} name={'email'} label={'E-mail'} />
							</div>

							{gameRoles.length < 1
								? null
								: canEdit(userData?.user?.id, teamData) && (
										<div className="form-row double">
											<div
												className={clsx('select__form-row custom-select__wrap', {
													['error']: form.formState.errors['game_role'],
												})}
											>
												<Controller
													render={({ field: { value, name, onChange }, ref }) => {
														return (
															<Select
																{...form.register('game_role')}
																options={gameRoles}
																closeMenuOnSelect={true}
																inputRef={ref}
																className={!userData?.user?.guest ? '' : 'disabled'}
																classNamePrefix="custom-select"
																id="game_role"
																instanceId="game_role"
																onChange={onChange}
																placeholder="Выбор роли"
																name={name}
																value={value || null}
															/>
														)
													}}
													control={control}
													name="game_role"
													defaultValue={''}
													options={gameRoles}
													onChange={(e) => onChangeSelfRole(e)}
												/>
												{form.formState.errors['game_role'] && (
													<label className="input-box__error">{form.formState.errors['game_role']?.message}</label>
												)}
											</div>
										</div>
								  )}
							{(teamData?.commander_id || isSecondCommander) && userData.user && membersList.length > 0 && (
								<div className="form-row double">
									<div className="checkbox-box">
										<input
											type="checkbox"
											id={'only_team_pay'}
											name={'only_team_pay'}
											className={'checkbox-box__input'}
											onChange={() => setOnlyTeam((prev) => !prev)}
										/>
										<label htmlFor={'only_team_pay'} className="checkbox-box__label">
											Оплатить только за игроков команды
											<span className="checkbox-box__custom"></span>
										</label>
									</div>
								</div>
							)}
						</div>
						{canEdit(userData?.user?.id, teamData) && userData?.user?.team?.id && (
							<div className="profile__form-fields form-fields form-fields-team">
								<h4 className="lk-container__title-primary lk-container-title h4">Добавить игроков</h4>
								<div className="team-cards">
									{membersList.map((e, i) => (
										<TeamCardPayment
											onRemove={onRemove}
											gameRoles={gameRoles}
											onChangeRole={onChangeRole}
											user={e}
											key={i}
											isGuest={userData?.user?.guest}
										/>
									))}
									<button onClick={showAddUsersModal} type="button" className="team-card team-card_empty">
										<div className="team-card__icon"></div>
										<div className="team-card__label p5">Добавить участника команды</div>
									</button>
								</div>
							</div>
						)}
					</div>

					<div className="profile__form-footer">
						<Button submit="submit" type="button" className="profile__form-btn button yellow" text="Продолжить" />
						<span className="profile__form-policy p8">
							Нажимая кнопку «Далее», вы соглашаетесь с обработкой персональных данных
						</span>
					</div>
				</form>
			</FormProvider>

			{userData?.user?.team?.id ? (
				<AddUsersModal isShown={openAddUsersModal} hideModal={hideAddUsersModal} addUsersCb={addUsers} />
			) : (
				<AddUserEmptyModal isShown={openAddUsersModal} hideModal={hideAddUsersModal} />
			)}
		</>
	)
}

export default PaymentForm
