import React from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from './Portal'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const AuthorsModal = ({ isShown, hideModal }) => {
	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	const closeModal = () => {
		hideModal()
	}

	return (
		<AnimatePresence exitBeforeEnter>
			{isShown && (
				<Portal>
					<motion.div
						className={clsx('pp', isShown && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={closeModal}></div>
						<motion.div className="pp__content pp__authors" variants={modal}>
							<button className="pp__close" onClick={closeModal}>
								<span className="close__lane" />
								<span className="close__lane" />
							</button>

							<div className="pp__content-head">
								<h4 className="pp__title">Кодекс авторов</h4>
							</div>
							<div className="pp__content-body pp__authors-body">
								<div className="pp__authors-body-inner">
									<div className="pp__authors-suptitle p1">
										<p>Это не документ, которого нужно строго придерживаться. Это вообще не документ.</p>
									</div>
									<h3 className="pp__authors-title h3">
										Это правила, <span>которые соблюдают некоторые авторы</span> на ресурсе.
									</h3>
									<ul className="pp__authors-list p11">
										<li className="pp__authors-list-item">
											<p>
												Я <strong>создаю авторские материалы</strong> и не перепечатываю с других сайтов.
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												Публикуя материалы других авторов, я <strong>всегда привожу ссылки на источники.</strong>
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												Я не матерюсь и <strong>не оскорбляю других</strong> на страницах Privatka Club.
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>Я стараюсь, чтобы в моих текстах не было ошибок.</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												Я <strong>уважаю администрацию и пользователей ресурса</strong>, не унижаю и не оскорбляю их — я
												здесь не для этого.
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>В споре я стараюсь не раздувать конфликт, а решить проблему в личной переписке.</p>
										</li>
										<li className="pp__authors-list-item">
											<p>Если мне не удалось разрешить конфликт, я не призываю других к активному противодействию.</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												<strong>Я лично решаю: публиковать контент на Privatka Club или нет,</strong> и не требую за это
												денег.
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												Если нужно, я <strong>помогаю новичкам.</strong>
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												Я <strong>пользуюсь поиском, чтобы уточнить, нет ли уже на сайте похожего материала.</strong>{' '}
												Если так вышло, я дополню его в комментариях.
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												Каждым своим действием на ресурсе я <strong>стремлюсь добавить порядка,</strong> а не внести
												хаоса.
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>Я стараюсь учесть конструктивную критику и пожелания.</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												<strong>Я на позитиве.</strong> Из самой сложной ситуации всегда есть выход.
											</p>
										</li>
										<li className="pp__authors-list-item">
											<p>
												Я понимаю, что Privatka Club — это сообщество людей с разными интересами.{' '}
												<strong>Если какая-то тема мне не интересна, я не мешаю другим ее обсуждать.</strong>
											</p>
										</li>
									</ul>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
