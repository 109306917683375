import React from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'

import { Portal } from './Portal'
import { Button } from '../Buttons/Button'
import { normalizedImageSrc } from '../../utils/helpers'
import TeamLogo from '../../static/img/team_default.png'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const TeamLeaveModal = ({ isShown, hideModal, teamLeaveCb, teamId, logoSrc, name }) => {
	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	const closeModal = () => {
		hideModal()
	}

	return (
		<AnimatePresence exitBeforeEnter>
			{isShown && (
				<Portal>
					<motion.div
						className={clsx('pp', isShown && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={closeModal}></div>
						<motion.div className="pp__content pp__team-effect" variants={modal}>
							<button className="pp__close" onClick={closeModal}>
								<span className="close__lane" />
								<span className="close__lane" />
							</button>

							<div className="pp__content-head">
								<h4 className="pp__title">Покинуть команду</h4>
							</div>
							<div className="pp__content-body">
								<div className="pp__content-text p3">Вы действительно хотите покинуть команду?</div>

								<div className="pp__content-logo">
									<img src={logoSrc ? normalizedImageSrc(logoSrc) : TeamLogo} alt="team-logo" title="лого-команды" />
								</div>
								<h5 className="pp__content-title h5">{name}</h5>
								<div className="pp__content-btns">
									<Button
										onClick={teamLeaveCb}
										type="button"
										className="pp__content-btn button yellow"
										text="Выйти из команды"
									/>
									<Button
										onClick={closeModal}
										type="button"
										className="pp__content-btn button lightyellow"
										text="Отмена"
									/>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
