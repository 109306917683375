import React, { useEffect, useState } from 'react'
import DialogItem from './DialogItem'
import LoaderMini from '../LoaderMini'

export default function MessagesList({ dialogs }) {
	return (
		<div className="messages-list">
			<div className="messages-list__head">
				<h4>Сообщения</h4>
			</div>
			<div className="messages-list__body">
				{!!dialogs.length &&
					dialogs.map((dialog) => {
						return <DialogItem key={dialog.id} {...dialog} />
					})}
				{!!!dialogs.length && <LoaderMini />}
			</div>
		</div>
	)
}
