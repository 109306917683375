import React from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'
import { ConfirmChangeCaptainModal } from './ConfirmChangeCaptainModal'
import { Portal } from './Portal'
import { Button } from '../Buttons/Button'

import { getTeamProfileMembers } from '../../services/Config'
import LoaderMini from '../LoaderMini'
import { Pagination } from '../Pagination/Pagination'
import { UserAssist } from '../User/UserAssist'
import Filters from '../Filters'
import { useSelector } from 'react-redux'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const ChangeCaptainModal = ({ isShown, hideModal, teamChangeCaptaion }) => {
	const defaultLimit = 10 // 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamMembers, setTeamMembers] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)
	const [confirmModal, setConfirmModal] = React.useState(false)
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const [selectedUser, setSelectedUser] = React.useState(null)

	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	React.useEffect(() => {
		const fetchMembers = async () => {
			try {
				setIsLoad(true)
				const limit = defaultLimit
				const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
				const res = await getTeamProfileMembers(limit, offset)
				setTeamMembers(res.data)
			} catch (error) {
				console.log('fetchMembers error', error)
			} finally {
				setIsLoad(false)
			}
		}
		fetchMembers()
	}, [page])

	const changePage = (num) => {
		setPage(num)
	}

	const closeModal = () => {
		hideModal()
	}

	const addСommander = () => {
		hideConfirmModal()
		teamChangeCaptaion(selectedUser)
		setSelectedUser(null)
	}

	const selectUser = (user) => {
		setSelectedUser(user)
	}
	const unSelectUser = () => {
		setSelectedUser(null)
	}

	const rejectHandle = () => {
		setSelectedUser(null)
		closeModal()
	}

	const hideConfirmModal = () => {
		setConfirmModal(false)
	}

	return (
		<>
			<AnimatePresence exitBeforeEnter>
				{isShown && (
					<Portal>
						<motion.div
							className={clsx('pp', isShown && 'show')}
							variants={backdrop}
							initial="hidden"
							animate="visible"
							exit="hidden"
						>
							<div className="pp__bg" onClick={closeModal} />
							<motion.div className="pp__content pp-users" variants={modal}>
								<button className="pp__close" onClick={closeModal}>
									<span className="close__lane" />
									<span className="close__lane" />
								</button>

								<div className="pp__content-head">
									<h4 className="pp__title">Назначить новым командиром</h4>
								</div>
								<div className="pp__content-body">
									{selectedUser && (
										<div className="pp-users__selected">
											<Filters className="pp-users__filters" filters={[selectedUser]} onClick={unSelectUser} />
										</div>
									)}
									<div className="pp-users__table">
										{isLoad ? (
											<LoaderMini />
										) : (
											<div className="pp-users__items users__cards">
												{teamMembers?.list
													?.filter((el) => el.id !== userState.id)
													?.map((user) => (
														<UserAssist
															key={user.id}
															user={user}
															selected={selectedUser ? selectedUser.id === user.id : false}
															onClick={selectUser}
														/>
													))}
											</div>
										)}
									</div>

									<div className="pp__content-footer">
										{teamMembers?.total_count > defaultLimit && (
											<div className="pagination pp__content-pagination">
												<Pagination
													changePage={changePage}
													page={page}
													length={teamMembers?.total_count}
													limit={defaultLimit}
												/>
											</div>
										)}
										<div className="pp__content-btns">
											<Button
												onClick={() => setConfirmModal(true)}
												type="button"
												className={clsx('pp__content-btn button yellow', {
													['disabled']: !selectedUser,
												})}
												text="Назначить"
											/>
											<Button
												onClick={rejectHandle}
												type="button"
												className="pp__content-btn button lightyellow"
												text="Отмена"
											/>
										</div>
									</div>
								</div>
							</motion.div>
						</motion.div>
					</Portal>
				)}
			</AnimatePresence>
			<ConfirmChangeCaptainModal isShow={confirmModal} hideModal={hideConfirmModal} addСommander={addСommander} />
		</>
	)
}
