import clsx from 'clsx'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'
import React, { useState } from 'react'

export const Tabs = ({ items, countPath, teamCaptain }) => {
	const [unreadCount, setunreadCount] = useState(0)

	const [activeTab, setActiveTab] = React.useState(items[0].path)
	// const unreadCount = useSelector((state) => state.unreadCount.count)
	// const router = useRouter()

	// React.useEffect(() => {
	//   if (Object.keys(router.query).length) {
	//     const queryString = Object.keys(router.query)

	//     const path = `${queryString[0]}`

	//     setActiveTab(path)
	//   } else {
	//     router.push(
	//       {
	//         pathname: router.pathname,
	//         query: { ['personal']: 'true' },
	//       },
	//       undefined,
	//       { shallow: true }
	//     )
	//   }
	// }, [router.query])

	const openTab = (path) => {
		// router.push(
		//   {
		//     pathname: router.pathname,
		//     query: { [path]: 'true' },
		//   },
		//   undefined,
		//   { shallow: true }
		// )
		setActiveTab(path)
	}

	return (
		<div className="tabs-wrapper">
			<div className="tabs-head">
				<AnimateSharedLayout>
					{items.map((tab, idx) => {
						return (
							<motion.div
								key={tab.path}
								className={clsx('tabs-head__item', {
									disabled: tab.isDisabled,
								})}
								initial={{ color: '#ADADAB' }}
								animate={{ color: tab.path === activeTab ? '#383636' : '#ADADAB' }}
							>
								<button
									className={clsx('tabs-head__item-button h4', {
										active: tab.path === activeTab,
									})}
									onClick={() => openTab(tab.path)}
									data-index={idx}
								>
									<span>{tab.title}</span>
									{unreadCount > 0 && tab.path === countPath && (
										<div className="tabs-head__count notif-count">{unreadCount}</div>
									)}
								</button>
							</motion.div>
						)
					})}
				</AnimateSharedLayout>
			</div>
			<AnimatePresence exitBeforeEnter>
				{items.map((tab) => {
					return (
						<motion.div
							key={tab.path}
							initial={{ opacity: 0 }}
							animate={tab.path === activeTab ? { opacity: 1 } : { opacity: 0, display: 'none' }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.3 }}
							className="tabs-body"
						>
							{<tab.component teamCaptain={teamCaptain} reRender={tab.path === activeTab} setCount={setunreadCount} />}
						</motion.div>
					)
				})}
			</AnimatePresence>
		</div>
	)
}
