import { useSelector } from 'react-redux'
import EmptyBlock from 'components/EmptyBlock'
import React, { useEffect, useState } from 'react'
import EmptyArticles from '../static/img/icons/empty-articles.svg'

import { ReactComponent as QuestionIcon } from '../static/img/icons/question-icon.svg'
import { Button } from '../components/Buttons/Button'
import { Pagination } from '../components/Pagination/Pagination'
import { ArticleItem } from '../components/Articles/ArticleItem'
import { getArticles } from 'services/Config'
import { LoaderMini } from '../components/LoaderMini.jsx'
import { AddArticleModal } from '../components/Modals/AddArticleModal'
import { AuthorsModal } from '../components/Modals/AuthorsModal'

export const Articles = () => {
	const defaultLimit = 8
	const defaultOffset = 0

	const userData = useSelector((state) => state.user.userData)
	const [page, setPage] = useState(1)
	const [isLoad, setIsLoad] = React.useState(false)
	const [isAddArticleModal, setIsAddArticleModal] = React.useState(false)
	const [openAuthorsModal, setOpenAuthorsModal] = React.useState(false)
	const [articles, setArticles] = useState({})
	const [article, setArticle] = useState({})

	const userAvatar = process.env.REACT_APP_IMAGES_URL + userData.user.avatar
	const callName = userData.user.callname || 'я'
	const name = userData.user.name || 'я'
	const lastname = userData.user.lastname
	const fullName = lastname !== undefined ? name + ' ' + lastname : name

	const changePage = (num) => {
		setPage(num)
	}
	const hideAuthorsModal = () => {
		setOpenAuthorsModal(false)
	}

	const fetchArticles = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getArticles(limit, offset)
			setArticles(res.data)
		} catch (error) {
			console.log('e', error)
		} finally {
			setIsLoad(false)
		}
	}

	useEffect(() => {
		fetchArticles()
	}, [page])

	const showAddArticleModal = () => {
		setIsAddArticleModal(true)
	}
	const showEditArticleModal = (article) => {
		setArticle(article)
		setIsAddArticleModal(true)
	}
	const hideAddArticleModal = () => {
		setArticle({})
		setIsAddArticleModal(false)
	}

	return (
		<>
			<div className="lk-container articles-page">
				<div className="articles-page__header">
					<h4 className="h4 articles-page__title">Ваши статьи</h4>
					{articles.total_count > 0 && (
						<>
							<Button
								// onClick={showAuthorsModal}
								type={'link'}
								href={process.env.REACT_APP_FRONTEND_URL + 'articles?authorsModal=1'}
								Icon={QuestionIcon}
								iconSide="left"
								className="articles-button__rules button lightyellow"
								text="Правила размещения статей"
							/>
							<Button
								type={'button'}
								onClick={showAddArticleModal}
								className="articles-button__write button yellow"
								text="Написать статью"
							/>
						</>
					)}
				</div>
				{isLoad ? (
					<LoaderMini />
				) : articles?.list?.length > 0 ? (
					<>
						<div className="lk-container__content articles-content">
							{articles?.list.map((article) => (
								<div key={article.id}>
									{article.status === 'moderate' && (<a onClick={() => showEditArticleModal(article)} key={article.id}>
										<ArticleItem article={article} userAvatar={userAvatar} callName={callName} key={article.id} />
									</a>)}
									{article.status !== 'moderate' && (
										<ArticleItem article={article} userAvatar={userAvatar} callName={callName} key={article.id} />
									)}
								</div>
							))}
						</div>
						{articles?.total_count > defaultLimit && (
							<div className="articles-page__pagination">
								<Pagination changePage={changePage} page={page} length={articles?.total_count} limit={defaultLimit} />
								<div className="pagination__count p13">
									Показано {defaultLimit * page >= articles?.total_count ? articles?.total_count : defaultLimit * page}{' '}
									из {articles?.total_count}
								</div>
							</div>
						)}
					</>
				) : (
					<div className="lk-container__content">
						<EmptyBlock
							img={EmptyArticles}
							title={'Нет написанных статей'}
							text="Вы можете ознакомиться с правилами или написать статью. За каждую одобренную статью вы получите баллы"
						/>
						<div className="articles-page__buttons">
							<Button
								type={'button'}
								onClick={showAddArticleModal}
								className="articles-button__write button yellow"
								text="Написать статью"
							/>
							<Button
								Icon={QuestionIcon}
								type={'link'}
								href={process.env.REACT_APP_FRONTEND_URL + 'articles?authorsModal=1'}
								iconSide="left"
								className="articles-button__rules button lightyellow"
								text="Правила размещения статей"
							/>
						</div>
					</div>
				)}
			</div>
			<AddArticleModal
				isShow={isAddArticleModal}
				hideModal={hideAddArticleModal}
				userAvatar={userAvatar}
				callName={callName}
				fullName={fullName}
				article={article}
				// setPage={setPage}
				fetchArticles={fetchArticles}
			/>
			<AuthorsModal isShown={openAuthorsModal} hideModal={hideAuthorsModal} />
		</>
	)
}
