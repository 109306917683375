import { yupResolver } from '@hookform/resolvers/yup'
import { useActions } from 'hooks/useActions'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { payment } from 'services/Config'
import { notifyUserError } from 'services/Pnotify'
import { routeNames } from '../../../router'
import { PaymentFormUnAuth } from '../../../utils/validations'
import { Button } from '../../Buttons/Button'
import { FormField } from '../../FormField'

export const PaymentUnAuthForm = ({ game_id }) => {
	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(PaymentFormUnAuth),
	})

	const { loadingData } = useActions()

	const {
		register,
		control,
		formState: { errors },
		setValue,
	} = form

	const pay = async (data) => {
		try {
			loadingData(true)
			const res = await payment(data)
			window.location.replace(res.data.parameter_link)
		} catch (error) {
			notifyUserError('При оплате произошла ошибка')
		} finally {
			loadingData(false)
		}
	}

	const onSubmit = (data) => {
		const formData = {
			game_id,
			name: data.name,
			team: data.team,
			callname: data.callname,
			email: data.email,
		}
		// editProfileAction(formData)
		pay(formData)
	}

	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="profile__form profile__form-main">
					<div className="profile__form-main-body">
						<div className="lk-message">
							<p className="lk-message__title p6">
								Перед оплатой войдите в ваш аккаунт или пройдите регистрацию на портале для сохранения истории
								посещаемости игр, получения личных скидок, рейтинга и подарков от Клуба.
							</p>

							<div className="lk-message__btns">
								<Button
									className="lk-message__button button yellow"
									type="navlink"
									href={routeNames.LOGIN.path}
									text="Войти"
								/>
								<Button
									className="lk-message__button button white"
									type="navlink"
									href={routeNames.REGISTRATION.path}
									text="зарегистрироваться"
								/>
							</div>
						</div>
						<div className="profile__form-fields form-fields">
							<div className="form-row double">
								<FormField elem={'input'} name={'name'} label={'Имя'} />
								<FormField elem={'input'} name={'team'} label={'Команда'} />
							</div>
							<div className="form-row double">
								<FormField elem={'input'} name={'callname'} label={'Позывной'} />
								<FormField elem={'input'} name={'email'} label={'E-mail'} />
							</div>
						</div>
					</div>

					<div className="profile__form-footer">
						<Button type="button" className="profile__form-btn button yellow" text="Продолжить" />
						<span className="profile__form-policy p8">
							Нажимая кнопку «Далее», вы соглашаетесь с обработкой персональных данных
						</span>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default PaymentUnAuthForm
