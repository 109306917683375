import React, { useState } from 'react'
import { Switcher } from 'components/Switcher/Switcher'

export const Composition = ({ composition }) => {
	const [switcher, setSwitcher] = useState('year')
	const changeSwitcher = (newValue) => {
		setSwitcher(newValue)
	}
	return (
		<div className="composition-statistic">
			<div className="composition-statistic__header">
				<div className="composition-statistic__title h4">Информация о составе</div>
				<div className="composition-statistic__switcher">
					<Switcher
						className="switcher"
						values={[
							{ name: 'За год', value: 'year' },
							{ name: 'За все время', value: 'all' },
						]}
						value={switcher}
						changeValue={changeSwitcher}
					/>
				</div>
			</div>
			<div className="composition-statistic__body">
				{composition.map((item, idx) => (
					<div className="composition-statistic__col" key={idx}>
						<div className="composition-statistic__item">
							<div className="composition-statistic__text p8">{item.title}</div>
							<div className="composition-statistic__count h8">
								{switcher === 'year' ? item.count.for_year : item.count.all_along}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
