import React from 'react'
import { NavList } from '../Nav/NavList'
import { SidebarInfo } from './SidebarInfo'

export const Sidebar = () => {
	return (
		<div className="sidebar">
			<NavList className="sidebar__nav" />
			<SidebarInfo className="sidebar__info" />
		</div>
	)
}
