import EmptyBlock from 'components/EmptyBlock'
import React, { useEffect, useState } from 'react'
import { LoaderMini } from '../components/LoaderMini.jsx'
import TestsImage from '../static/img/icons/EmptyBlock/tests.svg'
import { Button } from '../components/Buttons/Button'
import { Pagination } from '../components/Pagination/Pagination'
import { TestsItem } from 'components/Tests/TestsItem'
import { getTests } from 'services/Config'

export const Tests = () => {
	const defaultLimit = 8
	const defaultOffset = 0

	const [page, setPage] = useState(1)
	const [isLoad, setIsLoad] = React.useState(false)
	const [tests, setTests] = useState({})

	const changePage = (num) => {
		setPage(num)
	}

	const fetchTests = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTests(limit, offset)
			const newTests = {
				total_count: res.data.total_count,
				list: res.data.list.filter((item) => item.test.length > 0),
			}
			setTests(newTests)
		} catch (error) {
			console.log('e', error)
		} finally {
			setIsLoad(false)
		}
	}

	useEffect(() => {
		fetchTests()
	}, [page])

	return (
		<>
			<div className="lk-container">
				<div className="tests">
					<div className="tests__header header-tests">
						<h4 className="header-tests__title h4">Пройденные тесты</h4>
						{tests?.list?.length > 0 && (
							<Button
								type={'link'}
								href={process.env.REACT_APP_FRONTEND_URL + 'knowledgebase'}
								className="header-tests__button button yellow"
								text="база знаний"
							/>
						)}
					</div>
					{isLoad ? (
						<LoaderMini />
					) : tests?.list?.length > 0 ? (
						<>
							<div className="tests__body">
								{tests?.list.map((test) => {
									if (test.test.length > 0) {
										return (
											<TestsItem
												key={test.test[0].id}
												title={test.test[0].title}
												updated_at={test.updated_at}
												test_id={test.test[0].id}
												correct_results={test.correct_results}
											/>
										)
									}
								})}
							</div>
							{tests?.total_count > defaultLimit && (
								<div className="tests__paggination">
									<Pagination changePage={changePage} page={page} length={tests?.total_count} limit={defaultLimit} />
									<div className="pagination__count p13">
										Показано {defaultLimit * page >= tests?.total_count ? tests?.total_count : defaultLimit * page} из{' '}
										{tests?.total_count}
									</div>
								</div>
							)}
						</>
					) : (
						<div className="lk-container__content">
							<EmptyBlock
								img={TestsImage}
								title={'Нет пройденных тестов'}
								text={'Все пройденные тесты будут отображаться здесь'}
								buttonText={'База знаний'}
								buttonLink={process.env.REACT_APP_FRONTEND_URL + 'knowledgebase'}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
