import { useActions } from 'hooks/useActions'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { getTransactions } from 'services/Config'
import EmptyBlock from '../components/EmptyBlock'
import { Pagination } from '../components/Pagination/Pagination'
import PaymentItem from '../components/Payments/PaymentItem'
import Calendar from '../static/img/icons/EmptyBlock/calendar.svg'
import FailedIcon from '../static/img/icons/payment_failed.svg'
import SuccessIcon from '../static/img/icons/payment_success.svg'

export const Payments = () => {
	const defaultLimit = 5
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [payment, setPayment] = React.useState([])
	const [searchParams, setSearchParams] = useSearchParams()
	const { loadingData } = useActions()

	React.useEffect(() => {
		const currentParams = Object.fromEntries([...searchParams])
	}, [searchParams])

	React.useEffect(() => {
		ReactTooltip.rebuild()
	}, [payment])

	const changePage = (num) => {
		// const offset = num !== 1 ? (num - 1) * defaultLimit : defaultOffset

		const currentParams = Object.fromEntries([...searchParams])
		const newParams = {
			...currentParams,
			page: num,
		}
		setSearchParams(newParams)
		setPage(num)

		// router
		//   .push({
		//     query: { ...router.query, offset, limit: defaultLimit },
		//   })
		//   .then(() => {
		//     setPage(num);
		//   });
	}

	const fetch = async () => {
		try {
			loadingData(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTransactions(limit, offset)
			setPayment(res.data)
		} catch (error) {
		} finally {
			loadingData(false)
		}
	}

	useEffect(() => {
		fetch()
	}, [page])

	return (
		<div className="lk-container">
			<div className="lk-container__title-primary">
				<h4 className="lk-container-title h4">История оплат</h4>
			</div>
			{payment?.list?.length < 1 ? (
				<EmptyBlock
					img={Calendar}
					title={'Нет оплаченных игр'}
					text={'Все оплаченые игры будут отображаться здесь'}
					buttonText={'Мероприятия'}
					buttonLink={process.env.REACT_APP_FRONTEND_URL + 'payment'}
				/>
			) : (
				<div className="lk-container__content game-history">
					<div className="game-history-table">
						<div className="game-history-table__head p14">
							<div className="game-history-table__row">
								<div className="game-history-table__col col-status">СТАТУС</div>
								<div className="game-history-table__col col-id">ID</div>
								<div className="game-history-table__col col-date">ДАТА</div>
								<div className="game-history-table__col col-name">МЕРОПРИЯТИЕ</div>
								<div className="game-history-table__col col-payment">ОПЛАТА</div>
								<div className="game-history-table__col col-deposit">ДЕПОЗИТ</div>
							</div>
						</div>
						<div className="game-history-table__body p12">
							{payment?.list
								?.slice(defaultOffset, defaultLimit)
								.map(({ status, icon, id, created_at, transaction_id, total_amount, deposit, game_title }, i) => (
									<PaymentItem
										key={i}
										status={status}
										icon={status !== 'success' ? FailedIcon : SuccessIcon}
										id={id}
										date={created_at}
										name={transaction_id}
										payment={total_amount}
										by_deposit={deposit}
										title={game_title}
									/>
								))}
						</div>
					</div>
					<div className="pagination game-history-pagination">
						{payment?.total_count > defaultLimit && (
							<Pagination changePage={changePage} page={page} length={payment?.total_count} limit={defaultLimit} />
						)}
						<div className="pagination__count p13">
							Показано {defaultLimit * page > payment?.total_count ? payment?.total_count : defaultLimit * page} из{' '}
							{payment?.total_count}
						</div>
					</div>
				</div>
			)}
			{/* <ReactTooltip effect="solid" className="react-tooltip" /> */}
		</div>
	)
}
