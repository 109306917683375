import React from 'react'
import Image1 from 'static/img/icons/points-image-1.png'
import Image2 from 'static/img/icons/points-image-2.png'
import Image3 from 'static/img/icons/points-image-3.png'
import Image4 from 'static/img/icons/points-image-4.png'

export const Games = ({ games }) => {
	return (
		<div className="games-statistic">
			<div className="games-statistic__body">
				<div className="games-statistic__left">
					<div className="games-statistic__title h5">Всего посещенных игр</div>
					<div className="games-statistic__count h7">{games.total_count}</div>
				</div>
				<div className="games-statistic__cards">
					{games.list.map((item) => (
						<div className="card-games-statistic" key={item.id}>
							<div className="card-games-statistic__bg">
								<img
									src={
										item.id === 4
											? Image3
											: item.id === 3
											? Image4
											: item.id === 2
											? Image1
											: item.id === 1
											? Image2
											: null
									}
									title="фото мероприятия"
									alt="bg"
								/>
							</div>
							<div className="card-games-statistic__body">
								<div className="card-games-statistic__head">
									<div className="card-games-statistic__title h5">{item.title}</div>
								</div>
								<div className="card-games-statistic__count h7">{item.count}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
