import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import EmptyBlock from '../components/EmptyBlock'
import MessagesBody from '../components/Messages/MessagesBody'
import MessagesList from '../components/Messages/MessagesList'
import { useActions } from '../hooks/useActions'
import { getProfileDialogs } from '../services/Config'
import ImageEmptyMessages from '../static/img/icons/EmptyBlock/messages.svg'

export const Messages = () => {
	const dialogs = useSelector((state) => state.dialogs)
	const dispatch = useDispatch()

	const { loadingData } = useActions()
	const params = useParams()

	useEffect(() => {
		fetchDialogs()
	}, [])

	const fetchDialogs = async () => {
		try {
			loadingData(true)
			const res = await getProfileDialogs()
			dispatch({ type: 'DIALOGS_SET', payload: res.data })
		} catch (error) {
		} finally {
			loadingData(false)
		}
	}

	return (
		<>
			{!!dialogs.length ? (
				<div className="messages">
					<MessagesList dialogs={dialogs} />
					{params.id ? (
						<MessagesBody />
					) : (
						<EmptyBlock
							img={ImageEmptyMessages}
							title={'Выберите, кому хотели бы написать'}
							text={'Здесь будет отображаться ваша переписка с друзьями'}
						/>
					)}
				</div>
			) : (
				<div className="lk-container">
					<div className="lk-container__title-primary">
						<h4 className="lk-container-title h4">Сообщения</h4>
					</div>

					<div className="lk-container__content">
						<EmptyBlock
							img={ImageEmptyMessages}
							title={'У вас нет сообщений'}
							text={'Здесь будет отображаться ваша переписка с друзьями'}
							buttonText={'Написать друзьям'}
							buttonLink={'/friends'}
							buttonType="navlink"
						/>
					</div>
				</div>
			)}
		</>
	)
}
