import React from 'react'

import { useFormContext } from 'react-hook-form'

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
// import { format } from 'date-fns'
import { dateForRequest } from '../../utils/helpers'
import { ru } from 'date-fns/locale'
import clsx from 'clsx'
registerLocale('ru', ru)
setDefaultLocale('ru', ru)

const CustomDatePickerInput = React.forwardRef(({ value, label, name, onClick, onChange, autoComplete }, ref) => {
	const { register, formState } = useFormContext()
	const currValue = value.split('.').join('')

	return (
		<div
			className={clsx('datepicker-input-box', {
				active: value,
			})}
			onClick={onClick}
		>
			<input
				{...register(name)}
				name={name}
				className={clsx('datepicker-input', {
					['error']: formState.errors[name],
				})}
				onChange={onChange}
				ref={ref}
				value={currValue}
				placeholder=" "
				autoComplete={autoComplete}
			/>
			<span className="datepicker-input-placeholder">{label}</span>
			<span className="datepicker-input-icon" />
			{!currValue && <label className="input-box__error">{formState.errors[name]?.message}</label>}
		</div>

		// <div className="input-box">
		//   <input
		//     className="datepicker-input input-box__input"
		//     onClick={onClick}
		//     ref={ref}
		//     id="birthday"
		//     name="birthday"
		//     defaultValue={currValue}
		//     placeholder=" "
		//   />
		//   <label htmlFor="birthday" className="input-box__label">
		//     День рождения
		//   </label>
		// </div>
	)
})

export const CustomDatePicker = ({ label, name, defaultValue }) => {
	const [date, setDate] = React.useState(null)
	const { register, setValue, formState } = useFormContext()

	const dateRef = React.useRef({})

	React.useEffect(() => {
		if (defaultValue) {
			onSelectDate(defaultValue)
		}
	}, [])

	// React.useEffect(() => {
	//   if (router?.query?.from && router?.query?.to) {
	//     const startDate = new Date(router?.query?.from);
	//     const endDate = new Date(router?.query?.to);
	//     setDateRange([startDate, endDate]);
	//   }
	// }, [router.isReady]);

	// const submitDate = (update) => {
	//   const [from, to] = update;

	//   setDateRange(update);

	//   if (!!from !== !!to) return; // проверка что только один из них null

	//   let queryObj = router.query;
	//   delete queryObj["offset"];
	//   delete queryObj["limit"];
	//   if (from == null && to == null) {
	//     delete queryObj["from"];
	//     delete queryObj["to"];
	//   } else {
	//     queryObj = {
	//       ...queryObj,
	//       from: dateForRequest(from),
	//       to: dateForRequest(to),
	//     };
	//   }

	//   router
	//     .push({
	//       query: queryObj,
	//     })
	//     .then(() => {
	//       changeDate(update);
	//     });
	// };

	const onSelectDate = (date) => {
		setDate(date)
		setValue(name, date)
		delete formState.errors[name]
	}

	return (
		<>
			<DatePicker
				className="datepicker-input-box"
				popperClassName="datepicker-calendar"
				onChange={(date) => onSelectDate(date)}
				onSelect={(date) => onSelectDate(date)}
				customInput={<CustomDatePickerInput label={label} />}
				selected={date}
				showMonthDropdown
				showYearDropdown
				scrollableYearDropdown
				dateFormat="dd MMMM yyyy"
				name={name}
				autoComplete="new-password"
			/>
		</>
	)
}

CustomDatePicker.displayName = 'CustomDatePicker'
