import React from 'react'
import clsx from 'clsx'

import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'

import { routeNames } from '../router'
import { motion, AnimatePresence } from 'framer-motion'

import { PasswordRecovery } from '../components/Forms/PasswordRecovery'
import { RecoverySuccess } from '../components/Forms/RecoverySuccess'
import { PasswordNew } from '../components/Forms/PasswordNew'
import { Switcher } from '../components/Switcher/Switcher'
import { Login } from '../components/Forms/Login'
import { Registration } from '../components/Forms/Registration'
import { RegistrationSuccess } from '../components/Forms/RegistrationSuccess'

import AuthBg from '../static/img/auth-bg_desc.jpg'
import { ReactComponent as Logo } from '../static/img/logo.svg'

const up = {
	hidden: {
		y: '50%',
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
}
const right = {
	hidden: {
		width: '100%',
	},
	visible: {
		width: '0',
	},
}
const image = {
	hidden: {
		scale: 1.3,
	},
	visible: {
		scale: 1,
	},
}

export const LoginPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { isAuth, isLoading } = useSelector((state) => state.user)
	const { changeForm } = useActions()
	const { authFormType } = useSelector((state) => state.user)

	React.useEffect(() => {
		if (location.pathname === routeNames.LOGIN.path && location.search.indexOf('token') !== -1) {
			changeForm('newpassword')
		} else if (location.pathname === routeNames.LOGIN.path && authFormType !== 'login') {
			changeForm('login')
		}
		if (location.pathname === routeNames.REGISTRATION.path && authFormType !== 'register') {
			changeForm('register')
		}
	}, [location])

	React.useEffect(() => {
		if (!isLoading && isAuth) {
			navigate('/', { replace: true })
		}
	}, [isAuth])

	const changeFormHandler = (value) => {
		navigate('/' + value, { replace: true })
	}
	return (
		<AnimatePresence exitBeforeEnter>
			<div className="pp__auth">
				<div className="pp__content pp__auth-content">
					<div className={clsx('pp__auth-form', {})}>
						<motion.a
							className="pp__auth-logo"
							href={process.env.REACT_APP_FRONTEND_URL}
							variants={up}
							initial="hidden"
							animate="visible"
							exit="hidden"
							transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
						>
							<Logo />
						</motion.a>

						<motion.div
							className={clsx('pp__auth-form-fields', {})}
							variants={up}
							initial="hidden"
							animate="visible"
							exit="hidden"
							transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
						>
							{(authFormType === 'login' || authFormType === 'register') && (
								<Switcher
									className="pp__auth-switcher"
									values={[
										{ name: 'Вход', value: 'login' },
										{ name: 'Регистрация', value: 'register' },
									]}
									value={authFormType}
									changeValue={changeFormHandler}
								/>
							)}
							{authFormType === 'login' && <Login />}
							{authFormType === 'register' && <Registration />}
							{authFormType === 'register_success' && <RegistrationSuccess />}
							{authFormType === 'recovery' && <PasswordRecovery />}
							{authFormType === 'recovery_success' && <RecoverySuccess />}
							{authFormType === 'newpassword' && <PasswordNew />}
						</motion.div>
					</div>

					<div className="pp__auth-bg">
						<motion.div
							className="pp__auth-bg-curtain"
							variants={right}
							transition={{ ease: [0.89, 0.03, 0.69, 0.22], duration: 0.8 }}
							initial="hidden"
							animate="visible"
							exit="hidden"
						/>

						<div className="pp__auth-bg-image">
							<motion.img
								className="img_cover"
								variants={image}
								initial="hidden"
								animate="visible"
								exit="hidden"
								transition={{ ease: 'easeOut', duration: 6 }}
								src={AuthBg}
								alt="auth-bg"
							/>
						</div>

						<div className="pp__auth-cont">
							{/* <motion.h4
								className="pp__auth-suptitle h4"
								variants={up}
								initial="hidden"
								animate="visible"
								exit="hidden"
								transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
							>
								Мы всегда помним
							</motion.h4> */}

							<motion.h2
								className="pp__auth-title h2"
								variants={up}
								initial="hidden"
								animate="visible"
								exit="hidden"
								transition={{ ease: 'easeOut', duration: 0.4, delay: 0.7 }}
							>
								<span>от уровня команд зависит какие игры мы сможем создавать,</span>
								<br />от уровня игр зависит какие команды будут формироваться
							</motion.h2>
						</div>
					</div>
				</div>
			</div>
		</AnimatePresence>
	)
}
