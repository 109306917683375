import React, { useEffect, useState } from 'react'
import { Switcher } from 'components/Switcher/Switcher'
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts'
import { Button } from 'components/Buttons/Button'
import { formateDate, declOfNum } from 'utils/helpers'

export const PointsChart = ({ points }) => {
	const [data, setData] = useState(null)
	const [switcher, setSwitcher] = useState('year')
	const [totalCount, setTotalCount] = useState(0)

	const changeSwitcher = (newValue) => {
		setSwitcher(newValue)
	}

	useEffect(() => {
		const arr = []
		let total = 0
		let prevValue = 0
		if (Object.values(points.for_year).length > 0) {
			if (switcher === 'year') {
				Object.values(points.for_year.point).map((item) => {
					arr.push({ time: formateDate(item.date, 'dd MMM'), count: item.points })
					prevValue <= item.points ? (total += item.points - prevValue) : (total = total)
					prevValue = item.points
				})
			} else {
				Object.values(points.all_along.point).map((item) => {
					arr.push({ time: formateDate(item.date, 'dd MMM'), count: item.points })
					prevValue <= item.points ? (total += item.points - prevValue) : (total = total)
					prevValue = item.points
				})
			}
			setData(arr)
			setTotalCount(total)
		}
	}, [switcher])

	const CustomTooltip = (object) => (
		<div className="tooltip-statistic">
			<p className="tooltip-statistic__time p8">{object?.label}</p>
			<p className="tooltip-statistic__count p8">
				Баллов: <b>{object?.payload[0]?.payload?.count}</b>
			</p>
		</div>
	)

	return (
		<div className="points-statistic">
			<div className="points-statistic__header">
				<div className="points-statistic__title h4">Баллы команды</div>
				{data && data?.length > 0 ? (
					<div className="points-statistic__switcher">
						<Switcher
							className="switcher"
							values={[
								{ name: 'За год', value: 'year' },
								{ name: 'За все время', value: 'all' },
							]}
							value={switcher}
							changeValue={changeSwitcher}
						/>
					</div>
				) : (
					<Button className={'button yellow'} type={'link'} href="/points" text="как заработать баллы" />
				)}
			</div>
			<div className="points-statistic__body">
				{data && data?.length > 0 ? (
					<>
						<div className="points-statistic__chart">
							<ResponsiveContainer width="100%" height="100%">
								<AreaChart data={data}>
									<XAxis dataKey="time" stroke="#ADADAB" tick={{ fontSize: 12, transform: 'translate(0, 15)' }} />
									<YAxis
										stroke="#ADADAB"
										tick={{ fontSize: 12, paggingRight: '20px', transform: 'translate(-20, 0)' }}
									/>
									<Tooltip content={<CustomTooltip />} />
									<defs>
										<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
											<stop offset={0} stopColor="#E78346" stopOpacity={1} />
											<stop offset={1} stopColor="#E78346" stopOpacity={0.1} />
										</linearGradient>
									</defs>
									<Area type="monotone" dataKey="count" stroke="#E78346" fill="url(#splitColor)" />
								</AreaChart>
							</ResponsiveContainer>
						</div>
						<div className="points-statistic__aside aside-points-statistic">
							<div className="aside-points-statistic__total">
								<div className="aside-points-statistic__balls h7">
									{totalCount > 0 ? totalCount + ' ' + declOfNum(totalCount, ['балл', 'балла', 'баллов']) : '0 баллов'}
								</div>
								<div className="p8">
									{switcher === 'year' ? 'Заработано за год' : 'Заработано за все время игр в клубе'}
								</div>
								<div className="p8">Статистика обновляется раз в месяц</div>
							</div>
							<div className="aside-points-statistic__item">
								<div className="aside-points-statistic__text p8">Статей написано в команде</div>
								<div className="aside-points-statistic__count h8">
									{switcher === 'year' ? points.for_year.posted_articles : points.all_along.posted_articles}
								</div>
							</div>
							<div className="aside-points-statistic__item">
								<div className="aside-points-statistic__text p8">Статей на модерации в данный момент</div>
								<div className="aside-points-statistic__count h8">
									{switcher === 'year' ? points.for_year.moderating_articles : points.all_along.moderating_articles}
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="p2">Нет баллов</div>
				)}
			</div>
		</div>
	)
}
