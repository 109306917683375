import React from 'react'

export const LoaderMini = () => {
	return (
		<div className="loader-mini-wrapper">
			<div className="loader">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	)
}

export default LoaderMini
