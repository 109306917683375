import clsx from 'clsx'
import React from 'react'
import { routeNames } from '../../../router'
import { Button } from '../../Buttons/Button'
import SocialItem from 'components/User/SocialItem'

import VIcon from 'static/img/lk/lk-list-link-ico-1.svg'
import DIcon from 'static/img/lk/lk-list-link-ico-3.svg'
import SIcon from 'static/img/lk/website-48.svg'

export const About = ({ about, anotherTeam, ds, vk, site }) => {
	const [open, setOpen] = React.useState(false)
	const [isBtnShown, setIsBtnShown] = React.useState(false)
	const [activeHeight, setActiveHeight] = React.useState(null)

	const textWrapperRef = React.useRef()
	const textRef = React.useRef()

	React.useEffect(() => {
		if (about && about !== 'null') {
			const textWrapperHeight = textWrapperRef.current.clientHeight
			const textHeight = textRef.current.clientHeight
			const showBtn = textHeight > textWrapperHeight

			setIsBtnShown(showBtn)
			setActiveHeight(textHeight)
		}
	}, [])

	return (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">О команде</h4>
			</div>
			<div className="lk-container__content">
				<ul className="lk-container__about-links">
					{!vk || vk === 'null' ? null : <SocialItem icon={VIcon} text={vk} type='open' />}
					{!ds || ds === 'null' ? null : <SocialItem icon={DIcon} text={ds} type='open' />}
					{!site || site === 'null' ? null : <SocialItem icon={SIcon} text={site} type='open' />}
				</ul>
				{about && about !== 'null' ? (
					<div className="lk-container__about-wrap">
						<div
							className="lk-container__about"
							ref={textWrapperRef}
							style={open && activeHeight ? { height: activeHeight } : {}}
						>
							<div className="lk-container__about-text p4" ref={textRef}>
								{about}
							</div>
						</div>
						{isBtnShown && (
							<button
								onClick={() => setOpen(!open)}
								className={clsx('lk-container__about-btn lk-container__toggle-btn p7', open && 'active')}
								title="кнопка свернуть/развернуть текст о себе"
							>
								<span className="lk-container__toggle-btn-arrow" />
								{open ? 'свернуть' : 'развернуть'}
							</button>
						)}
					</div>
				) : anotherTeam ? (
					<div className="lk-container__about-empty lk-container-empty">
						<div className="lk-container-empty__text p4">Информация отсутствует</div>
					</div>
				) : (
					<div className="lk-container__about-empty lk-container-empty">
						<div className="lk-container-empty__text p4">
							Вы не заполнили информацию о команде <br />
							Тут будет текст о вашей команде.
						</div>
						<div className="lk-container-empty__btns">
							<Button
								type="navlink"
								href={routeNames.TEAMEDIT.path}
								className="lk-container-empty__btn button large yellow"
								text="Заполнить"
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
