import React from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'

import { Portal } from './Portal'
import { Button } from '../Buttons/Button'

import userDefault from '../../static/img/user_default.png'
import { ReactComponent as Logo } from '../../static/img/icons/club-request-pp-icon.svg'
import { dateForRequest, normalizedImageSrc } from '../../utils/helpers'

import { getTeamProfileMembers, getTransactionPrice } from '../../services/Config'
import LoaderMini from '../LoaderMini'
import { Pagination } from '../Pagination/Pagination'
import { UserAssist } from '../User/UserAssist'
import Filters from '../Filters'
import { useSelector } from 'react-redux'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const AddUsersModal = ({ isShown, hideModal, addUsersCb }) => {
	const defaultLimit = 10 // 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const [teamMembers, setTeamMembers] = React.useState({})
	const [filteredTeamMembers, setFilteredTeamMembers] = React.useState([])
	const [isLoad, setIsLoad] = React.useState(false)
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	const [selectedUsers, setSelectedUsers] = React.useState([])

	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	React.useEffect(() => {
		const fetchMembers = async () => {
			try {
				setIsLoad(true)
				const limit = defaultLimit
				const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
				const res = await getTransactionPrice(limit, offset)
				setTeamMembers(res.data)
				setFilteredTeamMembers(
					res.data.list
						.filter((el) => el.id !== userState.id)
						.filter(
							(el) =>
								el?.callname?.toLowerCase()?.includes(search?.toLowerCase()) ||
								el?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
								el?.lastname?.toLowerCase()?.includes(search?.toLowerCase())
						)
				)
			} catch (error) {
				console.log('fetchMembers error', error)
			} finally {
				setIsLoad(false)
			}
		}
		fetchMembers()
	}, [page])

	const searchHandle = (e) => {
		const value = e.target.value
		setSearch(value)
		setFilteredTeamMembers(
			teamMembers.list
				.filter((el) => el.id !== userState.id)
				.filter(
					(el) =>
						el?.callname?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
						el?.name?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
						el?.lastname?.toLowerCase()?.includes(e.target.value.toLowerCase())
				)
		)
	}

	const changePage = (num) => {
		setPage(num)
	}

	const closeModal = () => {
		hideModal()
	}

	const addUsers = () => {
		addUsersCb(selectedUsers)
	}

	const selectUser = (user) => {
		const newArr = [...selectedUsers]
		const alreadyChosen = newArr.filter((u) => user.id === u.id)
		if (alreadyChosen.length < 1) {
			newArr.push(user)
			setSelectedUsers(newArr)
		} else {
			unSelectUser(user)
		}
	}
	const unSelectUser = (user) => {
		const newArr = [...selectedUsers].filter((elem) => elem.id !== user.id)
		setSelectedUsers(newArr)
	}

	return (
		<AnimatePresence exitBeforeEnter>
			{isShown && (
				<Portal>
					<motion.div
						className={clsx('pp', 'users', isShown && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={closeModal} />
						<motion.div className="pp__content pp-users" variants={modal}>
							<div className="pp__content-head">
								<h4 className="pp__title">Добавить игрока</h4>
								<button className="pp__close" onClick={closeModal}>
									<span className="close__lane" />
									<span className="close__lane" />
								</button>
							</div>
							<div className="pp__content-body">
								<div className="pp-users__search">
									<input
										type="text"
										className="pp-users__search-inp"
										onChange={searchHandle}
										value={search}
										placeholder="Поиск..."
									/>
								</div>
								{selectedUsers.length > 0 && (
									<div className="pp-users__selected">
										<Filters className="pp-users__filters" filters={selectedUsers} onClick={unSelectUser} />
									</div>
								)}
								<div className="pp-users__table">
									{isLoad ? (
										<LoaderMini />
									) : (
										<div className="pp-users__items users__cards">
											{filteredTeamMembers.map((user) => (
												<UserAssist
													key={user.id}
													user={user}
													selected={selectedUsers.filter((elem) => elem.id === user.id).length > 0}
													onClick={selectUser}
												/>
											))}
										</div>
									)}
								</div>

								<div className="pp__content-footer">
									{teamMembers?.total_count > defaultLimit && (
										<div className="pagination pp__content-pagination">
											<Pagination
												changePage={changePage}
												page={page}
												length={teamMembers?.total_count}
												limit={defaultLimit}
											/>
										</div>
									)}
									<div className="pp__content-btns">
										<Button
											onClick={addUsers}
											type="button"
											className="pp__content-btn button yellow"
											text="Добавить"
										/>
									</div>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
