import React, { useState, useEffect, useRef } from 'react'
import { LoaderMini } from '../../components/LoaderMini.jsx'
import { HistoryItem } from './HistoryItem.jsx'
import EmptyHistory from 'static/img/icons/EmptyBlock/history.svg'
import { Pagination } from '../../components/Pagination/Pagination'
import EmptyBlock from 'components/EmptyBlock'
import { getProfilePointsHistory } from 'services/Config'

export const History = () => {
	const defaultLimit = 5
	const defaultOffset = 0

	const [history, setHistory] = useState({})
	const [page, setPage] = useState(1)
	const [openHistoryId, setOpenHistoryId] = useState(new Set())
	const [isLoad, setIsLoad] = React.useState(false)

	const changePage = (num) => {
		setPage(num)
	}

	const changeOpenHistory = (id) => {
		let newHistoryId
		if (!openHistoryId.has(id)) {
			newHistoryId = new Set(openHistoryId)
			newHistoryId.add(id)
			setOpenHistoryId(newHistoryId)
			return
		}
		newHistoryId = new Set(openHistoryId)
		newHistoryId.delete(id)
		setOpenHistoryId(newHistoryId)
	}

	const fetchPointsHistory = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getProfilePointsHistory(limit, offset)
			setHistory(res.data)
		} catch (e) {
			console.log('error', e)
		} finally {
			setIsLoad(false)
		}
	}

	useEffect(() => {
		fetchPointsHistory()
	}, [page])

	return (
		<div className="history-points">
			<div className={history.total_count > 0 ? 'history-points__head not-empty' : 'history-points__head'}>
				<div className="history-points__title h4">История начисления баллов</div>
			</div>
			{isLoad ? (
				<LoaderMini />
			) : history?.list?.length > 0 ? (
				<>
					{history?.list.map((item) => {
						const isOpen = openHistoryId.has(item.id)
						return (
							<HistoryItem
								key={item.id}
								id={item.id}
								status={item.status}
								title={item.title}
								updated_at={item.updated_at}
								points_count={item.points_count}
								text={item.text}
								isOpen={isOpen}
								onChange={changeOpenHistory}
							/>
						)
					})}
				</>
			) : (
				<div className="history-points__emptyblock">
					<EmptyBlock
						img={EmptyHistory}
						text="Все заработанные и списанные баллы будут отображены тут. Чем больше баллов - тем выше скидка на мероприятия клуба!"
					/>
				</div>
			)}
			{history?.total_count > defaultLimit && (
				<div className="notifications__pagination">
					<Pagination changePage={changePage} page={page} length={history?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {defaultLimit * page >= history?.total_count ? history?.total_count : defaultLimit * page} из{' '}
						{history?.total_count}
					</div>
				</div>
			)}
		</div>
	)
}
