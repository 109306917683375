import React from 'react'
import { Tabs } from '../components/Tabs'
import { MyFriends } from '../components/Profile/Personal/Friends'
import { MyFriendsRequests } from '../components/Profile/Personal/FriendsRequests'

const tabs = [
	{
		title: 'Мои друзья',
		path: 'friends',
		path2: '?friends="true"',
		component: MyFriends,
	},
	{
		title: 'Заявки в друзья',
		path: 'friendsRequests',
		path2: '?friendsRequests="true"',
		component: MyFriendsRequests,
	},
]

export const Friends = () => {
	return (
		<div className="lk-container">
			<Tabs items={tabs} countPath="friendsRequests" />
		</div>
	)
}
