import React, { useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as EyeIcon } from '../../static/img/icons/eye-icon-gray.svg'
import { normalizeDate, normalizedImageSrc } from 'utils/helpers'
import DefaultImage from 'static/img/articles-default.jpg'

export const ArticleItem = ({ article, userAvatar, callName }) => {
	const [isTagsHidden, setIsTagsHidden] = useState(true)
	const showHiddenTags = () => {
		setIsTagsHidden(false)
	}
	return (
		<>
			{article.status === 'published' ? (
				<a
					className="article-item"
					key={article.id}
					href={`${process.env.REACT_APP_FRONTEND_URL}/articles/${article.id}`}
					target="_blank"
				>
					<div className="article-header">
						<div className="article-header__info">
							<div className="article-header__tags">
								{article?.categories?.length > 0 &&
									article?.categories?.map((tag, idx) => {
										if (isTagsHidden && idx < 3) {
											return (
												<span className="p16 article-tag" key={tag.id}>
													{tag.name}
												</span>
											)
										}
										if (!isTagsHidden) {
											return (
												<span className="p16 article-tag" key={tag.id}>
													{tag.name}
												</span>
											)
										}
									})}
								{isTagsHidden && article?.categories?.length > 3 && (
									<button className="p16 article-tag" onClick={showHiddenTags}>
										...
									</button>
								)}
							</div>
							<div className="articles-rating">
								{Array.from({ length: 5 }).map((_, i) => {
									return <span key={i} className={clsx('rate__item', article.rating > i ? 'active' : '')} />
								})}
							</div>
							{/* <Rating value={article.rating} /> */}
						</div>
						<div className="article-header__image">
							<img
								src={article?.image === '' ? DefaultImage : normalizedImageSrc(article?.image)}
								alt={article?.title}
							/>
						</div>
					</div>
					<div className="article-body">
						<div className="article-status">
							<span className="p15 article-status__item --published">Опубликован</span>
						</div>

						<div className="article-title">
							<p className="p2">{article?.title}</p>
						</div>
						<div className="article-bottom">
							<span className="article-author">
								<img className="article-author__image" src={userAvatar} alt="Позывной" />
								<span className="article-author__text">{callName}</span>
							</span>
							<div className="article-bottom__right">
								<span className="p14 article-date">{normalizeDate(article?.created_at)}</span>
								<span className="p14 article-view__count">
									<EyeIcon className="article-eye__icon" /> <span className="p14">{article?.view__count || 0}</span>
								</span>
							</div>
						</div>
					</div>
				</a>
			) : (
				<div className="article-item" key={article.id}>
					<div className="article-header">
						<div className="article-header__info">
							<div className="article-header__tags">
								{article?.categories?.length > 0 &&
									article?.categories?.map((tag, idx) => {
										if (isTagsHidden && idx < 3) {
											return (
												<span className="p16 article-tag" key={tag.id}>
													{tag.name}
												</span>
											)
										}
										if (!isTagsHidden) {
											return (
												<span className="p16 article-tag" key={tag.id}>
													{tag.name}
												</span>
											)
										}
									})}
								{isTagsHidden && article?.categories?.length > 3 && (
									<button className="p16 article-tag" onClick={showHiddenTags}>
										...
									</button>
								)}
							</div>
							<div className="articles-rating">
								{Array.from({ length: 5 }).map((_, i) => {
									return <span key={i} className={clsx('rate__item', article.rating > i ? 'active' : '')} />
								})}
							</div>
							{/* <Rating value={article.rating} /> */}
						</div>
						<div className="article-header__image">
							<img src={article.image === '' ? DefaultImage : normalizedImageSrc(article.image)} alt={article?.title} />
						</div>
					</div>
					<div className="article-body">
						<div className="article-status">
							<span
								className={clsx('p15 article-status__item', {
									'--consideration': article.status === 'moderate',
								})}
							>
								{article.status === 'moderate' ? 'На модерации' : 'Отклонено'}
							</span>
						</div>

						<div className="article-title">
							<p className="p2">{article?.title}</p>
						</div>
						<div className="article-bottom">
							<span className="article-author">
								<img className="article-author__image" src={userAvatar} alt="Позывной" />
								<span className="article-author__text">{callName}</span>
							</span>
							<div className="article-bottom__right">
								<span className="p14 article-date">{normalizeDate(article?.created_at)}</span>
								<span className="p14 article-view__count">
									<EyeIcon className="article-eye__icon" /> <span className="p14">{article?.view__count || 0}</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

// const Rating = ({ value }) => {
// 	const ratingArray = new Array(5).fill('')
// 	return (
// 		<div className="articles-rating">
// 			{ratingArray.map((el, idx) => {
// 				return (
// 					<StarIcon
// 						key={idx}
// 						className={clsx('articles-rating__star', {
// 							'--grey': idx > value,
// 						})}
// 					/>
// 				)
// 			})}
// 		</div>
// 	)
// }
