import React from 'react'
import { Button } from '../Buttons/Button'
import { ReactComponent as CheckIcon } from '../../static/img/icons/check-icon-orange.svg'

export const VariantsItem = ({ title, text, icon, button, isComplete }) => {
	return (
		<div className="variants-points__item">
			<div className="variants-points__wrap">
				<div className="variants-points__content">
					<div className="variants-points__icon">
						<img
							src={
								icon
									? icon
									: 'https://privatka-back-demo.flat12-dev-server.ru/storage/user_titles/February2022/LlWkaCjG6BG7Glwmk48W.png'
							}
							alt="иконка"
						/>
					</div>
					<div className="variants-points__info">
						<div className="variants-points__title h7">{title}</div>
						<div className="variants-points__text p6">{text}</div>
					</div>
				</div>
				<Button
					type={'link'}
					href={button.link}
					className={
						isComplete ? 'variants-points__link button lightyellow check' : 'variants-points__link button yellow'
					}
					text={isComplete ? 'Выполнено' : button.text}
					Icon={isComplete ? CheckIcon : null}
					iconSide="right"
				/>
			</div>
		</div>
	)
}
