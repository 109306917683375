import React, { useState } from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

import { routeNames } from '../../../router'

import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

import { ReactComponent as EditIcon } from '../../../static/img/icons/pen-icon-small-black.svg'
import { ReactComponent as StatIcon } from '../../../static/img/icons/stat-icon-small-black.svg'
import { ReactComponent as LogOutIcon } from '../../../static/img/icons/log-out-icon-small-black.svg'
import defaultLogo from '../../../static/img/team_default.png'
import defaultUserLogo from '../../../static/img/user_default.png'
import { ReactComponent as BtnCheck } from '../../../static/img/icons/btn-check-black.svg'
import { ReactComponent as UserIcon } from '../../../static/img/icons/user-icon-white.svg'
import { ReactComponent as MapPinIcon } from '../../../static/img/icons/map-pin-icon-white.svg'
import { ReactComponent as HistoryIcon } from '../../../static/img/icons/history-icon-black.svg'
import { ReactComponent as FriendsIcon } from '../../../static/img/icons/friends-icon.svg'
import { ReactComponent as AddUserIcon } from '../../../static/img/icons/add-user-icon.svg'
import { declOfNum, normalizedImageSrc } from '../../../utils/helpers'
import { Button } from '../../Buttons/Button'
import { NavLink } from 'react-router-dom'
import { TeamLeaveModal } from '../../Modals/TeamLeaveModal'
import { useActions } from '../../../hooks/useActions'
import { addTeamFriend, sendToTeamRequest, transferRights } from '../../../services/Config'
import { TeamRequestAddSended } from '../../Modals/TeamRequestAddSended'
import { notifyUser, notifyUserError } from '../../../services/Pnotify'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { TeamLeaveByComanderModal } from '../../Modals/TeamLeaveByComanderModal'

export const Main = ({ leaveTeam, team, members_count, anotherTeam, teamCaptain, teamDeputy, friends = [] }) => {
	const [openTeamLeaveModal, setOpenTeamLeaveModal] = useState(false)
	const [openTeamAddModal, setOpenTeamAddModal] = useState(false)
	const [openMenu, setOpenMenu] = useState(false)
	const [requestSent, setRequestSent] = useState(false)
	const menu = React.useRef()

	const {
		isAuth,
		userData: { user: userState },
	} = useSelector((state) => state.user)

	React.useEffect(() => {
		ReactTooltip.rebuild()
	}, [])

	const { loadingData } = useActions()

	const onOpenMenu = () => {
		setOpenMenu((prev) => !prev)
	}
	const onCloseMenu = () => {
		setOpenMenu(false)
	}
	useOnClickOutside(menu, onCloseMenu)

	const showTeamLeaveModal = () => {
		setOpenTeamLeaveModal(true)
	}
	const hideTeamLeaveModal = () => {
		setOpenTeamLeaveModal(false)
	}
	const leaveTeamCb = () => {
		hideTeamLeaveModal()
		leaveTeam()
	}

	const leaveTeamByComanderCb = async (user) => {
		if (user) {
			try {
				loadingData(true)
				const res = await transferRights(user.id)
				hideTeamLeaveModal()
				leaveTeam()
			} catch (error) {
				notifyUser('Ошибка при передаче прав командира')
			} finally {
				loadingData(false)
			}
		} else {
			notifyUserError('Выберите пользователя')
		}
	}

	const showTeamAddModal = () => {
		setOpenTeamAddModal(true)
	}
	const hideTeamAddModal = () => {
		setOpenTeamAddModal(false)
	}

	const teamFriendRequest = async () => {
		try {
			const res = await addTeamFriend(team.id)
			notifyUser('Заявка на заключение союза отправлена')
			setRequestSent(true)
		} catch (error) {
			notifyUserError('Ошибка при отправке заявки на заключение союза')
		}
	}
	const teamRequest = async () => {
		try {
			const res = await sendToTeamRequest(team.id)
			showTeamAddModal()
			setRequestSent(true)
		} catch (error) {
			notifyUserError('Ошибка отправки заявки на вступление в команду')
		}
	}

	return (
		<>
			<div className="lk-container">
				<div className="lk-container__content team-main">
					{!anotherTeam && (
						<div
							ref={menu}
							className={clsx('team-main__card-menu card-menu', {
								active: openMenu,
							})}
						>
							<span onClick={onOpenMenu} className="card-menu-head">
								<span />
								<span />
								<span />
							</span>
							<div className="card-menu-body">
								{(teamCaptain || teamDeputy) && (
									<NavLink
										to={routeNames.TEAMEDIT.path}
										className="card-menu-link p5"
										title="кнопка редактирования команды"
									>
										<EditIcon className="card-menu-link-icon" />
										<div className="card-menu-link-text">Редактировать</div>
									</NavLink>
								)}
								{teamCaptain && (
									<NavLink
										// disabled
										to={routeNames.STATISTICS.path}
										className="card-menu-link p5"
										title="кнопка просмотра статистики"
									>
										<StatIcon className="card-menu-link-icon" />
										<div className="card-menu-link-text">Статистика команды</div>
									</NavLink>
								)}
								<button onClick={showTeamLeaveModal} className="card-menu-link p5" title="кнопка покинуть команду">
									<LogOutIcon className="card-menu-link-icon" />
									<div className="card-menu-link-text">Покинуть команду</div>
								</button>
							</div>
						</div>
					)}

					<div className="team-main__logo">
						<img
							className="img_cover"
							src={team.avatar ? normalizedImageSrc(team.avatar) : defaultLogo}
							alt="team-logo"
							title="логотип команды"
						/>
					</div>

					<div className="team-main__cont">
						<div className="team-main__head">
							<h4 className="team-main__name h4">{team.name}</h4>
							<h6 className="team-main__points h7">
								{team.point > 0 ? team.point + ' ' + declOfNum(team.point, ['балл', 'балла', 'баллов']) : '0 баллов'}
							</h6>
						</div>
						<div className="team-main__info">
							{team.country && (
								<div className="team-main__info-item">
									<MapPinIcon className="team-main__info-icon" />
									<div className="team-main__info-text">{team.country}</div>
								</div>
							)}
							<div className="team-main__info-item">
								<UserIcon className="team-main__info-icon" />
								<div className="team-main__info-text">
									{members_count} {declOfNum(members_count, ['участник', 'участника', 'участников'])}
								</div>
							</div>
							{team.based_at && (
								<div className="team-main__info-item">
									<HistoryIcon className="team-main__info-icon" />
									<div className="team-main__info-text">Год основания: {team.based_at} г</div>
								</div>
							)}
							{team.models_subdivisions ? (
								<div className="team-main__info-item">
									<FriendsIcon className="team-main__info-icon" />
									<div className="team-main__info-text">Моделирует подразделение</div>
								</div>
							) : null}
							{team.is_recruiting > 0 ? (
								<div className="team-main__info-item">
									<AddUserIcon className="team-main__info-icon" />
									<div className="team-main__info-text">Ведет набор новичков</div>
								</div>
							) : null}
						</div>
						<div className="team-main__footer">
							<div
								className="team-main__btns"
								data-html={true}
								data-for="myTooltip"
								data-tip={
									anotherTeam && !teamCaptain && userState && userState?.team?.id
										? `<span style="display: block;text-align:center" >Вы не можете вступить в <br/> команду, не покинув текущую<span>`
										: null
								}
							>
								
								{isAuth ? 
									(anotherTeam ? (
										teamCaptain ? (
											friends?.filter((f) => f.id === userState.team_id).length > 0 ? (
												<Button type="button" className="team-main__btn button medium yellow" text="В друзьях" />
											) : team?.request ? (
												<Button type="button" className="team-main__btn button medium yellow" text="Заявка отправлена" />
											) : (
												<Button
													onClick={requestSent ? null : teamFriendRequest}
													type="button"
													className={'team-main__btn button medium yellow'}
													text={requestSent ? 'Заявка отправлена' : 'Заключить союз'}
												/>
											)
										) : team?.request ? (
											<Button type="button" className={'team-main__btn button medium yellow'} text="Заявка отправлена" />
										) : (
											<Button
												onClick={requestSent ? null : teamRequest}
												type="button"
												className={'team-main__btn button medium yellow'}
												text={requestSent ? 'Заявка отправлена' : 'Вступить в команду'}
											/>
										)
									) : (
										<Button
											onClick={showTeamLeaveModal}
											type="span"
											iconSide="right"
											Icon={BtnCheck}
											className="team-main__btn button medium lightyellow"
											text="Вы участник"
										/>
									)) : (<></>)
							}
							</div>
							<div className="team-main__managers">
								{team?.commander && (
									<NavLink to={routeNames.USER.path + team.commander.id} className="team-main__manager">
										<div className="team-main__manager-photo">
											<img
												className="img_cover"
												src={team.commander.avatar ? normalizedImageSrc(team.commander.avatar) : defaultUserLogo}
												alt={team.commander.callname}
												title={team.commander.callname}
											/>
										</div>
										<div className="team-main__manager-cont">
											<div className="team-main__manager-rank p7">Командир</div>
											<div className="team-main__manager-nickname p5">{team.commander.callname}</div>
										</div>
									</NavLink>
								)}
								{team?.second_commander?.length > 0 &&
									team?.second_commander.map((d, dId) => {
										if (team?.commander_id !== d.id)
											return (
												<NavLink to={routeNames.USER.path + d.id} key={dId} className="team-main__manager">
													<div className="team-main__manager-photo">
														<img
															className="img_cover"
															src={d.avatar ? normalizedImageSrc(d.avatar) : defaultUserLogo}
															alt={d.callname}
															title={d.callname}
														/>
													</div>
													<div className="team-main__manager-cont">
														<div className="team-main__manager-rank p7">Заместитель</div>
														<div className="team-main__manager-nickname p5">{d.callname}</div>
													</div>
												</NavLink>
											)
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
			{isAuth && userState?.id === userState?.team?.commander_id ? (
				<TeamLeaveByComanderModal
					isShown={openTeamLeaveModal}
					hideModal={hideTeamLeaveModal}
					teamLeaveCb={leaveTeamByComanderCb}
				/>
			) : (
				<TeamLeaveModal
					isShown={openTeamLeaveModal}
					hideModal={hideTeamLeaveModal}
					teamLeaveCb={leaveTeamCb}
					teamId={team.id}
					name={team.name}
					logoSrc={team.avatar ? team.avatar : defaultLogo}
				/>
			)}
			<TeamRequestAddSended
				isShown={openTeamAddModal}
				hideModal={hideTeamAddModal}
				name={team.name}
				logoSrc={team.avatar}
			/>
		</>
	)
}
