import React, { useEffect, useState, useRef } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { getDialogById, getProfileDialogs, sendDialogMessage } from '../../services/Config'
import Img from '../../static/img/user_default.png'
import { FormField } from '../FormField'
import { useForm, FormProvider } from 'react-hook-form'
import clsx from 'clsx'
import { Button } from '../../components/Buttons/Button'
import { ReactComponent as IconPin } from '../../static/img/icons/pin-icon.svg'
import { ReactComponent as IconPlane } from '../../static/img/icons/plane-icon.svg'
import { ReactComponent as IconClose } from '../../static/img/icons/close-icon-small-black.svg'
import { ReactComponent as IconDownLoad } from '../../static/img/icons/download-icon.svg'

import { format } from 'date-fns'
import { LoaderMini } from '../LoaderMini'
import { useDispatch, useSelector } from 'react-redux'
import { distinct, formatDay, groupBy } from 'utils/helpers'
import { notifyUser, notifyUserError } from 'services/Pnotify'
import { routeNames } from 'router'
import { motion, AnimatePresence } from 'framer-motion'

export default function MessagesBody({
	id,
	active,
	dialog_with_user,
	avatar,
	callname,
	user_id,
	last_message_text,
	unread_count,
}) {
	let params = useParams()
	const thisInterval = React.useRef(null)
	const dialogId = params.id
	const inputRef = useRef(null)
	const hasMoreMessages = useRef(true)

	const [isLoading, setIsLoading] = useState(false)
	const [isFormDisable, setFormDisable] = useState(false)
	const dialogData = useSelector((state) => state.singleDialog)

	const [groupedDialogData, setGroupedDialogData] = useState(null)
	const [files, setFiles] = useState([])
	const lastMessageRef = useRef(null)

	const navigate = useNavigate()

	const form = useForm({
		mode: 'onSubmit',
	})

	const offsetRef = useRef(0)
	const dispatch = useDispatch()

	const isKeyUp = (evt) => {
		evt = evt || window.event
		if (evt.keyCode === 27) {
			navigate(routeNames.MESSAGES.path)
		}
	}

	useEffect(() => {
		window.addEventListener('keyup', isKeyUp)
		return () => {
			window.removeEventListener('keyup', isKeyUp)
			dispatch({ type: 'SINGLE_DIALOG_SET', payload: {} })
		}
	}, [])

	const fetchDialogCurrent = async (id) => {
		try {
			setIsLoading(true)
			const res = await getDialogById(id, {
				limit: 20,
				offset: 0,
			})
			if (res.status === 200) {
				res.data.messages = res.data.messages.reverse()

				if (dialogData) {
					const newState = JSON.parse(JSON.stringify(dialogData))
					newState.messages = distinct(dialogData.messages.concat(res.data.messages), {
						equals(v1, v2) {
							return v1.id === v2.id
						},
					}).sort((a, b) => (a.id > b.id) - (b.id > a.id))

					dispatch({ type: 'SINGLE_DIALOG_SET', payload: newState })

					setGroupedDialogData(groupBy([...newState.messages], formatDay))
					scrollDown()
				}
			}
		} catch (error) {
			console.error('error: ', error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		thisInterval.current = setInterval(() => {
			fetchDialogCurrent(dialogId)
		}, 5000)

		return () => {
			clearInterval(thisInterval.current)
		}
	}, [dialogData, dispatch])

	useEffect(() => {
		if (dialogId) {
			offsetRef.current = 0
			fetchDialogData(dialogId)
			// fetchDialogCurrent(dialogId)
		}
	}, [dialogId])

	const scrollDown = () => {
		let scrollH = messagesListRef.current.scrollHeight
		let selfH = messagesListRef.current.offsetHeight
		if (messagesListRef.current.scrollTop > (scrollH - selfH) / 2) {
			messagesListRef.current.scrollTo(0, messagesListRef.current.scrollHeight)
		}
	}

	const onSubmit = async (data) => {
		try {
			if (data.text || files.length) {
				setIsLoading(true)
				setFormDisable(true)
				const formData = new FormData()
				if (files.length) {
					files.forEach((file) => {
						formData.append('files[]', file)
					})
				}
				formData.append('text', data.text)

				const res = await sendDialogMessage(dialogId, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})

				if (res.status === 200) {
					form.reset()
					setFiles([])
				}
			} else {
				notifyUser('Введите сообщение')
			}
		} catch (error) {
			notifyUserError('Ошибка')
		} finally {
			setIsLoading(false)
			setFormDisable(false)
		}
	}

	const fetchDialogData = async (id) => {
		try {
			setIsLoading(true)
			const res = await getDialogById(id, {
				limit: 20,
				offset: offsetRef.current,
			})
			if (res.status === 200) {
				offsetRef.current = res.data.messages.length
				res.data.messages = res.data.messages.reverse()
				dispatch({ type: 'SINGLE_DIALOG_SET', payload: res.data })
				setGroupedDialogData(groupBy(res.data.messages, formatDay))
				messagesListRef.current.scrollTo(0, messagesListRef.current.scrollHeight)
			}
		} catch (error) {
			console.error('error: ', error)
		} finally {
			setIsLoading(false)
		}
	}

	const fetchDialogs = async () => {
		try {
			const res = await getProfileDialogs()
			dispatch({ type: 'DIALOGS_SET', payload: res.data })
		} catch (error) {
		} finally {
		}
	}

	const onChange = async (e) => {
		e.preventDefault()

		const file = e.target.files[0]

		if (!file) return

		setFiles((prev) => [...prev, file])
		return
	}
	const handleClick = () => {
		inputRef.current.click()
	}

	const removeFile = (idx) => {
		return () => {
			const newFilesState = files.filter((el, index) => index !== idx)
			setFiles(newFilesState)
		}
	}

	const messagesListRef = useRef(null)

	const scrollHandler = React.useCallback(() => {
		if (messagesListRef.current.scrollTop < 100) {
			if (!hasMoreMessages.current) return
			fetchMoreDialogs()
		}
	}, [isLoading])

	useEffect(() => {
		messagesListRef?.current?.addEventListener('scroll', scrollHandler)
		return () => messagesListRef?.current?.removeEventListener('scroll', scrollHandler)
	}, [isLoading, scrollHandler])

	const fetchMoreDialogs = async () => {
		try {
			setIsLoading(true)

			const res = await getDialogById(dialogId, {
				limit: 20,
				offset: offsetRef.current,
			})

			if (res.status === 200) {
				if (!res.data.messages.length) {
					hasMoreMessages.current = false
					return
				}
				offsetRef.current = res.data.messages.length + offsetRef.current
				res.data.messages = res.data.messages.reverse()

				if (dialogData) {
					const newState = JSON.parse(JSON.stringify(dialogData))
					newState.messages = [...res.data.messages, ...dialogData.messages]
					dispatch({ type: 'SINGLE_DIALOG_SET', payload: newState })
					setGroupedDialogData(groupBy([...res.data.messages, ...dialogData.messages], formatDay))
				}
			}
		} catch (error) {
			console.error('error: ', error)
		} finally {
			setIsLoading(false)
		}
	}

	const calcExtension = (fileName) => {
		return fileName.split('.').pop()
	}

	const backdrop = {
		exit: { clipPath: 'inset(0% 100% 0% 0%)' },
		visible: { clipPath: 'inset(0% 0% 0% 0%)' },
	}

	return (
		<motion.div
			transition={{ ease: 'easeOut', duration: 0.5 }}
			variants={backdrop}
			initial="exit"
			animate="visible"
			exit="exit"
			className="messages-body"
		>
			{dialogData.user_id && (
				<div className="messages-body__head">
					<NavLink className="messages-body__back" to={routeNames.MESSAGES.path}></NavLink>
					<NavLink to={`/user/${dialogData.user_id}`} className="messages-body__user">
						<div className="messages-body__photo">
							<img
								src={dialogData.user_photo ? process.env.REACT_APP_IMAGES_URL + dialogData.user_photo : Img}
								alt={dialogData.user_callname}
							/>
						</div>
						<div className="messages-body__content">
							<div className="messages-body__title">
								<p className="p10 messages-body__callname">{dialogData?.user_callname}</p>
							</div>
							<p className="p6 messages-body__name">{dialogData?.user_name}</p>
						</div>
					</NavLink>
				</div>
			)}
			<div className="messages-body__container" ref={messagesListRef}>
				{isLoading && <LoaderMini />}
				{!groupedDialogData
					? null
					: Object.keys(groupedDialogData).map((date, dateIdx) => {
							return (
								<ul className="messages-body__list" key={dateIdx}>
									<li className="messages-body__list-date">{date}</li>

									{groupedDialogData[date].map((message, idx) => {
										return (
											<li
												className={clsx('messages-body__list-item', {
													'message-from': message.me_author,
												})}
												key={idx}
											>
												<div className="messages-body__wrapper">
													{!message.me_author && (
														<div className="messages-body__avatar">
															<img src={avatar ? process.env.REACT_APP_IMAGES_URL + avatar : Img} alt="" />
														</div>
													)}
													<div className="message-body">
														<p className="message-text ">{message.text}</p>

														{message.files &&
															message.files.map((file) => {
																return (
																	<div key={file.name} className="messages-file">
																		<span className="messages-file__icon">.{calcExtension(file.name)}</span>
																		<span className="messages-file__name">{file.name}</span>
																		<a
																			className="messages-file__remove"
																			href={file.file}
																			target="_blank"
																			rel="noreferrer noopener"
																			download
																		>
																			<IconDownLoad />
																		</a>
																	</div>
																)
															})}
													</div>
												</div>
												<time className="message-time">{format(new Date(message.date), 'HH:mm')}</time>
											</li>
										)
									})}
								</ul>
							)
					  })}
			</div>

			<div
				className={clsx('messages-chat__input', {
					disabled: isFormDisable,
				})}
			>
				<FormProvider {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="messages-chat__form">
						<FormField elem={'inputNative'} name={'text'} label={'Напишите сообщение'} />
						<div className="messages-chat__form-buttons">
							<button type="button" className="messages-chat__input-btn" onClick={handleClick}>
								<IconPin />
							</button>
							<input
								ref={inputRef}
								name="picture"
								style={{
									display: 'none',
								}}
								type="file"
								accept=".doc, .docx, .jpg, .png, .gif, .csv, .txt, .xlx, .xls, .pdf"
								onChange={onChange}
							/>
							<button type="submit" className="messages-chat__submit-btn">
								<IconPlane />
							</button>
						</div>
					</form>
				</FormProvider>
			</div>
			{files.length > 0 ? (
				<ul className="messages-files__list">
					{files.map((file, idx) => {
						return (
							<li key={file.name} className="messages-file">
								<span className="messages-file__icon">.{calcExtension(file.name)}</span>
								<span className="messages-file__name">{file.name}</span>
								<button className="messages-file__remove" onClick={removeFile(idx)}>
									<IconClose />
								</button>
							</li>
						)
					})}
				</ul>
			) : null}
		</motion.div>
	)
}
