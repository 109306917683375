import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { FormField } from '../../FormField'
import { Button } from '../../Buttons/Button'
import { useActions } from 'hooks/useActions'
import { setProfileSocio } from 'services/Config'
import { notifyUser, notifyUserError } from 'services/Pnotify'
import { useSelector } from 'react-redux'
import { userTypes } from 'store/types/user'

export const Socials = ({ socials }) => {
	const { loadingData } = useActions()
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const form = useForm()

	React.useEffect(() => {
		Object.keys(socials).forEach((soc) => {
			if (socials[soc]?.name?.length > 0) {
				form.setValue(soc, socials[soc].name)
			}
		})
	}, [])

	const onSubmit = async (data) => {
		try {
			loadingData(true)
			let editData = { ...data }
			let formData = new FormData()

			Object.keys(editData).forEach((e, i) => {
				if (!editData[e]) {
					formData.append(e, '')
				} else {
					formData.append(e, editData[e])
				}
			})

			const res = await setProfileSocio(formData)

			Object.keys(editData).forEach((soc, i) => {
				if (editData[soc]?.name?.length > 0) {
					form.setValue(soc, editData[soc].name)
				}
			})
			notifyUser('Данные успешно обновлены')
		} catch (error) {
			notifyUserError('Ошибка обновления данных')
		} finally {
			loadingData(false)
		}
	}

	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="profile__form profile__form-socials">
					<div className="profile__form-text p6">
						Заполните поля с соцсетями, которые хотите чтобы были видны в вашем публичном профиле
					</div>
					<div className="profile__form-socials-body">
						<div className="profile__form-fields form-fields">
							<div className="form-row profile__form-socials-row double">
								<FormField elem="input" name="vk" className="profile__form-socials-field --vk" />
								<FormField elem="input" name="discord" className="profile__form-socials-field --discord" />
							</div>
							{/* <div className="form-row profile__form-socials-row double">
								<FormField elem="input" name="facebook" className="profile__form-socials-field --fb" />
								<FormField elem="input" name="instagram" className="profile__form-socials-field --inst" />
							</div> */}
						</div>
					</div>

					<div className="profile__form-footer">
						<Button type="button" className="profile__form-btn button yellow" text="Сохранить" />
					</div>
				</form>
			</FormProvider>
		</>
	)
}
