import React from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'

import { Portal } from './Portal'
import { Button } from '../Buttons/Button'

import userDefault from '../../static/img/user_default.png'
import { ReactComponent as Logo } from '../../static/img/icons/club-request-pp-icon.svg'
import { dateForRequest, normalizedImageSrc } from '../../utils/helpers'

import { getTeamDeputies, getTeamProfileMembers } from '../../services/Config'
import LoaderMini from '../LoaderMini'
import { Pagination } from '../Pagination/Pagination'
import { UserAssist } from '../User/UserAssist'
import Filters from '../Filters'
import { useSelector } from 'react-redux'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const SetTeamAssistsModal = ({ isShown, hideModal, addAssistsCb }) => {
	const defaultLimit = 10 // 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const [teamMembers, setTeamMembers] = React.useState({})
	const [filteredTeamMembers, setFilteredTeamMembers] = React.useState([])
	const [isLoad, setIsLoad] = React.useState(false)
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	const [selectedUsers, setSelectedUsers] = React.useState([])

	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	React.useEffect(() => {
		const fetchMembers = async () => {
			try {
				setIsLoad(true)
				const limit = defaultLimit
				const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
				const deputies = await getTeamDeputies(100, 0)
				const res = await getTeamProfileMembers(limit, offset)
				deputies.data.members = deputies?.data?.members.map((d) => d.id)
				res.data.list = res?.data?.list?.filter((m) => !deputies.data.members.includes(m.id))
				setTeamMembers(res.data)
				setFilteredTeamMembers(
					res.data.list.filter(
						(el) =>
							el?.callname?.toLowerCase()?.includes(search?.toLowerCase()) ||
							el?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
							el?.lastname?.toLowerCase()?.includes(search?.toLowerCase())
					)
				)
			} catch (error) {
				console.log('fetchMembers error', error)
			} finally {
				setIsLoad(false)
			}
		}

		fetchMembers()
	}, [page])

	const searchHandle = (e) => {
		const value = e.target.value
		setSearch(value)
		setFilteredTeamMembers(
			teamMembers.list.filter(
				(el) =>
					el?.callname?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
					el?.name?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
					el?.lastname?.toLowerCase()?.includes(e.target.value.toLowerCase())
			)
		)
	}

	const changePage = (num) => {
		setPage(num)
	}

	const closeModal = () => {
		hideModal()
	}

	const addAssists = () => {
		addAssistsCb(selectedUsers)
	}

	// const selectUser = (user) => {
	// 	const newArr = [...selectedUsers]
	// 	newArr.push(user)
	// 	setSelectedUsers(newArr)
	// }
	const unSelectUser = (user) => {
		const newArr = [...selectedUsers].filter((elem) => elem.id !== user.id)
		setSelectedUsers(newArr)
	}

	const selectUser = (id) => {
		// const isUserSelected = selectedUsers.includes(id)
		// if (isUserSelected) {
		// 	const newState = selectedUsers.filter((el) => el !== id)
		// 	console.log("newState",newState);
		// 	setSelectedUsers(newState)
		// 	return
		// }
		// setSelectedUsers((prev) => [...prev, id])
		setSelectedUsers([id])
	}

	return (
		<AnimatePresence exitBeforeEnter>
			{isShown && (
				<Portal>
					<motion.div
						className={clsx('pp', isShown && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={closeModal} />
						<motion.div className="pp__content pp-users" variants={modal}>
							<button className="pp__close" onClick={closeModal}>
								<span className="close__lane" />
								<span className="close__lane" />
							</button>

							<div className="pp__content-head">
								<h4 className="pp__title">Назначить заместителем</h4>
							</div>
							<div className="pp__content-body">
								<div className="pp-users__search">
									<input
										type="text"
										className="pp-users__search-inp"
										onChange={searchHandle}
										value={search}
										placeholder="Поиск..."
									/>
								</div>
								{selectedUsers.length > 0 && (
									<div className="pp-users__selected">
										<Filters className="pp-users__filters" filters={selectedUsers} onClick={unSelectUser} />
									</div>
								)}
								<div className="pp-users__table">
									{isLoad ? (
										<LoaderMini />
									) : (
										<div className="pp-assists__items users__cards">
											{filteredTeamMembers
												.filter((u) => u.id !== userState.id)
												.map((user) => {
													return (
														<UserAssist
															key={user.id}
															user={user}
															selected={selectedUsers.filter((e) => e.id === user.id).length > 0}
															onClick={selectUser}
														/>
													)
												})}
										</div>
									)}
								</div>

								<div className="pp__content-footer">
									{teamMembers?.total_count > defaultLimit && (
										<div className="pagination pp__content-pagination">
											<Pagination
												changePage={changePage}
												page={page}
												length={teamMembers?.total_count}
												limit={defaultLimit}
											/>
										</div>
									)}
									<div className="pp__content-btns">
										<Button
											onClick={addAssists}
											type="button"
											className={clsx('pp__content-btn button yellow', {
												disabled: selectedUsers.length < 1,
											})}
											text="Добавить"
										/>
									</div>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
