import React from 'react'
import { Tabs } from '../../Tabs'
import { Friends } from './Friends'
import { FriendsRequests } from './FriendsRequests'

const tabs = [
	{
		title: 'Команды друзья',
		path: 'friends',
		path2: '?friends="true"',
		component: Friends,
	},
	{
		title: 'Заявки',
		path: 'friendsRequests',
		path2: '?friendsRequests="true"',
		component: FriendsRequests,
	},
]

export const TeamFriends = ({ teamCaptain }) => {
	return teamCaptain ? (
		<div className="lk-container">
			<Tabs items={tabs} countPath="friendsRequests" />
		</div>
	) : (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">Команды друзья</h4>
			</div>
			<Friends reRender={true} />
		</div>
	)
}
