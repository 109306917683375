import React from 'react'
import { NavLink } from 'react-router-dom'
import { routeNames } from 'router'

import MedalActive from '../../../static/img/medal-active.svg'
import MedalInactive from '../../../static/img/medal-inactive.svg'

import Achiev_1 from '../../../static/img/achievs/lk-progress-1.svg'
import Achiev_2 from '../../../static/img/achievs/lk-progress-2.svg'
import Achiev_3 from '../../../static/img/achievs/lk-progress-3.svg'
import Achiev_4 from '../../../static/img/achievs/lk-progress-4.svg'
import Achiev_5 from '../../../static/img/achievs/lk-progress-5.svg'
import { Button } from '../../Buttons/Button'
import { normalizedImageSrc } from 'utils/helpers'

// const achievements = [
// 	{
// 		id: 1,
// 		image: MedalActive, //  ссылка на  изображение ачивки активной
// 		image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
// 		status: true, // получена ли ачивка данным юзером
// 		title: 'string',
// 		text: 'string',
// 	},
// 	{
// 		id: 2,
// 		image: MedalActive, //  ссылка на  изображение ачивки активной
// 		image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
// 		status: false, // получена ли ачивка данным юзером
// 		title: 'string',
// 		text: 'string',
// 	},
// 	{
// 		id: 3,
// 		image: MedalActive, //  ссылка на  изображение ачивки активной
// 		image__inactive: MedalInactive, // ссылка на изображение ачивки неактивной
// 		status: false, // получена ли ачивка данным юзером
// 		title: 'string',
// 		text: 'string',
// 	},
// ]

// const achievements = []

export const Achievement = ({ achievements, anotherUser, team }) => {
	return (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">Последние достижения</h4>
				{achievements?.length > 0 && !anotherUser && (
					<NavLink
						to={team ? routeNames.TEAM_ACHIEVEMENTS.path : routeNames.ACHIEVEMENTS.path}
						className="lk-container-link p7"
						title="Кнопка смотреть все"
					>
						Смотреть все
					</NavLink>
				)}
			</div>
			<div className="lk-container__content">
				{achievements?.length > 0 ? (
					<div className="lk-progress">
						<div className="lk-progress__items">
							{Array.from(achievements)
								.reverse()
								.map((item) => (
									<div key={item.id} className="lk-progress__item">
										<img
											className="img_contain lk-progress__item-img"
											src={item.picture ? normalizedImageSrc(item.picture) : normalizedImageSrc(item.image)}
											alt={item.title}
											title={item.title}
										/>
									</div>
								))}
						</div>
					</div>
				) : anotherUser ? (
					<div className="lk-progress__empty lk-container-empty">
						<div className="lk-container-empty__text p4">Информация отсутствует</div>
					</div>
				) : (
					<div className="lk-progress__empty lk-container-empty">
						<div className="lk-container-empty__text p4">
							У вас пока нет достижений. <br />
							Примите участие в играх и получите первую ачивку
						</div>
						<div className="lk-container-empty__btns">
							{!team && (
								<Button
									type="link"
									href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
									className="lk-container-empty__btn button large yellow"
									text="Мероприятия"
								/>
							)}
							<Button
								className="lk-container-empty__btn button large lightyellow"
								type="navlink"
								href={!team ? routeNames.ACHIEVEMENTS.path : routeNames.TEAM_ACHIEVEMENTS.path}
								text="Все достижения"
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
