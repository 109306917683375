import React from 'react'

import { useActions } from '../../hooks/useActions'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordRecoveryFormSchema } from '../../utils/validations'

import { FormField } from '../FormField'
import { Button } from '../Buttons/Button'

import { motion } from 'framer-motion'

export const PasswordRecovery = () => {
	const { sendPasswordRecoveryRequest, changeForm } = useActions()
	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(PasswordRecoveryFormSchema),
	})

	const onSubmit = (data) => {
		sendPasswordRecoveryRequest(data).then(
			() => {
				changeForm('recovery_success')
			},
			() => {}
		)
	}

	const backToAuth = () => {
		changeForm('login')
	}

	return (
		<FormProvider {...form}>
			<motion.form
				animate={{ opacity: 1, x: 0 }}
				initial={{ opacity: 0, x: '-10%' }}
				onSubmit={form.handleSubmit(onSubmit)}
				className="auth__form password__recovery"
			>
				<h5 className="password__recovery-title">Восстановление пароля</h5>
				<div className="password__recovery-text p6">
					Введите e-mail, указанный при регистрации, чтобы мы смогли выслать инструкции по восстановлению
				</div>

				<div className="form-row password__recovery-field">
					<FormField elem={'input'} name={'email'} label={'Email'} placeholder={' '} />
				</div>
				<div className="form-row password__recovery-btns">
					<Button type={'button'} className="password__recovery-btn button yellow large" text={'Отправить'} />
					<span onClick={backToAuth} className="password__recovery-btn --back p7">
						Вернуться к авторизации
					</span>
				</div>
			</motion.form>
		</FormProvider>
	)
}
