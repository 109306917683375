import React from 'react'

import { UserCard } from '../../User/UserCard'
import { Pagination } from '../../Pagination/Pagination'
import {
	addTeamMemberFromRequest,
	deleteTeamMemberFromRequest,
	getTeamProfileMembersRequests,
} from '../../../services/Config'
import { LoaderMini } from '../../LoaderMini'

// const teamMembers = {
// 	list: [
// 		{
// 			id: 1,
// 			avatar: '',
// 			callname: 'Перехватчик',
// 			name: 'Петр',
// 		},
// 		{
// 			id: 2,
// 			avatar: '',
// 			callname: 'Бритва',
// 			name: 'Константин',
// 		},
// 		{
// 			id: 3,
// 			avatar: '',
// 			callname: 'Призрак',
// 			name: 'Александр',
// 		},
// 		{
// 			id: 4,
// 			avatar: '',
// 			callname: 'Перехватчик',
// 			name: 'Петр',
// 		},
// 		{
// 			id: 5,
// 			avatar: '',
// 			callname: 'Бритва',
// 			name: 'Константин',
// 		},
// 		{
// 			id: 6,
// 			avatar: '',
// 			callname: 'Призрак',
// 			name: 'Александр',
// 		},
// 		{
// 			id: 7,
// 			avatar: '',
// 			callname: 'Перехватчик',
// 			name: 'Петр',
// 		},
// 		{
// 			id: 8,
// 			avatar: '',
// 			callname: 'Бритва',
// 			name: 'Константин',
// 		},
// 		{
// 			id: 9,
// 			avatar: '',
// 			callname: 'Призрак',
// 			name: 'Александр',
// 		},
// 		{
// 			id: 10,
// 			avatar: '',
// 			callname: 'Перехватчик',
// 			name: 'Петр',
// 		},
// 		{
// 			id: 11,
// 			avatar: '',
// 			callname: 'Бритва',
// 			name: 'Константин',
// 		},
// 		{
// 			id: 12,
// 			avatar: '',
// 			callname: 'Призрак',
// 			name: 'Александр',
// 		},
// 	],
// 	total_count: 12,
// }

export const MembersRequests = ({ reRender, setCount }) => {
	const defaultLimit = 10
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamMembers, setTeamMembers] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const fetchMembers = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamProfileMembersRequests(limit, offset)
			setTeamMembers(res.data.members_requests)
		} catch (error) {
			console.log('fetchMembers error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		fetchMembers()
	}, [])

	React.useEffect(() => {
		if (reRender) fetchMembers()
	}, [reRender, page])

	React.useEffect(() => {
		setCount(teamMembers.total_count)
	}, [setCount, teamMembers.total_count])

	const changePage = (num) => {
		setPage(num)
	}

	const confirmAssist = async (id) => {
		try {
			const res = await addTeamMemberFromRequest(id)
			fetchMembers()
		} catch (error) {}
	}

	const rejectAssist = async (id) => {
		try {
			const res = await deleteTeamMemberFromRequest(id)
			fetchMembers()
		} catch (error) {}
	}
	return (
		<>
			{isLoad ? (
				<LoaderMini />
			) : teamMembers?.list?.length > 0 ? (
				<div className="users__cards">
					{teamMembers?.list?.map((user) => (
						<UserCard
							onConfirm={confirmAssist}
							onReject={rejectAssist}
							key={user.id}
							user={user}
							fromAssistsRequests="true"
						/>
					))}
				</div>
			) : (
				<div className="lk-container__membersrequests-empty lk-container-empty">
					<div className="lk-container-empty__text p4">У вас нет заявок на вступление.</div>
				</div>
			)}
			{teamMembers.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={teamMembers?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {teamMembers?.list?.length} из {teamMembers?.total_count}
					</div>
				</div>
			)}
		</>
	)
}
