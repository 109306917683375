import React, {useEffect} from 'react'
import { BackBtn } from '../components/Buttons/BackBtn/BackBtn'
import { About } from '../components/Forms/Profile/About'
import { Accounts } from '../components/Forms/Profile/Accounts'
import { Avatar } from '../components/Forms/Profile/Avatar'
import { Main } from '../components/Forms/Profile/Main'
import { Socials } from '../components/Forms/Profile/Socials'
import { useActions } from '../hooks/useActions'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Library from "../components/Library/Library";
import { AvatarFormSchema, TeamAddPhotoFormSchema } from 'utils/validations'
import { notifyUser, notifyUserError } from 'services/Pnotify'

export const UserProfileEdit = () => {
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const { addPhotoAction } = useActions()
	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(TeamAddPhotoFormSchema),
	})
	const {
		register,
		formState: { errors },
		setValue,
	} = form

	const addPhoto = (data) => {
		let formData = new FormData()
		const files = [...data.picture]
		files.forEach((item, index) => {
			formData.append('photo[]', item)
		})
		//formData.append('photo', data.picture)
		addPhotoAction(formData)
	}

	useEffect(() => {
		if (form.formState.errors.picture) {
			notifyUserError(form.formState?.errors?.picture?.message)
			form.clearErrors('picture')
		}
    }, [form.formState.errors?.picture])

	return (
		<section className="section-lk">
			<div className="profile-edit__main">
				<div className="lk-col lk-col__profile-edit">
					<div className="lk-container">
						<div className="lk-container__title-primary">
							<BackBtn className="lk-container__back-btn" />
							<h4 className="lk-container-title h4">Редактирование профиля</h4>
						</div>
						<div className="lk-container__title">
							<h6 className="lk-container-title p1">Основная информация</h6>
						</div>
						<div className="lk-container__content">
							<Main />
						</div>
					</div>
				</div>
				<div className="user-sideinfo">
					<Avatar />
					{!userState.isProfileFilled && (
						<div className="user-sideinfo__personinfo-note">
							<div className="user-sideinfo__personinfo-note-ico"></div>
							<div className="user-sideinfo__personinfo-note-text p14">
								Заполните все поля профиля <br />и получите <b>+ 30 баллов</b>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="lk-col lk-col__profile-edit">
				<div className="lk-container">
					<Library edit={true} />
					<form
							onChange={form.handleSubmit(addPhoto)}
							className="profile__form profile__form-avatar"
							encType="multipart/form-data"
						>
							<div className="profile__form-footer">
								<div className="profile__form-avatar-cont">
									<div className="profile__form-avatar-btns">
										<div className="profile__form-avatar-btn button yellow">
											<input className="profile__form-avatar-input" {...register('picture')} multiple name="picture" type="file" />
											<span className="button-text">Добавить фото</span>
										</div>
									</div>
								</div>
							</div>
						</form>
				</div>
				<div className="lk-container">
					<div className="lk-container__title">
						<h6 className="lk-container-title p1">Дополнительная информация</h6>
					</div>
					<div className="lk-container__content">
						<About />
					</div>
				</div>

				<div className="lk-container">
					<div className="lk-container__title">
						<h6 className="lk-container-title p1">Социальные профили</h6>
					</div>
					<div className="lk-container__content">
						<div className="lk-container__socials">
							{/* TODO сделать авторизацию через соцсети */}
							<div className="lk-container__socials-part">
								<Accounts />
							</div>
							<div className="lk-container__socials-part">
								<Socials socials={userState.socials} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
