import React, { useEffect, useState } from 'react'
import { Switcher } from 'components/Switcher/Switcher'
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts'
import { getMonth } from '../../../utils/helpers'

export const GamesChart = ({ games }) => {
	const [data, setData] = useState(null)

	const [switcher, setSwitcher] = useState('year')
	const changeSwitcher = (newValue) => {
		setSwitcher(newValue)
	}

	const calcAverage = (arr) => Math.round(arr.reduce((acc, num) => acc + num?.count, 0) / arr.length)
	const calcMax = (arr) => Math.max(...arr.map((item) => item?.count))
	const calcMin = (arr) => Math.min(...arr.map((item) => item?.count))
	const calcAllForYear = (arr) => arr.reduce((acc, num) => acc + num?.count, 0)

	useEffect(() => {
		const arr = []
		const keysYear = Object.keys(games?.for_year)
		const keysAll = Object.keys(games?.all_along)
		if (keysYear.length > 0) {
			if (switcher === 'year') {
				keysYear.map((item) => {
					arr.push({ time: getMonth(item), count: games.for_year[item] })
				})
			} else {
				keysAll.map((item) => {
					arr.push({ time: item, count: games.all_along[item] })
				})
			}
			setData(arr)
		}
	}, [switcher])
	const CustomTooltip = (object) => (
		<div className="tooltip-statistic">
			<p className="tooltip-statistic__time p8">{object.label}</p>
			<p className="tooltip-statistic__count p8">
				Игр: <b>{object?.payload[0]?.payload?.count}</b>
			</p>
		</div>
	)

	return (
		<div className="players-statistic">
			<div className="players-statistic__header">
				<div className="players-statistic__title h4">Количество игр</div>
				{data && (
					<div className="players-statistic__switcher">
						<Switcher
							className="switcher"
							values={[
								{ name: 'За год', value: 'year' },
								{ name: 'За все время', value: 'all' },
							]}
							value={switcher}
							changeValue={changeSwitcher}
						/>
					</div>
				)}
			</div>
			<div className="players-statistic__body">
				{data ? (
					<>
						<div className="players-statistic__chart">
							<ResponsiveContainer width="100%" height="100%">
								<AreaChart data={data}>
									<XAxis dataKey="time" stroke="#ADADAB" tick={{ fontSize: 12, transform: 'translate(0, 15)' }} />
									<YAxis
										stroke="#ADADAB"
										tick={{ fontSize: 12, paggingRight: '20px', transform: 'translate(-20, 0)' }}
									/>
									<Tooltip content={<CustomTooltip />} />
									<defs>
										<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
											<stop offset={0} stopColor="#E78346" stopOpacity={1} />
											<stop offset={1} stopColor="#E78346" stopOpacity={0.1} />
										</linearGradient>
									</defs>
									<Area type="monotone" dataKey="count" stroke="#E78346" fill="url(#splitColor)" />
								</AreaChart>
							</ResponsiveContainer>
						</div>
						<div className="players-statistic__aside aside-players-statistic">
							{ switcher === 'all' &&
							<div className="aside-players-statistic__item">
								<div className="aside-players-statistic__text p8">Среднее количество игр</div>
								<div className="aside-players-statistic__count h8">{calcAverage(Object.values(data))}</div>
							</div>
							}
							<div className="aside-players-statistic__item">
								{
									switcher === 'year' ? 
									(
										<>
											<div className="aside-players-statistic__text p8">Общее количество игр</div>
											<div className="aside-players-statistic__count h8">{calcAllForYear(Object.values(data))}</div>
										</>
									) 
									: (
										<>
											<div className="aside-players-statistic__text p8">Максимальное количество игр</div>
											<div className="aside-players-statistic__count h8">{calcMax(Object.values(data))}</div>
										</>
									)
								}
								
							</div>
							{ switcher === 'all' &&
							<div className="aside-players-statistic__item">
								<div className="aside-players-statistic__text p8">Минимальное количество игр</div>
								<div className="aside-players-statistic__count h8">{calcMin(Object.values(data))}</div>
							</div>
							}
						</div>
					</>
				) : (
					<div className="players-statistic__empty p8">Ещё нет данных для статистики</div>
				)}
			</div>
		</div>
	)
}
