import React from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'

import { Button } from '../Buttons/Button'
import { Portal } from './Portal'

import "croppie/croppie.css"
import Croppie from "croppie"

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}
let croppieInstance = false;
export const EditArticlePictureModal = ({ isShown, hideModal, cropImg, imgUrl }) => {
	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	const closeModal = () => {
		if (croppieInstance){
			croppieInstance.destroy()
		}
		hideModal()
	}
	const saveCropImg = () => {
		// debugger
		croppieInstance.result({
			type : "blob",
		}).then(result => {
			//console.log(result);
			cropImg(result)
			closeModal()
		});
	}
	if (isShown) {
		setTimeout(() => {
			const el = document.getElementById("image-helper");
			if (el) {
				croppieInstance = new Croppie(el, {
					// enableExif: true,
					viewport: {
						height: 280,
						width: 450,
						type: 'square'
					},
					boundary: {
						height: 300,
						width: 500,
					},
				});
				//console.log(imgUrl);
				croppieInstance.bind({
					url: imgUrl
				});
			}
		}, 100);
	}
	return (
		<AnimatePresence exitBeforeEnter>
			{isShown && (
				<Portal>
					<motion.div
						className={clsx('pp', isShown && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={closeModal}></div>
						<motion.div className="pp__content pp__contacts" variants={modal}>
							<button className="pp__close" onClick={closeModal}>
								<span className="close__lane" />
								<span className="close__lane" />
							</button>

							<div className="pp__content-head">
								<h4 className="pp__title">Выберите область</h4>
							</div>
							<div className="pp__content-body">
								<div id="image-helper"></div>
								<Button
									onClick={saveCropImg}
									className="pp__contacts-btn button yellow"
									type={'button'}
									text={'Обрезать'}
								/>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
