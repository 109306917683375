import React from 'react'
import { NavLink } from 'react-router-dom'

export const Button = ({ dataTip, type, href, className, iconSide, Icon, text, onClick, submit }) => {
	return (
		<>
			{type === 'navlink' && (
				<NavLink to={href} className={className} onClick={onClick} data-tip={dataTip}>
					{Icon && iconSide === 'left' && <Icon className="button-icon" />}
					<span className="button-text">{text}</span>
					{Icon && iconSide === 'right' && <Icon className="button-icon" />}
				</NavLink>
			)}
			{type === 'link' && (
				<a href={href} className={className} onClick={onClick} data-tip={dataTip}>
					{Icon && iconSide === 'left' && <Icon className="button-icon" />}
					<span className="button-text">{text}</span>
					{Icon && iconSide === 'right' && <Icon className="button-icon" />}
				</a>
			)}
			{type === 'button' && (
				<button className={className} type={submit} onClick={onClick} data-tip={dataTip}>
					{Icon && iconSide === 'left' && <Icon className="button-icon" />}
					<span className="button-text">{text}</span>
					{Icon && iconSide === 'right' && <Icon className="button-icon" />}
				</button>
			)}
			{type === 'span' && (
				<span className={className} onClick={onClick} data-tip={dataTip}>
					{Icon && iconSide === 'left' && <Icon className="button-icon" />}
					<span className="button-text">{text}</span>
					{Icon && iconSide === 'right' && <Icon className="button-icon" />}
				</span>
			)}
		</>
	)
}
