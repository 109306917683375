import React from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { overflowHidden, overflowVisible, scrollDirection } from '../utils/helpers'

import { Menu } from './Profile/Menu'
import { Button } from './Buttons/Button'

import { ReactComponent as Logo } from '../static/img/logo.svg'
import { ReactComponent as LogoWhite } from '../static/img/logo-white.svg'
import { ReactComponent as LogoMob } from '../static/img/icons/club-logo.svg'
import { ReactComponent as AuthBtnArrow } from '../static/img/icons/auth-arrow-right-black.svg'
import { ReactComponent as VIcon } from '../static/img/icons/v-icon.svg'
import { ReactComponent as FIcon } from '../static/img/icons/f-icon.svg'
import { ReactComponent as YIcon } from '../static/img/icons/y-icon.svg'

export function Header() {
	const [isMenuShown, setIsMenuShown] = React.useState(false)
	const [isScroll, setIsScroll] = React.useState(false)
	const [isScrollDown, setIsScrollDown] = React.useState(false)
	const mobileMenu = React.useRef()

	const { isAuth } = useSelector((state) => state.user)

	const showMobileMenu = () => {
		setIsMenuShown(true)
		overflowHidden()
	}
	const hideMobileMenu = (e) => {
		setIsMenuShown(false)
		overflowVisible()
	}

	const headerScroll = () => {
		if (window.scrollY > window.innerHeight / 2) {
			setIsScroll(true)
		} else {
			setIsScroll(false)
		}
		setIsScrollDown(scrollDirection())
	}

	const clickOutsideCb = () => {
		if (!isMenuShown) return
		hideMobileMenu()
	}

	useOnClickOutside(mobileMenu, clickOutsideCb)

	React.useEffect(() => {
		// const handleRouteChange = () => {
		//   setIsMenuShown(false);
		// };
		// router.events.on("routeChangeStart", handleRouteChange);
		window.addEventListener('scroll', headerScroll)
		return () => {
			// router.events.off("routeChangeStart", handleRouteChange);
			window.removeEventListener('scroll', headerScroll)
		}
		// }, [router.events]);
	}, [])

	return (
		<header className={clsx('header', isScroll && 'scroll', isScrollDown && 'scroll-down')}>
			<div className="header__top">
				<div className="grid">
					<div className="header__top-part">
						<nav className="header__top-nav">
							<ul className="header__top-list">
								{/* <li className="header__top-list-item p15">
                  <Link href="/about">
                    <a className="header__top-list-link">О НАС</a>
                  </Link>
                </li> */}
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'news'} className="header__top-list-link">
										НОВОСТИ
									</a>
								</li>
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'reviews'} className="header__top-list-link">
										ОТЗЫВЫ
									</a>
								</li>
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'contacts'} className="header__top-list-link">
										КОНТАКТЫ
									</a>
								</li>
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'articles'} className="header__top-list-link">
										СТАТЬИ
									</a>
								</li>
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'knowledgebase'} className="header__top-list-link">
										БАЗА ЗНАНИЙ
									</a>
								</li>
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'rules'} className="header__top-list-link">
										ПРАВИЛА
									</a>
								</li>
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'system'} className="header__top-list-link">
										СИСТЕМА БАЛЛОВ
									</a>
								</li>
								<li className="header__top-list-item p15">
									<a href={process.env.REACT_APP_FRONTEND_URL + 'faq'} className="header__top-list-link">
										FAQ
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<div className="header__top-part">
						<ul className="header__top-socs header__top-socs-items">
							<li className="header__top-socs-item p15">
								<a
									className="header__top-socs-link"
									target="_blank"
									href="https://clck.ru/ZFEb8"
									rel="noopener noreferrer"
								>
									VKONTAKTE
								</a>
							</li>
							{/* <li className="header__top-socs-item p15">
								<a
									className="header__top-socs-link"
									target="_blank"
									href="https://clck.ru/ZFEmG"
									rel="noopener noreferrer"
								>
									INSTAGRAM
								</a>
							</li> */}
							<li className="header__top-socs-item p15">
								<a
									className="header__top-socs-link"
									target="_blank"
									href="https://clck.ru/ZFEcu"
									rel="noopener noreferrer"
								>
									YOUTUBE
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="header__bottom">
				<div className="grid">
					<a href={process.env.REACT_APP_FRONTEND_URL} className="header__logo">
						<Logo className="header__logo-icon" />
						<LogoMob className="header__logo-icon --mobile" />
					</a>

					<nav className="header__bottom-nav">
						<ul className="header__bottom-list">
							<li className="header__bottom-list-item p12">
								<a href={process.env.REACT_APP_FRONTEND_URL + 'payment'} className="header__bottom-list-link">
									МЕРОПРИЯТИЯ
								</a>
							</li>
							<li className="header__bottom-list-item p12">
								<a href={process.env.REACT_APP_FRONTEND_URL + 'services'} className="header__bottom-list-link">
									СЕРВИСЫ
								</a>
							</li>
							<li className="header__bottom-list-item p12">
								<a href={process.env.REACT_APP_FRONTEND_URL + 'about'} className="header__bottom-list-link">
									О КЛУБЕ
								</a>
							</li>
							<li className="header__bottom-list-item p12">
								<a href={process.env.REACT_APP_FRONTEND_URL + 'members'} className="header__bottom-list-link">
									УЧАСТНИКИ
								</a>
							</li>
							<li className="header__bottom-list-item p12">
								<a href={process.env.REACT_APP_FRONTEND_URL + 'toclub'} className="header__bottom-list-link">
									КАК ПОПАСТЬ В КЛУБ
								</a>
							</li>
						</ul>
					</nav>

					<div className="header__btns">
						<div className="header__auth">
							{isAuth ? (
								<Menu />
							) : (
								<Button
									className="header__auth-btn button yellow"
									type="navlink"
									href={'/login'}
									iconSide="left"
									Icon={AuthBtnArrow}
									text="Войти"
								></Button>
							)}
						</div>
						<div className="header__burger" onClick={showMobileMenu}></div>
					</div>
				</div>
			</div>

			<div className={clsx('header__mobile', isMenuShown && 'shown')}>
				<div className="header__mobile-overlay"></div>
				<div className="header__mobile-content" ref={mobileMenu}>
					<div className="header__mobile-top">
						<div className="grid">
							<div className="header__mobile-headline">
								<a href={process.env.REACT_APP_FRONTEND_URL} className="header__mobile-logo">
									<LogoWhite />
								</a>
								<div className="header__mobile-close-btn" onClick={hideMobileMenu}></div>
							</div>
							<nav className="header__mobile-top-nav">
								<ul className="header__mobile-top-nav-list">
									<li className="header__mobile-top-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'payment'} className="header__mobile-top-nav-link">
											МЕРОПРИЯТИЯ
										</a>
									</li>
									<li className="header__mobile-top-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'services'} className="header__mobile-top-nav-link">
											СЕРВИСЫ
										</a>
									</li>
									<li className="header__mobile-top-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'about'} className="header__mobile-top-nav-link">
											О КЛУБЕ
										</a>
									</li>
									<li className="header__mobile-top-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'members'} className="header__mobile-top-nav-link">
											СОСТАВ КЛУБА
										</a>
									</li>
									<li className="header__mobile-top-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'toclub'} className="header__mobile-top-nav-link">
											КАК ПОПАСТЬ В КЛУБ
										</a>
									</li>
								</ul>
							</nav>
						</div>
					</div>
					<div className="header__mobile-bottom">
						<div className="grid">
							<nav className="header__mobile-bottom-nav">
								<ul className="header__mobile-bottom-nav-list">
									{/* <li className="header__mobile-bottom-nav-item">
                    <Link href="/about">
                      <a className="header__mobile-bottom-nav-link">О НАС</a>
                    </Link>
                  </li> */}
									<li className="header__mobile-bottom-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'news'} className="header__mobile-bottom-nav-link">
											НОВОСТИ
										</a>
									</li>
									<li className="header__mobile-bottom-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'reviews'} className="header__mobile-bottom-nav-link">
											ОТЗЫВЫ
										</a>
									</li>
									<li className="header__mobile-bottom-nav-item">
										<a
											href={process.env.REACT_APP_FRONTEND_URL + 'contacts'}
											className="header__mobile-bottom-nav-link"
										>
											КОНТАКТЫ
										</a>
									</li>
									<li className="header__mobile-bottom-nav-item">
										<a
											href={process.env.REACT_APP_FRONTEND_URL + 'articles'}
											className="header__mobile-bottom-nav-link"
										>
											СТАТЬИ
										</a>
									</li>
									<li className="header__mobile-bottom-nav-item">
										<a
											href={process.env.REACT_APP_FRONTEND_URL + 'knowledgebase'}
											className="header__mobile-bottom-nav-link"
										>
											БАЗА ЗНАНИЙ
										</a>
									</li>
									<li className="header__mobile-bottom-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'rules'} className="header__mobile-bottom-nav-link">
											ПРАВИЛА
										</a>
									</li>
									<li className="header__mobile-bottom-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'system'} className="header__mobile-bottom-nav-link">
											СИСТЕМА БАЛЛОВ
										</a>
									</li>
									<li className="header__mobile-bottom-nav-item">
										<a href={process.env.REACT_APP_FRONTEND_URL + 'faq'} className="header__mobile-bottom-nav-link">
											FAQ
										</a>
									</li>
								</ul>
							</nav>

							<div className="header__mobile-socs socs">
								<a
									href="/"
									className="header__mobile-socs-item socs__item black"
									target="_blank"
									rel="noopener noreferrer"
								>
									<VIcon className="socs__icon" />
								</a>
								<a
									href="/"
									className="header__mobile-socs-item socs__item black"
									target="_blank"
									rel="noopener noreferrer"
								>
									<YIcon className="socs__icon" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}
