import React from 'react'
import { Tabs } from '../../Tabs'
import { Members } from './Members'
import { MembersRequests } from './MembersRequests'

const tabs = [
	{
		title: 'Состав',
		path: 'members',
		path2: '?members="true"',
		component: Members,
	},
	{
		title: 'Заявки',
		path: 'membersRequests',
		path2: '?membersRequests="true"',
		component: MembersRequests,
	},
]

export const TeamMembers = ({ teamDeputy, teamCaptain }) => {
	return teamCaptain || teamDeputy ? (
		<div className="lk-container">
			<Tabs items={tabs} countPath="membersRequests" />
		</div>
	) : (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">Состав</h4>
			</div>
			<Members reRender={true} />
		</div>
	)
}
