import React from 'react'
import Img from '../../static/img/user_default.png'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { routeNames } from '../../router'

export default function DialogItem({
	id,
	active,
	dialog_with_user,
	avatar,
	callname,
	last_message_text,
	unread_count,
}) {
	return (
		<NavLink
			to={'/messages/' + id}
			className={clsx('dialog-item', {
				active,
			})}
		>
			<div className="dialog-item__photo">
				<img src={avatar ? process.env.REACT_APP_IMAGES_URL + avatar : Img} alt={callname} />
			</div>
			<div className="dialog-item__content">
				<div className="dialog-item__title">
					<p className="dialog-item__name p10">{callname}</p>
					{unread_count ? <div className="dialog-item__counter">{unread_count}</div> : null}
				</div>
				{!!last_message_text?.length && (
					<p className="dialog-item__text p6">{last_message_text.map((message) => message.text)}</p>
				)}
			</div>
		</NavLink>
	)
}
