import React, { useEffect, useState } from 'react'
import { declOfNum } from 'utils/helpers'
import Logo from '../../../static/img/lk/test.png'

export const Titles = ({ anotherUser, titles, points, guest }) => {
	const [title, settitle] = useState(titles.filter((t) => t.status === 'active')[0] || null)
	return (
		<div className="lk-container">
			<div className="lk-container__content">
				<div className="lk-titles-another">
					{title?.id && (
						<div className="lk-titles-another__photo">
							<img
								src={
									guest
										? process.env.REACT_APP_IMAGES_URL + title?.image_disabled
										: process.env.REACT_APP_IMAGES_URL + title?.image_active
								}
								alt={title?.title}
								title={title?.title}
							/>
						</div>
					)}
					<div className="lk-titles-another__cont">
						<h6 className="lk-titles-another__title h7">{title?.id ? title?.title : 'Без опыта'}</h6>
						<h6 className="lk-titles-another__title-secondary h7">
							{points > 0 ? points + ' ' + declOfNum(points, ['балл', 'балла', 'баллов']) : '0 баллов'}
						</h6>
					</div>
				</div>
			</div>
		</div>
	)
}
