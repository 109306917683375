import React from 'react'

import { ReactComponent as Logo } from '../static/img/logo-white.svg'

import { ReactComponent as WIcon } from '../static/img/icons/w-icon.svg'
import { ReactComponent as VIcon } from '../static/img/icons/v-icon.svg'
import { ReactComponent as FIcon } from '../static/img/icons/f-icon.svg'
import { ReactComponent as YIcon } from '../static/img/icons/y-icon.svg'
import { ReactComponent as TIcon } from '../static/img/icons/t-icon.svg'

import { ReactComponent as DevLogo } from '../static/img/flat12-logo.svg'

import MastercardWebp from '../static/img/mastercard.webp'
import MirWebp from '../static/img/mir.webp'
import VisaWebp from '../static/img/visa.webp'
import Mastercard from '../static/img/mastercard.png'
import Mir from '../static/img/mir.png'
import Visa from '../static/img/visa.png'

import requisites from '../static/pdf/Rekvizity_i_vozvrat.pdf'
import soglashenie from '../static/pdf/soglashenie.pdf'

export function Footer() {
	return (
		<footer className="footer">
			<div className="footer__top">
				<div className="grid">
					<div className="footer__top-part footer__top-part-links">
						<a href={process.env.REACT_APP_FRONTEND_URL} className="footer__logo" rel="noopener noreferrer">
							<Logo />
						</a>
						<nav className="footer__nav">
							<ul className="footer__nav-col">
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'services'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Сервисы
									</a>
								</li>
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Мероприятия
									</a>
								</li>
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'knowledgebase'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										База знаний
									</a>
								</li>
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'rules'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Правила
									</a>
								</li>
							</ul>
							<ul className="footer__nav-col">
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'faq'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										FAQ
									</a>
								</li>
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'news'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Новости
									</a>
								</li>
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'articles'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Статьи
									</a>
								</li>
								{/* <li className="footer__nav-item">
                  <Link href="/about">
                    <a className="footer__nav-link p4">О нас</a>
                  </Link>
                </li> */}
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'members'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Участники
									</a>
								</li>
							</ul>
							<ul className="footer__nav-col">
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'contacts'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Контакты
									</a>
								</li>
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'toclub'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Как попасть в клуб
									</a>
								</li>
								<li className="footer__nav-item">
									<a
										href={process.env.REACT_APP_FRONTEND_URL + 'system'}
										className="footer__nav-link p4"
										rel="noopener noreferrer"
									>
										Система баллов
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<div className="footer__top-part footer__contacts">
						<div className="footer__contacts-links">
							{/* <a href="tel:88003002525" className="footer__contacts-link h8">
								8 (800) 300–25–25
							</a> */}
							<a href="mailto:privatka.club@gmail.com" className="footer__contacts-link h8">
								privatka.club@gmail.com
							</a>
						</div>
						<div className="footer__contacts-socs socs">
							<a
								href="https://wa.me/79197201457"
								className="footer__contacts-socs-item socs__item gray"
								target="_blank"
								rel="noopener noreferrer"
							>
								<WIcon className="socs__icon" />
							</a>
							<a
								href="https://clck.ru/ZFEb8"
								className="footer__contacts-socs-item socs__item gray"
								target="_blank"
								rel="noopener noreferrer"
							>
								<VIcon className="socs__icon" />
							</a>
							<a
								href="https://clck.ru/ZFEcu"
								className="footer__contacts-socs-item socs__item gray"
								target="_blank"
								rel="noopener noreferrer"
							>
								<YIcon className="socs__icon" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="footer__bottom">
				<div className="grid">
					<div className="footer__bottom-part">
						<div className="footer__bottom-items">
							<div className="footer__bottom-item p15">© Приватка Клуб, {new Date().getFullYear()}</div>
							<div className="footer__bottom-item p15">Все права защищены</div>
							<a href={soglashenie} className="footer__bottom-item p15" target="_blank" rel="noopener noreferrer">
								Политика конфенденциальности
							</a>
							<a href={requisites} className="footer__bottom-item p15" target="_blank" rel="noopener noreferrer">
								Реквизиты и возврат
							</a>
						</div>
						<div className="footer__bottom-items logos">
							<picture>
								<source type="image/webp" srcSet={VisaWebp} />
								<img src={Visa} alt="visa" />
							</picture>
							<picture>
								<source type="image/webp" srcSet={MirWebp} />
								<img src={Mir} alt="МИР" />
							</picture>
							<picture>
								<source type="image/webp" srcSet={MastercardWebp} />
								<img src={Mastercard} alt="mastercard" />
							</picture>
						</div>
					</div>
					<div className="footer__bottom-part">
						<a className="footer__bottom-dev-link" target="_blank" href="https://flat12.ru/" rel="noopener noreferrer">
							<DevLogo />
						</a>
					</div>
				</div>
			</div>
		</footer>
	)
}
