import React from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/useActions'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AvatarFormSchema } from '../../../utils/validations'
import { Button } from '../../Buttons/Button'
import { EditPictureModal } from '../../Modals/EditPictureModal'

import DefaultLogo from '../../../static/img/user_default.png'
import { normalizedImageSrc } from '../../../utils/helpers'
import {AvatarModal} from "../../Modals/AvatarModal";

let avatar = false

export const Avatar = () => {
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const { editProfileAction, deleteProfileAvatarAction, addPhotoAction } = useActions()
	const { setValue, register, reset, formState, handleSubmit } = useForm({
		mode: 'onChange',
		resolver: yupResolver(AvatarFormSchema),
	})
	const [openEditAvatarModal, setOpenEditAvatarModal] = React.useState(false)

	const [isAvatarModal, setIsAvatarModal] = React.useState(false)

	const showAvatarModal = () => {
		setIsAvatarModal(true)
	}
	const hideAvatarModal = () => {
		setIsAvatarModal(false)
	}


	const showEditAvatarModal = () => {
		// fix cache browser for new image, and preload img before open
		fetch(process.env.REACT_APP_API_URL + "/profile/avatar?id="+ userState.id).then(r=>{
			setOpenEditAvatarModal(true);
		})

	}
	const hideEditAvatarModal = () => {
		setOpenEditAvatarModal(false)
	}
	const corpImg = (newImg) => {
		let formData = new FormData();
		let file = new File([newImg],`test.${newImg.type.split('/').pop()}`)
		formData.append('avatar', file)
		formData.append('name', userState.name)
		formData.append('lastname', userState.lastname)
		formData.append('city', userState.city)
		formData.append('email', userState.email)

		editProfileAction(formData)
	}


	const removeAvatar = () => {
		deleteProfileAvatarAction()
	}

	const onSubmit = (data) => {
		let formData = new FormData()
		formData.append('avatar', data.picture[0])
		formData.append('name', userState.name)
		formData.append('lastname', userState.lastname)
		formData.append('city', userState.city)
		formData.append('email', userState.email)

		editProfileAction(formData)
	}

	const addPhoto = (data) => {
		let formData = new FormData()
		formData.append('photo', data.picture[0])

		addPhotoAction(formData)
	}

	return (
		<>
			<form
				onChange={handleSubmit(onSubmit)}
				className="profile__form profile__form-avatar"
				encType="multipart/form-data"
			>
				<div className="profile__form-avatar-body">
					<div className="profile__form-avatar-box-wrap">
						<div className="profile__form-avatar-box">
							<a onClick={showAvatarModal}>
								<img
									className="profile__form-avatar-box-photo"
									// src={avatar ? avatar : DefaultLogo}
									src={userState.avatar ? normalizedImageSrc(userState.avatar) : DefaultLogo}
									alt="user-logo"
									title="аватар"
								/>
							</a>
							<AvatarModal isShow={isAvatarModal} hideModal={hideAvatarModal} img={userState.avatar ? normalizedImageSrc(userState.avatar) : DefaultLogo}/>
						</div>
						{formState.errors['picture'] && (
							<label className="input-box__error">{formState.errors['picture']?.message}</label>
						)}
					</div>
					<div className="profile__form-avatar-cont">
						<div className="profile__form-avatar-text p6">Максимальный размер 300х300, вес менее 1 Мб</div>

						<div className="profile__form-avatar-btns">
							{userState.avatar ? (
								<>
									<div className="profile__form-avatar-btn button medium yellow">
										<input className="profile__form-avatar-input" {...register('picture')} name="picture" type="file" />
										<span className="button-text">Изменить</span>
									</div>
									<Button
										onClick={showEditAvatarModal}
										type="span"
										className="profile__form-avatar-btn button medium yellow"
										text="Обрезать"
									/>
									<Button
										onClick={removeAvatar}
										type="span"
										className="profile__form-avatar-btn button medium lightyellow"
										text="Удалить"
									/>
								</>
							) : (
								<div className="profile__form-avatar-btn button medium yellow">
									<input className="profile__form-avatar-input" {...register('picture')} name="picture" type="file" />
									<span className="button-text">Загрузить</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</form>
			{/*<form
				onChange={handleSubmit(addPhoto)}
				className="profile__form profile__form-avatar"
				encType="multipart/form-data"
			>
				<div className="profile__form-avatar-body">
					<div className="profile__form-avatar-cont">
						<div className="profile__form-avatar-btns">
							{userState.avatar && (
								<>
									<div className="profile__form-avatar-btn button medium yellow">
										<input className="profile__form-avatar-input" {...register('picture')} name="picture" type="file" />
										<span className="button-text">Добавить фото</span>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
							</form>*/}
			<EditPictureModal
				isShown={openEditAvatarModal}
				hideModal={hideEditAvatarModal}
				cropImg={corpImg}
				imgUrl={process.env.REACT_APP_API_URL + "/profile/avatar?id="+ userState.id}
			/>
		</>
	)
}
