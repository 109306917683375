import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { UserCard } from '../../User/UserCard'
import { Pagination } from '../../Pagination/Pagination'
import { addFriendFromRequest, deleteFriendRequest, getProfileFriendsRequests } from '../../../services/Config'
import { LoaderMini } from '../../LoaderMini'
import EmptyBlock from '../../EmptyBlock'
import Nofriends from '../../../static/img/icons/EmptyBlock/nofriends.svg'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

export const MyFriendsRequests = ({ reRender, setCount }) => {
	const defaultLimit = 15
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const friendsRequests = useSelector((state) => state.friendsRequests)
	const dispatch = useDispatch()
	const [teamMembers, setTeamMembers] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const fetchMembers = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getProfileFriendsRequests(limit, offset)
			// setTeamMembers(res.data)
			dispatch({ type: 'FRIENDSREQS_SET', payload: res.data })
		} catch (error) {
			console.log('fetchMembers error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		fetchMembers()
	}, [])

	React.useEffect(() => {
		if (reRender) fetchMembers()
	}, [reRender, page])

	React.useEffect(() => {
		setCount(friendsRequests?.total_count)
	}, [setCount, friendsRequests?.total_count])

	const changePage = (num) => {
		setPage(num)
	}

	const confirmAssist = async (id) => {
		try {
			const res = await addFriendFromRequest(id)
			fetchMembers()
		} catch (error) {}
	}

	const rejectAssist = async (id) => {
		try {
			const res = await deleteFriendRequest(id)
			fetchMembers()
		} catch (error) {}
	}
	return (
		<>
			{isLoad ? (
				<LoaderMini />
			) : friendsRequests?.list?.length > 0 ? (
				<div className="users__cards">
					{friendsRequests.list.map((user) => (
						<UserCard
							onConfirm={confirmAssist}
							onReject={rejectAssist}
							key={user.id}
							user={user}
							fromAssistsRequests="true"
						/>
					))}
				</div>
			) : (
				<EmptyBlock img={Nofriends} title={'У вас нет заявок в друзья'} />
			)}
			{friendsRequests?.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={friendsRequests?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {friendsRequests?.list?.length} из {friendsRequests?.total_count}
					</div>
				</div>
			)}
		</>
	)
}
