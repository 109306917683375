import clsx from 'clsx'
import { isPast } from 'date-fns/esm'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { routeNames } from 'router'
import { getPlayers } from 'services/Config'

import eventImage from '../../static/img/event-card-bg.jpg'
import { normalizedImageSrc, rangeOfDates } from '../../utils/helpers'
import { Button } from '../Buttons/Button'
import { WhoPaidModal } from '../Modals/WhoPaidModal'
import { useSelector } from 'react-redux'

export const EventCard = ({ event, isPast, payment, className, openModal }) => {
	const id = event?.id
	const name = event?.name
	const type = event?.type?.name
	const polygon = event?.polygon?.name
	const date_start = event?.date_start
	const date_end = event?.date_end
	const country = event?.country
	const price = event?.price
	const image = event?.image

	const [players, setPlayers] = useState({})
	const [openWhoPaidModal, setOpenWhoPaidModal] = React.useState(false)
	const { isAuth, userData: userState } = useSelector((state) => state.user)

	const navigate = useNavigate()

	useEffect(() => {
		const fetchPlayers = async () => {
			const res = await getPlayers(id)
			setPlayers(res.data)
		}
		if (isAuth) {
			fetchPlayers()
		}
	}, [])

	const showWhoPaidModal = (e) => {
		e.stopPropagation()
		e.preventDefault()
		setOpenWhoPaidModal(true)
	}
	const hideWhoPaidModal = () => {
		setOpenWhoPaidModal(false)
	}

	return (
		<>
			<a
				href={process.env.REACT_APP_FRONTEND_URL + 'payment/' + id}
				className={clsx('event__card', className ? className : '')}
				title="ссылка на мероприятие"
			>
				<div className="event__card-bg-wrap">
					<div className="event__card-bg">
						<img
							className="event__card-bg"
							src={image ? normalizedImageSrc(image) : eventImage}
							title={name}
							alt={name}
						/>
					</div>
				</div>
				<div className="event__card-cont">
					<h6 className="event__card-title h3">{name}</h6>

					<div className="event__card-info">
						<div className="event__card-params">
							{type && (
								<div className="event__card-param param-type">
									<div className="event__card-param-head p16">Тип Игры</div>
									<div className="event__card-param-body p5">{type}</div>
								</div>
							)}
							{polygon && (
								<div className="event__card-param param-area">
									<div className="event__card-param-head p16">Полигон</div>
									<div className="event__card-param-body p5">{polygon}</div>
								</div>
							)}
							{date_start && date_end && (
								<div className="event__card-param param-date">
									<div className="event__card-param-head p16">Дата </div>
									<div className="event__card-param-body p5">{rangeOfDates(date_start, date_end)}</div>
								</div>
							)}
							{country && (
								<div className="event__card-param param-city">
									<div className="event__card-param-head p16">город</div>
									<div className="event__card-param-body p5">{country}</div>
								</div>
							)}
						</div>

						{!isPast && players?.list?.length > 0 && (
							<div className="event__card-payment">
								<div className="event__card-payment-head p16">Участвуют:</div>
								<div className="event__card-payment-body h6">
									<div className="user-sideinfo__list-friends">
										{players?.list?.slice(0, 4)?.map((p) => (
											<button
												key={p.id}
												onClick={(e) => (e.preventDefault(), navigate(routeNames.USER.path + p.id))}
												className="user-sideinfo__list-friend"
												title="ссылка на страницу друга"
											>
												<img
													src={process.env.REACT_APP_IMAGES_URL + p.avatar}
													className="img_cover"
													alt={p.callname}
													title={p.callname}
												/>
											</button>
										))}
									</div>
								</div>
								<Button
									onClick={showWhoPaidModal}
									type={'button'}
									className={'event__card-payment-btn button medium blue'}
									text={'Кто оплатил?'}
								/>
							</div>
						)}
					</div>
				</div>
			</a>
			<WhoPaidModal items={{ name: name, ...players }} isShown={openWhoPaidModal} hideModal={hideWhoPaidModal} />
		</>
	)
}
