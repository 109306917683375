import React from 'react'
import clsx from 'clsx'

import { motion, AnimatePresence } from 'framer-motion'

import { Portal } from './Portal'
import { Button } from '../Buttons/Button'
import { normalizedImageSrc } from '../../utils/helpers'
import TeamLogo from '../../static/img/team_default.png'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const DeleteDeputyModal = ({ isShown, hideModal, deleteUserCb, user }) => {
	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	const closeModal = () => {
		hideModal()
	}

	return (
		<AnimatePresence exitBeforeEnter>
			{isShown && (
				<Portal>
					<motion.div
						className={clsx('pp', isShown && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={closeModal}></div>
						<motion.div className="pp__content pp__friend-delete" variants={modal}>
							<button className="pp__close" onClick={closeModal}>
								<span className="close__lane" />
								<span className="close__lane" />
							</button>

							<div className="pp__content-head">
								<h4 className="pp__title">Разжаловать</h4>
							</div>
							<div className="pp__content-body">
								<div className="pp__content-text p3">
									Вы действительно хотите разжаловать пользователя из заместителя командира
								</div>

								<div className="pp__content-user">
									<div className="pp__content-user-logo">
										<img src={normalizedImageSrc(user.avatar)} className="img_cover" alt="user-logo" />
									</div>
									<div className="pp__content-user-callname p1">{user.callname}</div>
									<div className="pp__content-user-name p6">
										{user.name ? user.name : ''} {user.lastname ? user.lastname : ''}
									</div>
								</div>
								<div className="pp__content-btns">
									<Button
										onClick={() => deleteUserCb(user)}
										type="button"
										className="pp__content-btn button yellow"
										text="Разжаловать"
									/>
									<Button
										onClick={closeModal}
										type="button"
										className="pp__content-btn button lightyellow"
										text="Отмена"
									/>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
