import React from 'react'

import { useActions } from '../../hooks/useActions'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RegisterFormSchema } from '../../utils/validations'

import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { FormField } from '../FormField'
import { Button } from '../Buttons/Button'
import { ReactComponent as Logo } from '../../static/img/icons/register-success-logo.svg'

import { motion } from 'framer-motion'
import { Social } from './Social'
import { getTeams } from '../../services/Config'

import { AddressSuggestions } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'

export const Registration = () => {
	const { registerUser, changeForm } = useActions()

	const [city, setCity] = React.useState(null)

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(RegisterFormSchema),
	})

	const onSubmit = async (data) => {
		const formData = {
			...data,
			city: city ? city.value : '',
		}
		registerUser(formData)
	}

	const filterTeams = (inputValue, teamsOptions) => {
		return teamsOptions.filter((i) => i.name.toLowerCase().includes(inputValue.toLowerCase()))
	}

	const asyncOptions = async (inputValue) => {
		const {
			response: { teams },
		} = await getTeams()
		const temsOptopins = teams.map((team) => ({ ...team, value: team.id, label: team.name }))
		return filterTeams(inputValue, temsOptopins)
	}

	return (
		<FormProvider {...form}>
			<motion.form
				animate={{ opacity: 1, x: 0 }}
				initial={{ opacity: 0, x: '-10%' }}
				onSubmit={form.handleSubmit(onSubmit)}
				className="auth__form register__form"
			>
				<h4 className="register__form-title">
					Зарегистрируйтесь <br />в <span>Privatka Club</span>
				</h4>

				<div className="register__form-fields">
					<div className="form-row">
						<FormField elem={'input'} name={'email'} label={'Email'} placeholder={' '} />
					</div>
					<div className="form-row double">
						<FormField elem={'input'} name={'name'} label={'Имя'} placeholder={' '} />
						<FormField elem={'input'} name={'callname'} label={'Позывной'} placeholder={' '} />
					</div>
				</div>

				<div className="register__form-fields">
					{/* <div className="form-row select__form-row custom-select__wrap select__teams">
						<Controller
							render={({ field: { value, name, onChange }, ref }) => {
								return (
									<AsyncSelect
										cacheOptions
										defaultOptions
										loadOptions={asyncOptions}
										closeMenuOnSelect={true}
										// hideSelectedOptions={false}
										// styles={selectStyles('custom__select register_city')}
										inputRef={ref}
										classNamePrefix="custom-select"
										id="user_team"
										instanceId="user_team"
										onChange={onChange}
										placeholder=" "
										name={name}
										value={value || null}
									/>
								)
							}}
							name="team"
							defaultValue=""
						/>
					</div> */}
					{/* <div className="form-row select__form-row custom-select__wrap select__citys">
						<Controller
							render={({ field: { value, name, onChange }, ref }) => {
								return (
									<Select
										options={citys}
										closeMenuOnSelect={true}
										// styles={selectStyles('custom__select register_city')}
										inputRef={ref}
										classNamePrefix="custom-select"
										id="user_city"
										instanceId="user_city"
										onChange={onChange}
										placeholder=" "
										name={name}
										value={value || null}
									/>
								)
							}}
							name="city"
							defaultValue=""
							options={citys}
						/>
					</div> */}

					<div className="form-row">
						<AddressSuggestions
							token={process.env.REACT_APP_DADATA_API_KEY}
							value={city}
							onChange={setCity}
							delay={300}
							minChars={3}
							inputProps={{
								placeholder: 'Город',
							}}
							containerClassName="input-box__dadata"
						/>
					</div>

					<div className="form-row">
						<FormField
							elem={'input'}
							inputType="password"
							autocomplete="new-password"
							name={'password'}
							label={'Пароль'}
							placeholder={' '}
						/>
					</div>
				</div>

				<div className="form-row register__form-btns">
					<Button type={'button'} className="register__form-btn button yellow large" text={'Зарегистрироваться'} />
				</div>
				<div className="register__form-policy p8">
					Нажимая кнопку “Зарегистрироваться” вы принимаете <a href="/">Условия использования</a> и{' '}
					<a href="/">Политику конфиденциальности Privatka Club.</a>
				</div>
			</motion.form>
		</FormProvider>
	)
}
