import React from 'react'
import { EventCard } from '../Events/EventCard'

export const PastGamesList = ({ events }) => {
	return (
		<div className="past-games events-cards">
			{events.map((game) => (
				<EventCard isPast={true} key={game.id} event={game} className="past-games__item" />
			))}
		</div>
	)
}
