import React from 'react'
import { UpcomingGames } from '../components/GameHistory/UpcomingGames'
import { PastGames } from '../components/GameHistory/PastGames'

export default function GameHistory() {
	return (
		<div className="section-lk section-game-history">
			<UpcomingGames />
			<PastGames />
		</div>
	)
}
