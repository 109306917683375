// import {
//   register,
//   login,
//   getUserData,
//   updateUserData,
//   sendForgotPassword,
//   createNewPassword,
//   sendEmailConfirmation,
// } from 'services/Config'
import {
	getProfile,
	login,
	register,
	sendForgotPassword,
	createNewPassword,
	editProfile,
	deleteProfileAvatar,
	editTeamProfile,
	deleteTeamProfileAvatar,
	leaveTeam,
	createTeam,
	getProfileDialogs, addPhoto, addTeamPhoto,
} from '../../services/Config'
import { deleteCookie, getCookie, setCookie } from '../../utils/helpers'
import { userTypes } from '../types/user'
import Cookies from 'js-cookie'
import { notifyUser, notifyUserError } from '../../services/Pnotify.js'

// import {
//   showSubscribeModal
// } from './modals'
// import { notifyUser } from '../../services/Pnotify.js'

export const loadingData = (payload) => {
	return {
		type: userTypes.SET_USER_LOADING,
		payload,
	}
}

export const checkAuth = () => async (dispatch) => {
	const getDialogs = async () => {
		// получаем все диалоги
		try {
			const res = await getProfileDialogs()
			dispatch({ type: 'DIALOGS_SET', payload: res.data })
		} catch (error) {
			notifyUserError('Не удалось получить список диалогов')
		}
	}

	try {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: true,
		})
		const res = await getProfile()
		dispatch({
			type: userTypes.SET_USER_DATA,
			payload: res.data,
		})

		getDialogs()
	} catch (e) {
		dispatch({
			type: userTypes.LOGOUT,
		})
	} finally {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: false,
		})
	}
}

export const loginUser = (formData, loginAdmin=false) => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

	try {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: true,
		})
		const res = await login(formData)
		Cookies.set('prv_token', res.response.access_token, { expires: 7, domain: process.env.REACT_APP_COOKIE_DOMAIN })
		Cookies.set('prv_token_expires', Date.now() + 300000) // записываю время, которое больше текущего на 5 минут(через 5 минут апдейит токена)
		if (loginAdmin) {
			Cookies.set('loginAdmin', 1)
		}
		dispatch({
			type: userTypes.SET_USER_DATA,
			payload: res.response,
		})
		notifyUser('Вы успешно вошли в аккаунт')
	} catch (e) {
		console.log(e.response)

		notifyUserError('Пользователь не найден. Возможно вы не подтвердили email, либо пароль введен неверно')
	} finally {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: false,
		})
	}
}

export const logOutUser = () => {
	// localStorage.removeItem('token')
	// deleteCookie('prv_token')
	Cookies.remove('prv_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN || '' })
	let test = Cookies.get('loginAdmin');
	if (Cookies.get('loginAdmin')==1){
		Cookies.set('loginAdmin', 0)
		window.location.href = `${process.env.REACT_APP_BACKEND_URL}admin/users`;
	}
	return {
		type: userTypes.LOGOUT,
	}
}

export const registerUser = (formData) => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

	try {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: true,
		})
		const res = await register(formData)
		dispatch({
			type: userTypes.CHANGE_FORM,
			payload: 'register_success',
		})
		notifyUser('Вы успешно зарегистрировались')
	} catch (e) {
		if (e?.response?.data?.email) {
			if (e?.response?.data?.email[0] === 'The email has already been taken.') {
				notifyUserError('Ошибка', 'Извините, эта почта уже занята')
				// throw new Error('Извините, эта почта уже занята')
			}
		} else {
			notifyUserError('Ошибка', 'Ошибка при регистрации')
			// throw new Error(e.response)
		}
	} finally {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: false,
		})
	}
}

// export const updateUserDataAction = (formData) => async (dispatch) => {
//   const {
//     notifyUser
//   } = await import('services/Pnotify.js')
//   try {
//     dispatch({
//       type: 'SET_USER_UPDATING',
//       payload: true
//     })
//     if (formData.newEmail) {
//       // dispatch({
//       // 	type: 'UPDATE_USER_DATA',
//       // 	payload: { email_verified: 0 },
//       // })
//       const res = await sendEmailConfirmation({
//         email: formData.newEmail
//       })logInspatch({
//         //     type: 'UPDATE_USER_DATA',
//         //     payload: res.data.user,
//         //   })
//         // }
//       }
//       // if (res)
//     } else {
//       const res = await updateUserData(formData)
//       if (res.status === 200) {
//         dispatch({
//           type: 'UPDATE_USER_DATA',
//           payload: res.data.user,
//         })
//       }

//       notifyUser('Информация успешо обновлена')
//     }
//   } catch (e) {
//     console.error('error ~ updateUserDataAction', e)
//     notifyUser('Ошибка', 'Ошибка при обновлении данных')
//     if (e.response.data.message === 'old password does not match') {
//       throw new Error('Старый пароль введен неверно')
//     } else {
//       throw new Error(e.response)
//     }
//   } finally {
//     dispatch({
//       type: 'SET_USER_UPDATING',
//       payload: false
//     })
//   }
// }

export const sendPasswordRecoveryRequest = (formData) => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')
	try {
		dispatch({
			type: userTypes.SET_USER_UPDATING,
			payload: true,
		})
		const res = await sendForgotPassword(formData)

		if (res.status === 200) {
			notifyUser('Ваша заявка успешно отправлена')
		} else {
			if (res.response.message === 'User not found') {
				notifyUserError('Ошибка', 'Ошибка восстановления пароля')
				throw new Error('Пользователь с таким email не найден')
			} else {
				throw new Error(res.response.message)
			}
		}
	} catch (e) {
		notifyUserError('Ошибка', 'Ошибка восстановления пароля')
		throw new Error(e)
	} finally {
		dispatch({
			type: userTypes.SET_USER_UPDATING,
			payload: false,
		})
	}
}

export const createNewUserPassword = (formData) => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')
	try {
		dispatch({
			type: userTypes.SET_USER_UPDATING,
			payload: true,
		})
		const res = await createNewPassword(formData)
		if (res.status === 200) {
			notifyUser('Пароль успешно изменен, теперь вы можете войти с новым паролем')
		}
	} catch (e) {
		notifyUserError('Ошибка', 'Ошибка изменения пароля')
		if (e.response.data.message === 'old password does not match') {
			throw new Error('Старый пароль введен неверно')
		} else {
			throw new Error(e.response)
		}
	} finally {
		dispatch({
			type: userTypes.SET_USER_UPDATING,
			payload: false,
		})
	}
}

export const changeForm = (payload) => {
	return {
		type: userTypes.CHANGE_FORM,
		payload,
	}
}

export const editProfileAction =
	(formData, config = {}) =>
	async (dispatch) => {
		const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')
		try {
			dispatch({
				type: userTypes.SET_USER_LOADING,
				payload: true,
			})
			const res = await editProfile(formData, config)
			dispatch({
				type: userTypes.EDIT_USER_DATA,
				payload: res.data,
			})
			notifyUser('Вы успешно обновили профиль')
			if (formData.email && res.data.email !== formData.email) {
				notifyUser('Подтвердите смену email')
			}
		} catch (e) {
			console.log(e)
			if (e.response?.data?.message === 'Email not confirm') {
				notifyUserError('Необходимо подтвердить email')
			} else if (e.data?.message === 'The email has already been taken') {
				notifyUserError('Данный email уже занят')
			} else {
				notifyUserError('Ошибка обновления профиля')
			}
		} finally {
			dispatch({
				type: userTypes.SET_USER_LOADING,
				payload: false,
			})
		}
	}

export const deleteProfileAvatarAction = () => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

	try {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: true,
		})
		const res = await deleteProfileAvatar()

		dispatch({
			type: userTypes.EDIT_USER_DATA,
			payload: res.data,
		})
		notifyUser('Вы успешно удалили аватар')
	} catch (e) {
		notifyUserError('Ошибка при удалении аватара')
		// if (e.response.data.message === 'Email not confirm') {
		//   notifyUserError('Необходимо подтвердить email')
		// } else {
		//   notifyUserError('Ошибка обновления профиля')
		// }
	} finally {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: false,
		})
	}
}

export const createTeamAction = (formData) => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

	try {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: true,
		})
		const teamRes = await createTeam(formData)

		const res = await getProfile()
		dispatch({
			type: userTypes.SET_USER_DATA,
			payload: res.data,
		})
		notifyUser('Вы создали команду')
	} catch (e) {
		notifyUserError('Ошибка при создании команды')
		// if (e.response.data.message === 'Email not confirm') {
		//   notifyUserError('Необходимо подтвердить email')
		// } else {
		//   notifyUserError('Ошибка обновления профиля')
		// }
	} finally {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: false,
		})
	}
}

export const addPhotoAction =
	(formData, config = {}) =>
		async (dispatch) => {
			const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

			try {
				dispatch({
					type: userTypes.SET_USER_LOADING,
					payload: true,
				})
				const res = await addPhoto(formData, config)

				dispatch({
					type: userTypes.EDIT_USER_DATA,
					payload: res.data,
				})
				notifyUser('Вы успешно добавили фото')
			} catch (e) {
				notifyUserError('Невозможно загрузить больше 10 фото')
			} finally {
				dispatch({
					type: userTypes.SET_USER_LOADING,
					payload: false,
				})
			}
		}

export const addPhotoTeamAction =
	(formData, config = {}) =>
		async (dispatch) => {
			const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

			try {
				dispatch({
					type: userTypes.SET_USER_LOADING,
					payload: true,
				})
				const res = await addTeamPhoto(formData, config)

				dispatch({
					type: userTypes.EDIT_USER_DATA,
					payload: res.data,
				})
				notifyUser('Вы успешно добавили фото')
			} catch (e) {
				notifyUserError('Невозможно загрузить больше 10 фото')
			} finally {
				dispatch({
					type: userTypes.SET_USER_LOADING,
					payload: false,
				})
			}
		}

export const editTeamProfileAction =
	(formData, config = {}) =>
	async (dispatch) => {
		const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

		try {
			dispatch({
				type: userTypes.SET_USER_LOADING,
				payload: true,
			})
			const teamRes = await editTeamProfile(formData, config)
			const res = await getProfile()
			dispatch({
				type: userTypes.SET_USER_DATA,
				payload: res.data,
			})
			notifyUser('Вы успешно обновили команду')
			return res
		} catch (e) {
			// if (e.response.data.message === 'Email not confirm') {
			//   notifyUserError('Необходимо подтвердить email')
			// } else {
			//   notifyUserError('Ошибка обновления профиля')
			// }

			notifyUserError('Ошибка обновления команды')
		} finally {
			dispatch({
				type: userTypes.SET_USER_LOADING,
				payload: false,
			})
		}
	}

export const deleteTeamProfileAvatarAction = () => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

	try {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: true,
		})
		const teamRes = await deleteTeamProfileAvatar()

		const res = await getProfile()
		dispatch({
			type: userTypes.SET_USER_DATA,
			payload: res.data,
		})
		notifyUser('Вы успешно удалили аватар')
	} catch (e) {
		notifyUserError('Ошибка при удалении аватара')
		// if (e.response.data.message === 'Email not confirm') {
		//   notifyUserError('Необходимо подтвердить email')
		// } else {
		//   notifyUserError('Ошибка обновления профиля')
		// }
	} finally {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: false,
		})
	}
}

export const leaveTeamAction = () => async (dispatch) => {
	const { notifyUser, notifyUserError } = await import('../../services/Pnotify.js')

	try {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: true,
		})
		const teamRes = await leaveTeam()

		const res = await getProfile()
		dispatch({
			type: userTypes.SET_USER_DATA,
			payload: res.data,
		})
		notifyUser('Вы вышли из команды')
	} catch (e) {
		if (e.response.data.error === 'Forbidden') {
			notifyUserError('Вы не можете покинуть команду')
		} else {
			notifyUserError('Ошибка при выходе из команды')
		}
	} finally {
		dispatch({
			type: userTypes.SET_USER_LOADING,
			payload: false,
		})
	}
}
