import React from 'react'
import { NotificationsItem } from './NotificationsItem'
import { normalizeDate } from 'utils/helpers'

export const NotificationsList = ({ date, notifications, readOnce }) => {
	return (
		<div className="notifications__item">
			<p className="notifications__date p14">{normalizeDate(date)}</p>
			<ul className="notifications__list list-notifications">
				{notifications.map((item) => (
					<NotificationsItem key={item.id} item={item} readOnce={readOnce} />
				))}
			</ul>
		</div>
	)
}
