// import React, { Component } from 'react'

import { Stack, alert, defaultModules } from '@pnotify/core'
import '@pnotify/core/dist/PNotify.css'
// import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from '@pnotify/confirm'
import * as PNotifyMobile from '@pnotify/mobile'
import '@pnotify/confirm/dist/PNotifyConfirm.css'

defaultModules.set([PNotifyMobile, Confirm], {})

var defaultStack = new Stack({
	dir1: 'down',
	dir2: 'left',
	firstpos1: 25,
	firstpos2: 25,
	spacing1: 36,
	spacing2: 36,
	push: 'bottom',
	maxOpen: 4,
	maxStrategy: 'close', //wait
	context: document.body,
	modal: false, //ish
})

export function notificationsReadToggle() {
	alert({
		title: 'Notify has been toggled',
		// text: "You have clicked the button. You may now complete the process of reading the notice.",
		stack: defaultStack,
		addClass: 'custom-notify',
		icon: false,
		closerHover: false,
		sticker: false,
		stickerHover: false,
		styling: '',
		// modules: new Map([
		//   [
		//     Confirm,
		//     {
		//       confirm: true,
		//       buttons: [
		//         {
		//           text: "Ok",
		//           primary: true,
		//           click: notice => {
		//             notice.close();
		//           }
		//         }
		//       ]
		//     }
		//   ]
		// ])
	})
}

export function notifyUser(title, text, className) {
	alert({
		title,
		text,
		stack: defaultStack,
		addClass: `custom-notify ${className || ''}`,
		icon: true,
		closerHover: false,
		sticker: false,
		stickerHover: false,
		styling: '',
	})
}

export function notifyUserError(title, text) {
	alert({
		title,
		text,
		stack: defaultStack,
		addClass: 'custom-notify error',
		icon: false,
		closerHover: false,
		sticker: false,
		stickerHover: false,
		styling: '',
	})
}
export const mailingNotify = (text, className) => {
	alert({
		title: false,
		text,
		stack: defaultStack,
		addClass: `${className || ''}`,
		width: '480px',
		icon: true,
		closerHover: false,
		sticker: false,
		stickerHover: false,
		styling: '',
	})
}

export function getConfirmationUser(title) {
	alert({
		title,
		stack: defaultStack,
		addClass: 'custom-notify',
		icon: false,
		closerHover: false,
		sticker: false,
		stickerHover: false,
		modules: new Map([
			[
				Confirm,
				{
					confirm: true,
					buttons: [
						{
							text: 'Ok',
							primary: true,
							click: (notice) => {
								notice.close()
							},
						},
						{
							text: 'Cancel',
							primary: false,
							click: (notice) => {
								notice.close()
							},
						},
					],
				},
			],
		]),
	})
}
