import React from 'react'
import { BackBtn } from '../Buttons/BackBtn/BackBtn'
import EmptyBlock from '../EmptyBlock'
import Calendar from '../../static/img/icons/EmptyBlock/calendar.svg'
import { UpcomingGamesList } from './UpcomingGamesList'
import { Button } from '../Buttons/Button'
import { getProfileGamesComingPaid, getTeamGamesComing } from '../../services/Config'
import { LoaderMini } from '../LoaderMini'
import clsx from 'clsx'

export const UpcomingGames = ({ team }) => {
	const [games, setGames] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const fetchGames = async () => {
		try {
			setIsLoad(true)
			const res = await getProfileGamesComingPaid()
			setGames(res.data)
		} catch (error) {
			console.log('fetchGames error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		fetchGames()
	}, [])

	return (
		<div
			className={clsx('lk-container upcomig-games-container', {
				['--empty']: !games?.game?.length,
			})}
		>
			<div className="lk-container__title">
				<div className="lk-container__title-primary">
					<BackBtn className="lk-container__back-btn" />
					<h4 className="lk-container-title h4">Ближайшие игры</h4>
				</div>
				{games?.game?.length > 0 && (
					<Button
						type="link"
						href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
						className="lk-container-title__btn button large lightyellow"
						text="Все Мероприятия"
					/>
				)}
			</div>
			<div className="lk-container__content">
				{isLoad ? (
					<LoaderMini />
				) : games?.game?.length > 0 ? (
					<UpcomingGamesList events={games?.game} />
				) : (
					<EmptyBlock
						img={Calendar}
						title={'Нет запланированных игр'}
						text={'Не найдено ближайших игр. Оплатите мероприятие и оно будет отображено в этом окне'}
						buttonText={'Мероприятия'}
						buttonLink={process.env.REACT_APP_FRONTEND_URL + 'payment'}
					/>
				)}
			</div>
		</div>
	)
}
