import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { useActions } from '../../../hooks/useActions'

import { useForm, FormProvider, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TeamCreateFormSchema } from '../../../utils/validations'
import Select, { components } from 'react-select'
import { FormField } from '../../FormField'
import { Button } from '../../Buttons/Button'
import DefaultLogo from '../../../static/img/user_default.png'
import { routeNames } from '../../../router'
import { AddressSuggestions } from 'react-dadata'

let avatar = false

const citys = [
	{ value: 'Москва', label: 'Москва' },
	{ value: 'Санкт-Петербург', label: 'Санкт-Петербург' },
	{ value: 'Нижний Новгород', label: 'Нижний Новгород' },
	{ value: 'Казань', label: 'Казань' },
	{ value: 'Екатеринбург', label: 'Екатеринбург' },
	{ value: 'Новосибирск', label: 'Новосибирск' },
	{ value: 'Самара', label: 'Самара' },
	{ value: 'Казань', label: 'Казань' },
	{ value: 'Ростов-на-Дону', label: 'Ростов-на-Дону' },
	{ value: 'Владивосток', label: 'Владивосток' },
	{ value: 'Красноярск', label: 'Красноярск' },
	{ value: 'Воронеж', label: 'Воронеж' },
	{ value: 'Волгоград', label: 'Волгоград' },
]

const years = [
	{ value: '2000-01-01', label: '2000' },
	{ value: '2001-01-01', label: '2001' },
	{ value: '2002-01-01', label: '2002' },
	{ value: '2003-01-01', label: '2003' },
	{ value: '2004-01-01', label: '2004' },
	{ value: '2005-01-01', label: '2005' },
	{ value: '2006-01-01', label: '2006' },
	{ value: '2007-01-01', label: '2007' },
	{ value: '2008-01-01', label: '2008' },
	{ value: '2009-01-01', label: '2009' },
	{ value: '2010-01-01', label: '2010' },
	{ value: '2011-01-01', label: '2011' },
	{ value: '2012-01-01', label: '2012' },
	{ value: '2013-01-01', label: '2013' },
	{ value: '2014-01-01', label: '2014' },
	{ value: '2015-01-01', label: '2015' },
	{ value: '2016-01-01', label: '2016' },
	{ value: '2017-01-01', label: '2017' },
	{ value: '2018-01-01', label: '2018' },
	{ value: '2019-01-01', label: '2019' },
	{ value: '2020-01-01', label: '2020' },
	{ value: '2021-01-01', label: '2021' },
	{ value: '2022-01-01', label: '2022' },
	{ value: '2023-01-01', label: '2023' },
	{ value: '2024-01-01', label: '2024' },
	{ value: '2025-01-01', label: '2025' },
	{ value: '2026-01-01', label: '2026' },
	{ value: '2027-01-01', label: '2027' },
	{ value: '2028-01-01', label: '2028' },
	{ value: '2029-01-01', label: '2029' },
	{ value: '2030-01-01', label: '2030' },
]

const boolList = [
	{ value: 'true', label: 'Да' },
	{ value: 'false', label: 'Нет' },
]

export const Create = () => {
	const { createTeamAction } = useActions()
	const [teamName, setTeamName] = React.useState('')
	const [uploadedAvatar, setUploadedAvatar] = React.useState(null)

	const navigate = useNavigate()

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(TeamCreateFormSchema),
	})
	const {
		register,
		formState: { errors },
	} = form

	const fileChange = (e) => {
		const file = e.target.files[0]
		const reader = new FileReader()
		const url = reader.readAsDataURL(file)
		reader.onloadend = function (e) {
			setUploadedAvatar(reader.result)
		}
	}

	const onSubmit = async (data) => {
		console.log(data)
		setTeamName(data.name)

		let formData = new FormData()
		if (data?.picture?.length > 0) {
			formData.append('avatar', data.picture[0])
		}
		formData.append('name', data.name)
		formData.append('city', data.city.value)
		formData.append('based', new Date(data.year.value).getFullYear())
		formData.append('subdivisions', data.modeling.value)
		formData.append('recruiting', data.recruiting.value)
		formData.append('description', data.about)

		// const formData = {
		// 	...userState,
		// 	team: {
		// 		avatar: "",
		// 		name: data.name,
		// 		city: data.city.value,
		// 		based: data.year.value,
		// 		subdivisions: data.modeling.value,
		// 		recruiting: data.recruiting.value,
		// 		description: data.about
		// 	}
		// }

		try {
			const res = await createTeamAction(formData)
			navigate(routeNames.TEAMPROFILE.path + '?ppCreate=true')
		} catch (e) {}
	}

	const removeAvatar = () => {}

	return (
		<>
			<FormProvider {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="profile__form profile__form-team-create"
					encType="multipart/form-data"
				>
					<div className="profile__form-main-body">
						<div className="profile__form-avatar-body">
							{errors?.['picture'] && <label className="input-box__error">{errors?.['picture']?.message}</label>}
							<div className="profile__form-avatar-box-wrap">
								<div className="profile__form-avatar-box">
									<img
										className="profile__form-avatar-box-photo"
										src={uploadedAvatar ? uploadedAvatar : DefaultLogo}
										alt="user-logo"
										title="аватар"
									/>
								</div>
							</div>
							<div className="profile__form-avatar-cont">
								<div className="profile__form-avatar-text p6">Максимальный размер 300х300, вес менее 1 Мб</div>

								<div className="profile__form-avatar-btns">
									{uploadedAvatar ? (
										<>
											<div className="profile__form-avatar-btn button medium yellow">
												<input
													className="profile__form-avatar-input"
													{...register('picture')}
													onChange={(e) => {
														register('picture').onChange(e)
														fileChange(e)
													}}
													name="picture"
													type="file"
												/>
												<span className="button-text">Изменить</span>
											</div>
											<Button
												onClick={removeAvatar}
												type="span"
												className="profile__form-avatar-btn button medium lightyellow"
												text="Удалить"
											/>
										</>
									) : (
										<div className="profile__form-avatar-btn button medium yellow">
											<input
												className="profile__form-avatar-input"
												{...register('picture')}
												onChange={(e) => {
													register('picture').onChange(e)
													fileChange(e)
												}}
												name="picture"
												type="file"
											/>
											<span className="button-text">Загрузить</span>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="form-row double">
							<FormField elem={'input'} name={'name'} label={'Название команды'} />
							<div className="form-row double team-create-first-row">
								<div
									className={clsx('select__form-row select__citys custom-select__wrap', {
										['error']: form.formState.errors['city'],
									})}
								>
									<Controller
										render={({ field: { value, name, onChange }, ref }) => {
											return (
												<AddressSuggestions
													{...form.register('city')}
													inputRef={ref}
													token={process.env.REACT_APP_DADATA_API_KEY}
													value={value || null}
													onChange={onChange}
													delay={300}
													minChars={3}
													inputProps={{
														placeholder: 'Город',
														name,
													}}
													containerClassName="input-box__dadata"
												/>
											)
										}}
										name="city"
										defaultValue={''}
									/>
									{form.formState.errors['city'] && (
										<label className="input-box__error">{form.formState.errors['city']?.message}</label>
									)}
								</div>

								<div
									className={clsx('select__form-row select__team_year custom-select__wrap', {
										['error']: form.formState.errors['year'],
									})}
								>
									<Controller
										render={({ field: { value, name, onChange }, ref }) => {
											return (
												<Select
													{...form.register('year')}
													options={years}
													closeMenuOnSelect={true}
													inputRef={ref}
													classNamePrefix="custom-select"
													id="team_year"
													instanceId="uteam_year"
													onChange={onChange}
													placeholder=" "
													name={name}
													value={value || null}
												/>
											)
										}}
										name="year"
										defaultValue={''}
										options={years}
									/>
									{form.formState.errors['year'] && (
										<label className="input-box__error">{form.formState.errors['year']?.message}</label>
									)}
								</div>
							</div>
						</div>

						<div className="form-row double">
							<div className="form-row double team-create-second-row">
								<div
									className={clsx('select__form-row select__team_modeling custom-select__wrap', {
										['error']: form.formState.errors['modeling'],
									})}
								>
									<Controller
										render={({ field: { value, name, onChange }, ref }) => {
											return (
												<Select
													{...form.register('modeling')}
													options={boolList}
													closeMenuOnSelect={true}
													inputRef={ref}
													classNamePrefix="custom-select"
													id="team_modeling"
													instanceId="team_modeling"
													onChange={onChange}
													placeholder=" "
													name={name}
													value={value || null}
												/>
											)
										}}
										name="modeling"
										defaultValue={''}
										options={boolList}
									/>
									{form.formState.errors['modeling'] && (
										<label className="input-box__error">{form.formState.errors['modeling']?.message}</label>
									)}
								</div>

								<div
									className={clsx('select__form-row select__team_recruiting custom-select__wrap', {
										['error']: form.formState.errors['recruiting'],
									})}
								>
									<Controller
										render={({ field: { value, name, onChange }, ref }) => {
											return (
												<Select
													{...form.register('recruiting')}
													options={boolList}
													closeMenuOnSelect={true}
													inputRef={ref}
													classNamePrefix="custom-select"
													id="team_recruiting"
													instanceId="team_recruiting"
													onChange={onChange}
													placeholder=" "
													name={name}
													value={value || null}
												/>
											)
										}}
										name="recruiting"
										defaultValue={''}
										options={boolList}
									/>
									{form.formState.errors['recruiting'] && (
										<label className="input-box__error">{form.formState.errors['recruiting']?.message}</label>
									)}
								</div>
							</div>
						</div>

						<div className="form-row textarea-row">
							<FormField elem={'textarea'} name={'about'} label={'Небольшое описание команды...'} />
						</div>
					</div>

					<div className="profile__form-footer">
						<Button type="button" className="profile__form-btn button yellow" text="Создать команду" />
						<Button
							onClick={() => navigate(-1)}
							type="span"
							className="profile__form-btn button lightyellow"
							text="Отмена"
						/>
					</div>
				</form>
			</FormProvider>
		</>
	)
}
