import Achievements from 'pages/Achievements'
import TeamAchievements from 'pages/TeamAchievements'
import { Navigate } from 'react-router'
import NoAccessPage from '../pages/403'
import NotFoundPage from '../pages/404'
import { Articles } from '../pages/Articles'
import Failed from '../pages/Failed'
import { Friends } from '../pages/Friends'
import GameHistory from '../pages/GameHistory'
import TeamGameHistory from '../pages/TeamGameHistory'
import { LoginPage } from '../pages/Login'
import { Messages } from '../pages/Messages'
import { Notifications } from '../pages/Notifications'
import { Points } from '../pages/Points'
import { Payment } from '../pages/Payment'
import { Payments } from '../pages/Payments'
import Success from '../pages/Success'
import { Team } from '../pages/Team'
import { TeamCreate } from '../pages/TeamCreate'
import { TeamEdit } from '../pages/TeamEdit'
import { TeamProfile } from '../pages/TeamProfile'
import { Tests } from '../pages/Tests'
import { User } from '../pages/User'
import { UserProfile } from '../pages/UserProfile'
import { UserProfileEdit } from '../pages/UserProfileEdit'
import { Statistics } from '../pages/Statistics'
import TechWorks from 'pages/TechWorks'

export const routeNames = {
	ACHIEVEMENTS: { path: '/achievements', name: 'Достижения' },
	TEAM_ACHIEVEMENTS: { path: '/team/achievements', name: 'Достижения команды' },
	PROFILE: { path: '/', name: 'Профиль' },
	LOGIN: { path: '/login', name: 'Логин' },
	REGISTRATION: { path: '/register', name: 'Регистрация' },
	MESSAGES: { path: '/messages/', name: 'Сообщения' },
	MESSAGESID: { path: '/messages/:id', name: 'Сообщения' },
	FRIENDS: { path: '/friends', name: 'Друзья' },
	ARTICLES: { path: '/articles', name: 'Статьи' },
	TESTS: { path: '/tests', name: 'Пройденные тесты' },
	POINTS: { path: '/points', name: 'Как заработать баллы' },
	PAYMENT: { path: '/payment', name: 'Оплата' },
	PAYMENTS: { path: '/payments', name: 'История оплат' },
	GAME_HISTORY: { path: '/game_history', name: 'История игр' },
	TEAM_GAME_HISTORY: { path: '/team/game_history', name: 'История игр команды' },
	USER: { path: '/user/', name: 'Профиль пользователя' },
	PROFILEEDIT: { path: '/edit', name: 'Редактирование профиля' },
	TEAMPROFILE: { path: '/team', name: 'Профиль команды' },
	TEAMCREATE: { path: '/team/create', name: 'Создание своей команды' },
	TEAMEDIT: { path: '/team/edit', name: 'Редактиование своей команды' },
	TEAM: { path: '/team/', name: 'Профиль команды' },
	NOTIFICATIONS: { path: '/notifications', name: 'Уведомления' },
	STATISTICS: { path: '/team/statistics', name: 'Статистика команды' },
	SUCCESS: { path: '/success', name: 'Оплата прошла успешно' },
	FAILED: { path: '/failed', name: 'Ошибка оплаты' },
	NOTFOUND: { path: '/404', name: 'Страница не найдена' },
	TECHWORKS: { path: '/techworks', name: 'Технические работы' },
	NOACCESS: { path: '/403', name: 'В доступе отказано' },
}

export const privateRoutes = [
	{
		path: '*',
		exact: false,
		element: <Navigate to={routeNames.NOTFOUND.path} />,
	},
	{
		path: '/',
		exact: true,
		element: <UserProfile />,
	},
	{
		path: routeNames.LOGIN.path,
		exact: true,
		element: <LoginPage />,
	},
	{
		path: routeNames.REGISTRATION.path,
		exact: false,
		element: <LoginPage />,
	},
	{
		path: routeNames.ACHIEVEMENTS.path,
		exact: true,
		element: <Achievements />,
	},
	{
		path: routeNames.TEAM_ACHIEVEMENTS.path,
		exact: true,
		element: <TeamAchievements />,
	},
	{
		path: routeNames.MESSAGES.path,
		exact: true,
		element: <Messages />,
	},
	{
		path: routeNames.MESSAGESID.path,
		exact: true,
		element: <Messages />,
	},
	{
		path: routeNames.FRIENDS.path,
		exact: true,
		element: <Friends />,
	},
	{
		path: routeNames.ARTICLES.path,
		exact: true,
		element: <Articles />,
	},
	{
		path: routeNames.TESTS.path,
		exact: true,
		element: <Tests />,
	},
	{
		path: routeNames.PAYMENT.path,
		exact: true,
		element: <Payment />,
	},
	{
		path: routeNames.PAYMENTS.path,
		exact: true,
		element: <Payments />,
	},
	{
		path: routeNames.USER.path + ':id',
		exact: true,
		element: <User />,
	},
	{
		path: routeNames.PROFILEEDIT.path,
		exact: true,
		element: <UserProfileEdit />,
	},
	{
		path: routeNames.TEAMPROFILE.path,
		exact: true,
		element: <TeamProfile />,
	},
	{
		path: routeNames.TEAMCREATE.path,
		exact: true,
		element: <TeamCreate />,
	},
	{
		path: routeNames.TEAMEDIT.path,
		exact: true,
		element: <TeamEdit />,
	},
	{
		path: routeNames.TEAM.path + ':id',
		exact: true,
		element: <Team />,
	},
	{
		path: routeNames.POINTS.path,
		exact: true,
		element: <Points />,
	},
	{
		path: routeNames.NOTIFICATIONS.path,
		exact: true,
		element: <Notifications />,
	},
	{
		path: routeNames.STATISTICS.path,
		exact: true,
		element: <Statistics />,
	},
	{
		path: routeNames.GAME_HISTORY.path,
		exact: true,
		element: <GameHistory />,
	},
	{
		path: routeNames.TEAM_GAME_HISTORY.path,
		exact: true,
		element: <TeamGameHistory />,
	},
	{
		path: routeNames.SUCCESS.path,
		exact: true,
		element: <Success />,
	},
	{
		path: routeNames.FAILED.path,
		exact: true,
		element: <Failed />,
	},
	{
		path: routeNames.NOTFOUND.path,
		exact: true,
		element: <NotFoundPage />,
	},

	{
		path: routeNames.NOACCESS.path,
		exact: true,
		element: <NoAccessPage />,
	},
	{
		path: routeNames.TECHWORKS.path,
		exact: true,
		element: <TechWorks />,
	},
]

export const publicRoutes = [
	{
		path: '*',
		exact: false,
		element: <Navigate to="/login" replace={true} />,
	},
	{
		path: routeNames.LOGIN.path,
		exact: false,
		element: <LoginPage />,
	},
	{
		path: routeNames.REGISTRATION.path,
		exact: false,
		element: <LoginPage />,
	},
	{
		path: routeNames.PAYMENT.path,
		exact: true,
		element: <Payment />,
	},
	{
		path: routeNames.SUCCESS.path,
		exact: true,
		element: <Success />,
	},
	{
		path: routeNames.FAILED.path,
		exact: true,
		element: <Failed />,
	},
	{
		path: routeNames.NOTFOUND.path,
		exact: true,
		element: <NotFoundPage />,
	},

	{
		path: routeNames.NOACCESS.path,
		exact: true,
		element: <NoAccessPage />,
	},
	{
		path: routeNames.TECHWORKS.path,
		exact: true,
		element: <TechWorks />,
	},
	{
		path: routeNames.USER.path + ':id',
		exact: true,
		element: <User />,
	},
	{
		path: routeNames.TEAM.path + ':id',
		exact: true,
		element: <Team />,
	},
]
