import React from 'react'
import { useSelector } from 'react-redux'
import { Variants } from 'components/Points/Variants.jsx'
import { Events } from 'components/Points/Events.jsx'
import { History } from 'components/Points/History.jsx'
import { Button } from '../components/Buttons/Button'
import { BackBtn } from '../components/Buttons/BackBtn/BackBtn'
import { ReactComponent as QuestionIcon } from '../static/img/icons/question-icon.svg'
import Icon1 from 'static/img/icons/points-icon-1.svg'
import Icon2 from 'static/img/icons/points-icon-2.svg'
import Icon3 from 'static/img/icons/points-icon-3.svg'
import Image1 from 'static/img/icons/points-image-1.png'
import Image2 from 'static/img/icons/points-image-2.png'
import Image3 from 'static/img/icons/points-image-3.png'
import Image4 from 'static/img/icons/points-image-4.png'
import GamesIcon1 from 'static/img/icons/points-games-icon-1.svg'
import GamesIcon2 from 'static/img/icons/points-games-icon-2.svg'
import GamesIcon3 from 'static/img/icons/points-games-icon-3.svg'
import GamesIcon4 from 'static/img/icons/points-games-icon-4.svg'
import { getTests } from 'services/Config'
import { useActions } from 'hooks/useActions'

export const Points = () => {
	const userData = useSelector((state) => state.user.userData)
	const { loadingData } = useActions()
	const [variants, setVariants] = React.useState([])
	const [events, setEvents] = React.useState([])
	let test = true
	let profile = true
	let review = true

	const checkActions = async () => {
		try {
			loadingData(true)
			const res = await getTests(1, 0)
			res.data.list.length > 0 ? (test = true) : (test = false)
			userData.user.isProfileFilled ? (profile = true) : (profile = false)
			userData.user.last_review_id ? (review = true) : (review = false)
			setVariants([
				//массив карточек с описанием возможных способов получения баллов
				{
					id: 1,
					icon: Icon1,
					title: '10 баллов', // наименование элемента
					text: 'За пройденный тест в базе знаний', // описание элемента
					isComplete: test,
					button: {
						// кнопка со ссылкой на требуемое действие
						text: 'База знаний',
						link: `${process.env.REACT_APP_FRONTEND_URL}knowledgebase`,
					},
				},
				{
					id: 2,
					icon: Icon2,
					title: '20 баллов', // наименование элемента
					text: 'За заполненный профиль', // описание элемента
					isComplete: profile,
					button: {
						// кнопка со ссылкой на требуемое действие
						text: 'Профиль',
						link: `/edit`,
					},
				},
				{
					id: 3,
					icon: Icon3,
					title: '30 баллов', // наименование элемента
					text: 'За отзыв о клубе', // описание элемента
					isComplete: review,
					button: {
						// кнопка со ссылкой на требуемое действие
						text: 'Оставить отзыв',
						link: `${process.env.REACT_APP_FRONTEND_URL}reviews`,
					},
				},
			])
			setEvents([
				{
					id: 1,
					image: Image1,
					icon: GamesIcon1,
					count: 30, // количество баллов за мероприятие
					text: 'За дневную игру', // описание элемента ( за что баллы, например "за дневную игру ) тут думаю будет хорошо в админке сделать селект по типам мероприятий, чтобы не писали отсебятину
				},
				{
					id: 2,
					image: Image2,
					icon: GamesIcon2,
					count: 50, // количество баллов за мероприятие
					text: 'За ночную игру', // описание элемента ( за что баллы, например "за дневную игру ) тут думаю будет хорошо в админке сделать селект по типам мероприятий, чтобы не писали отсебятину
				},
				{
					id: 3,
					image: Image3,
					icon: GamesIcon3,
					count: 60, // количество баллов за мероприятие
					text: 'За суточную игру', // описание элемента ( за что баллы, например "за дневную игру ) тут думаю будет хорошо в админке сделать селект по типам мероприятий, чтобы не писали отсебятину
				},
				{
					id: 4,
					image: Image4,
					icon: GamesIcon4,
					count: 100, // количество баллов за мероприятие
					text: 'За челлендж', // описание элемента ( за что баллы, например "за дневную игру ) тут думаю будет хорошо в админке сделать селект по типам мероприятий, чтобы не писали отсебятину
				},
			])
		} catch (error) {
			console.log('e', error)
		} finally {
			loadingData(false)
		}
	}

	React.useEffect(() => {
		checkActions()
	}, [])

	return (
		<div className="points">
			<div className="lk-container">
				<div className="lk-container__title">
					<div className="lk-container__title-primary">
						<BackBtn className="lk-container__back-btn" />
						<h4 className="lk-container-title h4">Как заработать баллы</h4>
					</div>
				</div>
				<div className="lk-container__content">
					{variants.length > 0 && <Variants variants={variants} />}
					{events.length > 0 && <Events events={events} />}
					<div className="articles-points">
						<div className="articles-points__title h4">За публикации статей</div>
						<div className="articles-points__text p6">
							За каждую опубликованную статью администраторы будут начислять вам баллы
						</div>
						<div className="articles-points__buttons">
							<Button
								type={'navlink'}
								href="/articles"
								className="articles-button__write button yellow"
								text="Написать статью"
							/>
							<Button
								type={'navlink'}
								href="/articles"
								Icon={QuestionIcon}
								iconSide="left"
								className="articles-button__rules button lightyellow"
								text="Правила размещения статей"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="lk-container">
				<History />
			</div>
		</div>
	)
}
