import React from 'react'
import { UpcomingGames } from '../components/GameHistory/UpcomingGames'
import { PastGames } from '../components/GameHistory/PastGames'
// import { useSelector } from 'react-redux'

export default function TeamGameHistory() {
	// const {
	// 	userData: { user: userState },
	// } = useSelector((state) => state.user)
	return (
		<div className="section-lk section-game-history">
			<UpcomingGames />
			<PastGames team={true} />
		</div>
	)
}
