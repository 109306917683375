import React from 'react'
import { NotFound } from '../components/NotFound'

export default function NoAccessPage() {
	return (
		<NotFound
			primaryTitle=""
			title="В доступе отказано"
			text="Доступ к запрошенному ресурсу запрещен"
			btnText="Вернуться в профиль"
			btnHref="/"
			btnClassNames="button yellow"
		/>
	)
}
