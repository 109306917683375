import React from 'react'
import { NotFound } from '../components/NotFound'

export default function NotFoundPage() {
	return (
		<NotFound
			primaryTitle="Поздравляем."
			title="Ты все сломал"
			text="Такой странички не существует."
			btnText="Вернуться в профиль"
			btnHref="/"
			btnClassNames="button yellow"
		/>
	)
}
