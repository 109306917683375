import React from 'react'

import { EventCard } from '../Events/EventCard'

export const UpcomingGamesList = ({ events }) => {
	return (
		<div className="upcoming-games events-cards">
			{events.map((game) => (
				<EventCard key={game.id} event={game} className="upcoming-games__item" />
			))}
		</div>
	)
}
