import React from 'react'

// import { useSearchParams } from 'react-router-dom'
import { getTeamFriends } from '../../../services/Config'

import { Button } from '../../Buttons/Button'
import { LoaderMini } from '../../LoaderMini'
import { Pagination } from '../../Pagination/Pagination'
import { TeamCard } from '../TeamCard'

export const Friends = ({ id }) => {
	const defaultLimit = 4
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamFriends, setTeamFriends] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	// const [searchParams, setSearchParams] = useSearchParams()

	const fetchFriends = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamFriends(id, limit, offset)
			setTeamFriends(res.response)
		} catch (error) {
			console.log('fetchFriends error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		setPage(1)
		fetchFriends()
	}, [id])

	React.useEffect(() => {
		fetchFriends()
	}, [page])

	const changePage = async (num) => {
		setPage(num)
	}
	return isLoad ? (
		<LoaderMini />
	) : (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">Команды друзья</h4>
			</div>
			<div className="lk-container__content">
				{teamFriends?.list?.length > 0 ? (
					<div className="teams__cards">
						{teamFriends.list.map((team) => (
							<TeamCard key={team.id} team={team} />
						))}
					</div>
				) : (
					<div className="lk-container__friends-empty lk-container-empty">
						<div className="lk-container-empty__text p4">Информация отсутствует</div>
						<div className="lk-container-empty__btns">
							<Button
								className="lk-container-empty__btn button large yellow"
								type="link"
								href={process.env.REACT_APP_FRONTEND_URL + 'members?shownType=команды'}
								text="Смотреть все команды"
							/>
						</div>
					</div>
				)}

				{teamFriends?.list?.length > 0 && teamFriends?.total_count > defaultLimit && (
					<div className="pagination">
						<Pagination changePage={changePage} page={page} length={teamFriends?.total_count} limit={defaultLimit} />
						<div className="pagination__count p13">
							Показано {defaultLimit * page > teamFriends?.total_count ? teamFriends?.total_count : defaultLimit * page}{' '}
							из {teamFriends?.total_count}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
