import React, { useState, useEffect } from 'react'
import { BackBtn } from 'components/Buttons/BackBtn/BackBtn'
import { getTeamStatistics, getTeamAttendance } from 'services/Config'
import { useActions } from 'hooks/useActions'
import { Games } from 'components/Team/Statistics/Games.jsx'
import { MostExperienced } from 'components/Team/Statistics/MostExperienced.jsx'
import { Dates } from 'components/Team/Statistics/Dates.jsx'
import { Composition } from 'components/Team/Statistics/Composition.jsx'
import { Attendance } from 'components/Team/Statistics/Attendance.jsx'
import { PlayersChart } from 'components/Team/Statistics/PlayersChart.jsx'
import { PointsChart } from 'components/Team/Statistics/PointsChart.jsx'
import { GamesChart } from 'components/Team/Statistics/GamesChart.jsx'
import { useNavigate } from 'react-router-dom'
import { routeNames } from 'router'
import { useSelector } from 'react-redux'
// import { getMonth } from "../utils/helpers"

export const Statistics = () => {
	const [teamStatistics, setTeamStatistics] = useState(null)
	// const [gamesPerYear, setGamesPerYear] = useState(null)
	// const [gamesPerAll, setGamesPerAll] = useState(null)
	const { loadingData } = useActions()
	const navigate = useNavigate()
	const { userData } = useSelector((state) => state.user)

	const fetchTeamStatistics = async () => {
		if (userData?.user?.id !== userData?.user?.team?.commander_id) {
			navigate(routeNames.TEAMPROFILE.path)
			return
		}
		try {
			loadingData(true)
			const res = await getTeamStatistics()
			setTeamStatistics(res.data)
		} catch (error) {
			navigate(routeNames.TEAMPROFILE.path)
		} finally {
			loadingData(false)
		}
	}
	// const fetchGames = async () => {
	// 	try {
	// 		loadingData(true)
	// 		const res = await getTeamAttendance(1000, 0)
	// 		const gamesPerYear = []
	// 		res.data.list.map(item => gamesPerYear.push(item.games_per_year))
	// 		const obj = {}
	// 		const obj2 = {}
	// 		const arr = []
	// 		const arr2 = []
	// 		gamesPerYear.map(item => {
	// 			Object.entries(item).map(el => {
	// 				obj[el[0]] = Object.values(el[1]).reduce((prev, curr) => prev + curr, obj[el[0]] ? obj[el[0]] : 0)
	// 				Object.entries(el[1]).map(elem => {
	// 					obj2[elem[0]] = obj2[elem[0]] ? obj2[elem[0]] + elem[1] : elem[1]
	// 				})
	// 			})
	// 		})
	// 		Object.entries(obj).map(item => arr.push({ time: item[0], count: item[1] }))
	// 		Object.entries(obj2).map(item => arr2.push({ time: getMonth(item[0]), count: item[1] }))
	// 		setGamesPerAll(arr)
	// 		setGamesPerYear(arr2)
	// 	} catch (error) {
	// 		console.log('e', error)
	// 	} finally {
	// 		loadingData(false)
	// 	}
	// }

	useEffect(() => {
		fetchTeamStatistics()
		// fetchGames()
	}, [])

	return (
		<div className="statistic">
			<div className="statistic__section">
				<div className="lk-container">
					<div className="lk-container__title">
						<div className="lk-container__title-primary">
							<BackBtn className="lk-container__back-btn" />
							<h4 className="lk-container-title h4">Статистика</h4>
						</div>
					</div>
					{teamStatistics?.games?.list.length > 0 && <Games games={teamStatistics.games} />}
				</div>
			</div>
			<div className="statistic__section">
				{teamStatistics?.most_experienced.length > 0 && <MostExperienced users={teamStatistics.most_experienced} />}
			</div>
			<div className="statistic__section">
				<div className="lk-container">
					{teamStatistics?.games_count && <GamesChart games={teamStatistics.games_count} />}
				</div>
			</div>
			<div className="statistic__section">
				<div className="lk-container">
					{teamStatistics?.players && <PlayersChart players={teamStatistics.players} />}
				</div>
			</div>
			<div className="statistic__section">
				<div className="lk-container">{teamStatistics?.dates.length > 0 && <Dates dates={teamStatistics.dates} />}</div>
			</div>
			<div className="statistic__section">
				<div className="lk-container">{teamStatistics?.points && <PointsChart points={teamStatistics.points} />}</div>
			</div>
			<div className="statistic__section">
				<div className="lk-container">
					{teamStatistics?.composition.length > 0 && <Composition composition={teamStatistics.composition} />}
				</div>
			</div>
			<div className="statistic__section">
				<div className="lk-container">
					<Attendance />
				</div>
			</div>
		</div>
	)
}
