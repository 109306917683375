import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as KnowledgeArrowRightGray } from '../../static/img/icons/knowledge-arrow-right-gray.svg'
import { normalizeDate } from 'utils/helpers'

export const TestsItem = ({ title, updated_at, test_id }) => {
	return (
		<div className="tests__wrap">
			<a
				href={`${process.env.REACT_APP_FRONTEND_URL}test/${test_id}`}
				target="_blank"
				rel="nofollow noopener noreferrer"
				className="tests__item"
			>
				<div className="tests__content">
					<div className="tests__title">{title}</div>
					<div className="tests__footer">
						<div className="tests__date">{normalizeDate(updated_at)}</div>
						<div className="tests__link">
							<KnowledgeArrowRightGray className="tests__icon" />
						</div>
					</div>
				</div>
			</a>
		</div>
	)
}
