import React from 'react'
import { useSelector } from 'react-redux'
import { BackBtn } from '../components/Buttons/BackBtn/BackBtn'
import { Button } from '../components/Buttons/Button'
import S from '../static/img/payment-success-icon.svg'

export default function Success() {
	const { isAuth } = useSelector((state) => state.user)
	return (
		<div className="lk-container payment-status payment-status_success">
			<div className="payment-status__img">
				<img src={S} alt="icon" />
			</div>
			<div className="payment-status__container">
				<h4 className="lk-container-title h4">Оплата прошла успешно</h4>
				<div className="payment-status__body">
					<div className="p6 payment-status__text">
						<p>Информация будет выслана вам на почту в ближайшее время.</p>
						{isAuth ? (
							<p>
								<b>На следующий день после прохождения игры</b> на ваш аккаунт будут начислены бонусные баллы.
							</p>
						) : (
							<p>
								Оплата была <b>без регистрации</b>, поэтому, к сожалению, мы не сможем сформировать баллы, подарки и
								скидки на предстоящие игры. В следующий раз не забудьте <b>войти или зарегистрироваться!</b>
							</p>
						)}
					</div>
					<div className="payment-status__buttons">
						<Button
							type="navlink"
							href="/"
							text="Перейти в профиль"
							className="lk-container-empty__btn button large yellow"
						/>
						<Button
							type="link"
							href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
							text="Вернуться к мероприятиям"
							className="lk-container-empty__btn button large lightyellow"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
