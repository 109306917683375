import React from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import { useForm, FormProvider, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ProfileMainFormSchema } from '../../../utils/validations'
import Select, { components } from 'react-select'
import { FormField } from '../../FormField'
import { Button } from '../../Buttons/Button'
import { CallNamesModal } from '../../Modals/CallNamesModal'

import { ReactComponent as HistoryIcon } from '../../../static/img/icons/history-icon-small-black.svg'
import { CustomDatePicker } from '../../CustomDatePicker/CustomDatePicker'
import { getProfileCallnames } from '../../../services/Config'
import { useActions } from '../../../hooks/useActions'
import { AddressSuggestions } from 'react-dadata'

const citys = [
	{ value: 'Москва', label: 'Москва' },
	{ value: 'Санкт-Петербург', label: 'Санкт-Петербург' },
	{ value: 'Нижний Новгород', label: 'Нижний Новгород' },
	{ value: 'Казань', label: 'Казань' },
	{ value: 'Екатеринбург', label: 'Екатеринбург' },
	{ value: 'Новосибирск', label: 'Новосибирск' },
	{ value: 'Самара', label: 'Самара' },
	{ value: 'Казань', label: 'Казань' },
	{ value: 'Ростов-на-Дону', label: 'Ростов-на-Дону' },
	{ value: 'Владивосток', label: 'Владивосток' },
	{ value: 'Красноярск', label: 'Красноярск' },
	{ value: 'Воронеж', label: 'Воронеж' },
	{ value: 'Волгоград', label: 'Волгоград' },
]

// const callnames = [
// 	{
// 		id: 1,
// 		callname: 'Перехватчик',
// 		edited_at: '2021-02-19 16:45:00',
// 	},
// 	{
// 		id: 1,
// 		callname: 'Патриот',
// 		edited_at: '2021-04-18 16:45:00',
// 	},
// 	{
// 		id: 1,
// 		callname: 'Восьмой',
// 		edited_at: '2021-07-08 16:45:00',
// 	},
// 	{
// 		id: 1,
// 		callname: 'Термит',
// 		edited_at: '2021-11-25 16:45:00',
// 	},
// ]

export const Main = () => {
	const [openCallnamesModal, setOpenCallNamesModal] = React.useState(false)
	const [callnames, setCallnames] = React.useState([])
	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)
	const { editProfileAction } = useActions()

	React.useEffect(() => {
		const fetchCallnames = async () => {
			const res = await getProfileCallnames()
			if (res.status === 200) {
				setCallnames(res.data)
			}
		}

		fetchCallnames()
	}, [userState.callname])

	const showCallNamesModal = () => {
		setOpenCallNamesModal(true)
	}
	const hideCallNamesModal = () => {
		setOpenCallNamesModal(false)
	}

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(ProfileMainFormSchema),
	})

	const {
		register,
		control,
		formState: { errors },
		setValue,
	} = form

	React.useEffect(() => {
		setValue('callname', userState.callname)
		setValue('email', userState.email)
		setValue('name', userState.name)

		if (userState.lastname) {
			setValue('surname', userState.lastname)
		}
		if (userState.city) {
			setValue('city', {
				label: userState.city,
				value: userState.city,
			})
		}
	}, [])

	const onSubmit = (data) => {
		const formData = {
			callname: data.callname,
			email: data.email,
			name: data.name,
			lastname: data.surname,
			birthday: new Date(data.birthday).toISOString(),
			city: data.city.value,
		}
		editProfileAction(formData)
	}

	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="profile__form profile__form-main">
					<div className="profile__form-main-body">
						<div className="profile__form-fields form-fields">
							<div className="form-row double">
								<FormField elem={'input'} name={'callname'} label={'Позывной'} />
								<FormField elem={'input'} name={'email'} label={'E-mail'} />
							</div>
							{callnames.length > 1 && (
								<Button
									onClick={() => showCallNamesModal()}
									type="span"
									className="profile__form-callnames-btn button medium lightgreen"
									Icon={HistoryIcon}
									iconSide="left"
									text="История позывных"
								/>
							)}
						</div>
						<div className="profile__form-fields form-fields --profile-data">
							<FormField elem={'input'} name={'name'} label={'Имя'} />
							<div>
								<CustomDatePicker
									label="Дата рождения"
									name="birthday"
									defaultValue={userState.birthday ? new Date(userState.birthday) : ''}
								/>
							</div>
							<FormField elem={'input'} name={'surname'} label={'Фамилия'} />
							<div
								className={clsx('select__form-row select__citys custom-select__wrap', {
									['error']: form.formState.errors['city'],
								})}
							>
								{/* <Controller
									render={({ field: { value, name, onChange }, ref }) => {
										return (
											<Select
												{...form.register('city')}
												options={citys}
												closeMenuOnSelect={true}
												inputRef={ref}
												classNamePrefix="custom-select"
												id="user_city"
												instanceId="user_city"
												onChange={onChange}
												placeholder=" "
												name={name}
												value={value || null}
											/>
										)
									}}
									name="city"
									defaultValue={''}
									options={citys}
								/> */}
								<Controller
									render={({ field: { value, name, onChange }, ref }) => {
										return (
											<AddressSuggestions
												{...form.register('city')}
												inputRef={ref}
												token={process.env.REACT_APP_DADATA_API_KEY}
												value={value || null}
												onChange={onChange}
												delay={300}
												minChars={3}
												inputProps={{
													placeholder: 'Город',
													name,
												}}
												containerClassName="input-box__dadata"
											/>
										)
									}}
									name="city"
									defaultValue={''}
								/>
								{form.formState.errors['city'] && (
									<label className="input-box__error">{form.formState.errors['city']?.message}</label>
								)}
							</div>
						</div>
					</div>

					<div className="profile__form-footer">
						<Button type="button" className="profile__form-btn button yellow" text="Сохранить" />
					</div>
				</form>
				<CallNamesModal isShown={openCallnamesModal} hideModal={hideCallNamesModal} callnames={callnames} />
			</FormProvider>
		</>
	)
}
