import clsx from 'clsx'
import React from 'react'

import { useFormContext } from 'react-hook-form'

export const FormField = ({ children, elem, inputType, autocomplete, name, label, className, autoFocus }) => {
	const { register, formState } = useFormContext()

	const [passwordShown, setPasswordShown] = React.useState(false)

	return (
		<>
			{elem === 'input' && inputType === 'password' ? (
				<div className={className ? 'input-box' + ' ' + className : 'input-box'}>
					<input
						{...register(name)}
						type={passwordShown ? 'text' : 'password'}
						id={name}
						name={name}
						className={clsx('input-box__input', formState.errors[name] && 'error')}
						placeholder=" "
						autoComplete={autocomplete ? autocomplete : ''}
						autoFocus={autoFocus}
					/>
					{label && (
						<label htmlFor={name} className="input-box__label">
							{label}
						</label>
					)}
					{/* {
						!inputType === "password" && (
							<span className="input-box__error-icon"></span>
						)
					} */}
					<span
						onClick={() => setPasswordShown((prev) => !prev)}
						className={clsx('input-box__password-switcher', { ['--shown']: passwordShown })}
					/>
					{formState.errors[name] && <label className="input-box__error">{formState.errors[name]?.message}</label>}
				</div>
			) : elem === 'input' ? (
				<div className={className ? 'input-box' + ' ' + className : 'input-box'}>
					<input
						{...register(name)}
						type="text"
						id={name}
						name={name}
						className={clsx('input-box__input', formState.errors[name] && 'error')}
						placeholder=" "
						autoComplete="off"
						autoFocus={autoFocus}
					/>
					{label && (
						<label htmlFor={name} className="input-box__label">
							{label}
						</label>
					)}
					{/* <span className="input-box__error-icon"></span> */}
					{formState.errors[name] && <label className="input-box__error">{formState.errors[name]?.message}</label>}
				</div>
			) : elem === 'inputNative' ? (
				<div className={className ? 'input-box' + ' ' + className : 'input-box'}>
					<input
						{...register(name)}
						type="text"
						id={name}
						name={name}
						className={clsx('input-box__input', formState.errors[name] && 'error')}
						placeholder={label}
						autoComplete="off"
						autoFocus={autoFocus}
					/>
					{formState.errors[name] && <label className="input-box__error">{formState.errors[name]?.message}</label>}
				</div>
			) : elem === 'textarea' ? (
				<div className={className ? 'textarea-box' + ' ' + className : 'textarea-box'}>
					<textarea
						{...register(name)}
						type="text"
						id={name}
						name={name}
						className={clsx('textarea-box__textarea', formState.errors[name] && 'error')}
						placeholder=" "
						autoComplete="off"
						autoFocus={autoFocus}
					/>
					{label && (
						<label htmlFor={name} className="textarea-box__label">
							{label}
						</label>
					)}
					{formState.errors[name] && <label className="input-box__error">{formState.errors[name]?.message}</label>}
				</div>
			) : (
				<div className={className ? 'checkbox-box' + ' ' + className : 'checkbox-box'}>
					<input
						{...register(name)}
						type="checkbox"
						id={name}
						name={name}
						className={clsx('checkbox-box__input', formState.errors[name] && 'error')}
						autoFocus={autoFocus}
					/>
					<label htmlFor={name} className="checkbox-box__label">
						{children}
						<span className="checkbox-box__custom"></span>
					</label>
					{formState.errors[name] && <label className="input-box__error">{formState.errors[name]?.message}</label>}
				</div>
			)}
		</>
	)
}
