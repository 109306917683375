import React from 'react'
import { authWithVk } from 'services/Config'
import { notifyUser, notifyUserError } from 'services/Pnotify'
import { ReactComponent as VIcon } from '../../static/img/icons/v-auth-icon.svg'
import { userTypes } from 'store/types/user'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { routeNames } from 'router'
import { useNavigate } from 'react-router-dom'

export const Social = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const socialAuth = () => {
		//eslint-disable-next-line no-undef
		VK.Auth.login(async (r) => {
			if (r.status === 'connected') {
				try {
					dispatch({
						type: userTypes.SET_USER_LOADING,
						payload: true,
					})
					const res = await authWithVk(r.session)
					Cookies.set('prv_token', res.data.access_token, { expires: 7, domain: process.env.REACT_APP_COOKIE_DOMAIN })
					Cookies.set('prv_token_expires', Date.now() + 300000) // записываю время, которое больше текущего на 10 минут(через 10 минут апдейит токена)
					dispatch({
						type: userTypes.SET_USER_DATA,
						payload: res.data,
					})
					navigate(routeNames.PROFILE.path)
					notifyUser('Вы успешно вошли в аккаунт')
				} catch (error) {
					notifyUserError('Не удалось войти через ВКонтакте')
				} finally {
					dispatch({
						type: userTypes.SET_USER_LOADING,
						payload: false,
					})
				}
			}
		})
	}

	return (
		<div className="auth__social">
			<div className="auth__social-title p5">Или используйте социальные сети:</div>
			<div className="auth__social-btns">
				<span className="auth__social-btn" onClick={() => socialAuth()}>
					<VIcon />
				</span>
			</div>
		</div>
	)
}
