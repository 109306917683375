import React from 'react'

import { ReactComponent as PaginationArrowLeft } from '../../static/img/icons/pagination-arrow-left-black.svg'
import { ReactComponent as PaginationArrowRight } from '../../static/img/icons/pagination-arrow-right-black.svg'

export const Pagination = ({ changePage, page, length, limit, finalPages = false }) => {
	const paginationLength = Math.ceil(length / limit)

	return (
		<div className="pagination__items">
			{page > 1 && (
				<span onClick={() => changePage(page === 1 ? 1 : page - 1)} className="pagination__item with-arrow prev p13">
					<PaginationArrowLeft className="pagination__item-icon" />
				</span>
			)}
			{finalPages && page > 3 && (
				<span
					id={1}
					onClick={() => changePage(1)}
					className="pagination__item p13"
					title="кнопка постраничной пагинации"
				>
					1
				</span>
			)}
			{page > 2 && <span className="pagination__item more p13">...</span>}

			{page > 1 && (
				<span id={page - 1} onClick={() => changePage(page - 1)} className="pagination__item p13">
					{page - 1}
				</span>
			)}

			<span id={page} onClick={() => changePage(page)} className="pagination__item p13 active">
				{page}
			</span>

			{page < paginationLength && (
				<span id={page + 1} onClick={() => changePage(page + 1)} className="pagination__item p13">
					{page + 1}
				</span>
			)}
			{page < paginationLength - 1 && <span className="pagination__item more p13">...</span>}
			{finalPages && page < paginationLength - 1 && (
				<span
					id={paginationLength}
					onClick={() => changePage(paginationLength)}
					className="pagination__item p13"
					title="кнопка постраничной пагинации"
				>
					{paginationLength}
				</span>
			)}
			{page < paginationLength && (
				<span
					onClick={() => changePage(page === length ? length : page + 1)}
					className="pagination__item with-arrow next p13"
				>
					<PaginationArrowRight className="pagination__item-icon" />
				</span>
			)}
		</div>
	)
}
