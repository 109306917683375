import clsx from 'clsx'
import React from 'react'
import { routeNames } from '../../../router'
import { Button } from '../../Buttons/Button'

const about = true

export const About = ({ bio, anotherUser }) => {
	const [open, setOpen] = React.useState(false)
	const [isBtnShown, setIsBtnShown] = React.useState(false)
	const [activeHeight, setActiveHeight] = React.useState(null)

	const textWrapperRef = React.useRef()
	const textRef = React.useRef()

	React.useEffect(() => {
		if (bio) {
			const textWrapperHeight = textWrapperRef.current.clientHeight
			const textHeight = textRef.current.clientHeight
			const showBtn = textHeight > textWrapperHeight

			setIsBtnShown(showBtn)
			setActiveHeight(textHeight)
		}
	}, [])

	return (
		<div className="lk-container">
			<div className="lk-container__title">
				<h4 className="lk-container-title h4">О себе</h4>
			</div>
			<div className="lk-container__content">
				{bio ? (
					<div className="lk-container__about-wrap">
						<div
							className="lk-container__about"
							ref={textWrapperRef}
							style={open && activeHeight ? { height: activeHeight } : {}}
						>
							<div className="lk-container__about-text p4 no-wrap" ref={textRef}>
								{bio}
							</div>
						</div>
						{isBtnShown && (
							<button
								onClick={() => setOpen(!open)}
								className={clsx('lk-container__about-btn lk-container__toggle-btn p7', open && 'active')}
								title="кнопка свернуть/развернуть текст о себе"
							>
								<span className="lk-container__toggle-btn-arrow" />
								{open ? 'свернуть' : 'развернуть'}
							</button>
						)}
					</div>
				) : anotherUser ? (
					<div className="lk-container__about-empty lk-container-empty">
						<div className="lk-container-empty__text p4">Информация о себе не указана</div>
					</div>
				) : (
					<div className="lk-container__about-empty lk-container-empty">
						<div className="lk-container-empty__text p4">
							Вы не заполнили информацию о себе <br />
							Заполните все поля профиля и получите 30 баллов
						</div>
						<div className="lk-container-empty__btns">
							<Button
								type="navlink"
								href={routeNames.PROFILEEDIT.path}
								className="lk-container-empty__btn button large yellow"
								text="Редактировать профиль"
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
