import React from 'react'

import { useNavigate } from 'react-router-dom'
import { routeNames } from '../../router'

import { Button } from '../Buttons/Button'
import { ReactComponent as Logo } from '../../static/img/icons/register-success-logo.svg'

import { motion } from 'framer-motion'

export const RegistrationSuccess = () => {
	const navigate = useNavigate()

	const showLoginForm = () => {
		navigate(routeNames.LOGIN.path)
	}

	return (
		<motion.div animate={{ opacity: 1, x: 0 }} initial={{ opacity: 0, x: '-10%' }} className="register-success">
			<Logo className="register-success__logo" />
			<div className="register-success__text p6">
				{/* <p>На почту было отправленно подтверждающее письмо.</p>
				<p>Пройдите по ссылке из письма, чтобы завершить регистрацию</p> */}
				<p>Используйте свой логин и пароль для входа в личный кабинет портала.</p>
			</div>
			<Button type="span" text="Войти" className="register-success__btn button yellow large" onClick={showLoginForm} />
		</motion.div>
	)
}
