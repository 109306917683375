import React from 'react'
import clsx from 'clsx'
import { ReactComponent as PublishArticles } from '../../static/img/icons/articles-publish-modal-icon.svg'
import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from './Portal'
import { Button } from '../Buttons/Button'

const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}

export const AddArticleModalPublish = ({ isShow, hideModal }) => {
	React.useEffect(() => {
		const handleEscKey = (e) => {
			if (e.keyCode === 27) {
				hideModal()
			}
		}
		window.addEventListener('keydown', handleEscKey)
		return () => window.removeEventListener('keydown', handleEscKey)
	}, [])

	return (
		<AnimatePresence exitBeforeEnter>
			{isShow && (
				<Portal>
					<motion.div
						className={clsx('pp', isShow && 'show')}
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<div className="pp__bg" onClick={hideModal}></div>
						<motion.div className="pp__content pp__article-note" variants={modal}>
							<button className="pp__close" onClick={hideModal} title="кнопка закрыть модальное окно">
								<span className="close__lane" />
								<span className="close__lane" />
							</button>

							<div className="pp__content-head pp__article-head">
								<div className="pp__article-note__title h4">Статья на модерации</div>
							</div>
							<div className="pp__content-body">
								<div className="pp__article-note__body">
									<div className="pp__article-note__icon">
										<PublishArticles />
									</div>
									<div className="pp__article-note__text">
										Когда статья будет опубликована вам придет оповещение и будут зачислены бонусы
									</div>
									<div className="pp__article-note__button">
										<Button type={'button'} onClick={hideModal} text="отлично" className="button yellow" />
									</div>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</Portal>
			)}
		</AnimatePresence>
	)
}
