import React from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import { Portal } from './Portal'
import { Button } from '../Buttons/Button'
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, CartesianGrid } from 'recharts'
import { UserStatisticDelete } from './UserStatisticDelete'
import { getTeamUserStatistics, deleteTeamMember } from 'services/Config'
import { notifyUser, notifyUserError } from 'services/Pnotify'
import { LoaderMini } from 'components/LoaderMini.jsx'
import { dateForRequest, declOfNum, normalizedImageSrc } from 'utils/helpers'
import DefaultAvatar from 'static/img/user_default.png'
import Image1 from 'static/img/icons/points-image-1.png'
import Image2 from 'static/img/icons/points-image-2.png'
import Image3 from 'static/img/icons/points-image-3.png'
import Image4 from 'static/img/icons/points-image-4.png'
const backdrop = {
	visible: { opacity: 1, visibility: 'visible' },
	hidden: { opacity: 0, visibility: 'hidden' },
}

const modal = {
	hidden: { opacity: 0, visibility: 'hidden' },
	visible: {
		opacity: 1,
		visibility: 'visible',
		transition: { delay: 0.2 },
	},
}
export const UserStatistic = ({ isShow, hideModal, user, setUser, fetchAttendance }) => {
	const [data, setData] = React.useState(null)
	const [userModalDel, setUserModalDel] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(true)
	const [userStat, setUserStat] = React.useState({})
	const calcAverage = (arr) => Math.round(arr.reduce((acc, num) => acc + num.count, 0) / arr.length)
	const calcMax = (arr) => Math.max(...arr.map((item) => item.count))
	const calcMin = (arr) => Math.min(...arr.map((item) => item.count))
	const getAge = () => Math.floor((new Date().getTime() - new Date(user.birthday)) / (24 * 3600 * 365.25 * 1000))
	const fetchStatistic = async () => {
		try {
			setIsLoading(true)
			const res = await getTeamUserStatistics(user.id)
			setUserStat(res.data)
			const arr = []
			if (res.data?.games_count.length) {
				res.data?.games_count.map((item) => {
					arr.push({ time: item.year, count: item.count })
				})
			}
			setData(arr)
		} catch (e) {
			hideModal()
			setUser(null)
			notifyUserError('Не удалось получить статистику пользователя')
		} finally {
			setIsLoading(false)
		}
	}
	const openUserModalDel = () => {
		setUserModalDel(true)
	}
	const hideUserModalDel = () => {
		setUserModalDel(false)
	}
	const deleteFromTeam = async () => {
		try {
			const res = await deleteTeamMember([user.id])
			notifyUser('Игрок исключен из команды')
			fetchAttendance()
		} catch (error) {
			notifyUserError('Не удалось исключить игрока')
		}
	}
	const removeUser = () => {
		deleteFromTeam()
		hideModal()
		hideUserModalDel()
	}
	React.useEffect(() => {
		fetchStatistic()
	}, [user])
	const CustomTooltip = (object) => (
		<div className="tooltip-statistic">
			<p className="tooltip-statistic__time p8">{object.label} год</p>
			<p className="tooltip-statistic__count p8">
				<b>{object?.payload[0]?.payload?.count}</b> игр
			</p>
		</div>
	)
	return (
		<>
			<AnimatePresence exitBeforeEnter>
				{isShow && (
					<Portal>
						<motion.div
							className={clsx('pp', isShow && 'show')}
							variants={backdrop}
							initial="hidden"
							animate="visible"
							exit="hidden"
						>
							<div className="pp__bg" onClick={hideModal}></div>
							<motion.div className="pp__content user-statistic" variants={modal}>
								<button
									className="pp__close user-statistic__close"
									onClick={hideModal}
									title="кнопка закрыть модальное окно"
								>
									<span className="close__lane" />
									<span className="close__lane" />
								</button>
								{isLoading ? (
									<LoaderMini />
								) : (
									<div className="user-statistic__body">
										<div className="user-statistic__aside aside-user-statistic">
											<div className="aside-user-statistic__avatar">
												<img src={user.avatar ? normalizedImageSrc(user.avatar) : DefaultAvatar} alt="user avatar" />
											</div>
											<div className="aside-user-statistic__content">
												<div className="aside-user-statistic__info">
													<div className="aside-user-statistic__data">
														<div className="aside-user-statistic__name p4">
															{user.name || 'Я'} {user.lastname || ''}
														</div>
														{user.callname && <div className="aside-user-statistic__callname h5">{user.callname}</div>}
														<div className="aside-user-statistic__points p9">
															{user.point > 0
																? user.point + ' ' + declOfNum(user.point, ['балл', 'балла', 'баллов'])
																: '0 баллов'}
														</div>
													</div>
													<div className="aside-user-statistic__desc p13">
														<div className="aside-user-statistic__row">
															{user.birthday && (
																<div className="aside-user-statistic__age">
																	{getAge()} {declOfNum(getAge(), ['год', 'года', 'лет'])}
																	{(user.country || user.start_year) && ','}
																</div>
															)}
															{user.country && (
																<div className="aside-user-statistic__city">
																	{user.country}
																	{user.start_year && ','}
																</div>
															)}
														</div>
														{user.start_year && (
															<div className="aside-user-statistic__since">
																в страйкболе с {user.start_year.split('-')[0]}г
															</div>
														)}
													</div>
												</div>
												<Button
													type={'button'}
													className={'aside-user-statistic__button button lightyellow'}
													text="Исключить из команды"
													onClick={openUserModalDel}
												/>
											</div>
										</div>
										<div className="user-statistic__wrapper">
											<div className="user-statistic__since p14">
												В команде с {dateForRequest(userStat.since).split('-').reverse().join('.')}
											</div>
											<div className="user-statistic__games games-user-statistic">
												<div className="games-user-statistic__body">
													<div className="games-user-statistic__left">
														<div className="games-user-statistic__title p7">Количество посещенных игр</div>
														<div className="games-user-statistic__count p9">{userStat?.games?.total_count}</div>
													</div>
													<div className="games-user-statistic__cards">
														{userStat?.games?.list.map((item) => (
															<div className="card-games-user-statistic" key={item.id}>
																<div className="card-games-user-statistic__bg">
																	<img
																		src={
																			item.id === 4
																				? Image3
																				: item.id === 3
																				? Image4
																				: item.id === 2
																				? Image1
																				: item.id === 1
																				? Image2
																				: null
																		}
																		title="фото мероприятия"
																		alt="bg"
																	/>
																</div>
																<div className="card-games-user-statistic__body">
																	<div className="card-games-user-statistic__head">
																		<div className="card-games-user-statistic__title p7">{item.title}</div>
																	</div>
																	<div className="card-games-user-statistic__count p9">{item.count}</div>
																</div>
															</div>
														))}
													</div>
												</div>
											</div>
											<div className="user-statistic__chart chart-user-statistic">
												<div className="chart-user-statistic__title p7">Количество игр в составе команды</div>
												{data.length ? (
													<div className="chart-user-statistic__body">
														<div className="chart-user-statistic__graph">
															<ResponsiveContainer width="100%" height="100%">
																<AreaChart data={data}>
																	<XAxis
																		dataKey="time"
																		stroke="#ADADAB"
																		tick={{ fontSize: 12, transform: 'translate(0, 15)' }}
																	/>
																	<YAxis
																		stroke="#ADADAB"
																		tick={{ fontSize: 12, paggingRight: '20px', transform: 'translate(-20, 0)' }}
																	/>
																	<CartesianGrid vertical={false} stroke="#ADADAB" />
																	<Tooltip content={<CustomTooltip />} />
																	<defs>
																		<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
																			<stop offset={0} stopColor="#E78346" stopOpacity={1} />
																			<stop offset={1} stopColor="#E78346" stopOpacity={0.1} />
																		</linearGradient>
																	</defs>
																	<Area type="monotone" dataKey="count" stroke="#E78346" fill="url(#splitColor)" />
																</AreaChart>
															</ResponsiveContainer>
														</div>
														<div className="chart-user-statistic__aside">
															<div className="chart-user-statistic__item">
																<div className="chart-user-statistic__text p8">Среднее количество игр</div>
																<div className="chart-user-statistic__count h8">{calcAverage(data)}</div>
															</div>
															<div className="chart-user-statistic__item">
																<div className="chart-user-statistic__text p8">Максимальное количество игр</div>
																<div className="chart-user-statistic__count h8">{calcMax(data)}</div>
															</div>
															<div className="chart-user-statistic__item">
																<div className="chart-user-statistic__text p8">Минимальное количество игр</div>
																<div className="chart-user-statistic__count h8">{calcMin(data)}</div>
															</div>
														</div>
													</div>
												) : (
													<div className="p2">Ещё не играл в составе команды</div>
												)}
											</div>
											<div className="user-statistic__teams teams-user-statistic">
												<div className="teams-user-statistic__title p7">До этого состоял в командах:</div>
												{userStat.commands.length > 0 ? (
													<div className="teams-user-statistic__body">
														{userStat.commands.map((item, idx) => (
															<div className="teams-user-statistic__col" key={idx}>
																<div className="teams-user-statistic__item">
																	<div className="teams-user-statistic__avatar">
																		<img
																			src={item.avatar ? normalizedImageSrc(item.avatar) : DefaultAvatar}
																			alt="team avatar"
																		/>
																	</div>
																	<div className="teams-user-statistic__info">
																		<div className="teams-user-statistic__name p3">{item.name}</div>
																		<div className="teams-user-statistic__captain p6">
																			{item.position === 'member' ? 'член команды' : 'Капитан'}
																		</div>
																	</div>
																</div>
															</div>
														))}
													</div>
												) : (
													<div className="teams-user-statistic__empty p2">Раньше не состоял в командах</div>
												)}
											</div>
										</div>
									</div>
								)}
							</motion.div>
						</motion.div>
					</Portal>
				)}
			</AnimatePresence>
			<UserStatisticDelete isOpen={userModalDel} hide={hideUserModalDel} removeUser={removeUser} />
		</>
	)
}
