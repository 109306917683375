import React, { useEffect, useState } from 'react'
import { Switcher } from 'components/Switcher/Switcher'
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts'
import { formateDate } from 'utils/helpers'

export const PlayersChart = ({ players }) => {
	const [data, setData] = useState(null)
	const [switcher, setSwitcher] = useState('year')
	const changeSwitcher = (newValue) => {
		setSwitcher(newValue)
	}
	const calcAverage = (arr) => Math.round(arr.reduce((acc, num) => acc + num.players_count, 0) / arr.length)
	const calcMax = (arr) => Math.max(...arr.map((item) => item.players_count))
	const calcMin = (arr) => Math.min(...arr.map((item) => item.players_count))
	useEffect(() => {
		const arr = []
		if (Object.values(players.for_year).length > 0) {
			switcher === 'year'
				? Object.values(players.for_year)
						.reverse()
						.map((item) => {
							arr.push({ time: formateDate(item.date_start, 'dd MMM'), count: item.players_count })
						})
				: Object.values(players.all_along)
						.reverse()
						.map((item) => {
							arr.push({ time: formateDate(item.date_start, 'dd MMM'), count: item.players_count })
						})
			setData(arr)
		}
	}, [switcher])
	const CustomTooltip = (object) => (
		<div className="tooltip-statistic">
			<p className="tooltip-statistic__time p8">{object.label}</p>
			<p className="tooltip-statistic__count p8">
				Игроков: <b>{object?.payload[0]?.payload?.count}</b>
			</p>
		</div>
	)
	return (
		<div className="players-statistic">
			<div className="players-statistic__header">
				<div className="players-statistic__title h4">Количество игроков на играх</div>
				{data && (
					<div className="players-statistic__switcher">
						<Switcher
							className="switcher"
							values={[
								{ name: 'За год', value: 'year' },
								{ name: 'За все время', value: 'all' },
							]}
							value={switcher}
							changeValue={changeSwitcher}
						/>
					</div>
				)}
			</div>
			<div className="players-statistic__body">
				{data ? (
					<>
						<div className="players-statistic__chart">
							<ResponsiveContainer width="100%" height="100%">
								<AreaChart data={data}>
									<XAxis dataKey="time" stroke="#ADADAB" tick={{ fontSize: 12, transform: 'translate(0, 15)' }} />
									<YAxis
										stroke="#ADADAB"
										tick={{ fontSize: 12, paggingRight: '20px', transform: 'translate(-20, 0)' }}
									/>
									<Tooltip content={<CustomTooltip />} />
									<defs>
										<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
											<stop offset={0} stopColor="#E78346" stopOpacity={1} />
											<stop offset={1} stopColor="#E78346" stopOpacity={0.1} />
										</linearGradient>
									</defs>
									<Area type="monotone" dataKey="count" stroke="#E78346" fill="url(#splitColor)" />
								</AreaChart>
							</ResponsiveContainer>
						</div>
						<div className="players-statistic__aside aside-players-statistic">
							<div className="aside-players-statistic__item">
								<div className="aside-players-statistic__text p8">Среднее количество игроков на играх</div>
								<div className="aside-players-statistic__count h8">
									{switcher === 'year'
										? calcAverage(Object.values(players.for_year))
										: calcAverage(Object.values(players.all_along))}
								</div>
							</div>
							<div className="aside-players-statistic__item">
								<div className="aside-players-statistic__text p8">Максимальное количество игроков на играх</div>
								<div className="aside-players-statistic__count h8">
									{switcher === 'year'
										? calcMax(Object.values(players.for_year))
										: calcMax(Object.values(players.all_along))}
								</div>
							</div>
							<div className="aside-players-statistic__item">
								<div className="aside-players-statistic__text p8">Минимальное количество игроков на играх</div>
								<div className="aside-players-statistic__count h8">
									{switcher === 'year'
										? calcMin(Object.values(players.for_year))
										: calcMin(Object.values(players.all_along))}
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="players-statistic__empty p8">Ещё нет данных для статистики</div>
				)}
			</div>
		</div>
	)
}
