import React from 'react'

import { Button } from '../../Buttons/Button'
import { TeamCard } from '../TeamCard'
import { Pagination } from '../../Pagination/Pagination'
import { deleteTeamFriend, getTeamProfileFriends } from '../../../services/Config'
import { LoaderMini } from '../../LoaderMini'
import { notifyUser, notifyUserError } from '../../../services/Pnotify'
import { DeleteTeamFriendModal } from '../../Modals/DeleteTeamFriendModal'
import { useSelector } from 'react-redux'

export const Friends = ({ reRender }) => {
	const defaultLimit = 4
	const defaultOffset = 0
	const [page, setPage] = React.useState(1)
	const [teamFriends, setTeamFriends] = React.useState({})
	const [isLoad, setIsLoad] = React.useState(false)

	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [deletebleTeam, setDeletebleTeam] = React.useState(null)

	const {
		userData: { user: userState },
	} = useSelector((state) => state.user)

	const fetchFriends = async () => {
		try {
			setIsLoad(true)
			const limit = defaultLimit
			const offset = page !== 1 ? (page - 1) * defaultLimit : defaultOffset
			const res = await getTeamProfileFriends(limit, offset)
			setTeamFriends(res.data)
		} catch (error) {
			console.log('fetchFriends error', error)
		} finally {
			setIsLoad(false)
		}
	}

	React.useEffect(() => {
		if (reRender) fetchFriends()
	}, [reRender, page])

	const changePage = (num) => {
		setPage(num)
	}

	const deleteFromFriends = (team) => {
		setDeletebleTeam(team)
		showDeleteModal()
	}

	const onDelete = async () => {
		hideDeleteModal()
		try {
			const res = deleteTeamFriend(deletebleTeam.id)
			notifyUser('Команда удалена из друзей')
			fetchFriends()
		} catch (error) {
			notifyUserError('Ошибка при удалении из друзей')
		}
	}

	const showDeleteModal = () => {
		setOpenDeleteModal(true)
	}
	const hideDeleteModal = () => {
		setOpenDeleteModal(false)
	}

	return isLoad ? (
		<LoaderMini />
	) : (
		<>
			{teamFriends?.list?.length > 0 ? (
				<div className="teams__cards">
					{teamFriends?.list?.map((team) => (
						<TeamCard
							key={team.id}
							team={team}
							captain={userState.team.commander_id === userState.id}
							onDelete={deleteFromFriends}
							fromFriends="true"
						/>
					))}
				</div>
			) : (
				<div className="lk-container__friends-empty lk-container-empty">
					<div className="lk-container-empty__text p4">У вас пока нет дружественных команд.</div>
					<div className="lk-container-empty__btns">
						<Button
							className="lk-container-empty__btn button large yellow"
							type="link"
							href={process.env.REACT_APP_FRONTEND_URL + 'members?shownType=команды'}
							text="Смотреть все команды"
						/>
					</div>
				</div>
			)}

			{teamFriends.total_count > defaultLimit && (
				<div className="pagination">
					<Pagination changePage={changePage} page={page} length={teamFriends?.total_count} limit={defaultLimit} />
					<div className="pagination__count p13">
						Показано {defaultLimit * page >= teamFriends?.total_count ? teamFriends?.total_count : defaultLimit * page}{' '}
						из {teamFriends?.total_count}
					</div>
				</div>
			)}

			<DeleteTeamFriendModal
				isShown={openDeleteModal}
				hideModal={hideDeleteModal}
				deleteFriendCb={onDelete}
				team={deletebleTeam}
			/>
		</>
	)
}
