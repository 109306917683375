import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import user from './user'
import dialogs from './dialogs'
import friendsRequests from './friendsRequests'
import notifications from './notifications'
import singleDialog from './singleDialog'

export const rootReducer = combineReducers({
	routing: routerReducer,
	dialogs,
	user,
	friendsRequests,
	notifications,
	singleDialog,
})
