import { useActions } from 'hooks/useActions'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {getProfile, getProfileAchievements, getArticles, getPhotos} from 'services/Config'
import { userTypes } from 'store/types/user'

import { About } from '../components/Profile/Personal/About'
import { Achievement } from '../components/Profile/Personal/Achievement'
import { Game } from '../components/Profile/Personal/Game'
import { Review } from '../components/Profile/Personal/Review'
import { Titles } from '../components/Profile/Personal/Titles'
import { SideInfo } from '../components/User/SideInfo'
import { Articles } from 'components/Profile/Personal/Articles'
import Library from "../components/Library/Library";

export const UserProfile = () => {
	const { loadingData } = useActions()
	const dispatch = useDispatch()
	const { userData } = useSelector((state) => state.user)
	const [achievements, setAchievements] = useState({})
	const [articles, setArticles] = React.useState(null)

	const fetch = async () => {
		try {
			loadingData(true)
			const profileRes = await getProfile()
			const achRes = await getProfileAchievements()
			const res = await getArticles(4, 0)
			setArticles(res.data)
			setAchievements(achRes.data)
			dispatch({
				type: userTypes.SET_USER_DATA,
				payload: profileRes.data,
			})
		} catch (error) {
		} finally {
			loadingData(false)
		}
	}

	useEffect(() => {
		fetch()
	}, [])

	return (
		<section className="section-lk">
			{userData.user && (
				<div className="lk-cols">
					<div className="lk-col">
						<Titles />
						<Achievement achievements={achievements.user_achievements} />
						<About bio={userData.user.bio} />
						<div className="lk-container">
							<Library edit={false} />
						</div>
						<Game />
						<Review id={userData.user.last_review_id} userData={userData} />
						{articles?.list.length > 0 && <Articles articles={articles} userData={userData} fetch={fetch}/>}
					</div>

					<SideInfo friends={userData.friends} user={userData.user} />
				</div>
			)}
		</section>
	)
}
