import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper'
import clsx from 'clsx'
import { normalizedImageSrc } from '../utils/helpers'
import defaultLogo from '../static/img/user_default.png'

SwiperCore.use([Navigation])

// const filters = [
//   {
//     id: 0,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Перехватчик',
//   },
//   {
//     id: 1,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Charlie',
//   },
//   {
//     id: 2,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Foxtrot',
//   },
//   {
//     id: 3,
//     avatar: '',
//     name: 'Киловатт',
//   },
//   {
//     id: 4,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Перехватчик',
//   },
//   {
//     id: 5,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Жук',
//   },
//   {
//     id: 6,
//     avatar: '',
//     name: 'Перехватчик',
//   },
//   {
//     id: 7,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Вожатый ',
//   },
//   {
//     id: 8,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Foxtrot',
//   },
//   {
//     id: 9,
//     avatar: '',
//     name: 'Перехватчик',
//   },
//   {
//     id: 10,
//     avatar: 'users/qyCMkNvb4Jw.jpg',
//     name: 'Foxtrot',
//   },
// ]

export default function Filters({ filters, onClick, className }) {
	return (
		<Swiper
			className={'filters-swiper ' + className}
			spaceBetween={0}
			slidesPerView={'auto'}
			navigation={true}
			onAfterInit={function (swiper) {
				setTimeout(() => swiper.update(), 1000)
			}}
		>
			{filters?.map((filter, i) => (
				<SwiperSlide key={filter.id || i + filter.id} className="filters-item">
					<span className="filters-item__avatar">
						<img
							className="img_cover"
							src={filter.avatar ? normalizedImageSrc(filter.avatar) : defaultLogo}
							alt="фото-пользователя"
							title="фото-пользователя"
						/>
					</span>
					<span className="filters-item__name p7">{filter.name}</span>
					<span className="filters-item__btn" onClick={() => onClick(filter)} />
				</SwiperSlide>
			))}
		</Swiper>
	)
}
