import { Button } from 'components/Buttons/Button.jsx'
import React from 'react'
import { EventsItem } from './EventsItem.jsx'

export const Events = ({ events }) => {
	return (
		<div className="events-points">
			<div className="events-points__body">
				<div className="events-points__left">
					<div className="events-points__title h5">Награды за посещение игр</div>
					<Button
						type="link"
						href={process.env.REACT_APP_FRONTEND_URL + 'payment'}
						className="events-points__button button large yellow"
						text="Мероприятия"
					/>
				</div>
				<div className="events-points__cards">
					{events.map((event) => (
						<EventsItem key={event.id} image={event.image} icon={event.icon} count={event.count} text={event.text} />
					))}
				</div>
			</div>
		</div>
	)
}
