import clsx from 'clsx'
import React from 'react'

export const Switcher = ({ className, values, value, changeValue }) => {
	React.useEffect(() => {
		const toggleContainers = document.querySelectorAll('.switcher')
		toggleContainers.forEach((toggleContainer) => {
			const toggleBtn = toggleContainer.querySelector('.switcher__item.active') || null
			const toggleBg = toggleContainer.querySelector('.switcher__bg')

			const width = toggleBtn.getBoundingClientRect().width
			const btnLeft = toggleBtn.getBoundingClientRect().left
			const toggleContainerLeft = toggleContainer.getBoundingClientRect().left
			toggleBg.style.left = btnLeft - toggleContainerLeft - 1 + 'px'
			toggleBg.style.width = width + 2 + 'px'
		})
	}, [value])

	return (
		<div className={'switcher' + ' ' + className}>
			<button
				className={clsx('switcher__item', value === values[0].value ? 'active' : '')}
				onClick={() => changeValue(values[0].value)}
			>
				{values[0].name}
			</button>
			<button
				className={clsx('switcher__item', value === values[1].value ? 'active' : '')}
				onClick={() => changeValue(values[1].value)}
			>
				{values[1].name}
			</button>
			<span className="switcher__bg"></span>
		</div>
	)
}
