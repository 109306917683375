import React from 'react'
import { ArticleItem } from 'components/Articles/ArticleItem'
import { AddArticleModal } from 'components/Modals/AddArticleModal'

export const Articles = ({ articles, userData, profile, fetch }) => {
	const userAvatar = process.env.REACT_APP_IMAGES_URL + userData.user.avatar
	const callName = userData.user.callname || 'я'
	const name = userData.user.name || 'я'
	const lastname = userData.user.lastname
	const fullName = lastname !== undefined ? name + ' ' + lastname : name
	const [isAddArticleModal, setIsAddArticleModal] = React.useState(false)
	const [article, setArticle] = React.useState({})

	const showEditArticleModal = (article) => {
		setArticle(article)
		setIsAddArticleModal(true)
	}

	const hideAddArticleModal = () => {
		setArticle({})
		setIsAddArticleModal(false)
	}

	const fetchArticles = async () => {
		fetch()
	}

	return (
		<>
			{profile ? (
				<div className="lk-container">
					<div className="lk-container__title">
						<h4 className="lk-container-title h4">Статьи</h4>
					</div>
					<div className="lk-container__content articles-content">
						{articles?.articles.map((article) => (
							<ArticleItem article={article} userAvatar={userAvatar} callName={callName} key={article.id} />
						))}
					</div>
				</div>
			) : (
				<div className="lk-container">
					<div className="lk-container__title">
						<h4 className="lk-container-title h4">Статьи</h4>
					</div>
					<div className="lk-container__content articles-content">
						{articles?.list.map((article) => (
							<div key={article.id}>
								{article.status === 'moderate' ? 
								(
								<a onClick={() => showEditArticleModal(article)}>
									<ArticleItem article={article} userAvatar={userAvatar} callName={callName} />
								</a>)
								: (
									<ArticleItem article={article} userAvatar={userAvatar} callName={callName} />
								)}
							</div>
						))}
					</div>
				</div>
			)}
			<AddArticleModal
				isShow={isAddArticleModal}
				hideModal={hideAddArticleModal}
				userAvatar={userAvatar}
				callName={callName}
				fullName={fullName}
				article={article}
				// setPage={setPage}
				fetchArticles={fetchArticles}
			/>
		</>
	)
}
