import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'

export function declOfNum(number, titles) {
	const cases = [2, 0, 1, 1, 1, 2]
	return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export const normalizedImageSrc = (src) => {
	return process.env.REACT_APP_IMAGES_URL + src
}

export const onScrollDown = (e) => {
	e.preventDefault()

	let href = e.target.getAttribute('href').substring(1)

	const scrollTarget = document.getElementById(href)

	const topOffset = document.querySelector('.header').offsetHeight
	const elementPosition = scrollTarget.getBoundingClientRect().top
	const offsetPosition = elementPosition - topOffset

	window.scrollBy({
		top: offsetPosition,
		behavior: 'smooth',
	})
}

export const formateDate = (date, formatDate = 'dd.MM.yyyy') => {
	try {
		return format(parseISO(date), formatDate, { locale: ru })
	} catch (error) {
		return date
	}
}

export const normalizeDate = (date) => {
	try {
		return format(parseISO(date), 'dd MMMM yyyy', { locale: ru })
	} catch (error) {
		return date
	}
}

export const rangeOfDates = (date_start, date_end) => {
	try {
		if (format(parseISO(date_start), 'MMMM', { locale: ru }) === format(parseISO(date_end), 'MMMM', { locale: ru })) {
			return (
				format(parseISO(date_start), 'dd', { locale: ru }) + '-' + format(parseISO(date_end), 'dd MMMM', { locale: ru })
			)
		} else {
			return (
				format(parseISO(date_start), 'dd MMMM', { locale: ru }) +
				'-' +
				format(parseISO(date_end), 'dd MMMM', { locale: ru })
			)
		}
	} catch (error) {
		return date_start + ' - ' + date_end
	}
}
export const rangeOfDatesWithYear = (date_start, date_end) => {
	try {
		if (format(parseISO(date_start), 'MMMM', { locale: ru }) === format(parseISO(date_end), 'MMMM', { locale: ru })) {
			return (
				format(parseISO(date_start), 'dd', { locale: ru }) +
				'-' +
				format(parseISO(date_end), 'dd MMMM yyyy', { locale: ru })
			)
		} else {
			return (
				format(parseISO(date_start), 'dd MMMM', { locale: ru }) +
				'-' +
				format(parseISO(date_end), 'dd MMMM yyyy', { locale: ru })
			)
		}
	} catch (error) {
		return date_start + ' - ' + date_end
	}
}
export const dateForRequest = (stamp) => {
	const date = new Date(stamp).toISOString().split('T')[0]
	return date
}

let lastScrollTop = 0

export const scrollDirection = () => {
	let scrollDown = true
	const st = window.scrollY
	if (st > lastScrollTop) {
		scrollDown = true
	} else {
		scrollDown = false
	}
	lastScrollTop = st <= 0 ? 0 : st
	return scrollDown
}

export function overflowHidden() {
	document.body.style.overflow = 'hidden'
	document.body.style.overflow = 'hidden'
}
export function overflowVisible() {
	document.body.style.overflow = 'visible'
	document.body.style.overflow = 'visible'
}

export const gameRoles = [
	{ value: 0, label: 'Игрок' },
	{ value: 1, label: 'Водитель' },
	{ value: 2, label: 'Фотограф' },
	{ value: 3, label: 'Медик' },
	{ value: 4, label: 'NPC' },
]

export function setCookie(name, value, options = {}) {
	options = {
		path: '/',
		// при необходимости добавьте другие значения по умолчанию
		...options,
	}

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString()
	}

	let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

	for (let optionKey in options) {
		updatedCookie += '; ' + optionKey
		let optionValue = options[optionKey]
		if (optionValue !== true) {
			updatedCookie += '=' + optionValue
		}
	}

	document.cookie = updatedCookie
}

export function deleteCookie(name) {
	setCookie(name, '', {
		'max-age': -1,
	})
}

export function getCookie(name) {
	let matches = document.cookie.match(
		new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
	)
	return matches ? decodeURIComponent(matches[1]) : undefined
}

export const groupBy = (arr, fn) =>
	arr.map(typeof fn === 'function' ? fn : (val) => val[fn]).reduce((acc, val, i) => {
		acc[val] = (acc[val] || []).concat(arr[i])
		return acc
	}, {})

export const formatDay = (date) => {
	try {
		// return format(new Date(date.date), 'dd MMMM yyyy', { locale: ru })
		return format(parseISO(date.date), 'dd MMMM yyyy', { locale: ru })
	} catch (error) {
		return date
	}
}

export const isChatExist = (id, dialogs = []) => {
	const exist = dialogs.filter((d) => parseInt(id) === d.dialog_with_user)
	if (exist.length) {
		return exist[0].id
	}
	return false
}

export function distinct(array, /*IEqualityComparer<TSource>*/ comparer) {
	let count = array.length
	// if(!comparer) comparer = EqualityComparer.default(array[0]); // Для упрощения не используем компаратор по умолчанию
	let set = []
	for (let i = 0; i < count; ++i) {
		let item = array[i]
		if (!set.some((e) => comparer.equals(e, item))) {
			set.push(item)
		}
	}
	return set
}

export const getMonth = (num) => {
	const arr = [
		'01 янв',
		'01 фев',
		'01 мар',
		'01 апр',
		'01 май',
		'01 июнь',
		'01 июль',
		'01 авг',
		'01 сен',
		'01 окт',
		'01 нояб',
		'01 дек',
	]
	return arr[+num - 1]
}
