import React from 'react'
import { dateForRequest } from 'utils/helpers'
export const Dates = ({ dates }) => {
	return (
		<div className="dates-statistic">
			<div className="dates-statistic__title h4">Регистрация</div>
			<div className="dates-statistic__body">
				<div className="dates-statistic__col">
					<div className="dates-statistic__item">
						<div className="dates-statistic__label p7">На портале</div>
						<div className="dates-statistic__date h8">
							с {dateForRequest(dates[0]?.date).split('-').reverse().join('.')}
						</div>
					</div>
				</div>
				<div className="dates-statistic__col">
					<div className="dates-statistic__item">
						<div className="dates-statistic__label p7">Состоит в Клубе</div>
						<div className="dates-statistic__date h8">
							{dates[1] ? `c ${dateForRequest(dates[1]?.date).split('-').reverse().join('.')}` : 'нет'}
						</div>
					</div>
				</div>
				{/* <div className="dates-statistic__col">
					<div className="dates-statistic__item">
						<div className="dates-statistic__label p7">
							Покинули Клуб
						</div>
						<div className="dates-statistic__date h8">
							{dates[2] ? `${dateForRequest(dates[2]?.date).split("-").reverse().join(".")}` : "-"}
						</div>
					</div>
				</div>
				<div className="dates-statistic__col">
					<div className="dates-statistic__item">
						<div className="dates-statistic__label p7">
							Вернулись в Клуб
						</div>
						<div className="dates-statistic__date h8">
							{dates[3] ? `c ${dateForRequest(dates[3]?.date).split("-").reverse().join(".")}` : "-"}
						</div>
					</div>
				</div> */}
			</div>
		</div>
	)
}
